import React from "react";
import "../../../assets/css/filter.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { useAppDispatch } from "../../../redux/store";
import { fetchFaxData } from "../../../redux/store/FaxSliceReducer";
import refresh from '../../../assets/images/refreshicon.png'
import { FaxDirectory } from "../../../assets/images/Icon";
import { Tooltip } from "primereact/tooltip";
type FilterMeta = {
    value: string | null;
    matchMode: FilterMatchMode;
};

interface FilterdataProps {
    filtersCompo: React.ReactNode;
    setFilters?: React.Dispatch<React.SetStateAction<any>>;
    setStartDate?: React.Dispatch<React.SetStateAction<any>>;
    setEndDate?: React.Dispatch<React.SetStateAction<any>>;
}

type ViewType = 'Fax' | 'junk-fax' | 'archive';

const handleonclick = (
    dispatch: any,
    setFilters?: React.Dispatch<React.SetStateAction<any>>,
    setStartDate?: React.Dispatch<React.SetStateAction<any>>,
    setEndDate?: React.Dispatch<React.SetStateAction<any>>
) => {
    dispatch(fetchFaxData());

    // Resetting the filters
    if (setFilters) {
        setFilters((prev: any) => ({
            ...prev,
            global: { ...prev.global, value: null },
            fax_line: { ...prev.fax_line, value: null },
        }));
    }

    // Resetting the start date (if setStartDate is provided)
    setStartDate?.(() => null);
    setEndDate?.(() => null);
};

// const Filterdata: React.FC<FilterdataProps> = ({ filtersCompo, setFilters, setStartDate, setEndDate }) => {
//     const location = useLocation();
//     const path = location.pathname;
//     const dispatch = useAppDispatch();
//     const navigate = useNavigate();

//     return (
//         <div className="custom-form d-flex justify-content-between">
//             {filtersCompo}
//             <div className="d-flex align-items-center right-buttons mb-2 w-25 justify-content-end">
//                 <div className="ref-icon d-flex align-items-center me-2 cursor-pointer" onClick={() => handleonclick(dispatch, setFilters, setStartDate, setEndDate)}>
//                     {/* <i className="pi pi-sync" /> */}
//                     <img src={refresh} alt="refresh" width="28" height="25" />
//                 </div>
//                 <div onClick={() => navigate("/faxdirectory")} className="ref-icon d-flex align-items-center me-2 cursor-pointer">
//                     <Tooltip
//                         target=".fax-directory-icon"
//                         className="custom-tooltip"
//                     />
//                     {/* <i className="pi pi-sync" /> */}
//                     <img
//                     src={FaxDirectory}
//                     alt="fax-directory"
//                     width="30"
//                     height="28"
//                     className="fax-directory-icon"
//                     data-pr-tooltip={`Fax Directory`}
//                     data-pr-position="bottom"
//                     />
//                 </div>
//                 <Link
//                     to="/fax"
//                     className={`custom-fax-button fax-button ${path === "/fax" && "common-btn"}`}
//                 >
//                     Fax Screen
//                 </Link>
//                 <Link
//                     to="/fax/junk-fax"
//                     className={`custom-fax-button fax-button ${path === "/fax/junk-fax" && "common-btn"}`}
//                 >
//                     Junk Faxes
//                 </Link>
//                 <Link
//                     to="/fax/archive-fax"
//                     className={`custom-fax-button fax-button ${path === "/fax/archive-fax" && "common-btn"}`}
//                 >
//                     Archive
//                 </Link>
//             </div>
//         </div>
//     );
// };

const Filterdata = ({ filtersCompo, setFilters, setStartDate, setEndDate }: FilterdataProps) => {
    const location = useLocation();
    const path = location.pathname;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <div className="flex justify-content-between align-items-center">
            {filtersCompo}
            <div className="flex align-items-center right-buttons mb-2 w-6 justify-content-end">
                <div className="ref-icon flex align-items-center mr-2 cursor-pointer" onClick={() => handleonclick(dispatch, setFilters, setStartDate, setEndDate)}>
                    <img src={refresh} alt="refresh" width="28" height="25" />
                </div>
                <div onClick={() => navigate("/faxdirectory")} className="ref-icon flex align-items-center mr-2 cursor-pointer">
                    <Tooltip
                        target=".fax-directory-icon"
                        className="custom-tooltip"
                    />
                    <img
                        src={FaxDirectory}
                        alt="fax-directory"
                        width="30"
                        height="28"
                        className="fax-directory-icon"
                        data-pr-tooltip={`Fax Directory`}
                        data-pr-position="bottom"
                    />
                </div>
                <Link
                    to="/fax"
                    className={`custom-fax-button fax-button ${path === "/fax" && "common-btn"}`}
                >
                    Fax Screen
                </Link>
                <Link
                    to="/fax/junk-fax"
                    className={`custom-fax-button fax-button ${path === "/fax/junk-fax" && "common-btn"}`}
                >
                    Junk Faxes
                </Link>
                <Link
                    to="/fax/archive-fax"
                    className={`custom-fax-button fax-button ${path === "/fax/archive-fax" && "common-btn"}`}
                >
                    Archive
                </Link>
            </div>
        </div>
    );
};

// Exporting handleonclick for use in other components if needed
export { handleonclick };
export default Filterdata;
