import { Calendar } from "primereact/calendar";

const InputTime = ({
  value,
  label,
  id,
  labelclassName,
  inputclassName,
  handleChange,
  placeholder,
  invalid,
  requiredRight,
  minDate,
  maxDate,
}: {
  value?: Date | null;
  label?: string;
  id: string;
  labelclassName?: string;
  inputclassName?: string;
  handleChange?: (e: any) => void;
  placeholder?: string;
  invalid?: boolean;
  requiredRight?: boolean;
  minDate?: Date;
  maxDate?: Date;
}): React.ReactElement => {
  // Default minimum and maximum time if not provided by the user
  const defaultMinTime = new Date();
  defaultMinTime.setHours(9, 0, 0, 0); // 9:00 AM
  
  const defaultMaxTime = new Date();
  defaultMaxTime.setHours(17, 0, 0, 0); // 5:00 PM

  return (
    <>
      <div className="grid m-0 align-items-center">
        <div className={`${labelclassName ? `${labelclassName}` : "col-6"}`}>
          <label className="font-medium text-sm" htmlFor={id}>
            {label}{" "}
            {requiredRight ? <span className="text-danger">*</span> : null}
          </label>
        </div>
        <div className={`${inputclassName ? `${inputclassName}` : "col-6"}`}>
          <Calendar
            value={value}
            id={id}
            className={`w-full ${invalid ? "invalid-field" : ""}`}
            showIcon
            minDate={minDate || defaultMinTime} // Use user-defined minDate or default
            maxDate={maxDate || defaultMaxTime} // Use user-defined maxDate or default
            timeOnly
            hourFormat="24" // Optional: Change to "24" for a 24-hour clock
            onChange={handleChange}
            placeholder={placeholder || "Select Time"}
            icon={() => <i className="pi pi-clock" />} // Replace or modify as needed
          />
        </div>
      </div>
    </>
  );
};

export default InputTime;
