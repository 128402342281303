import React from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logoPath from '../../assets/images/RegisteredLogo.png';
import { FormatDate } from "../../utils/formatDate";

// Styles for the PDF
// const logoPath = '../../assets/images/logo.png';
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  logo: {
    width: 150, // Adjust size as needed
    height: 50,
  },
  header: {
    textAlign: "center",
    fontSize: 14,
    marginBottom: 10,
    fontWeight: "bold",
    borderBottom: "1px solid #4471c4"
  },
  subHeader: {
    fontSize: 14,
    fontWeight: 900,
    marginTop: 10,
    marginBottom: 5,
  },

  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flex: 1, // Each column takes equal width
    paddingHorizontal: 10,
  },
  leftcolumn: {
    flex: 1, // Each column takes equal width
    paddingRight: 10,
  },
  rightcolumn: {
    flex: 1, // Each column takes equal width
    paddingLeft: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    fontWeight: 'bold',
    flex: 1, // Labels take less space
    backgroundColor: "#f3f8fd",
    padding: '5px',
    border: '1px solid #d0cece'
  },
  value: {
    flex: 2, // Values take more space
    textAlign: 'left', // Align values to the right
     padding: '5px',
     border: '1px solid #d0cece',
     borderLeftWidth: 0,
  },

  section: {
    marginBottom: 15,
  },
  tableHeader: {
    flexDirection: "row",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderFirstcolumn: {
    flex: 1,
    textAlign: "left",
    padding: 5,
    border: '1px solid #d0cece',
    borderBottomWidth: 0,
  },
  tableHeadercolumn: {
    flex: 1,
    textAlign: "left",
    padding: 5,
    
    border: '1px solid #d0cece',
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },
  tableFirstcolumn: {
    flex: 1,
    textAlign: "left",
    padding: 5,
    border: '1px solid #d0cece',
  },
  tablecolumn: {
    flex: 1,
    textAlign: "left",
    padding: 5,
    border: '1px solid #d0cece',
    borderLeftWidth: 0,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 20,
    right: 20,
    textAlign: "center",
    fontSize: 10,
    color: "grey",
  },
});

// Sample Data
const invoiceData = [
  {
    invoiceNo: "489098",
    date: "11-18-2024",
    enteredBy: "Balamurgan Kalyansundaram",
    subject: "ADDITIONAL INFORMATION",
    type: "FAX",
    noteDetails: "Billing test",
  },
];

const patientDetails = {
  mrn: "216758",
  physician: "Bess Lee Chang",
  name: "Edgar Gavin",
  phone: "901-582-2260",
  dob: "05/14/3299",
  address: "1132 Kelton Ave, Ocoee, FL, 89147",
};

const NotesManagerPDF = ({ res, rowData ,patBasicinfo}: { res: any , rowData: any, patBasicinfo:any }) => {

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <Image style={styles.logo} src={logoPath} />
        <Text style={styles.subHeader}>Patient Details</Text>

        {/* Patient Information */}
        {/* <View style={styles.section}>
          <Text>MRN: {res.mrn}</Text>
          <Text>Physician: {res.full_name}</Text>
          <Text>Name: {res.full_name}</Text>
          <Text>Phone: {res.pat_phone}</Text>
          <Text>DOB: {res.date_of_birth}</Text>
          <Text>Address: {res.pat_address}</Text>
        </View> */}

{/* <View style={styles.table}> */}
<View style={styles.container}>
      {/* Left Column */}
      <View style={styles.leftcolumn}>
        <View style={styles.row}>
          <Text style={styles.label}>MRN:</Text>
          <Text style={styles.value}>{res.mrn}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Name:</Text>
          <Text style={styles.value}>{res.full_name}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>DOB:</Text>
          <Text style={styles.value}>{res.date_of_birth}</Text>
        </View>
      </View>

      {/* Right Column */}
      <View style={styles.rightcolumn}>
        <View style={styles.row}>
          <Text style={styles.label}>Physician:</Text>
          <Text style={styles.value}>{patBasicinfo.physician_full_name}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Phone:</Text>
          <Text style={styles.value}>{res.pat_phone}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Address:</Text>
          <Text style={styles.value}>{res.pat_address}</Text>
        </View>
      </View>
    </View>
    {/* </View> */}

        {/* Billing Notes */}
        <Text style={styles.subHeader}>Billing Notes</Text>
        <View style={styles.tableHeader}>
          <Text style={styles.tableHeaderFirstcolumn}>Invoice No</Text>
          <Text style={styles.tableHeadercolumn}>Date</Text>
          <Text style={styles.tableHeadercolumn}>Entered By</Text>
          <Text style={styles.tableHeadercolumn}>Subject</Text>
          <Text style={styles.tableHeadercolumn}>Type</Text>
          <Text style={styles.tableHeadercolumn}>Note Details</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableFirstcolumn}>{rowData?.invoice_number}</Text>
          <Text style={styles.tablecolumn}>{FormatDate(rowData.date)}</Text>
          <Text style={styles.tablecolumn}>{rowData?.entered_by}</Text>
          <Text style={styles.tablecolumn}>{rowData?.subject}</Text>
          <Text style={styles.tablecolumn}>{rowData?.notes_type}</Text>
          <Text style={styles.tablecolumn}>{rowData?.body}</Text>
        </View>

        {/* Footer */}
        <Text style={styles.footer}>
          © Copyright 2024. InfuCareRx. All Rights Reserved.
        </Text>
      </Page>
    </Document>
  );
};

export default NotesManagerPDF;
