import React, { useEffect, useState } from "react";
import "../../../assets/css/shipment.css";
import MultiSelectFilter from "../../formElement/MultiSelect";
import InputField from "../../formElement/InputField";
import { Button } from "primereact/button";
import MultiSelect from "../../../components/formElement/MultiSelect";
import { FilterMatchMode } from "primereact/api";
import { DataTableFilterMeta } from "primereact/datatable";
import { Completed, Pending } from "../../../assets/images/Icon";
import CustomTooltip from "../../Tooltip/CustomTooltip";

const ShipmentFilter = ({
  filters,
  setFilters,
  statusData,
  rxtype,
  shipmethod,
  team,
  alldata,
}: {
  filters: any;
  setFilters: any;
  statusData: any;
  rxtype: any;
  shipmethod: any;
  team: any;
  alldata: any;
}) => {
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedRxtype, setSelectedRxtype] = useState<string[]>([]);
  const [selectedShipmethod, setSelectedShipmethod] = useState<string[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string[]>([]);
  const [FilterMultiselect, setFilterMultiselect] =
    useState<DataTableFilterMeta>({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
  //Loader in button
  const [selectedButton, setSelectedButton] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingPending, setLoadingPending] = useState(false);

  const onStatusChange = (e: any) => {
    setSelectedStatus(e.value);
    setFilters((prevFilters: DataTableFilterMeta) => ({
      ...prevFilters,
      status: { value: e.value, matchMode: FilterMatchMode.IN },
    }));
  };

  const onRxtypeChange = (e: any) => {
    setSelectedRxtype(e.value);
    setFilters((prevFilters: DataTableFilterMeta) => ({
      ...prevFilters,
      rx_type: { value: e.value, matchMode: FilterMatchMode.IN },
    }));
  };

  const onShipmethodChange = (e: any) => {
    setSelectedShipmethod(e.value);
    setFilters((prevFilters: DataTableFilterMeta) => ({
      ...prevFilters,
      shipping_method: { value: e.value, matchMode: FilterMatchMode.IN },
    }));
  };

  const onTeamChange = (e: any) => {
    setSelectedTeam(e.value);
    setFilters((prevFilters: DataTableFilterMeta) => ({
      ...prevFilters,
      team: { value: e.value, matchMode: FilterMatchMode.IN },
    }));
  };

  









  //pending data
  const onPandingButtonClick = () => {
    setSelectedButton("pending");
    setLoadingPending(true);
    setFilters((prevFilters: DataTableFilterMeta) => ({
      ...prevFilters,
      ticket_status: { value: "pending", matchMode: FilterMatchMode.EQUALS },
    }));
    setTimeout(() => {
      setLoadingPending(false);
    }, 700);
  };

  //Completed data
  const onCompletedButtonClick = () => {
    setSelectedButton("completed");
    setLoading(true);
    setFilters((prevFilters: DataTableFilterMeta) => ({
      ...prevFilters,
      ticket_status: { value: "completed", matchMode: FilterMatchMode.EQUALS },
    }));
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };

    // @ts-ignore
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const filteredStatuslst = alldata.filter((status: any) => {
    if (selectedStatus.length === 0) return true;
    return selectedStatus.includes(status.status);
  });

  useEffect(() => {}, [statusData]);

  return (
    <div>
      <div className="flex align-items-center flex-wrap gap-2">
        <div className="lg:col-4 md:col-3 sm:col-12 p-1">
          <CustomTooltip target=".p-button-rounded" position="bottom" />
          <Button
            label="Pending"
            icon={() => <img className="mr-2" src={Pending} />}
            onClick={onPandingButtonClick}
            loading={loadingPending}
            className={`p-button-rounded Shipment-custom-button ${
              selectedButton === "pending" ? "Shipment-button-selected" : ""
            }`}
            data-pr-tooltip="Pending"
          />
          <Button
            label="Completed"
            icon={() => <img className="mr-1" src={Completed} />}
            onClick={onCompletedButtonClick}
            loading={loading}
            className={`p-button-rounded Shipment-custom-button ${
              selectedButton === "completed" ? "Shipment-button-selected" : ""
            }`}
            data-pr-tooltip="Completed"
          />
        </div>
      </div>

      {/* Row 1 */}
      <div className="flex align-items-center flex-wrap ">
        <div className="lg:col-2 md:col-3 sm:col-12 p-1">
          <InputField
            placeholder="Search Patient Name, MRN"
            globalFilterValue={globalFilterValue}
            onGlobalFilterChange={onGlobalFilterChange}
            id="patientSearch"
          />
        </div>

        <div className="lg:col-2 md:col-3 sm:col-12 p-1 ">
          <MultiSelect
            value={selectedStatus}
            filters={FilterMultiselect}
            data={statusData}
            label="label"
            filterValue="value"
            virtualScrollerOptions={{ itemSize: 50 }}
            placeholder="Select Status"
            onChange={onStatusChange}
          />
        </div>

        <div className="lg:col-2 md:col-3 sm:col-12 p-1">
          <MultiSelectFilter
            filterValue="value"
            id="collectorIds"
            value={selectedRxtype}
            onChange={onRxtypeChange}
            label="label"
            data={rxtype}
            placeholder="Rx Type"
          />
        </div>

        <div className="lg:col-2 md:col-3 sm:col-12 p-1">
          <MultiSelectFilter
            filterValue="value"
            id="collectorIds"
            value={selectedShipmethod}
            onChange={onShipmethodChange}
            label="label"
            data={shipmethod}
            placeholder="Ship Method"
          />
        </div>

        <div className="lg:col-2 md:col-3 sm:col-12 p-1">
          <MultiSelectFilter
            filterValue="value"
            id="collectorIds"
            value={selectedTeam}
            onChange={onTeamChange}
            label="label"
            data={team}
            placeholder="Team"
          />
        </div>
        <div className="lg:col-1 md:col-2 sm:col-12 p-1">
          <CustomTooltip target=".Resettooltip" position="bottom" />
          <Button
            label="Reset"
            outlined
            className="Resettooltip"
            data-pr-tooltip="Reset"
            onClick={() => {
              setGlobalFilterValue("");
              setSelectedStatus([]);
              setSelectedRxtype([]);
              setSelectedShipmethod([]);
              setSelectedTeam([]);
              setSelectedButton("");
              setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                status: { value: null, matchMode: FilterMatchMode.IN },
                rx_type: { value: null, matchMode: FilterMatchMode.IN },
                shipping_method: { value: null, matchMode: FilterMatchMode.IN },
                team: { value: null, matchMode: FilterMatchMode.IN },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ShipmentFilter;
