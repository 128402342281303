import React, { useEffect, useState } from "react";
import InputField from "../../components/formElement/InputField";
import { Button } from "primereact/button";
import AddNurseDatatable from "./infusion-nurse/AddNurseDatatable";
import AddAgencyDatatable from "./agency/AddAgencyDatatable";
import AddInfusionSuiteDatatable from "./infusion-suite/AddInfusionSuiteDatatable";
import AddNurseAppointments from "./new-appointments/AddNurseAppointments";
import AddAgencyAppointment from "./new-appointments/AddAgencyAppointment";
import AddSuiteAppointment from "./new-appointments/AddSuiteAppointment";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { useLocation } from "react-router-dom";
import { getAddAgencyList, getAddNurseDetail, getAddNurseList, getChairList, getpatientDetail, getSuitList, getTherapyList } from "../../redux/store/AppointmentsReducer";
import InputDate from "../../components/formElement/InputDate";
import InputDropdown from "../../components/formElement/InputDropdown";
import { FilterMatchMode } from "primereact/api";
import PatientLocation from "../../assets/images/Location.svg";
import Loader from "../../components/loader/Loader";

type FilterMeta = {
  value: string | null | (string | null)[]; // Accept arrays or strings
  matchMode: FilterMatchMode;
};

const AddNewPatient = ({
  name,
  userEmail,
}: {
  name?: string;
  userEmail?: string;
}) => {
  const [activeTab, setActiveTab] = useState(0);

  // filter data
  const [filters, setFilters] = useState<{
    global: FilterMeta;
  }>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilters(prev => ({
      ...prev,
      global: { ...prev.global, value }
    }));
  };

  const { patientDetail, patientDetailLoading, suiteList, chairList } = useSelector((state: RootState) => state.AppointmentsReducer);
  const [suite, setSuite] = useState<string | null>();
  const [selectedDate, setSelectedDate] = useState(new Date()); // Default to today

  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getSuitList());
    const searchParams = new URLSearchParams(location.search);
    const mrn = searchParams.get('mrn');
    dispatch(getAddNurseDetail({ mrn: mrn }));
    dispatch(getpatientDetail({ mrn: mrn }));
    dispatch(getAddAgencyList());
    dispatch(getAddNurseList());
    dispatch(getTherapyList());
    dispatch(getChairList());
  }, []);

  const renderDatatable = () => {
    switch (activeTab) {
      case 0:
        return <AddNurseDatatable filters={filters} globalFilterFields={["first_name", "agency_name"]} />;
      case 1:
        return <AddAgencyDatatable filters={filters} globalFilterFields={["first_name", "agency_name"]} />;
      case 2:
        return <AddInfusionSuiteDatatable />;
      default:
        return null;
    }
  };
  const renderForm = () => {
    switch (activeTab) {
      case 0:
        return <AddNurseAppointments />;
      case 1:
        return <AddAgencyAppointment />;
      case 2:
        return <AddSuiteAppointment suite={suite} />;
      default:
        return null;
    }
  };
  const renterFields = () => {
    switch (activeTab) {
      case 2:
        return (
          <div className="col-6 formgrid grid flex-wrap">
            <div className="col-7 sec-head-input">
              <InputDate
                id="DueDate"
                placeholder="Select a date"
                labelclassName="col-6 label-lg flex justify-content-start"
                inputclassName="col-6 TaskListCalender sec-head-input justify-content-end"
                label="Visit Date"
                value={selectedDate} // Pass the Date object as the value
                handleChange={(e: any) => setSelectedDate(e.value)} // Update state with the selected Date
              />
            </div>
            <div className="col-5">
              <InputDropdown
                options={suiteList.map((item) => ({
                  label: item.name_of_suite,
                  value: item.suite_id,
                }))}
                label=""
                placeholder='Select Suite'
                optionLabel="label"
                type="text"
                id="AssignedTo"
                value={suite ?? undefined}
                handleChange={(e) => setSuite((e.target.value))}
                dropdownclassName="col-12 Input-field task-list-dropdown"
                labelclassName="col-0 label-lg"
                filter
              />
            </div>
          </div>
        )
      default:
        return null;
    }
  }
  const locationLoading = window.location.pathname;

  return (
    <div className="add-appointments">
      <div className="flex-row">
        {patientDetailLoading ? (
          <div>
            <Loader label='Agency' location={locationLoading} path='/schedule-management' />
          </div>
        ) : (
          <div>
            <div className="flex">
              <div className="col-12">
                <div className="shadow-2 surface-overlay border-round flex justify-content-evenly flex-wrap p-3">
                  <div className="col-12 lg:col-4 flex justify-content-center">
                    {/* <img src={} className="" alt=""  /> */}
                    {patientDetail[0]?.full_name}
                  </div>
                  <div className="col-12 lg:col-4 flex justify-content-center">
                    {/* <img src={PatientLocation} className="w-8 h-8" alt="Excel" /> */}
                    {patientDetail[0]?.pat_address}, {patientDetail[0]?.pat_city}, {patientDetail[0]?.pat_state}, {patientDetail[0]?.pat_zip}
                  </div>
                  <div className="col-12 lg:col-4 flex justify-content-center">
                    {patientDetail[0]?.pat_cell_phone}
                  </div>
                </div>

              </div>
            </div>

            <div className="flex mt-1 flex-wrap">
              <div className="col-12 lg:col-8">
                <div className="shadow-2 surface-overlay border-round p-3 overflow-auto" style={{ height: '750px' }}>
                  <div className="search-bar">
                    {/* <InputField placeholder="Search.." id="search" /> */}
                    <InputField
                      placeholder="Search..."
                      id="search"
                      globalFilterValue={typeof filters.global?.value === "string" ? filters.global.value : ""}
                      onGlobalFilterChange={onGlobalFilterChange}
                    />
                  </div>
                  <div className="addnewp-tab-button mt-3 flex justify-content-start flex-wrap flex-row">
                    <div className="col-6 flex justify-content-start flex-wrap">
                      <Button
                        className={`${activeTab === 0 ? "active-tab" : ""}`}
                        onClick={() => setActiveTab(0)}
                        label="Nurse"
                      />
                      <Button
                        className={`${activeTab === 1 ? "active-tab" : ""}`}
                        onClick={() => setActiveTab(1)}
                        label="Agency"
                      />
                      <Button
                        className={`${activeTab === 2 ? "active-tab" : ""}`}
                        onClick={() => setActiveTab(2)}
                        label="Infusion Suite"
                      />
                    </div>
                    {renterFields()}
                  </div>
                  <div className="table-contents mt-3">
                    {renderDatatable()}
                  </div>
                </div>
              </div>

              <div className="col-12 lg:col-4">
                <div className="shadow-2 surface-overlay border-round p-3 overflow-auto" style={{ height: '750px' }}>
                  <div className="search-bar mt-4">
                    {renderForm()}
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddNewPatient;