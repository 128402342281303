import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Loader from "../../components/loader/Loader";
import FilterMessage from "../../components/FilterMessage/FilterMessage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CensusFilter from "../../components/Filter/CensusFilter/CensusFilter";
import CensusDataTable from "../../components/DataTable/CensusDataTable";
import ResponsiveCardView from "../../components/ResponsiveCardView/ResponsiveCardView";
import { useMedia } from "../../hooks/useMedia";
import { Badge } from "primereact/badge";

const Census = ({
  name,
  userEmail,
}: {
  name: string | undefined;
  userEmail: string | undefined;
}) => {
  const { censusLoading, filterLoading, censusData } =
    useSelector((state: RootState) => state.CensusReducer);

  const location = window.location.pathname;
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [expand, setExpand] = useState<number | null>(null);
  const [filterUpdate, setFilterUpdate] = useState("");
  const isSmallScreen = useMedia("(max-width: 576px)");

  const toggleExpand = (index: number) => {
    setExpand(expand === index ? null : index);
  };

  const cardHeader = (item: any, index: any): ReactNode => {

    return (
      <div className="card-header flex align-items-center justify-content-between px-3 py-3">
        <div className="header-name">{item.full_name}</div>
        <div className="header-options flex align-items-center gap-2">
          <div className="date flex gap-1 align-items-center">
            <i className="pi pi-calendar"></i>
            <span>{item.date_of_birth}</span>
          </div>
          <div className="more-options">
            <i className="pi pi-bars"></i>
          </div>
          <div className="expand">
            <i
              className={`${
                expand === index ? "rotate" : "no-rotate"
              } pi pi-angle-down`}
              onClick={() => toggleExpand(index)}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  const cardFooter = (item: any, index: any): ReactNode => {
    return (
      <>
        <div className="card-footer grid">
          <div className="col-6 flex">
            <div className="content-key">Site : </div>
            <div className="content-value">{item.site}</div>
          </div>
          <div className="col-6 flex">
            <div className="content-key">Phone : </div>
            <div className="content-value">{item.phone_number}</div>
          </div>
          {expand === index && (
            <>
              <div className="col-6 flex">
                <div className="content-key">Ref Date : </div>
                <div className="content-value">{item.referral_date}</div>
              </div>
              <div className="col-12 flex">
                <div className="content-key">Prescriber : </div>
                <div className="content-value">{item.prescriber_name}</div>
              </div>
              <div className="col-12 flex">
                <div className="content-key">Insurance : </div>
                <div className="content-value">{item.insurance_name}</div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const cardContent = (item: any): ReactNode => {
    return (
      <>
        <div className="card-content flex align-items-center justify-content-between">
          <div className="content-name">
            <div className="content-key">MRN : </div>
            <div className="content-value">{item.mrn}</div>
          </div>
          <div className="status">
            <Badge
              value={item.patient_status}
              severity={
                item.patient_status === "Active"
                  ? "success"
                  : item.patient_status === "Inactive"
                  ? "danger"
                  : item.patient_status === "Cancelled"
                  ? "warning"
                  : item.patient_status === "On Hold"
                  ? "info"
                  : item.patient_status === "Pending"
                  ? "contrast"
                  : null
              }
            />
          </div>
        </div>
      </>
    );
  };

  const handleStatusUpdateLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  return (
    <div className="census">
      {filterLoading || loading ? (
        <Loader location={location} path="/census" label="Census" />
      ) : censusLoading ? (
        <Loader location={location} path="/census" label="Census" />
      ) : null}
      <ToastContainer />
      <CensusFilter
        setNoData={setNoData}
        userEmail={userEmail}
        setFilterUpdate={setFilterUpdate}
        onStatusUpdateLoading={handleStatusUpdateLoading}
      />
      <div className="card-view flex flex-column gap-3">
        {censusData?.length > 0 ? (
          isSmallScreen ? (
            censusData?.map((item: any, index) => (
              <ResponsiveCardView
                key={index}
                cardHeader={cardHeader(item, index)}
                cardContent={cardContent(item)}
                cardFooter={cardFooter(item, index)}
              />
            ))
          ) : (
            <>
              <CensusDataTable
                filterUpdate={filterUpdate}
                name={name}
                userEmail={userEmail}
                data={censusData}
              />
            </>
          )
        ) : !filterLoading && !loading && !censusLoading ? (
          <FilterMessage noData={noData} />
        ) : null}
      </div>
    </div>
  );
};

export default Census;
