import { Logo, Profile } from "../../assets/images/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import CensusNavBar from "./CensusNavBar";

const Header = ({ name }: { name: string }): React.ReactElement => {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  // Access the `from` state if coming from access-denied route
  const deniedFrom = location.state?.from;

    // Define a mapping of paths to their labels
    const pathLabels: Record<string, string> = {
        '/': 'Home',
        '/census': 'Census',
        '/nurse-agency': 'Health Infusion Nurse/Agency',
        '/ar-manager': 'AR Manager',
        '/schedule-management': 'Schedule Management',
        '/appointments': 'Appointments',
        '/insurance': 'Insurance',
        '/faxdirectory': 'Fax Details',
        '/fax': 'Fax Screen',
        '/fax/junk-fax': 'Junk Faxes',
        '/fax/archive-fax': 'Archive',
        '/tasklist': 'Task List',
        '/pricing': 'Pricing',
        '/inventory': 'Inventory',
        '/shipment': 'Shipments',
        '/notes-manager' : 'Notes Manager',
        '/prescribers-order' : 'Prescribers Order',
        '/permissions' : 'User Privileges',

    };

  // Handle access-denied label dynamically
  if (path === "/access-denied" && deniedFrom) {
    pathLabels["/access-denied"] = `${
      pathLabels[deniedFrom] || "Unknown Page"
    }`;
  } else {
    pathLabels["/access-denied"] = "Access Denied";
  }

  // Dynamically get the label based on the current path or denied path
  const currentLabel = pathLabels[path] || "Unknown Page";

  return (
    <div className="header w-full flex justify-content-between align-items-center">
      <img className="w-7rem" src={Logo} alt="Infucare Logo" />
      {(path === "/fax" ||
        path === "/fax/junk-fax" ||
        path === "/faxdirectory" ||
        path === "/fax/archive-fax" ||
        path.startsWith("/fax/user-document")) && (
        <button
          onClick={() => navigate(-1)}
          className="absolute border-none flex justify-content-center align-items-center border-circle cursor-pointer"
          style={{
            marginLeft: "150px",
            backgroundColor: "#e4e7ec",
            height: "35px",
            width: "35px",
          }}
        >
          <i
            className="pi pi-chevron-left"
            style={{ color: "#2e5a86", fontSize: "18px" }}
          />
        </button>
      )}
      <div className="text-center font-bold text-xl">
        {path.startsWith("/fax/user-document")
          ? "User Document Section"
          : currentLabel}
      </div>
      <div className="profile flex gap-1">
          <div className="px-1">
            <img
              className="w-3rem h-3rem border-circle"
              src={Profile}
              alt="Profile"
            />
          </div>
          <div className="name text-xs font-semibold">{name}</div>
        </div>
    </div>
  );
};

export default Header;
