import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/api/index";

const baseUrl = process.env.REACT_APP_BASEURL;

export type NurseList = {
    patient_schedule_id: number,
    isLogHistory: boolean,
    mrn: string;
    patient_Fname: string;
    patient_Lname: string;
    site: string;
    next_Infusion_Date: string;
    dateAsInt: number,
    start_Time: string;
    duration?: null,
    duration_hour?: null,
    duration_minute?: null,
    status: string;
    agency_id: number,
    agency_Name: string;
    primary_Rn: string;
    primary_Rn_id: number,
    appointment_Count?: null,
    comments?: null,
    is_delete: boolean,
    created_by: string;
    createdon: string;
    createdonAsInt: string;
    teams: string;
    therapy_id: number,
    texT_?: null,
    in_time: string;
    out_time: string;
    total_time?: null,
    billed_Status?: null,
    full_name: string;
    pat_state: string;
    start_of_care: string;
    site_no: string;
    nurse_id: number;
    type: string;
    nurse_Name: string;
}

export type AgencyList = {
    patient_schedule_id: number,
    isLogHistory: boolean,
    mrn: string;
    patient_Fname: string;
    patient_Lname: string;
    site: string;
    next_Infusion_Date: string;
    dateAsInt: number,
    start_Time: string;
    duration_hour?: null,
    duration_minute?: null,
    status: string;
    agency_id: number,
    agency_Name: string;
    primary_Rn: string;
    primary_Rn_id: number,
    appointment_Count?: null,
    comments?: null,
    is_delete: boolean,
    created_by: string;
    createdon: string;
    createdonAsInt: string;
    teams: string;
    therapy_id: number,
    texT_?: null,
    in_time: string;
    out_time: string;
    total_time?: null,
    billed_Status?: null,
    full_name: string;
    pat_state: string;
    start_of_care: string;
    site_no: string;
}

export type InfusionList = {
    patient_schedule_id: number;
    mrn: string;
    patient_Fname: string;
    patient_Lname: string;
    site: string;
    next_Infusion_Date: string;
    dateAsInt: number;
    start_Time: string;
    end_Time: string;
    duration: string;
    duration_hour: string;
    duration_minute: string;
    status: string;
    chair: string;
    suite_Name: string;
    primary_Rn: string;
    appointment_Count: string;
    comments: string;
    is_delete: boolean;
    created_by: string;
    createdon: string;
    createdonAsInt: number;
    teams: string;
    therapy_id: number;
    texT_?: string;
    in_time: string;
    out_time: string;
    total_time: string;
    billed_Status: string;
    full_name: string;
    nurse_or_agency: string;
    agency_id: number;
    nurse_id: number;
    isLogHistory: boolean;
    color_code?: string;
    start_of_care: string;
    site_no: string;
}

export type HistoryList = {
    patient_schedule_id: number,
    is_current: boolean,
    old_patient_schedule_id: number,
    MRN: string,
    modified_by?: null,
    modified_date: string,
    modifiedDateAsInt: number,
    Formatmodifieddate: string,
    Next_Infusion_Date: string,
    DateAsInt: number,
    End_Time: string,
    duration_hour: number,
    duration_minute: number,
    Status: string,
    Appointment_Count: string,
    Comments: string,
    created_by: string,
    createdon: string,
    CreatedonAsInt: number,
    Formatcreatedon: string,
    in_time: string,
    out_time: string,
    total_time: string,
    RecursionLevel: number
}

export type PatientList = {
    mrn: string,
    full_name: string,
    first_name: string,
    last_name: string,
    date_of_birth: string,
    referral_date: string,
    prescriber_name: string,
    therapy: string,
    insurance_name: string,
    site: string,
    phone_number: string,
    patient_status: string,
    social_security_number: string,
    patient_SS: string,
    sales_rep: string,
    primary_rn: string,
    insurance_coordinator: string,
    patient_team: string,
    is_read: boolean,
    modified_date?: null,
    last_read_message_id?: null,
    site_no: string,
    referral_date_as_int: string,
    date_of_birth_as_int: string
}
export type PatientDetail = {
    mrn: string,
    first_name: string,
    last_name: string,
    full_name: string,
    company: string,
    pat_phone: string,
    phone_number: string,
    pat_work_phone: string,
    pat_cell_phone: string,
    patient_martial_status: string,
    patient_language: string,
    date_of_birth: string,
    patient_status: string,
    gender: string,
    site: string,
    pat_email: string,
    pat_address: string,
    pat_city: string,
    pat_county: string,
    pat_state: string,
    pat_zip: string,
    primary_rn: string,
    insurance_coordinator: string,
    patient_billing_contact: string,
    sales_rep: string,
    patient_team: string,
    start_of_care: string,
    payor?: null,
    primary_insurance_policy_status?: null,
    group?: null,
    hhaMessage: string,
    service_name?: null,
    code_status: string,
    site_of_service: string,
    referral_date?: null,
    referral_source_id?: null,
    referral_source?: null
}
export type AddAgencyList = {
    company_id: number,
    agency_name: string,
    company_no: string,
    company_type_id: number,
    is_current: boolean,
    is_delete: boolean,
    agency_id: number,
    first_name: string,
    last_name?: null,
    agency_cpr_id: number,
    initial_Visit_Rate_2hr_IV?: null,
    visit_Rate_2hr_V?: null,
    hourly_Rate_H?: null,
    weekend_Holiday_Rate_WH?: null,
    travel_Rate_per_hour_Tr?: null,
    country: string,
    notes?: null,
    status_id?: null,
    status?: null,
    full_address: string,
    address: string,
    city: string,
    state: string,
    zip_code: string,
    county?: null,
    phone: string,
    fax: string,
    email?: null
}
export type AddNurseDetail = {
    mrn?: null,
    nurse_id: number,
    agency_name: string,
    first_name: string,
    last_name: string,
    full_name: string,
    address: string
    city: string,
    state: string,
    zip_code: string,
    county: string,
    country: string,
    phone: string,
    email?: null,
    fax?: null,
    notes?: null,
    status: string
    polygon?: null,
    nurse_type: string,
    distance?: null
}
export type SuiteList = {
    suite_id: number,
    company_id?: null,
    name_of_suite: string,
    working_hours_from: string,
    working_hours_to: string,
    lunch_time_from?: null,
    lunch_time_to?: null,
    comment: string,
    is_current: boolean,
    is_delete: boolean,
    address: string,
    city?: null,
    state: string,
    zip_code: string,
    county: string,
    status_id: number,
    status: string,
    suite_type: string,
    no_of_chair: number,
    color_code: string,
    created_by: string,
    created_date: string,
    phone: string,
    fax?: null,
    email: string
}
export type ChairList = {
    chair_id: number,
    suit_id: number,
    description: string,
    created_date: string,
    modified_date?: null,
    created_by: string,
    modified_by?: null,
    is_current: boolean,
    is_delete: boolean
}
export type AddNurseList = {
    nurse_id: number,
    nurse_cpr_id?: null,
    agency_name: string,
    first_name: string,
    last_name: string,
    address: string,
    city: string,
    state: string,
    zip_code: string,
    county: string,
    country: string,
    phone: string,
    email?: null,
    fax?: null,
    notes?: null,
    status: string,
    latitutde: number,
    longitude: number,
    polygon?: null,
    nurse_type: string,
    old_nurse_id: number,
    created_by: string,
    created_date: string,
    modified_by?: null,
    modified_date?: null,
    is_current: boolean,
    is_delete: boolean
}
export type AppointmentData = {
    patient_schedule_id: number;
    chair_id: number;
    agency_id: number;
    nurse_id: number;
    employee_id: number;
    mrn: string;
    nurse_or_agency: boolean;
    in_time: string;
    out_time: string;
    duration_hour: number;
    duration_minute: number;
    created_by: string;
    status: string | undefined;
    appointment_count: string;
    pk_therapy_type: string | undefined;
    comments: string;
    total_time: string;
    appointment_type_id: number;
    bill_notification: string;
    site: string;
    input_json: string;
};

export type InitialState = {
    siteOption: {
        id: number | null;
        site: string;
        site_no: string | null;
    }[];
    TherapyList: {
        drug_category_id: number | null;
        description: string;
    }[];
    suiteList: SuiteList[]
    chairList: ChairList[]
    agencyList: AgencyList[];
    agencyLoading: boolean;
    nurseList: NurseList[];
    nurseLoading: boolean;
    infusionList: InfusionList[];
    infusionLoading: boolean;
    historyList: HistoryList[];
    historyLoading: boolean;
    patientList: PatientList[];
    patientLoading: boolean;
    patientDetail: PatientDetail[];
    patientDetailLoading: boolean;
    addAgencyList: AddAgencyList[];
    addNurseList: AddNurseList[];
    addNurseDetail: AddNurseDetail[];
    appointmentData: AppointmentData | null;
    appointmentLoading: boolean;
    appointmentError: string | null;
}
// save api
// Create AsyncThunk for adding/updating an appointment
export const addUpdateAppointment = createAsyncThunk(
    "appointments/addUpdateAppointment",
    async (appointmentData: AppointmentData) => {
        try {
            const response = await axios.post(
                `${baseUrl}/api/Appointment/addUpdateAppointment`,
                appointmentData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            return response.data; // This is the response from the API (appointment details)
        } catch (error) {
            console.error("Error adding/updating appointment:", error);
            throw new Error("Failed to add/update appointment");
        }
    }
);
// get appoint 
export const fetchAgencyData = createAsyncThunk(
    "appointments/fetchAgencyData",
    async ({ query }: { query: string }) => {
        try {
            // const response = await fetch(`${baseUrl}/api/FaxModule/getFaxQueueList`);
            const response = await axios.post(`${baseUrl}/api/Appointment/getAppointmentAgencyList`, query,
                {
                    headers: {
                        "Content-Type": "application/json", // Specify JSON content type
                    },
                }
            );
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.data?.data;
            return data;
        } catch (error) {
            console.error("Error fetching appointment details:", error);
            throw new Error("Failed to fetch appointment details");
        }
    }
);
export const fetchNurseData = createAsyncThunk(
    "appointments/fetchNurseData",
    async ({ query }: { query: string }) => {
        try {
            const response = await axios.post(`${baseUrl}/api/Appointment/getAppointmentNurseList`, query,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.data?.data;
            return data;
        } catch (error) {
            console.error("Error fetching appointment details:", error);
            throw new Error("Failed to fetch appointment details");
        }
    }
);
export const fetchInfusionData = createAsyncThunk(
    "appointments/fetchInfusionData",
    async ({ query }: { query: any }) => {
        try {
            const response = await axios.post(`${baseUrl}/api/Appointment/getAppointmentInfusionList`, query,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.data?.data;
            return data;
        } catch (error) {
            console.error("Error fetching appointment details:", error);
            throw new Error("Failed to fetch appointment details");
        }
    }
);
//history data
export const fetchHistoryData = createAsyncThunk(
    "appointments/fetchHistoryData",
    async ({ scheduleID, typeID }: { scheduleID: any, typeID: any }) => {
        try {
            const response = await axios.post(`${baseUrl}/api/Appointment/getAppointmentHistory?scheduleID=${scheduleID}&typeID=${typeID}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.data?.data;
            return data;
        } catch (error) {
            console.error("Error fetching appointment details:", error);
            throw new Error("Failed to fetch appointment details");
        }
    }
);
// cencus patient details
export const fetchPatientData = createAsyncThunk(
    "appointments/fetchPatientData",
    async ({ query }: { query: any }) => {
        try {
            const response = await axios.post(`${baseUrl}/api/Census/CensusSearchPatient`, query,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.data?.data;
            return data;
        } catch (error) {
            console.error("Error fetching appointment details:", error);
            throw new Error("Failed to fetch appointment details");
        }
    }
);

// get site options list
export const getSiteListTask = createAsyncThunk(
    "appointments/getSiteListTask",
    async () => {
        try {
            const endpoint = `${baseUrl}/api/Insurance/getSiteList`;

            const response = await axios.get(endpoint, {
                headers: {
                    Accept: "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose",
                },
                withCredentials: true,
            });
            return response.data?.data;
        } catch (error) {
            throw error;
        }
    }
);
// get Suit List
export const getSuitList = createAsyncThunk(
    "appointments/getSuitList",
    async () => {
        try {
            const endpoint = `${baseUrl}/api/Appointment/getSuitList`;

            const response = await axios.get(endpoint, {
                headers: {
                    Accept: "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose",
                },
                withCredentials: true,
            });
            return response.data?.data;
        } catch (error) {
            throw error;
        }
    }
);
// get Suit List
export const getChairList = createAsyncThunk(
    "appointments/getChairList",
    async () => {
        try {
            const endpoint = `${baseUrl}/api/Appointment/getChairList`;

            const response = await axios.get(endpoint, {
                headers: {
                    Accept: "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose",
                },
                withCredentials: true,
            });
            return response.data?.data;
        } catch (error) {
            throw error;
        }
    }
);
// get Therapy List
export const getTherapyList = createAsyncThunk(
    "appointments/getTherapyList",
    async () => {
        try {
            const endpoint = `${baseUrl}/api/Appointment/getTherapyList`;

            const response = await axios.get(endpoint, {
                headers: {
                    Accept: "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose",
                },
                withCredentials: true,
            });
            return response.data?.data;
        } catch (error) {
            throw error;
        }
    }
);
// Get Patient Demographic Details,
export const getpatientDetail = createAsyncThunk(
    "appointments/getpatientDetail",
    async ({ mrn }: { mrn: any }) => {
        try {
            const endpoint = `${baseUrl}/api/ArManager/GetPatientDemographicDetails/${mrn}`;

            const response = await axios.get(endpoint, {
                headers: {
                    Accept: "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose",
                },
                withCredentials: true,
            });
            return response.data?.data;
        } catch (error) {
            throw error;
        }
    }
);
// Get Nurse Detail,
export const getAddNurseDetail = createAsyncThunk(
    "appointments/getAddNurseDetail",
    async ({ mrn }: { mrn: any }) => {
        try {
            const endpoint = `${baseUrl}/api/Appointment/getNurseDetail?mrn=${mrn}`;

            const response = await axios.get(endpoint, {
                headers: {
                    Accept: "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose",
                },
                withCredentials: true,
            });
            return response.data?.data;
        } catch (error) {
            throw error;
        }
    }
);
// get Agency List,
export const getAddAgencyList = createAsyncThunk(
    "appointments/getAddAgencyList",
    async () => {
        try {
            const endpoint = `${baseUrl}/api/Appointment/getAgencyList`;

            const response = await axios.get(endpoint, {
                headers: {
                    Accept: "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose",
                },
                withCredentials: true,
            });
            return response.data?.data;
        } catch (error) {
            throw error;
        }
    }
);
// get Nurse List,
export const getAddNurseList = createAsyncThunk(
    "appointments/getAddNurseList",
    async () => {
        try {
            const endpoint = `${baseUrl}/api/Appointment/getNurseList`;

            const response = await axios.get(endpoint, {
                headers: {
                    Accept: "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose",
                },
                withCredentials: true,
            });
            return response.data?.data;
        } catch (error) {
            throw error;
        }
    }
);

const initialState: InitialState = {
    siteOption: [],
    suiteList: [],
    chairList: [],
    TherapyList: [],
    patientDetail: [],
    agencyList: [],
    nurseList: [],
    infusionList: [],
    historyList: [],
    patientList: [],
    addAgencyList: [],
    addNurseList: [],
    addNurseDetail: [],
    appointmentData: null,
    appointmentLoading: false,
    appointmentError: null,
    agencyLoading: false,
    nurseLoading: false,
    infusionLoading: false,
    historyLoading: false,
    patientLoading: false,
    patientDetailLoading: false,
}

export const AppointmentsSlice = createSlice({
    name: 'appointments',
    initialState: initialState,
    reducers: {
        handleClearPatient(state) {
            state.patientList = [];
        },
        handleHistoryPatient(state) {
            state.historyList = [];
        }
    },
    extraReducers: (builder) => {
        builder
            // SiteList
            .addCase(getSiteListTask.fulfilled, (state, action) => {
                state.siteOption = action.payload;
            })
            // ChairList
            .addCase(getChairList.fulfilled, (state, action) => {
                state.chairList = action.payload;
            })
            // Suite List
            .addCase(getSuitList.fulfilled, (state, action) => {
                state.suiteList = action.payload;
            })
            // therapy
            .addCase(getTherapyList.fulfilled, (state, action) => {
                state.TherapyList = action.payload;
            })
            // get Add Pateint Detail
            .addCase(getpatientDetail.pending, (state, action) => {
                state.patientDetailLoading = true;
            })
            .addCase(getpatientDetail.fulfilled, (state, action) => {
                state.patientDetailLoading = false;
                state.patientDetail = action.payload;
            })
            .addCase(getpatientDetail.rejected, (state, action) => {
                state.patientDetailLoading = false;
            })
            // get Add Nurse Detail
            .addCase(getAddNurseDetail.fulfilled, (state, action) => {
                state.addNurseDetail = action.payload;
            })
            // get Add Agency List
            .addCase(getAddAgencyList.fulfilled, (state, action) => {
                state.addAgencyList = action.payload;
            })
            // get Add Nurse List
            .addCase(getAddNurseList.fulfilled, (state, action) => {
                state.addNurseList = action.payload;
            })
            // fetch-Agency-Data
            .addCase(fetchAgencyData.pending, (state, action) => {
                state.agencyLoading = true;
            })
            .addCase(fetchAgencyData.fulfilled, (state, action) => {
                state.agencyLoading = false;
                state.agencyList = action.payload;
            })
            .addCase(fetchAgencyData.rejected, (state, action) => {
                state.agencyLoading = false;
            })
            .addCase(fetchNurseData.pending, (state, action) => {
                state.nurseLoading = true;
            })
            .addCase(fetchNurseData.fulfilled, (state, action) => {
                state.nurseLoading = false;
                state.nurseList = action.payload;
            })
            .addCase(fetchNurseData.rejected, (state, action) => {
                state.nurseLoading = false;
            })
            .addCase(fetchInfusionData.pending, (state, action) => {
                state.infusionLoading = true;
            })
            .addCase(fetchInfusionData.fulfilled, (state, action) => {
                state.infusionLoading = false;
                state.infusionList = action.payload;
            })
            .addCase(fetchInfusionData.rejected, (state, action) => {
                state.infusionLoading = false;
            })
            // history
            .addCase(fetchHistoryData.pending, (state, action) => {
                state.historyLoading = true;
            })
            .addCase(fetchHistoryData.fulfilled, (state, action) => {
                state.historyLoading = false;
                state.historyList = action.payload;
            })
            .addCase(fetchHistoryData.rejected, (state, action) => {
                state.historyLoading = false;
            })
            // patient
            .addCase(fetchPatientData.pending, (state, action) => {
                state.patientLoading = true;
            })
            .addCase(fetchPatientData.fulfilled, (state, action) => {
                state.patientLoading = false;
                state.patientList = action.payload;
            })
            .addCase(fetchPatientData.rejected, (state, action) => {
                state.patientLoading = false;
            })
            // addAppointment
            // Add/Update Appointment
            .addCase(addUpdateAppointment.pending, (state) => {
                state.appointmentLoading = true;
                state.appointmentError = null;
            })
            .addCase(addUpdateAppointment.fulfilled, (state, action) => {
                state.appointmentLoading = false;
                state.appointmentData = action.payload; // Store the appointment data
            })
            .addCase(addUpdateAppointment.rejected, (state, action) => {
                state.appointmentLoading = false;
                state.appointmentError = action.error.message || "Failed to add/update appointment";
            });
    },
});

export const { handleClearPatient, handleHistoryPatient } = AppointmentsSlice.actions;
export default AppointmentsSlice.reducer;