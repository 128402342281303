import React, { useEffect, useMemo, useRef, useState } from "react";
import InputField from "../../formElement/InputField";
import { Button } from "primereact/button";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/agency.css";
import edit from "../../../assets/images/editicon.svg";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import NurseAgencyDialogBox from "../../Dialog/NurseAgencyDialogBox/NurseAgencyDialogBox";
import { Column } from "primereact/column";
import {
  getNurseAgencyStatusOptionList,
  getNurseList,
  getAgencyList,
  getinfusionList,
  NurseRecord,
} from "../../../redux/store/NurseAgencyReducer";
import Loader from "../../loader/Loader";
import { FilterMatchMode } from "primereact/api";
import BlockSlotTable from "../../Dialog/NurseAgencyDialogBox/BlockSloteDialogBox";
import { SortDecre, SortIncre } from "../../../assets/images/Icon";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import AgencyMapViewDialogBox from "../../Dialog/NurseAgencyDialogBox/AgencyMapViewDialogBox";

const AgencyFilter = ({
  userEmail,
}: {
  userEmail: string | undefined;
}): React.ReactElement => {
  type ButtonState = "Nurse" | "Agency" | "Infusion Suite";

  const dispatch = useDispatch<AppDispatch>();
  const { StatusOptionList, NurseList, loading } = useSelector(
    (state: RootState) => state.NurseAgencyReducer
  );
  const [filteredData, setFilteredData] = useState<NurseRecord[]>(NurseList);
  const [editData, setEditData] = useState<NurseRecord[]>([]);
  const [selected, setSelected] = useState<ButtonState>("Nurse");
  const [error, setError] = useState("");
  const [action, setAction] = useState<string>("");
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValueReorder, setGlobalFilterValueReorder] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleBlock, setVisibleBlock] = useState(false);
  const [actionId, setActionId] = useState<number | undefined>(undefined);
  const [mapViewVisible, setMapViewVisible] = useState(false);
  const [NurseAgencyFilter, setNurseAgencyFilter] = useState<FilterState>({
    StatusOptionList: [],
  });
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null
  );

  const location = window.location.pathname;

  interface FilterState {
    StatusOptionList: any[];
  }

  type SelectOption = {
    FieldName: string;
    colorcode: string;
  };

  useEffect(() => {
    setFilteredData(NurseList);
  }, [NurseList]);

  const pageOptions = [5, 10, 15, 25, 75, 100];

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getNurseAgencyStatusOptionList());
      dispatch(getNurseList());
    };

    fetchData();
  }, [dispatch]);

  

  const filterData = (filteredData: any) => {
    if (NurseAgencyFilter.StatusOptionList.length === 0) return filteredData;
    return filteredData.filter((item: any) =>
      StatusOptionList.includes(item.status)
    );
  };

  const handleClick = (button: ButtonState): void => {
    if (selected === button) {
    } else {
      if (button === "Nurse") {
        dispatch(getNurseList());
      } else if (button === "Agency") {
        dispatch(getAgencyList());
      } else if (button === "Infusion Suite") {
        dispatch(getinfusionList());
      } else {
      }

      setSelected(button);
    }
  };
  const onGlobalFilterChangeReorder = (e: any) => {
    const value = e.target.value;
    setFilters((prev) => ({ ...prev, global: { ...prev.global, value } }));

    setGlobalFilterValueReorder(value);
  };

  const onRowSelect = (rowData: any[]) => {
    setEditData(rowData);
    setVisible(true);
    setAction("edit");
  };

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  const colors = [
    { FieldName: "Select All", colorcode: "rgb(106, 90, 205)" },
    { FieldName: "Active", colorcode: "rgb(0, 128, 0)" },
    { FieldName: "Inactive", colorcode: "rgb(255, 0, 0)" },
    { FieldName: "Pending", colorcode: "rgb(255, 255, 0)" },
  ];

  const itemTemplate = (option: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
          justifyItems: "space-between",
        }}
      >
        <span>{option.FieldName}</span>
        <div
          style={{
            width: "10px",
            height: "8px",
            backgroundColor: option.colorcode,
            borderRadius: "50%",
            marginRight: "10px",
          }}
        ></div>
      </div>
    );
  };

  const valueTemplate = (option: any) => {
    if (!option) {
      return <span>Select Status</span>;
    }
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{option.FieldName}</span>
      </div>
    );
  };

  const handleDropdownChange = (e: any) => {
    const value = e.value.FieldName;
    const Lable = e.value;

    setSelectedOption(Lable);

    if (value === "Select All") {
      setFilteredData(NurseList);
    } else {
      const filtered = NurseList.filter((item) => item.status === value);
      setFilteredData(filtered);
    }
  };


  useEffect(() => {
    if (selectedOption && selectedOption.FieldName !== "Select All") {
      const filtered = NurseList.filter(
        (item) => item.status === selectedOption.FieldName
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(NurseList);
    }
  }, [NurseList]);

  return (
    <>
      <div className="NurseAgency tab-button2 ">
        <Button
          className={`NurseAgency toggle-btn ${selected === "Nurse" ? "selected" : ""}`}
          onClick={() => handleClick("Nurse")}
        >
          Health Infusion Nurse
        </Button>

        <Button
          className={`NurseAgency toggle-btn ${selected === "Agency" ? "selected" : ""}`}
          onClick={() => handleClick("Agency")}
        >
          Agency
        </Button>

        <Button
          className={`NurseAgency toggle-btn ${
            selected === "Infusion Suite" ? "selected" : ""
          }`}
          label="Infusion Suite"
          onClick={() => handleClick("Infusion Suite")}
        />
      </div>
      <div className="NurseAgencyMainHeader">
        <div className="NurseAgencyHeader-first">
          <div className="align-items-center NurseSearch ">
            <div className="status-filter">
              <div>
                <Dropdown
                  value={selectedOption}
                  options={colors}
                  onChange={handleDropdownChange}
                  optionLabel="FieldName"
                  placeholder="Select Status"
                  itemTemplate={itemTemplate}
                  valueTemplate={valueTemplate}
                  panelClassName="field-dropdown-otp"
                />
              </div>
            </div>

            <div className="col-12">
              <InputField
                placeholder="Search By Infusion Name, County, Zip code, Phone, State"
                id="patientSearch"
                onGlobalFilterChange={onGlobalFilterChangeReorder}
                tooltip={"Search By Infusion Name, County, Zip code, Phone, State"}
                tooltipOptions={{ position: "bottom" }}
              
              />
            </div>
          </div>
        </div>
        <div className="NurseAgencyHeader-secound">
          <Button
            className="NurseMapBtn"
            label="Map View"
            icon="pi pi-map"
            outlined
            onClick={() => setMapViewVisible(true)}
          />
          <Button
            className="NurseAddTaskBtn"
            label="Add Task"
            icon="pi pi-plus"
            onClick={() => {
              setVisible(true);
              setAction("add");
            }}
            style={{
              display: "flex",
              alignItems: "center",
              background: "transparent",
              color: "#0a3e71",
            }}
          />
        </div>
        <NurseAgencyDialogBox
          visible={visible}
          setVisible={setVisible}
          action={action}
          editData={[editData]}
          selected={selected}
          userEmail={userEmail}
        />

        <AgencyMapViewDialogBox
          visible={mapViewVisible}
          setVisible={setMapViewVisible}
        />
      </div>

      <div className="NurseDataTable">
        {selected === "Nurse" && (
          <>
            {loading ? (
              <Loader
                location={location}
                path="/nurse-agency"
                label="Nurse List"
              />
            ) : (
              <DataTable
                onRowSelect={(e) => onRowSelect(e.data)}
                width={100}
                value={filteredData}
                filters={filters}
                globalFilterFields={[
                  "agency_name",
                  "first_name",
                  "last_name",
                  "county",
                  "state",
                  "zip_code",
                  "phone",
                  "status",
                ]}
                scrollable
                removableSort
                scrollHeight="750px"
                paginator
                selectionMode="single"
                rowsPerPageOptions={pageOptions}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rows={15}
                sortIcon={sortIcon}
                currentPageReportTemplate="Showing {first}-{last} of {totalRecords} Results"
                emptyMessage="No Records found."
              >
                <Column
                  field="agency_name"
                  header="Agency"
                  body={(data) => (
                    <>
                      <span
                        style={{
                          backgroundColor:
                            data.status === "Pending"
                              ? "yellow"
                              : data.status === "Active"
                              ? "green"
                              : "red",
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          marginRight: "10px",
                          display: "inline-block",
                        }}
                      />

                      <span>{data?.agency_name}</span>
                    </>
                  )}
                  sortable
                />
                <Column field="first_name" header="First Name" sortable />
                <Column field="last_name" header="Last Name" sortable />
                <Column field="county" header="County" sortable />
                <Column field="state" header="State" sortable />
                <Column field="zip_code" header="Zip Code" sortable />
                <Column field="phone" header="Phone" sortable />
                <Column
                  field=""
                  header=""
                  body={(data) => (
                    <div className=" p-0">
                      <Button
                        className="icon-button ml-2 border-none p-0 h-2rem w-2rem"
                        icon={() => (
                          <img
                            src={edit}
                            alt="Edit Icon"
                            width={20}
                            height={20}
                          />
                        )}
                        tooltip={"Update"}
                        tooltipOptions={{ position: "bottom" }}
                        onClick={() => onRowSelect(data)}
                      />
                    </div>
                  )}
                />
              </DataTable>
            )}
          </>
        )}

        {selected === "Agency" && (
          <>
            {loading ? (
              <Loader
                location={location}
                path="/nurse-agency"
                label="Agency Data"
              />
            ) : (
              <DataTable
                onRowSelect={(e) => onRowSelect(e.data)}
                value={filteredData}
                filters={filters}
                globalFilterFields={[
                  "agency_name",
                  "first_name",
                  "last_name",
                  "county",
                  "state",
                  "zip_code",
                  "phone",
                ]}
                scrollable
                removableSort
                scrollHeight="750px"
                paginator
                selectionMode="single"
                rowsPerPageOptions={pageOptions}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rows={15}
                sortIcon={sortIcon}
                currentPageReportTemplate="Showing {first}-{last} of {totalRecords} Results"
                emptyMessage="No Records found."
              >
                <Column
                  field="agency_name"
                  header="Agency"
                  body={(data) => (
                    <>
                      <span
                        style={{
                          backgroundColor:
                            data.status === "Pending"
                              ? "yellow"
                              : data.status === "Active"
                              ? "green"
                              : "red",
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          marginRight: "10px",
                          display: "inline-block",
                        }}
                      />

                      <span>{data?.agency_name}</span>
                    </>
                  )}
                  sortable
                />
                <Column field="first_name" header="First Name" sortable />
                <Column field="last_name" header="Last Name" sortable />
                <Column field="county" header="County" sortable />
                <Column field="state" header="State" sortable />
                <Column field="zip_code" header="Zip Code" sortable />
                <Column field="phone" header="Phone" sortable />
                <Column
                  field=""
                  header=""
                  body={(data) => (
                    <div className=" p-0">
                      <Button
                        className="icon-button ml-2 border-none p-0"
                        icon={() => (
                          <img
                            src={edit}
                            alt="Edit Icon"
                            width={20}
                            height={20}
                          />
                        )}
                        tooltip={"Update"}
                        tooltipOptions={{ position: "bottom" }}
                        onClick={() => onRowSelect(data)}
                      />
                    </div>
                  )}
                />
              </DataTable>
            )}
          </>
        )}

        {selected === "Infusion Suite" && (
          <>
            {loading ? (
              <Loader
                location={location}
                path="/nurse-agency"
                label="Infusion Suite Data"
              />
            ) : (
              <DataTable
                value={filteredData}
                filters={filters}
                globalFilterFields={[
                  "name_of_suite",
                  "no_of_chair",
                  "county",
                  "state",
                  "zip_code",
                  "phone",
                ]}
                onRowSelect={(e) => onRowSelect(e.data)}
                scrollable
                removableSort
                scrollHeight="750px"
                paginator
                selectionMode="single"
                rowsPerPageOptions={pageOptions}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rows={15}
                sortIcon={sortIcon}
                currentPageReportTemplate="Showing {first}-{last} of {totalRecords} Results"
                emptyMessage="No Records found."
              >
                <Column
                  field="name_of_suite"
                  header="Infusion Suite Name"
                  body={(data) => (
                    <>
                      <span
                        style={{
                          backgroundColor:
                            data.status === "Pending"
                              ? "yellow"
                              : data.status === "Active"
                              ? "green"
                              : "red",
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          marginRight: "10px",
                          display: "inline-block",
                        }}
                      />

                      <span>{data?.name_of_suite}</span>
                    </>
                  )}
                  sortable
                />
                <Column field="no_of_chair" header="No of Chairs" sortable />
                <Column field="county" header="County" sortable />
                <Column field="state" header="State" sortable />
                <Column field="zip_code" header="Zip Code" sortable />
                <Column field="phone" header="Phone" sortable />
                <Column
                  field="color_code"
                  header=""
                  body={(data) => (
                    <>
                      <Button
                        className="icon-button  ml-2 text-blue-900 hover:bg-blue-900 hover:text-white border-solid border-blue-100 text-black h-2rem w-6rem text-xs p-1 "
                        onClick={() => {
                          setVisibleBlock(true);
                          setActionId(data.suite_id);
                        }}
                      >
                        View Block Slot
                      </Button>

                      <span
                        style={{
                          backgroundColor: data.color_code || "transperent",
                          height: "10px",
                          width: "10px",
                          marginLeft: "10px",
                          display: "inline-block",
                        }}
                      />
                    </>
                  )}
                />
              </DataTable>
            )}
          </>
        )}

        <BlockSlotTable
          visible={visibleBlock}
          setVisible={setVisibleBlock}
          actionId={actionId}
          userEmail={userEmail}
        />
      </div>
    </>
  );
};

export default AgencyFilter;
