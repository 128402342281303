import React, { useEffect, useState } from "react";
import InputField from "../../components/formElement/InputField";
import MultiSelect from "../../components/formElement/MultiSelect";
import IsuranceDataTable from "../../components/DataTable/IsuranceDataTable";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import roleIcon from "../../assets/images/role.svg";
import pdfIcon from "../../assets/images/pdf-icon.svg";
import { AppDispatch, RootState } from "../../redux/store";
import {
  getAllQuestionAnswerValueList,
  getArManagerPayorTypeList,
  getBillingInvoiceType,
  getBillingQualifier,
  getInsuranceCompanyBillingOrganizationList,
  getInsuranceCompanyList,
  getKeyValueDataForContractStatus,
  getKeyValueDataForPayorPricing,
  getSiteList,
  getStateListInsuranceListing,
} from "../../redux/store/InsuranceReducer";
import { DataTableFilterMeta } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import AddNewInsuranceDialogBox from "../../components/Dialog/InsuranceDialogBox/AddNewInsuranceDialogBox";
import Loader from "../../components/loader/Loader";
import { getBillerList } from "../../redux/store/ARManagerReducer";
import InsurancePdfConverter, {
  paginateData,
} from "../../utils/PdfConverter/InsurancePdfConverter";
import { pdf } from "@react-pdf/renderer";
import { ToastContainer } from "react-toastify";
import { useUserPermission } from "../../hooks/useUserPermission";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
import {
  ActiveIcon,
  AllactiveIcon,
  InactiveIcon,
} from "../../assets/images/Icon";

const Insurance = () => {
  const { read: allRead, write: allWrite } = useUserPermission(
    "Insurance",
    "All Insurance"
  );
  const { read: activeRead, write: activeWrite } = useUserPermission(
    "Insurance",
    "Active Insurance"
  );
  const { read: inactiveRead, write: inactiveWrite } = useUserPermission(
    "Insurance",
    "Inactive Insurance"
  );

  const [visible, setVisible] = useState<boolean>(false);
  const [action, setAction] = useState<string>("add");
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedState, setSelectedState] = useState<string[]>([]);
  const {
    insuranceCompanyInactiveList,
    insuranceCompanyActiveList,
    allinsuranceCompanyList,
    stateListInsuranceListing,
    loading,
  } = useSelector((state: RootState) => state.insuranceReducer);
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const getPermission = (index: number) => {
    switch (index) {
      case 0:
        return activeWrite;
      case 1:
        return inactiveWrite;
      case 2:
        return allWrite;
      default:
        console.warn(`Invalid permission index: ${index}`);
        return false;
    }
  };

  const dispatch = useDispatch<AppDispatch>();
  const location = window.location.pathname;
  const isDisabled =
    loading ||
    allinsuranceCompanyList.length === 0 ||
    insuranceCompanyInactiveList.length === 0 ||
    insuranceCompanyActiveList.length === 0;

  useEffect(() => {
    const actions = [
      getInsuranceCompanyList(),
      getStateListInsuranceListing(),
      getArManagerPayorTypeList(),
      getSiteList(),
      getInsuranceCompanyBillingOrganizationList(),
      getKeyValueDataForContractStatus(),
      getKeyValueDataForPayorPricing(),
      getAllQuestionAnswerValueList(),
      getBillingInvoiceType(),
      getBillingQualifier(),
      getBillerList(),
    ];

    actions.forEach((action) => dispatch(action));
  }, [dispatch]);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };

    // @ts-ignore
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const filteredInsuranceCompanies = (
    activeIndex === 0
      ? insuranceCompanyActiveList
      : activeIndex === 1
      ? insuranceCompanyInactiveList
      : allinsuranceCompanyList
  ).filter((company: any) => {
    const companyStates: string[] = Array.isArray(company.states_contract)
      ? company.states_contract
      : company.states_contract
      ? company.states_contract.split(",").map((state: string) => state.trim())
      : [];

    const validSelectedState: string[] = Array.isArray(selectedState)
      ? selectedState
      : [];

    const stateMatch =
      validSelectedState.length === 0 ||
      validSelectedState.some((selectedStateValue: string) =>
        companyStates.some(
          (companyStateValue: string) =>
            companyStateValue === selectedStateValue
        )
      );

    return stateMatch;
  });

  const paginatedData = paginateData(filteredInsuranceCompanies, 18);

  const handleClearFilter = () => {
    setGlobalFilterValue("");
    setSelectedState([]);
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
  };

  return (
    <>
      <div className="insurance">
        <ToastContainer />
        <div className="flex gap-2 mb-3">
          <Button
            className={`custom-tab ${activeIndex === 0 ? "active" : ""}`}
            icon={
              <img src={ActiveIcon} alt="user role" className="mr-2 w-1rem" />
            }
            label="Active"
            onClick={() => setActiveIndex(0)}
            disabled={isDisabled || !activeRead}
          />
          <Button
            className={`custom-tab ${activeIndex === 1 ? "active" : ""}`}
            icon={
              <img src={InactiveIcon} alt="user role" className="mr-2 w-1rem" />
            }
            label="Inactive"
            onClick={() => setActiveIndex(1)}
            disabled={isDisabled || !inactiveRead}
          />
          <Button
            className={`custom-tab ${activeIndex === 2 ? "active" : ""}`}
            icon={
              <img
                src={AllactiveIcon}
                alt="user role"
                className="mr-2 w-1rem"
              />
            }
            label="All"
            onClick={() => setActiveIndex(2)}
            disabled={isDisabled || !allRead}
          />
        </div>
        <div className="flex justify-content-between align-items-center mb-2">
          <div className="grid w-8">
            <div className="col-3 pr-0">
              <InputField
                globalFilterValue={globalFilterValue}
                onGlobalFilterChange={onGlobalFilterChange}
                placeholder="Search"
                id="search"
                disabled={isDisabled}
              />
            </div>
            <div className="col-9">
              <div className="flex gap-2">
                <MultiSelect
                  className="w-12 min-w-10rem"
                  value={selectedState}
                  filters={filters}
                  data={stateListInsuranceListing?.map((option: any) => ({
                    label: option.state_code,
                    value: option.state_code,
                  }))}
                  label="label"
                  disabled={isDisabled}
                  filterValue="label"
                  virtualScrollerOptions={{ itemSize: 50 }}
                  placeholder="Select State"
                  onChange={(e: any) => setSelectedState(e.value)}
                />
                <Button
                  label="Reset"
                  outlined
                  disabled={isDisabled}
                  onClick={() => {
                    handleClearFilter();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="grid mx-0">
            <div
              className="cursor-pointer flex-center mr-2"
              title="Download Pdf"
            >
              <Button
                className="pdf-btn mr-0"
                icon={() => <img src={pdfIcon} alt="pdf-icon" />}
                disabled={isDisabled || selectedState.length === 0}
                onClick={async () => {
                  const blob = await pdf(
                    <InsurancePdfConverter
                      data={paginatedData}
                      selectedState={selectedState}
                    />
                  ).toBlob();
                  saveAs(blob, "InsuranceList.pdf");
                }}
              />
            </div>
            <Button
              label="Add Insurance"
              disabled={isDisabled || !getPermission(activeIndex)}
              onClick={() => {
                setVisible(true);
                setAction("add");
              }}
            />
          </div>
        </div>
        {isDisabled ? (
          <Loader location={location} path="/insurance" label="Insurance" />
        ) : (
          <IsuranceDataTable
            data={filteredInsuranceCompanies}
            filters={filters}
            index={activeIndex}
            setAction={setAction}
            action={action}
          />
        )}
      </div>
      <AddNewInsuranceDialogBox
        visible={visible}
        index={activeIndex}
        setVisible={setVisible}
        action={action}
        setAction={setAction}
      />
    </>
  );
};

export default Insurance;
