import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
 
const baseUrl = process.env.REACT_APP_BASEURL;
 
// GET Method ForNotesManager data
export const billingNoteSubjectList = createAsyncThunk(
    "NoteManager/billingNoteSubjectList",
    async () => {
      try {
        const endpoint = `${baseUrl}/api/NoteManager/billingNoteSubjectList`;
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data;
        return data;
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    }
  );

  export const getEmployeeList = createAsyncThunk(
    "notesmanager/getEmployeeList",
    async () => {
      try {
        const endpoint = `${baseUrl}/api/NoteManager/employeeList`;
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data;
        return data;
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    }
  );

  export const getNotesManagerData = createAsyncThunk(
    "notesmanager/getNotesManagerData",
    async ({ data }: { data: any }, apiThunk) => {
      try {
        const endpoint = `${baseUrl}/api/NoteManager/getNoteManagerData`;
        const response = await axios.post(endpoint, data, {
          headers: {
            Accept: "application/json;odata=verbose",
            "Content-Type": "application/json;odata=verbose",
          },
        });
  
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
  
        return response.data;
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    }
  );

  //Get Invoice Data

  export const getNotesManagerPatientInvoicesID = createAsyncThunk(
    "notesmanager/GetNotesManagerPatientInvoicesID",
    async () => {
      try {
        const endpoint = `${baseUrl}/api/ArManager/GetPatientInvoicesID`;
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data;
        return data;
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    }
  );
  
  //Post Method
  export const addUpdateNotesManagerBillingNotes = createAsyncThunk(
    "arManager/AddUpdateNotesManagerBillingNotes",
    async ({ data,callback }: { data: any,callback: any}, apiThunk) => {
      try {
        const endpoint = `${baseUrl}/api/NoteManager/AddUpdateNotesManager`;
        const response = await axios.post(endpoint, data);

        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        if (callback && response.data.success) {
          callback(response);
        }
          } catch (error) {
            console.error("Error fetching user details", error);
          }
    }
  );

  export const getPatientBasicInfoMRN = createAsyncThunk(
    "NotesManager/getPatientBasicInfoMRN",
    async (pat_mrn: any, apiThunk) => {
      try {
        // Construct query parameters from the function arguments
        const endpoint = `${baseUrl}/api/PatientHome/GetPatientBasicInfo/${pat_mrn}`;
  
        const response = await axios.post(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data;
        return data;
      } catch (error) {
        console.error("Error fetching line item parent", error);
        throw error; // Throw the error to be caught by redux-thunk's rejected action handler
      }
    }
  );

  //update C column data
  export const UpdateCcolumnBillingNotes = createAsyncThunk(
    "notesmanager/UpdateCcolumnBillingNotes",
    async ({ data }: { data: any }, apiThunk) => {
      try {
        const endpoint = `${baseUrl}/api/NoteManager/UpdateCcolumnBillingNotes`;
        const response = await axios.post(endpoint, data, {
          headers: {
            Accept: "application/json;odata=verbose",
            "Content-Type": "application/json;odata=verbose",
          },
        });
  
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
  
        return response.data;
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    }
  );



 
interface NotesManagerState {
    loading: boolean;
    subjectList: any;
    notesmanagerdata: any;
    invoices: any;
    employeeList:any;
    error: string | null;
    userEmail:any;
    patbasicinfo:any
  }
 
// Initial state for the fax slice
const initialState: NotesManagerState = {
    error: null,
    subjectList: [],
    notesmanagerdata:[],
    invoices:[],
    employeeList:[],
    loading: false,
    userEmail: '',
    patbasicinfo:[]
}
 
// Insurance Slice
const NotesManagerSlice = createSlice({
    name: 'insurance',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.userEmail = action.payload
        },
        resetNotesManagerData: (state) => {
          state.notesmanagerdata = []; // Reset notesmanagerdata to its initial state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(billingNoteSubjectList.pending, (state, action) => {
                state.loading = true
            })
            .addCase(billingNoteSubjectList.fulfilled, (state, action) => {
                state.subjectList = action.payload?.data
                state.loading = false
            })
            .addCase(billingNoteSubjectList.rejected, (state, action) => {
                state.error = action.error.message || ""
                state.loading = false
            })
            .addCase(getEmployeeList.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getEmployeeList.fulfilled, (state, action) => {
                state.employeeList = action.payload?.data
                state.loading = false
            })
            .addCase(getEmployeeList.rejected, (state, action) => {
                state.error = action.error.message || ""
                state.loading = false
            })
            .addCase(getNotesManagerData.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getNotesManagerData.fulfilled, (state, action) => {  
                state.notesmanagerdata = action.payload?.data
                state.loading = false
            })
            .addCase(getNotesManagerData.rejected, (state, action) => {
                state.error = action.error.message || ""
                state.loading = false
            })
            .addCase(getNotesManagerPatientInvoicesID.pending, (state, action) => {
              state.loading = true
          })
          .addCase(getNotesManagerPatientInvoicesID.fulfilled, (state, action) => {
              state.invoices = action.payload?.data
              state.loading = false
          })
          .addCase(getNotesManagerPatientInvoicesID.rejected, (state, action) => {
              state.error = action.error.message || ""
              state.loading = false
          })
          .addCase(getPatientBasicInfoMRN.pending, (state, action) => {
            state.loading = true
        })
        .addCase(getPatientBasicInfoMRN.fulfilled, (state, action) => {
            state.patbasicinfo = action.payload?.data
            state.loading = false
        })
        .addCase(getPatientBasicInfoMRN.rejected, (state, action) => {
            state.error = action.error.message || ""
            state.loading = false
        })
        }
    });
 
    export const { setEmail,resetNotesManagerData } = NotesManagerSlice.actions
export default NotesManagerSlice.reducer