import React, { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import Filterdata from "../../components/Filter/FaxFilter/FilterData";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchFaxData,
    toggleHistoryModal,
    getFaxHistory,
    getDocumentCatogory,
    getDocumentDescription,
} from "../../redux/store/FaxSliceReducer";
import ArchiveFaxDataTable from "./ArchiveFaxDataTable";
import HistoryModal from "../junk-fax/History-modal";
import GlobalFaxFilter from "../../components/Filter/FaxFilter/GlobalFilter";
import FaxLineFilter from "../../components/Filter/FaxFilter/FaxLineFilter";
import DateRangeFilter from "../../components/Filter/FaxFilter/DateRangeFilter";
import { AppDispatch, RootState } from "../../redux/store";
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { useMemo } from "react";

type FaxItem = {
    fax_detail_id: string;
    fax_line: string;
    fax_number: string;
    caller: string;
    pages: string;
    assignment_status: string;
    date_and_time: string;
    file_name: string;
    doc_category_description: string;
};

const ArchiveFax = () => {

    const dispatch = useDispatch<AppDispatch>();

    const { loading, archiveData, allFaxData, categoryOptions, DocumentDescription } = useSelector((state: RootState) => state.FaxSliceReducer);

    // State Variables
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [filteredData, setFilteredData] = useState<FaxItem[]>(archiveData);

    // Filters state
    const [filters, setFilters] = useState({
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        fax_line: { value: [] as string[], matchMode: FilterMatchMode.IN },
        fax_number: { value: "", matchMode: FilterMatchMode.CONTAINS },
        caller: { value: "", matchMode: FilterMatchMode.CONTAINS },
        pages: { value: "", matchMode: FilterMatchMode.CONTAINS },
        assignment_status: { value: "", matchMode: FilterMatchMode.CONTAINS },
        archive_line: { value: [], matchMode: FilterMatchMode.IN },
    });

    const [filters2, setFilters2] = useState({
        category: [] as string[],
        description: [] as string[],
    });

    const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
    const [faxFilterValue, setFaxFilterValue] = useState<string>("");

    useEffect(() => {
        const filtered = archiveData.filter((item) => {
            const itemDate = new Date(item.date_and_time);
            const inDateRange =
                startDate && endDate
                    ? itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
                    : true;

            return inDateRange;
        });
        setFilteredData(filtered);
    }, [startDate, endDate, archiveData]);

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilters((prev) => ({
            ...prev,
            global: { ...prev.global, value },
        }));
        setGlobalFilterValue(value);
    };

    const onFaxFilterChange = (e: { value: string[] }) => {
        const value = e.value;
        setFilters((prev) => ({
            ...prev,
            fax_line: { ...prev.fax_line, value },
        }));
    };

    const onCategoryChange = (e: { value: string[] }) => {
        const value = e.value;
        setFilters2((prev) => ({
            ...prev,
            category: value,
        }));
    };

    const onDescriptionChange = (e: { value: string[] }) => {
        const value = e.value;
        setFilters2((prev) => ({
            ...prev,
            description: value,
        }));
    };

    useEffect(() => {
        if (allFaxData?.length === 0) {
            dispatch(fetchFaxData());
        }
    }, []);

    useEffect(() => {
        // Dispatch action to fetch categories on component mount
        dispatch(getDocumentCatogory());
    }, []);

    useEffect(() => {
        dispatch(getDocumentDescription())
    }, []);

    const undoBody = (rowData: FaxItem) => (
        <div className="d-flex justify-content-center">
            <div
                onClick={() => {
                    dispatch(getFaxHistory(Number(rowData.fax_detail_id))); // Fetch fax history
                    dispatch(toggleHistoryModal({ historyModal: true, historyModalHeader: rowData.file_name })); // Open modal
                }}
                className="archive-btn edit-icon py-1"
            >
                <i className="pi pi-history" /> {/* History icon */}
            </div>
        </div>
    );

    const resetFilters = () => {
        setStartDate(undefined);
        setEndDate(undefined);
        setFilters({
            global: { value: "", matchMode: FilterMatchMode.CONTAINS },
            fax_line: { value: [], matchMode: FilterMatchMode.IN }, // Change here
            fax_number: { value: "", matchMode: FilterMatchMode.CONTAINS },
            caller: { value: "", matchMode: FilterMatchMode.CONTAINS },
            pages: { value: "", matchMode: FilterMatchMode.CONTAINS },
            assignment_status: { value: "", matchMode: FilterMatchMode.CONTAINS },
            archive_line: { value: [], matchMode: FilterMatchMode.IN },
        });
        setFilters2({
            category: [],
            description: [],
        });
        setGlobalFilterValue("")
    };

    const faxLineOptions = useMemo(() => {
        const uniqueLines = Array.from(new Set(archiveData?.map(item => item.fax_line)));
        return uniqueLines.map(line => ({
            label: line === null ? "Blank" : line, // Show "Blank" for null values
            value: line === null ? line : line // Map null values to "_blank"
        }));
    }, [archiveData]);

    const categoryFinalOptions = useMemo(() => {
        // const uniqueLines = Array.from(new Set(categoryOptions?.map(item => item.fax_line)));
        return categoryOptions.map(line => ({
            label: line.document_category === null ? "Blank" : line.document_category?.trim(), // Show "Blank" for null values
            value: line.document_category === null ? null : line.document_category?.trim() // Map null values to "_blank"
        }));
    }, [categoryOptions]);

    const DescriptionOptions = useMemo(() => {
        // const uniqueLines = Array.from(new Set(categoryOptions?.map(item => item.fax_line)));
        return DocumentDescription.map(line => ({
            label: line.document_description === null ? "Blank" : line.document_description?.trim(), // Show "Blank" for null values
            value: line.document_description === null ? null : line.document_description?.trim() // Map null values to "_blank"
        }));
    }, [DocumentDescription]);

    const finalFilteredData = useMemo(() => {
        if ((!filters2.category || filters2.category.length === 0) &&
            (!filters2.description || filters2.description.length === 0)) {
            return filteredData;
        }
        const filteredArray = filteredData.filter((item) => {
            const docDesc = item?.doc_category_description;

            // Check if any selected category and description match
            const matchesCategory = filters2.category?.some((category) =>
                docDesc?.includes(category)
            );
            const matchesDescription = filters2.description?.some((description) =>
                docDesc?.includes(description)
            );

            return matchesCategory || matchesDescription;
        });
        return filteredArray;
    }, [filteredData, filters2]);

    return (
        <div>
            {/* <Filterdata
                filtersCompo={
                    <div className="position-relative w-75">
                        <div className="row dataflex filter-header">
                            <div className="col-md-3 pe-1">
                                <div className="search-bar">
                                    <GlobalFaxFilter
                                        globalFilterValue={globalFilterValue}
                                        onGlobalFilterChange={onGlobalFilterChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 pe-1">
                                <DateRangeFilter
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                />
                            </div>
                            <div className="col-md-2  pe-1 dropdown-filter">
                                <MultiSelect
                                    value={filters.fax_line.value}
                                    options={faxLineOptions} // Assuming this is how fax lines are defined
                                    onChange={onFaxFilterChange}
                                    placeholder="Select Fax Lines"
                                    // display="chip" // Optional, for a better UI
                                    maxSelectedLabels={1}
                                    panelClassName="dropdown-filter-panel"
                                    filter
                                />
                            </div>
                            <div className='col-auto'>
                                <Button onClick={resetFilters} className="fax-reset-btn">Reset</Button>
                            </div>
                        </div>
                    </div>
                }
                setFilters={setFilters}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
            /> */}
            <Filterdata
                filtersCompo={
                    <div className="relative w-12">
                        <div className="grid align-items-center filter-header">
                            <div className="col-12 sm:col-2 pr-1">
                                <div className="search-bar">
                                    <GlobalFaxFilter
                                        globalFilterValue={globalFilterValue}
                                        onGlobalFilterChange={onGlobalFilterChange}
                                    />
                                </div>
                            </div>
                            <div className="col-12 sm:col-3 pr-1">
                                <DateRangeFilter
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                />
                            </div>
                            <div className="col-12 sm:col-2 pr-1 dropdown-filter">
                                <MultiSelect
                                    value={filters.fax_line?.value || []}
                                    options={faxLineOptions}
                                    onChange={onFaxFilterChange}
                                    placeholder="Select Fax Lines"
                                    style={{ width: '100%' }}
                                    maxSelectedLabels={1}
                                    panelClassName="dropdown-filter-panel"
                                    filter
                                />
                            </div>
                            <div className="col-12 sm:col-2 pr-1 dropdown-filter">
                                <MultiSelect
                                    value={filters2.category || []}
                                    options={categoryFinalOptions.sort((a, b) => a.label.trim().localeCompare(b.label.trim()))}
                                    onChange={onCategoryChange}
                                    placeholder="Select Category"
                                    style={{ width: '100%' }}
                                    maxSelectedLabels={1}
                                    panelClassName="dropdown-filter-panel"
                                    filter
                                />
                            </div>
                            <div className="col-12 sm:col-2 pr-1 dropdown-filter">
                                <MultiSelect
                                    value={filters2.description || []}
                                    options={DescriptionOptions.sort((a, b) => a.label.trim().localeCompare(b.label.trim()))}
                                    onChange={onDescriptionChange}
                                    placeholder="Select Description"
                                    style={{ width: '100%' }}
                                    maxSelectedLabels={1}
                                    panelClassName="dropdown-filter-panel"
                                    filter
                                />
                            </div>
                            <div className="col-auto">
                                <Button onClick={resetFilters} className="fax-reset-btn">Reset</Button>
                            </div>
                        </div>
                    </div>
                }
                setFilters={setFilters}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />

            <div className="card border-0  mt-0">
                <div className="table-responsive fax-table" style={{ overflowX: "auto" }}>
                    <ArchiveFaxDataTable
                        data={finalFilteredData}
                        filters={filters}
                        loading={loading}
                        undoBody={undoBody} // Pass the undoBody function
                    />
                </div>
            </div>
            <HistoryModal /> {/* History modal component */}
        </div>
    );
};

export default ArchiveFax;