import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Delete, GeneratePDFNM, Historyicon, IconViewNM, SortDecre, SortIncre } from "../../assets/images/Icon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomTooltip from "../Tooltip/CustomTooltip";
import ResendDialogBox from "../Dialog/PrescribersworkflowDialogbox/ResendDialogBox";
import DeleteDialogBox from "../Dialog/PrescribersworkflowDialogbox/DeleteDialogBox";
import HistoryPopupforPrescribersWorkflow from "../Dialog/PrescribersworkflowDialogbox/HistoryPopupforPrescribersWorkflow";

const PrescribersWorkflowDatatable = ({
  WorkflowData,
}: {
  WorkflowData: any;
}) => {


  const [visible, setVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleHistory, setVisiblehistory] = useState(false);


  const [Rowdata, Setrowdata] = useState([]);

  const pageOptions = [5, 10, 25, 50, 75, 100, 125];

  const dispatch = useDispatch<AppDispatch>();

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  const records = [
    {
      patient_name: "123456",
      prescriber_name: "Smith",
      mrn: "202222",
      status: "45678",
      sent_via: "12/01/2024",
      team: "12/02/2024",
      sent_on: "Retail",
      teamnew: "Specialty",

     
    },
    {
      patient_name: "234567",
      prescriber_name: "Johnson",
      mrn: "214272",
      status: "56789",
      sent_via: "12/03/2024",
      team: "12/04/2024",
      sent_on: "Specialty",
      teamnew: "Specialty",

      
    },
    {
      patient_name: "345678",
      prescriber_name: "Williams",
      mrn: "222810",
      status: "67890",
      sent_via: "12/05/2024",
      team: "12/06/2024",
      sent_on: "Mail Order",
      teamnew: "Specialty",

     
    },
    {
      patient_name: "456789",
      prescriber_name: "Brown",
      mrn: "210492",
      status: "78901",
      sent_via: "12/07/2024",
      team: "12/08/2024",
      sent_on: "Retail",
      teamnew: "Specialty",

      
    },
    {
      patient_name: "567890",
      prescriber_name: "Davis",
      mrn: "202222",
      status: "89012",
      sent_via: "12/09/2024",
      team: "12/10/2024",
      sent_on: "Specialty",
      teamnew: "Specialty",

      
    },
  ];
   const  OnResendClick=(rowdata :any)=>{
    setVisible(true);
    Setrowdata(rowdata)
  
   }

   const  OnDeleteClick=(rowdata :any)=>{
    setVisibleDelete(true);
    Setrowdata(rowdata)
  
   }
   const  OnHistoryClick=(rowdata :any)=>{
    setVisiblehistory(true);
    Setrowdata(rowdata)
  
   }



  //Columns
  const columns = [
    { field: "patient_name", header: "Patient Name", sortable: true },
    { field: "prescriber_name", header: "Prescriber Name", sortable: true },
    { field: "mrn", header: "MRN", sortable: true },
    { field: "status", header: "Status", sortable: true },
    {field: "sent_via",header: "Sent Via",sortable: true},
    { field: "teamnew", header: "Team", sortable: true },
    { field: "sent_on", header: "Sent on", sortable: true },
   
  ];

  // Action icons template
  const actionTemplate = (rowData: any) => (
      <div className="action-icons" style={{ display: "flex", gap: "10px" }}>
        <CustomTooltip target=".Prescriberworkflow" position="bottom" />
        <img
          src={Historyicon}
          alt="Resend"
          onClick={() =>OnResendClick(rowData)}
          width={20}
          height={20}
          data-pr-tooltip="Resend"
          className="Prescriberworkflow icon clickable"
        />
         <img
          src={Historyicon}
          alt="Upload"
          // onClick={(e) => {
          //   e.stopPropagation();
          //   onTrackingNoClick(rowData.tracking_no);
          // }}
          width={20}
          height={20}
          data-pr-tooltip="Upload"
          className="Prescriberworkflow icon clickable"
          
        />
         <img
          src={Historyicon}
          alt="Refax"
          onClick={() =>OnResendClick(rowData)}
          width={20}
          height={20}
          data-pr-tooltip="Refax"
          className="Prescriberworkflow icon clickable"
        />
        <img
          src={Historyicon}
          alt="View History"
          onClick={() =>OnHistoryClick(rowData)}
          width={20}
          height={20}
          data-pr-tooltip="View History"
          className="Prescriberworkflow icon clickable"
        />
        <img
          src={GeneratePDFNM}
          alt="PDF Download"
          onClick={(e) => {

            // setFolderpath(rowData);

            // setVisible(true);
          }}
          width={20}
          height={20}
          data-pr-tooltip="PDF Download"
          className="Prescriberworkflow icon clickable"
        />
        
        <img
          src={Delete}
          alt="Delete Record"
          onClick={() =>OnDeleteClick(rowData)}
          width={20}
          height={20}
          data-pr-tooltip="Delete Record"
          className="Prescriberworkflow icon clickable"
        />
      </div>
    );

  return (
    <>
      <DataTable
        // filteredStatuslist={filteredStatuslist}
        value={records}   //WorkflowData
        scrollable
        removableSort
        scrollHeight="645px"
        paginator
        // style={{ fontSize: "0.1rem !important" }}
        sortIcon={sortIcon}
        // onRowClick={(e) => onRowSelect(e, e.data)}
        selectionMode="single"
        rowsPerPageOptions={pageOptions}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rows={25}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        //filters={filters}
        // loading={loading}
        globalFilterFields={columns.map((column) => column.field)}
        emptyMessage="No Data Found"
      >
        <Column headerStyle={{ paddingLeft: "3px" }}></Column>

        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            // body={column.body}
            sortable={column.sortable}
            // bodyStyle={{ fontSize: "0.1rem !important" }} // Inline style for specific column
          />
        ))}
        {/*add action column */}
        <Column
          header="Actions" body={actionTemplate}
        />
      </DataTable>
      <ResendDialogBox visible={visible} setVisible={setVisible}  rowdata={Rowdata} />
      <DeleteDialogBox visible ={visibleDelete } setVisible={setVisibleDelete} rowdata={Rowdata} />
      <HistoryPopupforPrescribersWorkflow visible={visibleHistory} setVisible={setVisiblehistory} Treatmentid={Rowdata} />
    </>
  );
};

export default PrescribersWorkflowDatatable;
