import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { Column } from "primereact/column";
import {
    RolePrivileges,
    SortDecre,
    SortIncre,
} from "../../../assets/images/Icon";
import { FormatDate } from "../../../utils/formatDate";
import Header from "../../../layouts/Header/Header";
import { getDeliveryTicketItemList } from "../../../redux/store/ARManagerReducer";
import { formatCurrency } from "../../../utils/formatCurrency";
import InputDropdown from "../../formElement/InputDropdown";
import { data } from "../../../types/IHealthAppTypes";
import InputCheck from "../../formElement/InputCheck";
import { Button } from "primereact/button";
import Input from "../../formElement/Input";
import { useState } from "react";

const AddNewRegisterDialogBox = ({
    visible,
    setVisible,
}: {
    visible: boolean;
    setVisible: any;
}) => {

    const customHeader = () => {
        return (
            <>
                <div className="dialog-header flex gap-2">
                    <div className="header-icon mr-2">
                        <img className="mt-2" src={RolePrivileges} alt="Role Privileges" />
                    </div>
                    <div className="header-title">
                        <div className="text-xl">Register New User </div>
                        <div className="text-sm font-medium header-subtitle">
                            To register new privileges at the section level
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const customFooter = () => {
        return (
            <div className="outlined flex justify-content-end gap-2">
                <Button
                    label="Cancel"
                    outlined
                    className="w-7rem"
                    onClick={() => setVisible(false)}
                />
                <Button label="Save" className="w-7rem" />
            </div>
        );
    };
    const [isPrescriberChecked, setIsPrescriberChecked] = useState(false);
    const [selectedUserName, setSelectedUserName] = useState("");
    const [UserList, setUserList] = useState([
        { user_name: "John Doe", user_id: "1" },
        { user_name: "Jane Smith", user_id: "2" },
        // Add more users here
    ]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPrescriberChecked(event.target.checked);
        if (!event.target.checked) {
            setSelectedUserName(""); // Reset the dropdown value if unchecked
        }
    };
    return (
        <>
            <Dialog
                style={{ width: "30vw", zIndex: 1111 }}
                draggable
                header={customHeader}
                footer={customFooter}
                resizable
                visible={visible}
                onHide={() => setVisible(false)}
            >
                <div className=" ml-2">
                    <div className="pt-2">
                        <label className="ml-3 text-sm">First Name</label>
                        <div className="col-12 pt-0">
                            <Input
                                id="Allrole"
                                type="text"
                                labelclassName="custom-inputwd"
                                label=""
                                // // value={selectedRoleId || ""}
                                // options={permissionsList.map((role: data) => ({
                                //     label: role.role_name,
                                //     value: role.role_id,
                                // }))}
                                // handleChange={(event: any) => setSelectedRoleId(event.target.value)}
                                placeholder="First Name"
                            />
                        </div>
                    </div>
                    <div className="pt-2">
                        <label className="ml-3 text-sm">Last Name</label>
                        <div className="col-12 pt-0">
                            <Input
                                id="Allrole"
                                type="text"
                                labelclassName="custom-inputwd"
                                label=""
                                // // value={selectedRoleId || ""}
                                // options={permissionsList.map((role: data) => ({
                                //     label: role.role_name,
                                //     value: role.role_id,
                                // }))}
                                // handleChange={(event: any) => setSelectedRoleId(event.target.value)}
                                placeholder="Last Name"
                            />
                        </div>
                    </div>
                    <div className="pt-2">
                        <label className="ml-3 text-sm">Email ID</label>
                        <div className="col-12 pt-0">
                            <Input
                                id="Allrole"
                                type="text"
                                labelclassName="custom-inputwd"
                                label=""
                                // // value={selectedRoleId || ""}
                                // options={permissionsList.map((role: data) => ({
                                //     label: role.role_name,
                                //     value: role.role_id,
                                // }))}
                                // handleChange={(event: any) => setSelectedRoleId(event.target.value)}
                                placeholder="Enter Email"
                            />
                        </div>
                    </div>

                    <InputCheck
                        className="cheak"
                        labelclassName="col-3"
                        checkboxclassName="col-3"
                        id="isPrescriber"
                        checked={isPrescriberChecked}
                        handleChange={handleCheckboxChange}
                        label="Is Prescriber?"
                    />

                    {/* Dropdown */}
                    {isPrescriberChecked && (
                          <div className="col-6 pt-0">
                        <InputDropdown
                            id="username"
                            type="text"
                            placeholder="Search Prescriber"
                            labelclassName="col-2"
                            value={selectedUserName}
                            options={UserList.map((user) => ({
                                label: user.user_name,
                                value: user.user_id,
                            }))}
                            handleChange={(event: any) => setSelectedUserName(event.target.value)}
                        />
                         </div>
                    )}


                </div>
            </Dialog>
        </>
    );
};

export default AddNewRegisterDialogBox;
