// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASEURL;


// POST Method For get filter FaxDirectory data
export const getFaxDirectoryData = createAsyncThunk(
    "faxDirectory/getFaxDirectoryData",
    async ({ updateData }: { updateData: any }, apiThunk) => {
        try {

            const endpoint = `${baseUrl}/api/FaxModule/getFaxDirectoryList/${updateData}`;
            const response = await axios.get(endpoint);



            if (response.status !== 200) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response?.data;
            return data;
        } catch (error) {
            console.error("Error fetching user details", error);
        }
    }
);
//Update Owner name
export const UpdateFaxOwnername = createAsyncThunk(
    "faxDirectory/UpdateFaxOwnername",
    async ({ data, search_string }: { data: any; search_string: string }, apiThunk) => {

        try {
            const endpoint = `${baseUrl}/api/FaxModule/updateFaxDirectoryOwnerName`;
            // const response = await fetch(endpoint, {
            //     method: "POST",
            //     headers: {
            //         Accept: "application/json;odata=verbose",
            //         "Content-Type": "application/json;odata=verbose",
            //     },
            //     credentials: "same-origin",
            //     body: JSON.stringify(data),
            // })

            const response = await axios.post(endpoint, data, {
                headers: {
                    Accept: "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose",
                },
            });
            if (response.status !== 200) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            await apiThunk.dispatch(getFaxDirectoryData({ updateData: search_string }));
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

        } catch (error) {
            console.error("Error fetching user details", error);
        }
    }
);


export const FaxDrectorySlice = createSlice({
    name: 'faxDirectory',
    initialState: {
        FaxDirectoryData: [],
        errorMessage: "",
        loading: false,
        filterloading: false
    },
    reducers: {
        resetFilter: (state) => {
            state.FaxDirectoryData = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFaxDirectoryData.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getFaxDirectoryData.fulfilled, (state, action) => {
                state.FaxDirectoryData = action.payload?.data
                state.loading = false
            })
            .addCase(getFaxDirectoryData.rejected, (state, action) => {
                state.errorMessage = action.error.message || ""
                state.loading = false
            })
            .addCase(UpdateFaxOwnername.pending, (state, action) => {
                state.loading = true
            })
            .addCase(UpdateFaxOwnername.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(UpdateFaxOwnername.rejected, (state, action) => {
                state.errorMessage = action.error.message || ""
                state.loading = false
            })

    }
})

    ;
export const { resetFilter } = FaxDrectorySlice.actions
export default FaxDrectorySlice.reducer