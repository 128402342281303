import React from "react";
import "../../../assets/css/filter.css";
import { useLocation } from "react-router-dom";
import Excel from "../../../assets/images/appointment-excel.svg";
import Delete from "../../../assets/images/appointment-delete.svg";
import Calendar from "../../../assets/images/appointment-calendar.svg";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
type FilterMeta = {
    value: string | null;
    matchMode: FilterMatchMode;
};

interface FilterdataProps {
    filtersCompo: React.ReactNode;
    setFilters?: React.Dispatch<React.SetStateAction<any>>;
    setStartDate?: React.Dispatch<React.SetStateAction<any>>;
    setEndDate?: React.Dispatch<React.SetStateAction<any>>;
    setshowcalander?: React.Dispatch<React.SetStateAction<boolean>>;  // Set the state setter to accept a boolean
}

const AppointmentsHead = ({ filtersCompo, setFilters, setStartDate, setEndDate, setshowcalander }: FilterdataProps) => {
    const toggleCalendar = () => {
        // Manually toggle between true and false
        setshowcalander?.(prev => !prev);
    };

    return (
        <div className="flex justify-content-between align-items-center md:flex-row flex-wrap">
            {filtersCompo}
            <div className="d-flex align-items-center  mb-2 w-25 justify-content-end">
                <div className="flex justify-content-between align-items-center cursor-pointer">
                    <div className="excel">
                        <Button
                            style={{ background: "transparent", border: "none" }}
                            className="p-0"
                            icon={() => (
                                <img src={Excel} alt="Excel" title="Excel Export" />
                            )}
                        />
                    </div>
                    <div className="excel">
                        <Button
                            style={{ background: "transparent", border: "none" }}
                            className="p-0"
                            icon={() => (
                                <img src={Delete} alt="Delete" title="Delete" />
                            )}
                        />
                    </div>
                    <div className="excel">
                        <Button
                            style={{ background: "transparent", border: "none" }}
                            className="p-0"
                            icon={() => (
                                <img src={Calendar} alt="Calendar" title="Calendar View" />
                            )}
                            onClick={toggleCalendar}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppointmentsHead;
