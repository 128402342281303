import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable'
import { SortDecre, SortIncre } from '../../../assets/images/Icon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { AddAgencyList } from '../../../redux/store/AppointmentsReducer';

type AddAgencyDatatableProps = {
  filters: any;
  globalFilterFields: string[];
};

const AddAgencyDatatable = ({ filters, globalFilterFields }: AddAgencyDatatableProps) => {

  const { addAgencyList } = useSelector((state: RootState) => state.AppointmentsReducer);
  const [rowClick, setRowClick] = useState(false);
  const [checked, setChecked] = useState<AddAgencyList[]>([]);

  const onRowSelectChange = (e: { value: AddAgencyList[]}) => {
    setChecked(e.value.length > 0 ? [e.value[0]] : []); // Allow only one row to be checked
  };
  const isCheckboxDisabled = (rowData: AddAgencyList) => {
    return (
      checked.length > 0 &&
      !checked.some((selected) => selected.company_id === rowData.company_id)
    );
  };

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };
  const columns = [
    { field: "first_name", header: "Nurse", sortable: true },
    { field: "agency_name", header: "Agency", sortable: true },
  ];

  return (<>
    <div className='add-agency'>
      <DataTable
        scrollable
        removableSort
        scrollHeight="580px"
        value={addAgencyList}
        sortIcon={sortIcon}
        selection={checked}
        onSelectionChange={onRowSelectChange}
        selectionMode={rowClick ? null : "checkbox"}
        virtualScrollerOptions={{ itemSize: 50 }}
        filters={filters}
        globalFilterFields={globalFilterFields}
        emptyMessage="No Records found."
      >
        <Column 
        body={(rowData) => (
          <Checkbox
            checked={checked.some((selected) => selected.company_id === rowData.company_id)}
            onChange={(e: any) => onRowSelectChange({ value: e.checked ? [rowData] : [] })}
            disabled={isCheckboxDisabled(rowData)}
          />
        )}
        headerStyle={{ width: "3rem" }} 
        />
        <Column field='agency_name' sortable header="Agency" />
        <Column field='first_name' sortable header="Nurse" />
        <Column field='phone' sortable header="Phone" />
        <Column field='' sortable header="Distance" />
      </DataTable>
    </div>
  </>
  )
}

export default AddAgencyDatatable
