import { Dialog } from "primereact/dialog";
import InputDropdown from "../../formElement/InputDropdown";
import { billerList } from "../../../types/IHealthAppTypes";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useState } from "react";
import {
  getArManagerData,
  UpdateArManagerBiller,
} from "../../../redux/store/ARManagerReducer";
import { displayToast } from "../../Toast/ToastMessage";

const AssignToDialogBox = ({
  userEmail,
  visible,
  setVisible,
  data,
  onStatusUpdateLoading,
  onStatusUpdated,
  filterUpdate,
}: {
  userEmail: string | undefined;
  visible: boolean;
  setVisible: any;
  data: string[];
  onStatusUpdateLoading: (isLoading: boolean) => void;
  onStatusUpdated: () => void;
  filterUpdate: string;
}) => {
  const { billerList } = useSelector(
    (state: RootState) => state.arManagerReducer
  );
  const [assign, setAssign] = useState<billerList | undefined>();
  const dispatch = useDispatch<AppDispatch>();
  const newData = data.join(",");

  // Update the Row Status
  const AddAssignTo = async () => {
    onStatusUpdateLoading(true);
    if (assign) {
      const updateAssignTo = {
        cpk_claim: newData,
        employee_id: assign.employee_id,
        loginEmail: userEmail,
      };
      setVisible(false);
      await dispatch(
        UpdateArManagerBiller({ updateData: updateAssignTo, callback: (res: any) => {
            if (res.data.success) {
              displayToast({
                msg: "The collectors for the selected claims has been updated successfully",
                type: "success",
              });
            } else {
              displayToast({
                msg: res?.data?.message || "Something went wrong",
                type: "error",
              });
            }
          },
        })
      );

      dispatch(getArManagerData({ updateData: filterUpdate }));
      onStatusUpdated();
    }
  };

  // Dialog Footer
  const Footer = (
    <div className="buttons flex gap-2 justify-content-end">
      <Button label="Cancel" outlined onClick={() => setVisible(false)} />
      <Button label="Save" onClick={AddAssignTo} />
    </div>
  );

  const handleChange = (e: any) => {
    const selectedAssign = billerList.find(
      (item: billerList) => item.full_name === e.target.value
    );
    if (selectedAssign) {
      setAssign(selectedAssign);
    }
  };

  return (
    <Dialog
      style={{ width: "700px" }}
      draggable={false}
      header="Assing To:"
      footer={Footer}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <InputDropdown
        value={assign?.full_name || ""}
        handleChange={handleChange}
        label="Select Collector"
        labelclassName="col-3"
        dropdownclassName="col-9"
        type="text"
        id="status_code_and_description"
        placeholder="Select Collector"
        filter
        options={billerList.map((assign: billerList) => assign.full_name)}
      />
    </Dialog>
  );
};

export default AssignToDialogBox;
