import React, { useEffect, useMemo, useRef, useState } from "react";
// import "../../../assets/css/notesmanager.css";
import InputField from "../../formElement/InputField";
import MultiSelectFilter from "../../formElement/MultiSelect";
import InputDropdown from "../../formElement/InputDropdown";
import InputDate from "../../formElement/InputDate";
import { Button } from "primereact/button";
import {
  AddFilter,
  AddNotes,
  Close,
  Delete,
  Filter,
} from "../../../assets/images/Icon";
import {
  getEmployeeList,
  getNotesManagerData,
  getNotesManagerPatientInvoicesID,
  resetNotesManagerData,
  billingNoteSubjectList,
} from "../../../redux/store/NotesManagerReducer";
import { AsyncThunk, ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { FormatDate } from "../../../utils/formatDate";
import AddBillingNotesDialogBox from "../../Dialog/NotesManagerDialogBox/AddBillingNotesDialogBox";
import {
  AddNewCustomFilter,
  getCprUser,
  GetCustomFilterList,
  getPatientBillingSubject,
} from "../../../redux/store/ARManagerReducer";
import { OverlayPanel } from "primereact/overlaypanel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { CustomFilterList } from "../../../types/IHealthAppTypes";
import CustomTooltip from "../../Tooltip/CustomTooltip";

interface Type {
  name: string;
}

interface Option {
  label: string;
  value: string;
}

interface NoteMangerType {
  invno: string;
  notes_type: any;
  subject: any;
  fp_from_date: Date | undefined;
  fp_to_date: Date | undefined;
  fp_user_name: any;
  from_date: Date | undefined;
  to_date: Date | undefined;
  user_name: any;
}

const NotesManagerFilter = ({
  setVisible,
  userEmail,
  setNoData,
  setFilterUpdate,
  onStatusUpdateLoading,

}: {
  setVisible: any;
  userEmail: string | undefined;
  //  setFilterUpdate: any;
  setNoData: any;
  setFilterUpdate: any;
  onStatusUpdateLoading: (isLoading: boolean) => void;

}) => {
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  const formatToDateInput = (date: Date) => {
    return date.toISOString().split("T")[0]; // Formats as "YYYY-MM-DD"
  };

  const getMonday = (date: Date) => {
    const monday = new Date(date);
    monday.setDate(date.getDate() - ((date.getDay() + 6) % 7));
    return monday;
  };
  // Function to calculate start date of the quarter
  const getQuarterStartDate = (date: Date) => {
    const quarter = Math.floor((date.getMonth() + 3) / 3);
    return new Date(date.getFullYear(), (quarter - 1) * 3, 1);
  };

  // Function to calculate end date of the quarter
  const getQuarterEndDate = (date: Date) => {
    const quarter = Math.floor((date.getMonth() + 3) / 3);
    return new Date(date.getFullYear(), quarter * 3, 0);
  };

  const [customFilterJson, setCustomFilterJson] = useState("");
  const [filterValue, setFilterValue] = useState<string>("");
  const op = useRef<OverlayPanel>(null);
  const [error, setError] = useState("");
  const [showSaveFilter, setShowSaveFilter] = useState<boolean>(false);
  const [AddBillingNotesVisible, setAddBillingNotesVisible] = useState(false);
  // const [SelectPostedDate, setSelectPostedDate] = useState<{ StartDate: Date; EndDate: Date } | undefined>(undefined);
  const [SelectPostedDate, setSelectPostedDate] = useState<
    | { label: string; value: { ID: number; StartDate: Date; EndDate: Date } }
    | undefined
  >(undefined);
  const [SelectFollowUpDate, setSelectFollowUpDate] = useState<
    | { label: string; value: { ID: number; StartDate: Date; EndDate: Date } }
    | undefined
  >(undefined);

  //new
  const { subjectList, employeeList, notesmanagerdata } = useSelector(
    (state: RootState) => state.notesManagerReducer
  );
  const { customFilterList, filterloading } = useSelector(
    (state: RootState) => state.arManagerReducer
  );
  const dispatch = useDispatch<AppDispatch>();

  const [NotesManagerFilter, setNotesManagerFilter] = useState<NoteMangerType>({
    invno: "",
    notes_type: [],
    subject: [],
    fp_from_date: undefined,
    fp_to_date: undefined,
    fp_user_name: [],
    from_date: undefined,
    to_date: undefined,
    user_name: [],
  });

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setNotesManagerFilter((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleStatusUpdate = () => {
    onStatusUpdateLoading(false);
  };

  const resetFilterHandler = () => {
    setNotesManagerFilter({
      invno: "",
      notes_type: [],
      subject: [],
      fp_from_date: undefined,
      fp_to_date: undefined,
      fp_user_name: [],
      from_date: undefined,
      to_date: undefined,
      user_name: [],
    });
    setNoData(false)
    setShowSaveFilter(false);
    setSelectFollowUpDate(undefined);
    setSelectPostedDate(undefined);
    dispatch(resetNotesManagerData());
  };

  //Fav filter
  const handleCustomFilter = (e: any, op: any) => {
    op.current?.toggle(e);
    dispatch(
      GetCustomFilterList({
        updateData: JSON.stringify({
          email_address: `${userEmail}`,
          preference_name: "Notes_page_filter",
          usage: "USER",
        }),
      })
    );
    // if (customFilterList.length > 0) { setShowSaveFilter(false) };
  };

  const handleSaveFilter = () => {
    const filterExists = customFilterList.some(
      (filter: CustomFilterList) => filter.name === filterValue
    );

    if (filterExists) {
      setError("Filter already exists");
      return;
    }
    if(filterValue){
      dispatch(
        AddNewCustomFilter({
          preference_name: "Notes_page_filter",
          filter_name: filterValue,
          preference_json_value: customFilterJson,
          usage: "USER",
          type: 1,
          custom_preference_id: 0,
          email_address: `${userEmail}`,
        })
      ).then(() => {
        setShowSaveFilter(false);
        refreshCustomFilterList();
      });
    }
  };

  const handleFilterAction = (e: any, rowData: any) => {
    const { innerText } = e.target;

    if (innerText === "Apply Filter") {
      const data = JSON.parse(rowData.json_value);

      dispatch(getNotesManagerData({ data: rowData.json_value })).then(() => {
        setShowSaveFilter(false);
      });
      setNotesManagerFilter({
        invno: data.invno || "",
        notes_type: data.note_type !== "" ? data.note_type?.split(",") : [],
        subject: data.subject !== "" ? data.subject?.split(",") : [],
        fp_from_date: new Date(data.fp_from_date) || undefined,
        fp_to_date: new Date(data.fp_to_date) || undefined,
        fp_user_name:
          data.fp_user_name !== "" ? data.fp_user_name?.split(",") : [],
        from_date: new Date(data.from_date) || undefined,
        to_date: new Date(data.to_date) || undefined,
        user_name: data.user_name !== "" ? data.user_name?.split(",") : [],
      });
    } else {
      dispatch(
        AddNewCustomFilter({
          preference_name: "Notes_page_filter",
          filter_name: rowData?.name,
          preference_json_value: JSON.stringify(rowData.json_value),
          usage: "USER",
          type: 3,
          custom_preference_id: rowData?.custom_preference_id,
          email_address: `${userEmail}`,
        })
      ).then(() => {
        refreshCustomFilterList(); // Refresh table after deleting
      });
    }
  };

  const refreshCustomFilterList = () => {
    dispatch(
      GetCustomFilterList({
        updateData: JSON.stringify({
          email_address: `${userEmail}`,
          preference_name: "Notes_page_filter",
          usage: "USER",
        }),
      })
    );
  };

  const FilterActionBody = (rowData: any) => {
    return (
      <>
        <Button
          outlined
          className="p-1 text-sm"
          label="Apply Filter"
          onClick={(e) => handleFilterAction(e, rowData)}
        />
        <Button
          icon={() => <img src={Delete} alt="Filter" />}
          style={{ background: "transparent", border: "none" }}
          onClick={(e) => handleFilterAction(e, rowData)}
        />
      </>
    );
  };

  // Function to Check Filter Empty
  const isFiltersEmpty = () => {
    const areFiltersEmpty = Object.values(NotesManagerFilter).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return value === "" || value === undefined;
    });
  
    return areFiltersEmpty && notesmanagerdata.length === 0;
  };
  

  useEffect(() => {
    dispatch(billingNoteSubjectList());
    dispatch(getEmployeeList());
    dispatch(getNotesManagerPatientInvoicesID());
    dispatch(getPatientBillingSubject());
    dispatch(getCprUser());
  }, [dispatch]);

  const handlePostedDropdownChange = (e: any) => {
    setSelectPostedDate(e.value);

    if (e.value) {
      const startDate = new Date(e.value.StartDate);
      const endDate = new Date(e.value.EndDate);
      setNotesManagerFilter((prev) => ({
        ...prev,
        to_date: endDate,
        from_date: startDate,
      }));
    }
  };

  const handleFollowUpDropdownChange = (e: any) => {
    setSelectFollowUpDate(e.value);

    if (e.value) {
      const startDate = new Date(e.value.StartDate);
      const endDate = new Date(e.value.EndDate);
      setNotesManagerFilter((prev) => ({
        ...prev,
        fp_from_date: startDate,
        fp_to_date: endDate,
      }));
    }
  };

  const notesTypes = [
    {
      note_type: "BILLING",
    },
    {
      note_type: "PROGRESS",
    },
  ];

  const optionsData = {
    types: [{ name: "BILLING" }, { name: "PROGRESS" }],
    postedDate: [
      {
        label: "Today",
        value: {
          ID: 1,
          StartDate: new Date(todayDate),
          EndDate: new Date(todayDate),
        },
      },
      {
        label: "Yesterday",
        value: {
          ID: 2,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() - 1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() - 1
          ),
        },
      },
      {
        label: "This Week",
        value: {
          ID: 3,
          StartDate: getMonday(new Date(todayDate)),
          EndDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 6 * 24 * 60 * 60 * 1000
          ),
        },
      },
      {
        label: "This Week-to-date",
        value: {
          ID: 4,
          StartDate: getMonday(new Date(todayDate)),
          EndDate: todayDate,
        },
      },
      {
        label: "This Month",
        value: {
          ID: 5,
          StartDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 1),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            0
          ),
        },
      },
      {
        label: "This Month-to-date",
        value: {
          ID: 6,
          StartDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 1),
          EndDate: todayDate,
        },
      },
      {
        label: "This Quarter",
        value: {
          ID: 7,
          StartDate: getQuarterStartDate(new Date(todayDate)),
          EndDate: getQuarterEndDate(new Date(todayDate)),
        },
      },
      {
        label: "This Quarter-to-date",
        value: {
          ID: 8,
          StartDate: getQuarterStartDate(new Date(todayDate)),
          EndDate: todayDate,
        },
      },
      {
        label: "This Year",
        value: {
          ID: 9,
          StartDate: new Date(todayDate.getFullYear(), 0, 1),
          EndDate: new Date(todayDate.getFullYear(), 11, 31),
        },
      },
      {
        label: "This Year-to-date",
        value: {
          ID: 10,
          StartDate: new Date(todayDate.getFullYear(), 0, 1),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Week",
        value: {
          ID: 11,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() - 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(getMonday(new Date(todayDate)).getTime() - 1),
        },
      },
      {
        label: "Last Week-to-date",
        value: {
          ID: 12,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() - 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Month",
        value: {
          ID: 13,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() - 1,
            1
          ),
          EndDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 0),
        },
      },
      {
        label: "Last Month-to-date",
        value: {
          ID: 14,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() - 1,
            1
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Quarter",
        value: {
          ID: 15,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
          EndDate: getQuarterEndDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
        },
      },
      {
        label: "Last Quarter-to-date",
        value: {
          ID: 16,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Year",
        value: {
          ID: 17,
          StartDate: new Date(todayDate.getFullYear() - 1, 0, 1),
          EndDate: new Date(todayDate.getFullYear() - 1, 11, 31),
        },
      },
      {
        label: "Last Year-to-date",
        value: {
          ID: 18,
          StartDate: new Date(todayDate.getFullYear() - 1, 0, 1),
          EndDate: todayDate,
        },
      },
      {
        label: "Tomorrow",
        value: {
          ID: 19,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() + 1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() + 1
          ),
        },
      },
      {
        label: "Next Week",
        value: {
          ID: 20,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 13 * 24 * 60 * 60 * 1000
          ),
        },
      },
      {
        label: "Next Week-to-date",
        value: {
          ID: 21,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(todayDate.getTime() + 7 * 24 * 60 * 60 * 1000),
        },
      },
      {
        label: "Next Month",
        value: {
          ID: 22,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 2,
            0
          ),
        },
      },
      {
        label: "Next Month-to-date",
        value: {
          ID: 23,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            todayDate.getDate()
          ),
        },
      },
      {
        label: "Next Quarter",
        value: {
          ID: 24,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
          EndDate: getQuarterEndDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
        },
      },
      {
        label: "Next Quarter-to-date",
        value: {
          ID: 25,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
          EndDate: new Date(
            getQuarterStartDate(new Date(todayDate)).getTime() +
              (todayDate.getTime() -
                new Date(todayDate.getFullYear(), 0, 1).getTime())
          ),
        },
      },
      {
        label: "Next Year",
        value: {
          ID: 26,
          StartDate: new Date(todayDate.getFullYear() + 1, 0, 1),
          EndDate: new Date(todayDate.getFullYear() + 1, 11, 31),
        },
      },
      {
        label: "Next Year-to-date",
        value: {
          ID: 27,
          StartDate: new Date(todayDate.getFullYear() + 1, 0, 1),
          EndDate: new Date(
            new Date(todayDate.getFullYear() + 1, 0, 1).getTime() +
              (todayDate.getTime() -
                new Date(todayDate.getFullYear(), 0, 1).getTime())
          ),
        },
      },
    ].map((range) => ({
      label: range.label,
      value: {
        ID: range.value.ID,
        StartDate: range.value.StartDate,
        EndDate: range.value.EndDate,
      },
    })),
    followupDate: [
      {
        label: "Today",
        value: { ID: 1, StartDate: todayDate, EndDate: todayDate },
      },
      {
        label: "Yesterday",
        value: {
          ID: 2,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() - 1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() - 1
          ),
        },
      },
      {
        label: "This Week",
        value: {
          ID: 3,
          StartDate: getMonday(new Date(todayDate)),
          EndDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 6 * 24 * 60 * 60 * 1000
          ),
        },
      },
      {
        label: "This Week-to-date",
        value: {
          ID: 4,
          StartDate: getMonday(new Date(todayDate)),
          EndDate: todayDate,
        },
      },
      {
        label: "This Month",
        value: {
          ID: 5,
          StartDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 1),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            0
          ),
        },
      },
      {
        label: "This Month-to-date",
        value: {
          ID: 6,
          StartDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 1),
          EndDate: todayDate,
        },
      },
      {
        label: "This Quarter",
        value: {
          ID: 7,
          StartDate: getQuarterStartDate(new Date(todayDate)),
          EndDate: getQuarterEndDate(new Date(todayDate)),
        },
      },
      {
        label: "This Quarter-to-date",
        value: {
          ID: 8,
          StartDate: getQuarterStartDate(new Date(todayDate)),
          EndDate: todayDate,
        },
      },
      {
        label: "This Year",
        value: {
          ID: 9,
          StartDate: new Date(todayDate.getFullYear(), 0, 1),
          EndDate: new Date(todayDate.getFullYear(), 11, 31),
        },
      },
      {
        label: "This Year-to-date",
        value: {
          ID: 10,
          StartDate: new Date(todayDate.getFullYear(), 0, 1),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Week",
        value: {
          ID: 11,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() - 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(getMonday(new Date(todayDate)).getTime() - 1),
        },
      },
      {
        label: "Last Week-to-date",
        value: {
          ID: 12,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() - 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Month",
        value: {
          ID: 13,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() - 1,
            1
          ),
          EndDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 0),
        },
      },
      {
        label: "Last Month-to-date",
        value: {
          ID: 14,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() - 1,
            1
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Quarter",
        value: {
          ID: 15,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
          EndDate: getQuarterEndDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
        },
      },
      {
        label: "Last Quarter-to-date",
        value: {
          ID: 16,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() - 3, 1)
          ),
          EndDate: todayDate,
        },
      },
      {
        label: "Last Year",
        value: {
          ID: 17,
          StartDate: new Date(todayDate.getFullYear() - 1, 0, 1),
          EndDate: new Date(todayDate.getFullYear() - 1, 11, 31),
        },
      },
      {
        label: "Last Year-to-date",
        value: {
          ID: 18,
          StartDate: new Date(todayDate.getFullYear() - 1, 0, 1),
          EndDate: todayDate,
        },
      },
      {
        label: "Tomorrow",
        value: {
          ID: 19,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() + 1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() + 1
          ),
        },
      },
      {
        label: "Next Week",
        value: {
          ID: 20,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 13 * 24 * 60 * 60 * 1000
          ),
        },
      },
      {
        label: "Next Week-to-date",
        value: {
          ID: 21,
          StartDate: new Date(
            getMonday(new Date(todayDate)).getTime() + 7 * 24 * 60 * 60 * 1000
          ),
          EndDate: new Date(todayDate.getTime() + 7 * 24 * 60 * 60 * 1000),
        },
      },
      {
        label: "Next Month",
        value: {
          ID: 22,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 2,
            0
          ),
        },
      },
      {
        label: "Next Month-to-date",
        value: {
          ID: 23,
          StartDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            1
          ),
          EndDate: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            todayDate.getDate()
          ),
        },
      },
      {
        label: "Next Quarter",
        value: {
          ID: 24,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
          EndDate: getQuarterEndDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
        },
      },
      {
        label: "Next Quarter-to-date",
        value: {
          ID: 25,
          StartDate: getQuarterStartDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth() + 3, 1)
          ),
          EndDate: new Date(
            getQuarterStartDate(new Date(todayDate)).getTime() +
              (todayDate.getTime() -
                new Date(todayDate.getFullYear(), 0, 1).getTime())
          ),
        },
      },
      {
        label: "Next Year",
        value: {
          ID: 26,
          StartDate: new Date(todayDate.getFullYear() + 1, 0, 1),
          EndDate: new Date(todayDate.getFullYear() + 1, 11, 31),
        },
      },
      {
        label: "Next Year-to-date",
        value: {
          ID: 27,
          StartDate: new Date(todayDate.getFullYear() + 1, 0, 1),
          EndDate: new Date(
            new Date(todayDate.getFullYear() + 1, 0, 1).getTime() +
              (todayDate.getTime() -
                new Date(todayDate.getFullYear(), 0, 1).getTime())
          ),
        },
      },
    ].map((range) => ({
      label: range.label,
      value: {
        ID: range.value.ID,
        StartDate: range.value.StartDate,
        EndDate: range.value.EndDate,
      },
    })),
  };

  const handleApplyFilter = () => {
    setShowSaveFilter(true);
    const requestBody = {
      subject:
        NotesManagerFilter.subject.join(",") ||
        "",
      from_date: FormatDate(NotesManagerFilter.from_date) || "",
      to_date: FormatDate(NotesManagerFilter.to_date) || "",
      fp_from_date: FormatDate(NotesManagerFilter.fp_from_date) || "",
      fp_to_date: FormatDate(NotesManagerFilter.fp_to_date) || "",
      fp_user_name: NotesManagerFilter.fp_user_name?.join(",") || "",
      user_name: NotesManagerFilter.user_name?.join(",") || "",
      invno: NotesManagerFilter.invno || "", // If you have a search field for invoice number, you can map it here
      notes_type: NotesManagerFilter.notes_type?.join(",") || "",
    };

    const data = JSON.stringify(requestBody);
    // const newData = `\"${data.replace(/"/g, '\\"')}\"`;
    dispatch(getNotesManagerData({ data: requestBody })).then(() => {
      if (notesmanagerdata.length === 0) {
        setNoData(true);
      }
    });
    setCustomFilterJson(data);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(NotesManagerFilter.invno !== ''){
    if (e.key === "Enter") {
      handleApplyFilter()
    }
  }
  };
  return (
    <>
      <div className="filters">
        <div className="flex justify-content-between align-items-center pr-3">
          <div className="grid align-items-center w-9 mx-0">
            <div className="col-3">

            <CustomTooltip target=".searchbar" position="bottom"/>
              <InputField
              className="searchbar"
                type="text"
                placeholder="Search with Inv#, mrn, First Name and Last Name"
                id="invno"
                 tooltip="Search with Inv#, mrn, First Name and Last Name"
                 tooltipOptions='bottom'
                 handleKeyDown={handleKeyDown}
                globalFilterValue={NotesManagerFilter.invno}
                onGlobalFilterChange={handleChange}
              />
            </div>
            <div className="col-2">
              <MultiSelectFilter
                value={NotesManagerFilter.notes_type}
                id="notes_type"
                onChange={handleChange}
                label="note_type"
                data={notesTypes}
                placeholder="Search Type"
                filterValue="note_type"
                virtualScrollerOptions={{ itemSize: 50 }}
              />
            </div>
            <div className="col-2">
            <CustomTooltip target=".SubjectLine" position="bottom"/>
              <MultiSelectFilter
                value={NotesManagerFilter.subject}
                id="subject"
                onChange={handleChange}
                label="description"
                data={subjectList}
                placeholder="Search Subject"
                filterValue="description"
                virtualScrollerOptions={{ itemSize: 50 }}
                className="SubjectLine"
                
              />
            </div>
            <div className="col-2">
              <MultiSelectFilter
                value={NotesManagerFilter.fp_user_name}
                id="fp_user_name"
                onChange={handleChange}
                label="emp_name"
                data={employeeList}
                placeholder="Search FUAssignedTo"
                filterValue="emp_name"
                virtualScrollerOptions={{ itemSize: 50 }}
              />
            </div>
            <div className="col-2">
              <MultiSelectFilter
                value={NotesManagerFilter.user_name}
                id="user_name"
                onChange={handleChange}
                label="emp_name"
                data={employeeList}
                placeholder="Search Entered By"
                filterValue="emp_name"
                virtualScrollerOptions={{ itemSize: 50 }}
              />
            </div>
          </div>

          <div className="icons flex align-items-center">
            <div className="favourite-filter">
              <Button
                className="p-0"
                icon={() => (
                  <img
                    src={
                      notesmanagerdata.length > 0 && showSaveFilter
                        ? AddFilter
                        : Filter
                    }
                    alt="Filter"
                    title={`${
                      notesmanagerdata.length > 0 && showSaveFilter
                        ? `Save Filter`
                        : `Apply filter from the preferences`
                    }`}
                  />
                )}
                onClick={(e) => handleCustomFilter(e, op)}
                style={{ background: "transparent", border: "none" }}
              />
              <OverlayPanel ref={op}>
                <div className="favourite-filers">
                  <div className="filter-title flex align-items-center justify-content-between mb-3">
                    <div>
                      {showSaveFilter ? (
                        <>
                          <div className="text-sm font-bold">Save Filter</div>
                          <p className="text-xs m-0">
                            Mark filter as favourite
                          </p>
                        </>
                      ) : (
                        <>
                          <div className="text-sm font-bold">Apply Filter</div>
                          <p className="text-xs m-0">
                            Apply filter from your favorite
                          </p>
                        </>
                      )}
                    </div>
                    <div>
                      <img
                        src={Close}
                        alt="Close"
                        onClick={() => {op.current?.hide();   setError("") }}
                      />
                    </div>
                  </div>

                  {showSaveFilter ? (
                    <div className="filter-name">
                      <label
                        className="text-sm font-semibold"
                        htmlFor="filter-name"
                      >
                        Filter Name
                      </label>
                      <InputText
                        className="p-2 mb-1 mt-1"
                        id="filter-name"
                        aria-describedby="username-help"
                        placeholder="Write filter name"
                        onChange={(e) => setFilterValue(e.target.value)}
                      />
                      <p className="text-red-500 text-xs mb-1">{error}</p>
                      <Button
                        label="Save Filter"
                        outlined
                        onClick={handleSaveFilter}
                      />
                    </div>
                  ) : (
                    <div className="filter-table">
                      <DataTable
                        value={customFilterList}
                        loading={filterloading}
                      >
                        <Column header="Filter Name" field="name" />
                        <Column
                          header="Actions"
                          body={(rowData) => FilterActionBody(rowData)}
                        />
                      </DataTable>
                    </div>
                  )}
                </div>
              </OverlayPanel>
            </div>

            <Button
              outlined
              className="p-px-3 p-py-2 p-mx-2"
              icon={
                <img
                  src={AddNotes}
                  alt="Add Notes"
                  width={22}
                  height={22}
                  className="mr-2"
                />
              }
              onClick={() => setAddBillingNotesVisible(true)}
              label="Add Notes"
            />
          </div>
        </div>

        <div className="grid align-items-center w-9 mx-0">
          <div className="col-4 p-0">
            <InputDropdown
              type="text"
              value={SelectPostedDate}
              id="selectPostedDate"
              labelclassName="col-4"
              dropdownclassName="col-8"
              handleChange={handlePostedDropdownChange}
              optionLabel="label"
              // optionValue="value.ID"
              options={optionsData.postedDate}
              placeholder="Select Posted Date"
              label="Posted Date:"
              filter
            />
          </div>
          <div className="col-2 p-0">
            <InputDate
              id="from_date"
              label="From:"
              placeholder="Select From Date"
              labelclassName="col-3"
              inputclassName="col-9"
              handleChange={handleChange}
              value={NotesManagerFilter.from_date}
            />
          </div>

          <div className="col-2 p-0">
            <InputDate
              id="to_date"
              label="To:"
              placeholder="Select To Date"
              labelclassName="col-2"
              inputclassName="col-10"
              handleChange={handleChange}
              value={NotesManagerFilter.to_date}
            />
          </div>
        </div>

        <div className="grid align-items-center w-9 mx-0">
          <div className="col-4 p-0">
            <InputDropdown
              type="text"
              value={SelectFollowUpDate}
              id="selectFollowUpDate"
              labelclassName="col-4"
              dropdownclassName="col-8"
              handleChange={handleFollowUpDropdownChange}
              optionLabel="label"
              options={optionsData.followupDate}
              placeholder="Select Follow-Up Date"
              label="Follow-Up Date:"
              filter
            />
          </div>

          <div className="col-2 p-0">
            <InputDate
              id="fp_from_date"
              label="From:"
              placeholder="Select From Date"
              labelclassName="col-3"
              inputclassName="col-9"
              handleChange={handleChange}
              value={NotesManagerFilter.fp_from_date} // You can set the same value if needed or use a separate state
            />
          </div>

          <div className="col-2 p-0">
            <InputDate
              id="fp_to_date"
              label="To:"
              placeholder="Select To Date"
              labelclassName="col-2"
              inputclassName="col-10"
              handleChange={handleChange}
              value={NotesManagerFilter.fp_to_date} // Similar to above, you can adjust this according to the requirements
            />
          </div>

          <div className="col-2 gap-2 p-0 flex align-items-center">
            <Button
              label="Reset"
              outlined
              onClick={resetFilterHandler}
              disabled={isFiltersEmpty()}
            />
            <Button
              label="Apply Filter"
              onClick={handleApplyFilter}
              disabled={isFiltersEmpty()}
            />
          </div>
        </div>
      </div>
      <AddBillingNotesDialogBox
        userEmail={userEmail}
        //selectedCpkInvoices={selectedCpkInvoices}
        visible={AddBillingNotesVisible}
        setVisible={setAddBillingNotesVisible} //selectedCpkInvoices={[]} filterUpdate={""}     // onStatusUpdateLoading={onStatusUpdateLoading}
        onStatusUpdateLoading={onStatusUpdateLoading}
        onStatusUpdated={handleStatusUpdate}
        //  filterUpdate={filterUpdate}
      />
    </>
  );
};

export default NotesManagerFilter;
