import React, { useEffect, useMemo, useRef, useState } from "react";

import {
    PdfViewerComponent,
    Toolbar,
    Magnification,
    Navigation,
    LinkAnnotation,
    BookmarkView,
    ThumbnailView,
    Print,
    TextSelection,
    TextSearch,
    Annotation,
    FormFields,
    FormDesigner,
    Inject,
    PageOrganizer
} from '@syncfusion/ej2-react-pdfviewer';
import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-notifications/styles/material.css';
import "../../../node_modules/@syncfusion/ej2-react-pdfviewer/styles/material.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ShipmentDocument = ({ allShipmentdata, filename , setIsDisabled }
 ) => {

    const pdfViewerRef = useRef(null);

    const dispatch = useDispatch();


 

    // useEffect(() => {
    //     const filteredData = allFaxData.filter((val) => {
    //         return val.fax_detail_id == params.id;
    //     });
    //     if (filteredData.length > 0) {
    //         setFaxDetail(filteredData[0]);
    //     }
    // }, [allFaxData, params]);

    // useEffect(() => {
    //     const fetchBase64 = async (url) => {
    //         const response = await fetch(url);
    //         const blob = await response.blob();
    //         return new Promise((resolve) => {
    //             const reader = new FileReader();
    //             reader.onloadend = () => resolve(reader.result);
    //             reader.readAsDataURL(blob);
    //         });

    //     };

    //     fetchBase64(pdf).then(base64 => {
    //         if (pdfViewerRef.current) {
    //             const base64String = base64.startsWith('data:application/pdf;base64,')
    //                 ? base64
    //                 : `data:application/pdf;base64,${base64.split(',')[1]}`;
    //             pdfViewerRef.current.load(base64String);
    //         }
    //     });
    // }, [pdf]);

    const documentPath = useMemo(() => {
        // DEV LINK
        
        
        // return `https://prod-167.westus.logic.azure.com/workflows/0ff31ffa16824bf698f8045c2f230a2a/triggers/manual/paths/invoke/Folder1/HAPP_Fax/Folder2/ec/FileName/ct18666800870-20241016090909090-122-03.pdf?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=N3RU9GwmWFqXQahtRbNutq6um_zNoztQMqQezlHTElg`
        // return `https://prod-167.westus.logic.azure.com/workflows/0ff31ffa16824bf698f8045c2f230a2a/triggers/manual/paths/invoke/Folder1/${allShipmentdata?.folder1}/Folder2/ec/FileName/${allShipmentdata?.filename}?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=N3RU9GwmWFqXQahtRbNutq6um_zNoztQMqQezlHTElg`

        // PROD LINK
        return `https://prod-47.westus.logic.azure.com/workflows/9c4b5d61651b487d98aaeeabdc84c59c/triggers/manual/paths/invoke/FileName/${allShipmentdata?.filename}/Folder/${allShipmentdata?.folder1}?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Z4Lsu73tyqSWQulwTFKvIa0yyiG2ANERtEUb6Sak1q8`
    // }, [faxDetail]);
    });

  

    useEffect(() => {
        // Custom CSS to hide 'Select All' option, And to hide checkbox of pages
        const style = document.createElement('style');
        style.textContent = `
            .e-pdfviewer .e-pv-main-container .e-pv-sidebar-toolbar .e-pv-organize-view-button {
                display: none !important;
            }
            .e-pdfviewer .e-pv-main-container .e-pv-viewer-main-container .e-pv-toolbar  .e-toolbar-items .e-toolbar-left {
               right: 0 !important;
               left: unset !important;
            }
        `;
     
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

   

    return (
        <div className="row h-100  pdf-h">
          
              
                    
                        <PdfViewerComponent
                            className="h-100"
                            id="container"
                            ref={pdfViewerRef}
                            documentPath={documentPath}
                            // serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
                            resourceUrl="https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib"
                            toolbarSettings={{
                                showTooltip: true,
                                toolbarItems: ["MagnificationTool"],
                                annotationToolbarItems: [],
                                formDesignerToolbarItems: []
                            }}
                            enablePageOrganizer={true}
                            pageOrganizerSettings={{
                                canDelete: false,
                                canInsert: false,
                                canRotate: false,
                                canCopy: false,
                                canRearrange: false,
                            }}
                      
                            documentLoad={() => setIsDisabled(false)}
                        >
                            <Inject
                                services={[
                                    Toolbar,
                                    Magnification,
                                    Navigation,
                                    LinkAnnotation,
                                    BookmarkView,
                                    ThumbnailView,
                                    Print,
                                    TextSelection,
                                    TextSearch,
                                    Annotation,
                                    FormFields,
                                    FormDesigner,
                                    PageOrganizer,
                                ]}
                            />
                        </PdfViewerComponent>
                
                </div>
         


    );
};

export default ShipmentDocument;
