import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';

const UserManagement = () => {
    const columns = [
        {
            field: "UserID",
            header: "User ID",
            sortable: true,
        },
        {
            field: "User name",
            header: "User name",
            sortable: true,
        },
        {
            field: "user roles ",
            header: "user roles ",
            sortable: true,
        },
        {
            field: "action",
            header: "action",
        },


    ];

    // Sample data for the table
    const data = [
        { UserID: "12345" },
        { UserID: "67890" },
        { UserID: "11223" },
    ];

    return (
        <>
            <DataTable
                value={data}
                className="custom-bordered-table"
            >
                {columns.map((column, index) => (
                    <Column
                        key={index}
                        field={column.field}
                        header={column.header}
                        sortable={column.sortable}
                        headerClassName="custom-header"
                    />
                ))}
            </DataTable>
        </>
    );
};

export default UserManagement;
