import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React from "react";
import { SortDecre, SortIncre } from "../../assets/images/Icon";
import { Column } from "primereact/column";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import Loader from "../loader/Loader";

const HistoryPopupForShipment = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { HistoryDataFromAPIPASS, historyLoading } = useSelector(
    (state: RootState) => state.shipmentReducer
  );

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  const pageOptions = [5, 10, 25, 50, 75, 100, 125];

  const cityBody = (rowData: any) => <span>{rowData.scanLocation.city}</span>;

  // Columns
  const columns = [
    { field: "date", header: "Date", sortable: true },
    { field: "derivedStatus", header: "Status", sortable: true },
    { field: "eventDescription", header: "Event Description", sortable: true },
    { field: "eventType", header: "Event Type", sortable: true },
    {
      field: "exceptionDescription",
      header: "Exception Description",
      sortable: true,
    },
    // { field: "confirmation_date ", header: "Conf. Date", sortable: true },
    { field: "locationId", header: "Location ID", sortable: true },
    { field: "locationType", header: "Destination", sortable: true }, //,body:cityBody
  ];

  return (
    <div>
      <Dialog
        style={{ width: "1200px", height: "80vh" }}
        draggable
        header="Delivery Track"
        //   footer={Footer}
        visible={visible}
        onHide={() => setVisible(false)}
      >
        {historyLoading ? (
          <div className="ShipmentHistoryLoader">
            <Loader label="History Data" path="" location="" />
          </div>
        ) : (
          <DataTable
            // filteredStatuslist={filteredStatuslist}
            value={HistoryDataFromAPIPASS}
            scrollable
            removableSort
            scrollHeight="645px"
            // paginator
            // style={{ fontSize: "0.1rem !important" }}
            sortIcon={sortIcon}
            // onRowClick={}
            // selectionMode="single"
            // rowsPerPageOptions={pageOptions}
            // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={25}
            // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            // filters={filters}
            // loading={loading}
            globalFilterFields={columns.map((column) => column.field)}
            emptyMessage="No Data Found"
          >
            <Column headerStyle={{ paddingLeft: "3px" }}></Column>

            {columns.map((column, index) => (
              <Column
                key={index}
                field={column.field}
                header={column.header}
                // body={column.body}
                sortable={column.sortable}
                // bodyStyle={{ fontSize: "0.1rem !important" }} // Inline style for specific column
              />
            ))}
          </DataTable>
        )}
      </Dialog>
    </div>
  );
};

export default HistoryPopupForShipment;
