import { Dialog } from "primereact/dialog";
import React, { useEffect } from "react";
import InputTextArea from "../../formElement/InputTextArea";
import InputDate from "../../formElement/InputDate";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../utils/formatCurrency";

const PricingDialogBox = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) => {
  const { specialPricesWithInsuranceAndInventory } = useSelector(
    (state: RootState) => state.pricingReducer
  );

  const pricingData = specialPricesWithInsuranceAndInventory[0];

  return (
    <>
      <Dialog
        className="ar-pricing"
        style={{ width: "600px" }}
        header="Pricing"
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <div className="pricing-box">
          <div className="pricingHeader">
            {/* <div className="mainHeading">
              <h1>Pricing</h1>
            </div> */}
            <div className="subHeading">
              <div className="firstSubHeading">
                <p>Insurance</p>
                <textarea
                  className="title"
                  value={pricingData?.insurance_company_name}
                  disabled
                />
              </div>
              <div className="secoudSubHeading">
                <p>Inventory</p>
                <textarea
                  className="title"
                  value={pricingData?.inventory_name}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="pricingBody">
            <div className="PricingRow">
              <p>Cost</p>
              <div>
                <input
                  className="custom-input cost"
                  value={`${formatCurrency(pricingData?.cost)}`}
                  disabled
                />
              </div>
            </div>
            <div className="PricingRow">
              <p>List</p>
              <div>
                <input value={`${formatCurrency(pricingData?.list)}`} disabled />
              </div>
            </div>
            <div className="PricingRow">
              <p>AWP</p>
              <div>
                <input value={`${formatCurrency(pricingData?.awp)}`} disabled />
              </div>
            </div>
            <div className="PricingRow">
              <p>Special</p>
              <div>
                <input
                  value={`${formatCurrency(pricingData?.special)}`}
                  disabled
                />
              </div>
            </div>
            <div className="PricingRow">
              <p>Expected</p>
              <div>
                <input
                  value={`${formatCurrency(pricingData?.expected)}`}
                  disabled
                />
              </div>
            </div>
            <div className="PricingRow">
              <p>Billing Code</p>
              <div>
                <input value={pricingData?.billinG_CODE} disabled />
              </div>
            </div>
            <div className="PricingRow">
              <p>Start Date</p>
              <div
                className="date"
                style={{ position: "relative", display: "inline-block" }}
              >
                <InputDate
                  id="start-date"
                  value={
                    pricingData?.start_date
                      ? new Date(pricingData?.start_date)
                      : undefined
                  }
                  placeholder="Select a date"
                  labelclassName="col-4 label-lg"
                  inputclassName="col-6"
                  disabled
                />
              </div>
            </div>
            <div className="PricingRow">
              <p>End Date</p>
              <div
                className="date"
                style={{ position: "relative", display: "inline-block" }}
              >
                <InputDate
                  id="end-date"
                  value={
                    pricingData?.end_date
                      ? new Date(pricingData?.end_date)
                      : undefined
                  }
                  placeholder="Select a date"
                  labelclassName="col-4 label-lg"
                  inputclassName="col-6"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PricingDialogBox;
