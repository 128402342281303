const FilterMessage = ({ noData }: { noData: boolean }) => {
  return (
    <>
      {!noData ? (
        <div className="filter-message">
          <span className="text-2xl font-bold">Apply the Filter!!</span>
          <span>To access and view the data, please apply the filter</span>
        </div>
      ) : (
        <div className="filter-message">
          <span className="text-2xl font-bold">No Data Found !!</span>
          <span>
            We were unable to find any data using the filter provided. Please
            adjust the filter to display the data
          </span>
        </div>
      )}
    </>
  );
};

export default FilterMessage;
