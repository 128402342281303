import React, { useEffect, useRef, useState } from "react";
import InputField from "../../components/formElement/InputField";
import { Button } from "primereact/button";
import "../../assets/css/tasklist.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import MultiSelectFilter from "../../components/formElement/MultiSelect";
import resetbutton from "../../assets/images/ResetButton.svg";
import whiteIcon from "../../assets/images/white-filter.svg";
import starbutton from "../../assets/images/FavButton (1).svg";
import blueIcon from "../../assets/images/blue-filter.svg";
import { InputText } from "primereact/inputtext";
import { Close, Delete } from "../../assets/images/Icon";
import { OverlayPanel } from "primereact/overlaypanel";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import {
  GetassignBy,
  GetassignTo,
  GetCategory,
  getPatientToDoList,
  getSiteListTask,
  getStatusOptionList,
  Getteams,
  resetTasklist,
} from "../../redux/store/TasklistReducer";
import InputDate from "../../components/formElement/InputDate";
import Loader from "../../components/loader/Loader";
import { CustomFilterList } from "../../types/IHealthAppTypes";
import {
  AddNewCustomFilter,
  GetCustomFilterList,
} from "../../redux/store/ARManagerReducer";
import TaskListDataTable from "../../components/DataTable/TaskListDataTable";
import { ToastContainer } from "react-toastify";
import AddNewTask from "../../components/Dialog/AddNewTask";

interface JsonString {
  due_date: string;
  patientSearch: string;
  site: string[];
  status: string[];
  assign_to: string[];
  assign_by: string[];
  category: string[];
  team: string[];
}
interface FilterQuery {
  jsonString: JsonString;
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  sortDirection: string;
}

const TaskList = ({ userDetail }: { userDetail: any }) => {
  const { userEmail, userName } = userDetail;
  const dispatch = useDispatch<AppDispatch>();
  const [selectedData, setSelectedData] = useState<any>(null);
  const op = useRef<OverlayPanel>(null);
  const location = window.location.pathname;
  const {
    TaskFilterList,
    filterloading,
    tasklist,
    sitesOptTask,
    teams,
    assignBy,
    assignTo,
    statusList,
    category,
    loading,
    totalRecord,
  } = useSelector((state: RootState) => state.tasklistReducer);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const [intialQuery, setIntialQuery] = useState<FilterQuery>({
    jsonString: {
      due_date: "",
      patientSearch: "",
      site: [],
      status: [],
      assign_to: [],
      assign_by: [],
      category: [],
      team: [],
    },
    pageNumber: 0,
    pageSize: 20,
    sortBy: "",
    sortDirection: "",
  });
  const { jsonString } = intialQuery;
  const [showSaveFilter, setShowSaveFilter] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [action, setAction] = useState<string | null>(null);
  const [error, setError] = useState("");
  const [customFilterJson, setCustomFilterJson] = useState<JsonString | null>(
    null
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getSiteListTask());
      await dispatch(getStatusOptionList());
      await dispatch(GetCategory());
      await dispatch(Getteams());
      await dispatch(GetassignTo());
      await dispatch(GetassignBy());
    };

    fetchData();
  }, [dispatch]);

  const updateFilters = (index: number, currentUserName: string) => {
    const updatedAssignTo =
      index === 0
        ? intialQuery.jsonString.assign_to.includes(currentUserName)
          ? intialQuery.jsonString.assign_to
          : [...intialQuery.jsonString.assign_to, currentUserName]
        : [];

    const updatedAssignBy =
      index === 1
        ? intialQuery.jsonString.assign_by.includes(currentUserName)
          ? intialQuery.jsonString.assign_by
          : [...intialQuery.jsonString.assign_by, currentUserName]
        : [];

    return {
      ...intialQuery.jsonString,
      assign_to: updatedAssignTo,
      assign_by: updatedAssignBy,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!userName || activeIndex === null) {
        resetFilters();
        return;
      }

      const updatedFilters = updateFilters(activeIndex, userName);
      setIntialQuery((prev) => ({
        ...prev,
        jsonString: {
          ...prev.jsonString,
          due_date: "",
          patientSearch: "",
          site: [],
          status: [],
          category: [],
          team: [],
          assign_by: updatedFilters.assign_by,
          assign_to: updatedFilters.assign_to,
        },
      }));

      await dispatch(
        getPatientToDoList({
          SearchString: {
            ...intialQuery,
            jsonString: updatedFilters,
          },
        })
      );
      if (!isEveryFilterEmpty()) setShowSaveFilter(true);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, activeIndex, dispatch]);

  const handleApplyFilter = async () => {
    await dispatch(
      getPatientToDoList({
        SearchString: {
          ...intialQuery,
          jsonString: {
            ...intialQuery.jsonString,
            due_date: intialQuery.jsonString.due_date
              ? new Date(intialQuery.jsonString.due_date).toLocaleDateString(
                  "en-CA"
                )
              : "",
          },
        },
      })
    );
    if (!isEveryFilterEmpty()) setShowSaveFilter(true);
    setCustomFilterJson(intialQuery.jsonString);
  };

  const isEveryFilterEmpty = () => {
    return (
      jsonString.category.length === 0 &&
      jsonString.team.length === 0 &&
      !jsonString.patientSearch &&
      (jsonString.assign_to.length === 0 || activeIndex === 0) &&
      (jsonString.assign_by.length === 0 || activeIndex === 1) &&
      jsonString.status.length === 0 &&
      jsonString.site.length === 0 &&
      jsonString.due_date.length === 0
    );
  };

  const resetFilters = async () => {
    const newQuery = {
      jsonString: {
        due_date: "",
        patientSearch: "",
        site: [],
        status: [],
        assign_to: activeIndex === 0 ? intialQuery.jsonString.assign_to : [],
        assign_by: activeIndex === 1 ? intialQuery.jsonString.assign_by : [],
        category: [],
        team: [],
      },
      pageNumber: 0,
      pageSize: 20,
      sortBy: "",
      sortDirection: "",
    };

    setIntialQuery(newQuery);
    activeIndex !== null
      ? await dispatch(getPatientToDoList({ SearchString: newQuery }))
      : dispatch(resetTasklist());
    setShowSaveFilter(false);
  };

  const handleSaveFilter = () => {
    const filterExists = TaskFilterList.some(
      (filter: CustomFilterList) => filter.name === filterValue
    );

    if (filterExists) {
      setError("Filter already exists");
      return;
    }

    dispatch(
      AddNewCustomFilter({
        preference_name: "task_list_page_filter",
        filter_name: filterValue,
        preference_json_value: JSON.stringify(customFilterJson),
        usage: "USER",
        type: 1,
        custom_preference_id: 0,
        email_address: `${userEmail}`,
      })
    ).then(() => {
      setShowSaveFilter(false);
      refreshCustomFilterList();
    });
  };

  const refreshCustomFilterList = () => {
    dispatch(
      GetCustomFilterList({
        updateData: JSON.stringify({
          email_address: `${userEmail}`,
          preference_name: "task_list_page_filter",
          usage: "USER",
        }),
      })
    );
  };

  const handleCustomFilter = (e: any, op: any) => {
    op.current?.toggle(e);
    dispatch(
      GetCustomFilterList({
        updateData: JSON.stringify({
          email_address: `${userEmail}`,
          preference_name: "task_list_page_filter",
          usage: "USER",
        }),
      })
    );
  };

  const handleFilterAction = async (e: any, rowData: any) => {
    const { innerText } = e.target;
    if (innerText === "Apply Filter") {
      const data = JSON.parse(rowData.json_value);
      await dispatch(
        getPatientToDoList({
          SearchString: {
            ...intialQuery,
            jsonString: data,
          },
        })
      ).then(() => {
        setShowSaveFilter(false);
      });
      setIntialQuery((prev) => ({
        ...prev,
        jsonString: {
          ...prev.jsonString,
          due_date: data.due_date || "",
          patientSearch: data.patientSearch || "",
          site: data.site || [],
          status: data.status || [],
          assign_to: data.assign_to || [],
          assign_by: data.assign_by || [],
          category: data.category || [],
          team: data.team || [],
        },
      }));
    } else {
      dispatch(
        AddNewCustomFilter({
          preference_name: "task_list_page_filter",
          filter_name: rowData?.name,
          preference_json_value: JSON.stringify(rowData.json_value),
          usage: "USER",
          type: 3,
          custom_preference_id: rowData?.custom_preference_id,
          email_address: `${userEmail}`,
        })
      ).then(() => {
        refreshCustomFilterList();
      });
    }
  };

  const filterActionBody = (rowData: any) => {
    return (
      <>
        <Button
          outlined
          className="p-1 text-sm"
          label="Apply Filter"
          onClick={(e) => handleFilterAction(e, rowData)}
        />
        <Button
          icon={() => <img src={Delete} alt="Filter" />}
          style={{ background: "transparent", border: "none" }}
          onClick={(e) => handleFilterAction(e, rowData)}
        />
      </>
    );
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (intialQuery.jsonString.hasOwnProperty(id)) {
      setIntialQuery((prevState) => ({
        ...prevState,
        jsonString: {
          ...prevState.jsonString,
          [id]: value,
        },
      }));
    }
  };

  const handleTabToggle = async (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      return;
    }

    if (!userName) return;
    setActiveIndex(index);
    const updatedFilters = updateFilters(index, userName);
    setIntialQuery((prevFilters) => ({
      ...prevFilters,
      jsonString: {
        ...prevFilters.jsonString,
        due_date: "",
        patientSearch: "",
        site: [],
        status: [],
        category: [],
        team: [],
        assign_by: updatedFilters.assign_by,
        assign_to: updatedFilters.assign_to,
      },
    }));
  };

  return (
    <>
      <div className="MainTaskList">
        {loading && (
          <Loader location={location} path="/tasklist" label="Tasklist" />
        )}
        <ToastContainer />
        <div className="flex col-12">
          <div className="tab-button2  ml-4 col-7">
            <Button
              className={`toggle-btn ${activeIndex === 0 && "selected"}`}
              onClick={() => handleTabToggle(0)}
              label="My Tasks"
            />
            <Button
              className={`toggle-btn ${activeIndex === 1 && "selected"}`}
              onClick={() => handleTabToggle(1)}
              label="Assigned Tasks"
            />
            <Button
              className={`toggle-btn ${activeIndex === 2 && "selected"}`}
              label="All Task"
              onClick={() => handleTabToggle(2)}
            />
          </div>

          <div className=" tab-button2 col-5  pr-8">
            <Button
              className="w-3"
              label="+ Add Task"
              onClick={() => {
                setVisible(true);
                setAction("add");
              }}
              style={{ display: "flex", alignItems: "center" }}
            />
          </div>
        </div>
        <div className="tasklist-head">
          <div className="filter-container">
            <div>
              <InputField
                placeholder="Search with MRN, F&L Name"
                id="patientSearch"
                value={jsonString.patientSearch}
                globalFilterValue={jsonString.patientSearch}
                className="search-input text-left"
                onGlobalFilterChange={handleChange}
              />
            </div>
            <div className="multi-container">
              <MultiSelectFilter
                filterValue="id"
                id="site"
                data={sitesOptTask || []}
                value={jsonString.site}
                label="site"
                virtualScrollerOptions={{ itemSize: 50 }}
                onChange={handleChange}
                placeholder="Search Sites"
              />
            </div>
            <div className="multi-container">
              <MultiSelectFilter
                filterValue="status_description"
                data={statusList || []}
                value={jsonString.status}
                onChange={handleChange}
                id="status"
                label="status_description"
                placeholder="Select Status"
              />
            </div>
          </div>

          <div className="filter-btns">
            <div>
              <Button
                className="reaload-btn"
                icon={<img src={resetbutton} alt="My Icon" />}
                onClick={resetFilters}
                tooltip={"Reset"}
                tooltipOptions={{ position: "bottom" }}
                style={{ display: "flex", alignItems: "center" }}
              />
            </div>
            <div>
              <Button
                className={`toggle-filter-btn ${
                  isFilterActive && "active-filter"
                }`}
                icon={
                  <img
                    src={isFilterActive ? whiteIcon : blueIcon}
                    alt="My Icon"
                  />
                }
                tooltip={"Extra Filters"}
                tooltipOptions={{ position: "bottom" }}
                onClick={() => setIsFilterActive(!isFilterActive)}
                style={{
                  backgroundColor: isFilterActive ? "#0a3e71" : "transparent",
                }}
              />
            </div>
            <div>
              <Button
                className="fav-btn"
                icon={<img src={starbutton} alt="My Icon" />}
                onClick={(e) => {
                  op.current?.toggle(e);
                  handleCustomFilter(e, op);
                }}
                tooltip={"Favourite Filter"}
                tooltipOptions={{ position: "bottom" }}
              />
              <div className="favourite-filter">
                <OverlayPanel ref={op}>
                  <div className="favourite-filers">
                    <div className="filter-title flex align-items-center justify-content-between mb-3">
                      <div>
                        {showSaveFilter ? (
                          <>
                            <div className="text-sm font-bold">Save Filter</div>
                            <p className="text-xs m-0">
                              Mark filter as favourite
                            </p>
                          </>
                        ) : (
                          <>
                            <div className="text-sm font-bold">
                              Apply filter
                            </div>
                            <p className="text-xs m-0">
                              Apply filter from your favourite
                            </p>
                          </>
                        )}
                      </div>
                      <div>
                        <img
                          src={Close}
                          alt="Close"
                          onClick={() => op.current?.hide()}
                        />
                      </div>
                    </div>

                    {showSaveFilter ? (
                      <div className="filter-name">
                        <label
                          className="text-sm font-semibold"
                          htmlFor="filter-name"
                        >
                          Filter Name
                        </label>
                        <InputText
                          className="p-2 mb-3 mt-1"
                          id="filter-name"
                          aria-describedby="username-help"
                          placeholder="Write filter name"
                          onChange={(e) => setFilterValue(e.target.value)}
                        />
                        <Button
                          label="Save Filter"
                          outlined
                          onClick={handleSaveFilter}
                        />
                      </div>
                    ) : (
                      <div className="filter-table">
                        <DataTable
                          value={TaskFilterList}
                          loading={filterloading}
                        >
                          <Column header="Filter Name" field="name" />
                          <Column
                            header="Actions"
                            headerTooltip="This is the tooltip for the header"
                            body={(rowData) => filterActionBody(rowData)}
                          />
                        </DataTable>
                      </div>
                    )}
                  </div>
                </OverlayPanel>
              </div>
            </div>
            <div className="applyfilter-btn">
              <Button
                className={`apply-filter-btn ${
                  isEveryFilterEmpty() ? "not-allowed-btn" : "pointer-btn"
                }`}
                label="Apply Filters"
                onClick={
                  isEveryFilterEmpty()
                    ? () => {
                        return;
                      }
                    : handleApplyFilter
                }
                style={{
                  cursor: isEveryFilterEmpty() ? "not-allowed" : "pointer",
                  opacity: isEveryFilterEmpty() ? "0.6" : "1",
                }}
              />
            </div>
          </div>
        </div>

        {isFilterActive && (
          <div className="drop-downs">
            <div className="multi-container">
              <MultiSelectFilter
                filterValue="description"
                data={category || []}
                value={jsonString.category}
                onChange={handleChange}
                id="category"
                label="description"
                placeholder="Select Category"
              />
            </div>
            <div className="multi-container">
              <MultiSelectFilter
                filterValue="team_name"
                data={teams || []}
                value={jsonString.team}
                onChange={handleChange}
                id="team"
                label="team_name"
                placeholder="Select Team"
              />
            </div>
            <div className="multi-container">
              <MultiSelectFilter
                filterValue="full_name"
                value={jsonString.assign_to}
                virtualScrollerOptions={{ itemSize: 50 }}
                data={assignTo || []}
                onChange={handleChange}
                id="assign_to"
                label="full_name"
                disabled={activeIndex === 0}
                placeholder="Select Assigned-To"
              />
            </div>
            <div className="multi-container">
              <MultiSelectFilter
                filterValue="user_name"
                data={assignBy || []}
                value={jsonString.assign_by}
                virtualScrollerOptions={{ itemSize: 50 }}
                onChange={handleChange}
                id="assign_by"
                label="user_name"
                disabled={activeIndex === 1}
                placeholder="Select Assigned-By"
              />
            </div>

            <div className="multi-container">
              <InputDate
                id="due_date"
                value={new Date(jsonString.due_date)}
                handleChange={handleChange}
                placeholder="Select Due-Date"
                labelclassName="Duedate "
                inputclassName=" w-full TaskListCalender"
              />
            </div>
          </div>
        )}

        {tasklist.length > 0 ? (
          <div className="mt-3">
            <TaskListDataTable
              totalRecords={totalRecord}
              setIntialQuery={setIntialQuery}
              data={tasklist}
              filterQuery={intialQuery}
              setAction={setAction}
              setSelectedData={setSelectedData}
              setVisible={setVisible}
            />
          </div>
        ) : (
          <div className="apply-filter">
            <div className="h1-container">
              <h3>Apply the filter !!</h3>
            </div>
            <div className="">
              <p>Once search criteria is entered, click on "Apply filter"</p>
            </div>
          </div>
        )}
      </div>
      <AddNewTask
        visible={visible}
        setVisible={setVisible}
        action={action}
        setAction={setAction}
        editData={selectedData}
        userDetail={userDetail}
        filterQuery={intialQuery}
      />
    </>
  );
};

export default TaskList;
