// ** Reducers Imports
import arManagerReducer from './store/ARManagerReducer';
import insuranceReducer from './store/InsuranceReducer';
import routePermissionReducer from './store/RoutePermission';
import FaxDirectoryReducer from './store/FaxDirectoryReducer';
import tasklistReducer from './store/TasklistReducer';
import FaxSliceReducer from "./store/FaxSliceReducer";
import inventoryReducer from "./store/InventoryReducer";
import shipmentReducer from "./store/ShipmentReducer"
import notesManagerReducer from "./store/NotesManagerReducer"
import pricingReducer from "./store/PricingReducer";
import CensusReducer from "./store/CensusReducer";
import NurseAgencyReducer from './store/NurseAgencyReducer'

import AppointmentsReducer from "./store/AppointmentsReducer";
import prescriberworkflowReducer from "./store/PrescribersWorkflowReducer";
import permissionsReducer from "./store/PermissionsReducer";


const rootReducer = {
    arManagerReducer,
    insuranceReducer,
    routePermissionReducer,
    FaxDirectoryReducer,
    tasklistReducer,
    inventoryReducer,
    FaxSliceReducer,
    shipmentReducer,
    notesManagerReducer,
    pricingReducer,
    CensusReducer,
    NurseAgencyReducer,
    AppointmentsReducer,
    prescriberworkflowReducer,
    permissionsReducer,
};

export default rootReducer;
