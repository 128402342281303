import { IconField } from "primereact/iconfield";
import React from "react";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Search } from "../../assets/images/Icon";
// import "../../assets/css/inventory.css"

const InputField = ({
  placeholder,
  globalFilterValue,
  onGlobalFilterChange,
  id,
  type,
  value,
  onChange,
  maxLength,
  className,
  disabled,
  tooltip,
  tooltipOptions,
  handleKeyDown,
}: {
  disabled?: boolean;
  placeholder: string;
  globalFilterValue?: string;
  value?: string;
  type?: string;
  maxLength?: number;
  className?: string;
  tooltip?:string;
  tooltipOptions?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onGlobalFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="globalfilter mr-3">
      <IconField iconPosition="left" className="search">
        <InputIcon>
          <img src={Search} alt="Search" />
        </InputIcon>
        <InputText
          id={id}
          onKeyDown={handleKeyDown}
          value={globalFilterValue}
          className={className}
          maxLength={maxLength}
          type={type || "string"}
          onChange={onGlobalFilterChange}
          placeholder={placeholder}
          disabled={disabled}
          tooltip={tooltip}
          tooltipOptions={tooltipOptions}
        />
      </IconField>
    </div>
  );
};

export default InputField;
