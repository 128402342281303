import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import "../../assets/css/appointments.css";
// import AddNew from "../../assets/images/add-btn.svg";
import AddNew from "../../assets/images/apppointment-add-new.svg";
import InfusionNurse from './infusion-nurse/InfusionNurse';
import Agency from './agency/Agency';
import InfusionSuite from './infusion-suite/InfusionSuite';
import AddNewPatient from './AddNewPatient';
import { AgencyList, fetchPatientData, getSiteListTask, handleClearPatient, InfusionList, NurseList } from '../../redux/store/AppointmentsReducer';
import { RootState, useAppDispatch } from '../../redux/store';
import { Dialog } from 'primereact/dialog';
import InputField from '../../components/formElement/InputField';
import { useSelector } from 'react-redux';
import Loader from '../../components/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { checkPermissions } from '../../utils/checkPermissions';
import { Checkbox } from 'primereact/checkbox';

// Define the type for a patient
type Patient = {
    mrn: string;
    first_name: string;
    last_name: string;
};

const fakePermission = [
    {
        "role_id": 1,
        "previlege_id": 113,
        "user_role_id": 443,
        "page_id": 11,
        "section_id": 114,
        "role": "Super User",
        "section": "All Section",
        "page": "Schedule Management",
        "read": "Y",
        "modify": "Y",
        "write": "Y",
        "delete": "Y",
        "all": "Y",
        "deny": "N",
        "prescriber_id": 9067,
        "user_id": 998
    },
    {
        "role_id": 1,
        "previlege_id": 113,
        "user_role_id": 443,
        "page_id": 11,
        "section_id": 114,
        "role": "Super User",
        "section": "Agency Scheduling",
        "page": "Schedule Management",
        "read": "Y",
        "modify": "N",
        "write": "N",
        "delete": "N",
        "all": "N",
        "deny": "N",
        "prescriber_id": 9067,
        "user_id": 998
    },
    {
        "role_id": 1,
        "previlege_id": 113,
        "user_role_id": 443,
        "page_id": 11,
        "section_id": 114,
        "role": "Super User",
        "section": "Nurse Scheduling",
        "page": "Schedule Management",
        "read": "Y",
        "modify": "Y",
        "write": "Y",
        "delete": "Y",
        "all": "Y",
        "deny": "N",
        "prescriber_id": 9067,
        "user_id": 998
    },
    {
        "role_id": 1,
        "previlege_id": 113,
        "user_role_id": 443,
        "page_id": 11,
        "section_id": 114,
        "role": "Super User",
        "section": "Infusion Suite",
        "page": "Schedule Management",
        "read": "Y",
        "modify": "Y",
        "write": "Y",
        "delete": "Y",
        "all": "Y",
        "deny": "N",
        "prescriber_id": 9067,
        "user_id": 998
    }
];

// const checkPermissions = (assignRoleByUser: RolePermissions[], page: string, section: string) => {
//     const checkSections = assignRoleByUser.filter((val) => {
//         return val.page === page && val.section === section
//     });
//     if (checkSections.length > 0) {
//         const newPermission = checkSections[0];
//         const newData = {
//             all: newPermission?.all === "Y",
//             delete: newPermission?.deny === "Y" ? false : newPermission?.delete === "Y",
//             deny: newPermission?.deny === "Y",
//             modify: newPermission?.deny === "Y" ? false : newPermission?.modify === "Y",
//             read: newPermission?.deny === "Y" ? false : newPermission?.read === "Y",
//             write: newPermission?.deny === "Y" ? false : newPermission?.write === "Y",
//         }
//         return newData;
//     } else {
//         const checkAllSections = assignRoleByUser.filter((val) => {
//             return val.page === page && val.section === "All Section"
//         });
//         const permissionData = checkAllSections.length > 0 ? checkAllSections[0] : undefined;
//         const newData = {
//             all: permissionData?.all === "Y",
//             delete: permissionData?.deny === "Y" ? false : permissionData?.delete === "Y",
//             deny: permissionData?.deny === "Y",
//             modify: permissionData?.deny === "Y" ? false : permissionData?.modify === "Y",
//             read: permissionData?.deny === "Y" ? false : permissionData?.read === "Y",
//             write: permissionData?.deny === "Y" ? false : permissionData?.write === "Y",
//         }
//         return newData;
//     }
// };

const Appointments = ({ name, userEmail }: { name?: string; userEmail?: string; }) => {

    const { assignRoleByUser } = useSelector((state: RootState) => state.routePermissionReducer);

    const [activeTab, setActiveTab] = useState<number | undefined>(undefined);
    const { patientList, patientLoading } = useSelector((state: RootState) => state.AppointmentsReducer);
    const [searchValue, setSearchValue] = useState('');

    // add new
    const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);

    const handleCheckboxChange = (patient: Patient) => {
        if (selectedPatient?.mrn === patient.mrn) {
            setSelectedPatient(null);
            setIsCheckboxDisabled(false);
        } else {
            setSelectedPatient(patient);
            setIsCheckboxDisabled(true);
        }
    };
    const handleContinue = () => {
        if (selectedPatient) {
            const redirectQuery = `/appointments?mrn=${selectedPatient.mrn}`;
            navigate(redirectQuery);
        } else {
            alert("Please select a patient to continue.");
        }
    };

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const PAGE_NAME = "Schedule Management";
    const NURSE_SCHEDULE_SECTION = "Nurse Scheduling";
    const AGENCY_SCHEDULE_SECTION = "Agency Scheduling";
    const INFUSION_SCHEDULE_SECTION = "Infusion Suite";

    useEffect(() => {
        if (checkPermissions(assignRoleByUser, PAGE_NAME, NURSE_SCHEDULE_SECTION).read) {
            setActiveTab(0);
        } else if (checkPermissions(assignRoleByUser, PAGE_NAME, AGENCY_SCHEDULE_SECTION).read) {
            setActiveTab(1);
        } else if (checkPermissions(assignRoleByUser, PAGE_NAME, INFUSION_SCHEDULE_SECTION).read) {
            setActiveTab(2);
        }
    }, [assignRoleByUser]);

    //toggle window
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);

    const handleCloseModal = () => {
        setIsAddNewModalOpen(false);
    };

    const patient_status = [
        { label: "1", value: "Active" },
        { label: "2", value: "Inactive" },
        { label: "3", value: "Cancelled" },
        { label: "4", value: "On Hold" },
        { label: "5", value: "Pending" },
    ];
    const site_no = [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
    ];
    // Filter Action From Favourite Filter
    const handleFilterAction = () => {

        const siteNoValues = site_no.map(item => item.value);

        const patientStatusValues = patient_status.map(item => item.value);

        const filterQuery = {
            patient_status: patientStatusValues,
            patientSearch: searchValue,
            site_no: siteNoValues,
        };
        const jsonFilter = JSON.stringify(filterQuery);
        // const newData = `\"${jsonFilter.replace(/"/g, '\\"')}\"`;
        dispatch(fetchPatientData({
            query: {
                "json": jsonFilter,
                "mail": userEmail
            }
        }));
    };

    useEffect(() => {
        dispatch(getSiteListTask());
    }, []);

    useEffect(() => {
        if (!isAddNewModalOpen) {
            dispatch(handleClearPatient());
        }
    }, [isAddNewModalOpen]);

    const location = window.location.pathname;

    const onRowSelect = (rowData: NurseList | AgencyList | InfusionList) => {
        const redirectQuery = `/appointments?mrn=${rowData.mrn}&schedule_id=${rowData.patient_schedule_id}`;
        navigate(redirectQuery);
    };

    return (
        <div className=''>
            <div className="flex justify-content-center flex-wrap">
                <div className="flex flex-wrap justify-content-center gap-2 appoint-tab-button">
                    {checkPermissions(assignRoleByUser, PAGE_NAME, NURSE_SCHEDULE_SECTION).read && (
                        <div className=''>
                            <Button className={`${activeTab === 0 ? "active-tab" : ""}`} onClick={() => setActiveTab(0)}>Health Infusion Nurse</Button>
                        </div>
                    )}
                    {checkPermissions(assignRoleByUser, PAGE_NAME, AGENCY_SCHEDULE_SECTION).read && (
                        <div className=''>
                            <Button className={`${activeTab === 1 ? "active-tab" : ""}`} onClick={() => setActiveTab(1)}>Agency</Button>
                        </div>
                    )}
                    {checkPermissions(assignRoleByUser, PAGE_NAME, INFUSION_SCHEDULE_SECTION).read && (
                        <div className=''>
                            <Button className={`${activeTab === 2 ? "active-tab" : ""}`} onClick={() => setActiveTab(2)}>Infusion Suite</Button>
                        </div>
                    )}
                </div>
                <div className="appoint-AddNew-button  flex relative right-0 mr-4 md:absolute sm:absolute">
                    <Button
                        icon={
                            <img
                                src={AddNew}
                                alt="My Icon"
                                style={{ width: "13px", height: "13px", marginRight: "4px", textAlign: "center", display: "flex", alignItems: 'center', justifyContent: 'center' }}
                            />
                        }
                        onClick={() => setIsAddNewModalOpen(true)}
                    > Add New</Button>
                </div>
            </div>

            <div className="table-contents">
                {activeTab === 0 && (
                    <InfusionNurse onRowSelect={onRowSelect} />
                )}
                {activeTab === 1 && (
                    <Agency onRowSelect={onRowSelect} />
                )}
                {activeTab === 2 && (
                    <InfusionSuite onRowSelect={onRowSelect} userEmail={userEmail} />
                )}
            </div>
            <Dialog
                visible={isAddNewModalOpen}
                // onHide={() => setIsAddNewModalOpen(false)}
                onHide={handleCloseModal}
                header="Search Patient"
                modal
                style={{ width: '45vw', overflow: "hidden" }}
                draggable={false}
                className="app-AddNew-name-modal"
            >
                <div className="">
                    <div className="flex align-items-center">
                        <div className="col-9">
                            <InputField
                                placeholder="Search with MRN and Name"
                                id=""
                                value={searchValue}   // Bind the value to the state
                                onGlobalFilterChange={(e) => setSearchValue(e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <Button
                                label="Search"
                                onClick={handleFilterAction}
                                disabled={!searchValue.trim()}
                            />
                        </div>
                    </div>
                    <div style={{ height: '250px', overflow: 'auto' }}>
                        <div className="m-2">
                            {patientLoading ? (
                                <div>
                                    <Loader label='Agency' location={location} path='/schedule-management' />
                                </div>
                            ) : (
                                <div className='m-1'>
                                    {patientList.length > 0 ? (
                                        patientList.map((patient, index) => (
                                            <div key={index} className="p-1 petient-hover-row">
                                                <Checkbox
                                                    type="checkbox"
                                                    checked={selectedPatient?.mrn === patient.mrn}
                                                    disabled={isCheckboxDisabled && selectedPatient?.mrn !== patient.mrn}
                                                    onChange={() => handleCheckboxChange(patient)}
                                                    className='mr-3'
                                                />
                                                <span className="mr-5 petient-hoverable">MRN: {patient.mrn}</span>
                                                <span className="petient-hoverable">Name: {patient.first_name} {patient.last_name}</span>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No patients available.</p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", flexShrink: 0 }}>
                        <Button
                            label="Continue"
                            onClick={handleContinue}
                            style={{ margin: '10px', width: "90px", backgroundColor: "white", color: "black" }}
                        />
                    </div>
                </div>
            </Dialog>
        </div >
    )
}

export default Appointments
