import React, { useEffect, useMemo, useState } from 'react';
import InputField from '../../../components/formElement/InputField';
import DateRangeFilter from '../../../components/Filter/FaxFilter/DateRangeFilter';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { SortDecre, SortIncre } from '../../../assets/images/Icon';
import { Column } from 'primereact/column';
import AppointmentsHead from '../../../components/Filter/Appointments/AppointmentsHead';
import { FilterMatchMode } from 'primereact/api';
import { AgencyList, fetchAgencyData, fetchHistoryData, handleHistoryPatient } from '../../../redux/store/AppointmentsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store';
import Loader from '../../../components/loader/Loader';
import Watch from "../../../assets/images/appointments-watch.svg";
import { Dialog } from 'primereact/dialog';
import InputDate from '../../../components/formElement/InputDate';
import AppApplyFilter from "../../../assets/images/appointment-apply-filter.svg";
import Calender from '../../../components/Filter/Appointments/Calender';


type FilterMeta = {
    value: string | null | (string | null)[]; // Accept arrays or strings
    matchMode: FilterMatchMode;
};

const Agency = ({ onRowSelect }: { onRowSelect: (rowData: AgencyList) => void; }) => {

    const { siteOption, agencyList, agencyLoading, historyList, historyLoading } = useSelector((state: RootState) => state.AppointmentsReducer);

    const newSiteOption = useMemo(() => {
        const filtered = siteOption.filter((val) => {
            return val.site_no && val.site !== "All Sites";
        });

        const newData = filtered.map((val) => {
            return {
                label: val.site, value: val.site
            }
        });
        return newData;
    }, [siteOption]);

    const dispatch = useAppDispatch();

    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [filters, setFilters] = useState<{
        global: FilterMeta;
        site: FilterMeta;
        status: FilterMeta;
    }>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        site: { value: [], matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.IN },
    });

    const [showData, setShowData] = useState(true);  // Track whether data should be shown
    const [hideShow, setHideShow] = useState(false);  // Track whether data should be shown
    const[showcalander,setshowcalander]=useState(false)

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilters(prev => ({
            ...prev,
            global: { ...prev.global, value }
        }));
    };

    const onFilterChange = (e: { value: (string | null)[] }, id: "site" | "status") => {
        const value = e.value;
        const updatedValues = value.includes("_blank")
            ? value.map(v => (v === "_blank" ? null : v)) // Convert "_blank" back to null
            : value;

        setFilters(prev => {
            const newFilters = {
                ...prev,
                [id]: { ...prev[id], value: updatedValues }
            };
            return newFilters;
        });
    };

    // check if field is empty or not?
    const isFilterEmpty =
        !filters.global.value &&
        !startDate &&
        !endDate &&
        (filters.site.value === null || filters.site.value.length === 0) &&
        (filters.status.value === null || filters.status.value.length === 0);

    const statusOptions = [
        { label: "Done", value: "Done" },
        { label: "Infused", value: "Infused" },
        { label: "Scheduled", value: "Scheduled" },
        { label: "Submitted for Billing", value: "Submitted for Billing" },
    ];

    const handleResetClick = () => {
        setStartDate(undefined); // Reset start date
        setEndDate(undefined); // Reset end date
        setFilters(prev => ({
            ...prev,
            global: { ...prev.global, value: null },
            status: { ...prev.status, value: null },
            site: { ...prev.site, value: [] }, // Reset site filter to empty
        }));

        setShowData(false);
        setHideShow(false);
    };

    const formatDateToYYYYMMDD = (date?: Date): string => {
        if (!date || !(date instanceof Date)) {
            return "";
        }

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}/${month}/${day}`;
    };

    // Filter Action From Favourite Filter
    const handleFilterAction = () => {

        const convertSitesToIds = (siteNames: string[] | null) => {
            if (!siteNames) return [];
            return siteNames.map(siteName => {
                const matchingSite = siteOption.find(site => site.site === siteName);
                return matchingSite ? Number(matchingSite.site_no) : null;
            }).filter(id => id !== null);
        };

        // @ts-ignore
        const selectedSite = convertSitesToIds(filters.site.value);

        const filterQuery = {
            site_no: selectedSite,
            status: filters.status.value,
            patientSearch: filters.global.value,
            from_date: formatDateToYYYYMMDD(startDate),
            to_date: formatDateToYYYYMMDD(endDate),
        };
        const jsonFilter = JSON.stringify(filterQuery);
        const newData = `\"${jsonFilter.replace(/"/g, '\\"')}\"`;
        setShowData(true);
        setHideShow(true);
        dispatch(fetchAgencyData({ query: newData })); // Dispatch filtered data
        setshowcalander(false)
    };


    // for Data Table
    const sortIcon = (order: any) => {
        if (order.sortOrder === 1) {
            return <img className='ml-1' src={SortIncre} alt='Sort Ascending' />;
        } else if (order.sortOrder === -1) {
            return <img className='ml-1' src={SortDecre} alt='Sort Descending' />;
        }
        return <img className='ml-1' style={{ opacity: "0" }} src={SortDecre} alt='Sort Descending' />;
    };

    const pageOptions = [5, 10, 15, 25, 75, 100];

    // formate time AM, PM
    function formatDate(dateString: string): string {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1);
        const day = date.getDate();
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
    }

    // State for toggle window and owner's name
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

    const handleCloseModal = () => {
        setIsHistoryModalOpen(false);
    };
    useEffect(() => {
        if (!isHistoryModalOpen) {
            dispatch(handleHistoryPatient());
        }
    }, [isHistoryModalOpen]);


    const handleOpenHistoryModal = (rowData: any) => {
        setIsHistoryModalOpen(true); // Open the modal
    };
    useEffect(() => {
        if(showcalander){
            setShowData(false)
        }
        else{
            setShowData(true)
        }
      
       }, [showcalander])
       
    const undoBody = (rowData: any) => {
        if (!rowData.isLogHistory) return null;
        return (
            <div className="d-flex justify-content-center" >
                <div className="archive-btn edit-icon" onClick={(e) => {
                    e.stopPropagation();

                    const scheduleID = rowData.patient_schedule_id;

                    const typeID = 8;
                    const query = {
                        scheduleID,
                        typeID
                    };
                    const jsonHistory = JSON.stringify(query);
                    dispatch(fetchHistoryData({ scheduleID: scheduleID, typeID: typeID }));

                    handleOpenHistoryModal(rowData)
                }}>
                    {/* <i className="pi pi-pencil" /> */}
                    <img src={Watch} alt="Watch_History" />
                </div>
            </div>
        )
    };

    const location = window.location.pathname;

    return (
        <div>
            <div className='mt-2'>
                <AppointmentsHead
                    filtersCompo={
                        <div className="position-relative w-75">
                            <div className="flex align-items-center ">
                                <div className='grid align-items-center md:justify-content-center'>
                                    <div className="col-12 md:col">
                                        <InputField
                                            placeholder="Search by Patient Name, Case Manager, Nurse or Agency"
                                            className='sec-head-input'
                                            id="search"
                                            globalFilterValue={typeof filters.global?.value === "string" ? filters.global.value : ""}
                                            onGlobalFilterChange={onGlobalFilterChange}
                                        />
                                    </div>
                                    <div className="col-12 md:col dropdown-filter">
                                        <MultiSelect
                                            value={filters.site?.value || []}
                                            options={newSiteOption}
                                            onChange={(e) => onFilterChange(e, "site")}
                                            placeholder="Select Site"
                                            style={{ width: '100%' }}
                                            maxSelectedLabels={1}
                                            panelClassName="dropdown-filter-panel custom-dropdown"
                                            filter
                                        />
                                    </div>
                                    <div className="col-12 md:col dropdown-filter">
                                        <MultiSelect
                                            value={filters.status?.value || []}
                                            options={statusOptions}
                                            onChange={(e) => onFilterChange(e, "status")}
                                            placeholder="Select Status"
                                            style={{ width: '100%' }}
                                            maxSelectedLabels={1}
                                            panelClassName="dropdown-filter-panel custom-dropdown"
                                            filter
                                        />
                                    </div>
                                    <div className="col-12 md:col m-0">
                                        <InputDate
                                            id="startDate"
                                            label="From:"
                                            labelclassName="col-3 md:col-3 sec-head-m0-r0"
                                            inputclassName="col-9 md:col-9 sec-head-m0-r0"
                                            handleChange={(e: { value: Date | undefined }) => setStartDate(e.value || undefined)}
                                            value={startDate}
                                        />
                                    </div>
                                    <div className="col-12 md:col">
                                        <InputDate
                                            id="endDate"
                                            label="To:"
                                            labelclassName="col-2 sec-head-m0-r0"
                                            inputclassName="col-10 sec-head-m0-r0"
                                            handleChange={(e: { value: Date | undefined }) => setEndDate(e.value || undefined)}
                                            value={endDate}
                                        />
                                    </div>
                                    <div className="col-12 md:col flex align-content-start align-items-center">
                                        <Button className='appointment-reset-apply-btn mr-3' label="Reset" outlined onClick={handleResetClick} />
                                        <Button
                                            icon={
                                                <img
                                                    src={AppApplyFilter}
                                                    alt="My Icon"
                                                    style={{ marginRight: "4px", marginBottom: "2px", textAlign: "center", display: "flex", alignItems: 'center', justifyContent: 'center' }}
                                                />
                                            }
                                            label="Apply Filter"
                                            style={{ backgroundColor: "#193E70", border: "0.66px solid #193E7099", fontWeight: "600" }}
                                            className="appointment-apply-btn"
                                            onClick={handleFilterAction}
                                            disabled={isFilterEmpty}
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    setFilters={setFilters}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setshowcalander={setshowcalander}
                />

                <div className="card border-0 mt-3">
                    {hideShow && (
                       <div className="table-responsive fax-table" style={{ overflowX: "auto" }}>
                       {agencyLoading ? (
                           <div>
                               <Loader label="Agency" location={location} path="/schedule-management" />
                           </div>
                       ) : (
                           <>
                               {showcalander && !showData && <Calender nurseList={agencyList} />}
                               {showData && (
                                   <DataTable
                                       scrollable
                                       removableSort
                                       scrollHeight="750px"
                                       value={agencyList}
                                       paginator
                                       sortIcon={sortIcon}
                                       selectionMode="single"
                                       rowsPerPageOptions={pageOptions}
                                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                       rows={15}
                                       currentPageReportTemplate="Showing {first}-{last} of {totalRecords} Results"
                                       filters={filters}
                                       globalFilterFields={["patient_Fname", "patient_Lname", "primary_Rn", "agency_Name"]}
                                       emptyMessage="No Records found."
                                       onRowSelect={(e) => onRowSelect(e.data)}
                                       paginatorClassName="agency-table-paginator"
                                   >
                                       <Column field="patient_Fname" sortable header="Patient First Name" />
                                       <Column field="patient_Lname" sortable header="Patient Last Name" />
                                       <Column field="site" sortable header="Site" />
                                       <Column field="next_Infusion_Date" sortable header="Date" />
                                       <Column field="status" sortable header="Status" />
                                       <Column field="agency_Name" sortable header="Agency Name" />
                                       <Column field="primary_Rn" sortable header="Case Manager" />
                                       <Column field="teams" sortable header="Team" />
                                       <Column field="comments" sortable header="Comments" />
                                       <Column field="created_by" sortable header="Created By" />
                                       <Column field="createdon" sortable header="Created On" body={(data) => formatDate(data.createdon)} />
                                       <Column field="isLogHistory" body={(data) => undoBody(data)} />
                                   </DataTable>
                               )}
                           </>
                       )}
                   </div>
                   
                    )}
                </div>
                {/* History Modal */}
                {isHistoryModalOpen && (
                    <Dialog
                        visible={isHistoryModalOpen}
                        // onHide={() => setIsHistoryModalOpen(false)}
                        onHide={handleCloseModal}
                        header="History"
                        modal
                        style={{ width: '60vw', minHeight: '200px', overflow: "hidden" }}
                        draggable={false}
                        className="owner-name-modal"
                    >
                        <div className="content-fax">
                            {historyLoading ? (
                                <div>
                                    <Loader label='Agency' location={location} path='/schedule-management' />
                                </div>
                            ) : (

                                <DataTable
                                    scrollable
                                    removableSort
                                    value={agencyList}
                                >
                                    <Column field='mrn' sortable header="MRN" />
                                    <Column field='duration_hour' sortable header="Duration" />
                                    <Column field='status' sortable header="Status" />
                                    <Column field="next_Infusion_Date" sortable header="Appointments Count" />
                                    <Column field='comments' sortable header="Comments" />
                                    <Column field='created_by' sortable header="Created By" />
                                    <Column field="formatcreatedon" sortable header="Created On" />
                                    <Column field="modified_by" sortable header="Modified By" />
                                    <Column field="formatmodifieddate" sortable header="Modified On" />
                                </DataTable>

                            )}

                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", flexShrink: 0 }}>
                                <Button
                                    label="Close"
                                    onClick={() => setIsHistoryModalOpen(false)}
                                    style={{ margin: '10px', width: "90px", backgroundColor: "white", color: "black" }}
                                />
                            </div>
                        </div>
                    </Dialog>
                )}
            </div>
        </div>
    );
};

export default Agency;
