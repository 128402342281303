import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { Column } from "primereact/column";
import { SortDecre, SortIncre } from "../../../assets/images/Icon";
import { FormatDate } from "../../../utils/formatDate";
import Header from "../../../layouts/Header/Header";
import { getDeliveryTicketItemList } from "../../../redux/store/ARManagerReducer";
import { formatCurrency } from "../../../utils/formatCurrency";

const DeliveryTicketItemListDialogBox = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) => {
  const { deliveryTicketItemLoading, deliveryTicketItemList } = useSelector(
    (state: RootState) => state.arManagerReducer
  );

  const progressColumns = [
    {
      field: "itemName",
      header: "Item Name",
      sortable: true,
    },
    {
      field: "quantity",
      header: "Quantity",
      sortable: true,
    },
    {
      field: "cost",
      header: "Cost",
      sortable: true,
      body: (rowData: any) => formatCurrency(rowData.cost),
    },
    {
      field: "list",
      header: "List",
      sortable: true,
      body: (rowData: any) => formatCurrency(rowData.list),
    },
    {
      field: "special",
      header: "Special",
      sortable: true,
      body: (rowData: any) => formatCurrency(rowData.special),
    },
    {
      field: "awp",
      header: "AWP",
      sortable: true,
      body: (rowData: any) => formatCurrency(rowData.awp),
    },
    {
      field: "expected",
      header: "Expected",
      sortable: true,
      body: (rowData: any) => formatCurrency(rowData.expected),
    },
    {
      field: "billing_code",
      header: "Billing Code",
      sortable: true,
    },
  ];

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  return (
    <>
      <Dialog
        style={{ width: "1200px", zIndex: 1111 }}
        draggable
        header="Delivery Ticket Item List"
        resizable
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <DataTable
          value={deliveryTicketItemList}
          emptyMessage="No Data Found"
          scrollable
          paginator
          rows={15}
          rowsPerPageOptions={[5, 10, 15, 50]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          removableSort
          scrollHeight="612px"
          sortIcon={sortIcon}
          loading={deliveryTicketItemLoading}
        >
          {progressColumns.map((column, index) => (
            <Column
              key={index}
              field={column.field}
              header={column.header}
              sortable={column.sortable}
              body={column.body}
            />
          ))}
        </DataTable>
      </Dialog>
    </>
  );
};

export default DeliveryTicketItemListDialogBox;
