import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useState } from "react";
import { Button } from "primereact/button";
import { getHealthMeasurement } from "../../../redux/store/CensusReducer";
import InputNum from "../../formElement/InputNumber";
import DignosisReportDialogBox from "./DignosisReportDialogBox";
import { DiagnosisReport, HealthInformationDialog } from "../../../assets/images/Icon";

const HealthInformationDialogBox = ({
  visible,
  setVisible,
  onStatusUpdateLoading,
  onStatusUpdated,
}: {
  visible: boolean;
  setVisible: any;
  onStatusUpdateLoading: (isLoading: boolean) => void;
  onStatusUpdated: () => void;
}) => {
  const [healthInformationData, setHealthInformationData] = useState<{
    heartRate: number | null;
    bloodPressure: number | null;
    oxygenSaturation: number | null;
    temprature: number | null;
    respiratoryRate: number | null;
    gastrointestinal: number | null;
    height: number | null;
    weight: number | null;
    genitourinary: number | null;
    musculoskeletal: number | null;
  }>({
    heartRate: null,
    bloodPressure: null,
    oxygenSaturation: null,
    temprature: null,
    respiratoryRate: null,
    gastrointestinal: null,
    height: null,
    weight: null,
    genitourinary: null,
    musculoskeletal: null,
  });
  const [errorMessage, setErrorMessage] = useState<any>({
    heartRate: "",
    bloodPressure: "",
    oxygenSaturation: "",
    temprature: "",
    respiratoryRate: "",
    gastrointestinal: "",
    height: "",
    weight: "",
    genitourinary: "",
    musculoskeletal: "",
  });

  const [dignosisReportVisible, setDignosisReportVisible] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setHealthInformationData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const requiredFields = [
    { field: "heartRate", message: "Heart Rate is required!" },
    { field: "bloodPressure", message: "Blood Pressure is required!" },
    { field: "oxygenSaturation", message: "Oxygen Saturation is required!" },
    { field: "temprature", message: "Temprature is required!" },
    { field: "respiratoryRate", message: "Respiratory Rate is required!" },
    { field: "gastrointestinal", message: "Gastrointestinal is required!" },
    { field: "height", message: "Height is required!" },
    { field: "weight", message: "Weight is required!" },
    { field: "genitourinary", message: "Genitourinary is required!" },
    { field: "musculoskeletal", message: "Musculoskeletal is required!" },
  ];

  const validateFields = (
    data: any,
    requiredFields: { field: string; message: string }[]
  ) => {
    let errors: any = {};

    requiredFields.forEach(({ field, message }) => {
      if (!data[field] || data[field] === "") {
        errors[field] = message;
      } else {
        errors[field] = "";
      }
    });

    return errors;
  };

  const SendHealthInformation = () => {
    const validationErrors = validateFields(
      healthInformationData,
      requiredFields
    );

    const filteredErrors = Object.fromEntries(
      Object.entries(validationErrors).filter(([key, value]) => value !== "")
    );

    if (Object.keys(filteredErrors).length > 0) {
      setErrorMessage(filteredErrors);
      return;
    }
    setVisible(false);
    setErrorMessage({});
    setHealthInformationData({
      heartRate: null,
      bloodPressure: null,
      oxygenSaturation: null,
      temprature: null,
      respiratoryRate: null,
      gastrointestinal: null,
      height: null,
      weight: null,
      genitourinary: null,
      musculoskeletal: null,
    });
    onStatusUpdateLoading(true);

    dispatch(
      getHealthMeasurement({
        healthData: {
          vitals: {
            heartRate: healthInformationData.heartRate?.toString(),
            bloodPressure: healthInformationData.bloodPressure?.toString(),
            oxygenSaturation:
              healthInformationData.oxygenSaturation?.toString(),
            temprature: healthInformationData.temprature?.toString(),
            respiratoryRate: healthInformationData.respiratoryRate?.toString(),
            gastrointestinal:
              healthInformationData.gastrointestinal?.toString(),
            height: healthInformationData.height?.toString(),
            weight: healthInformationData.weight?.toString(),
            genitourinary: healthInformationData.genitourinary?.toString(),
            musculoskeletal: healthInformationData.musculoskeletal?.toString(),
          },
        },
        callback: (res: any) => {
          if (res.data.success) {
            onStatusUpdated();
            setDignosisReportVisible(true);
          }
        },
      })
    );
  };

  const CustomFooter = (
    <div className="flex gap-2 justify-content-end">
      <Button
        label="Cancel"
        onClick={() => {
          setVisible(false);
          setErrorMessage({});
          setHealthInformationData({
            heartRate: null,
            bloodPressure: null,
            oxygenSaturation: null,
            temprature: null,
            respiratoryRate: null,
            gastrointestinal: null,
            height: null,
            weight: null,
            genitourinary: null,
            musculoskeletal: null,
          });
        }}
      />
      <Button label="Send" onClick={SendHealthInformation} />
    </div>
  );

  const customHeader = () => {
    return (
      <>
        <div className="dialog-header flex gap-2">
          <div className="header-icon mr-2">
            <img
              className="mt-2"
              src={HealthInformationDialog}
              alt="Health Information"
            />
          </div>
          <div className="header-title">
            <div className="text-xl">Health Information</div>
            <div className="text-sm font-medium header-subtitle">
              Enter Details to Access Health Information
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog
        // style={{ width: "1000px", zIndex: 1111 }}
        className="w-10 md:w-9 lg:w-7 xl:w-5"
        draggable
        header={customHeader}
        resizable
        visible={visible}
        onHide={() => {
          setVisible(false);
          setErrorMessage({});
          setHealthInformationData({
            heartRate: null,
            bloodPressure: null,
            oxygenSaturation: null,
            temprature: null,
            respiratoryRate: null,
            gastrointestinal: null,
            height: null,
            weight: null,
            genitourinary: null,
            musculoskeletal: null,
          });
        }}
        footer={CustomFooter}
      >
        <div className="grid">
          <div className="sm:col-6 col-12 py-1">
            <InputNum
              value={healthInformationData.heartRate}
              label="Heart Rate"
              id="heartRate"
              onChange={handleChange}
              labelclassName="col-3"
              inputClassName="col-9"
              invalid={errorMessage.heartRate}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputNum
              value={healthInformationData.bloodPressure}
              label="Blood Pressure"
              id="bloodPressure"
              onChange={handleChange}
              labelclassName="col-3"
              inputClassName="col-9"
              invalid={errorMessage.bloodPressure}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputNum
              value={healthInformationData.oxygenSaturation}
              label="Oxygen Saturation"
              id="oxygenSaturation"
              onChange={handleChange}
              labelclassName="col-3"
              inputClassName="col-9"
              invalid={errorMessage.oxygenSaturation}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputNum
              value={healthInformationData.temprature}
              label="Temperature"
              id="temprature"
              onChange={handleChange}
              labelclassName="col-3"
              inputClassName="col-9"
              invalid={errorMessage.temprature}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputNum
              value={healthInformationData.respiratoryRate}
              label="Respiratory Rate"
              id="respiratoryRate"
              onChange={handleChange}
              labelclassName="col-3"
              inputClassName="col-9"
              invalid={errorMessage.respiratoryRate}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputNum
              value={healthInformationData.gastrointestinal}
              label="Gastrointestinal"
              id="gastrointestinal"
              onChange={handleChange}
              labelclassName="col-3"
              inputClassName="col-9"
              invalid={errorMessage.gastrointestinal}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputNum
              value={healthInformationData.height}
              label="Height"
              id="height"
              onChange={handleChange}
              labelclassName="col-3"
              inputClassName="col-9"
              invalid={errorMessage.height}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputNum
              value={healthInformationData.weight}
              label="Weight"
              id="weight"
              onChange={handleChange}
              labelclassName="col-3"
              inputClassName="col-9"
              invalid={errorMessage.weight}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputNum
              value={healthInformationData.genitourinary}
              label="Genitourinary"
              id="genitourinary"
              onChange={handleChange}
              labelclassName="col-3"
              inputClassName="col-9"
              invalid={errorMessage.genitourinary}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputNum
              value={healthInformationData.musculoskeletal}
              label="Musculoskeletal"
              id="musculoskeletal"
              onChange={handleChange}
              labelclassName="col-3"
              inputClassName="col-9"
              invalid={errorMessage.musculoskeletal}
            />
          </div>
        </div>
      </Dialog>
      <DignosisReportDialogBox
        visible={dignosisReportVisible}
        setVisible={setDignosisReportVisible}
      />
    </>
  );
};

export default HealthInformationDialogBox;
