import React from "react";
import PrescribersWorkflowFilter from "../../components/Filter/PrescribersorderWorkflow/PrescribersWorkflowFilter";
import PrescribersWorkflowDatatable from "../../components/DataTable/PrescribersWorkflowDatatable";

const PrescribersorderWorkflow = () => {
  return (
    <>
      <div>
        <PrescribersWorkflowFilter Alldata={undefined}/>
        <PrescribersWorkflowDatatable WorkflowData={""}/>
        </div>
    </>
  );
};

export default PrescribersorderWorkflow;
