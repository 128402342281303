import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  addTask,
  getPatientToDoList,
  Task,
} from "../../redux/store/TasklistReducer";
import { displayToast } from "../Toast/ToastMessage";
import Input from "../formElement/Input";
import InputTextArea from "../formElement/InputTextArea";
import InputDropdown from "../formElement/InputDropdown";
import InputDate from "../formElement/InputDate";
import "../../assets/css/tasklist.css";
import { getSearchPatientList } from "../../redux/store/FaxSliceReducer";
import axios, { CancelTokenSource } from "axios";

interface PatientTodo {
  patient_todo_id: number;
  mrn: string;
  task_detail: string;
  created_by: string;
  patientName: string;
  assign_to: string;
  category_id: number;
  due_date: Date | null;
  comment: string;
  status_id: number;
  site: string;
  team: string;
  therapy: string;
}

interface PatientRecord {
  mrn: string;
  full_name: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  referral_date: string;
  prescriber_name: string;
  therapy: string;
  insurance_name: string;
  site: string;
  phone_number: string;
  patient_status: string;
  social_security_number: string;
  patient_SS: null | string;
  sales_rep: string;
  primary_rn: string;
  insurance_coordinator: string;
  patient_team: string;
  is_read: string;
  modified_date: string | null;
  last_read_message_id: string | null;
  site_no: string;
}

const AddNewTask = ({
  editData,
  visible,
  setVisible,
  action,
  setAction,
  userDetail,
  filterQuery,
}: {
  editData?: Task;
  visible: boolean;
  setAction: any;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  action: string | null;
  userDetail?: any;
  filterQuery: any;
}): React.ReactElement => {
  const { userEmail } = userDetail;
  const { category, assignTo, statusList } = useSelector(
    (state: RootState) => state.tasklistReducer
  );
  const dispatch = useDispatch<AppDispatch>();
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);
  const [isValidationTriggered, setIsValidationTriggered] = useState(false);

  const [filteredPatients, setFilteredPatients] = useState<PatientRecord[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<PatientRecord | null>(
    null
  );
  const [taskData, setTaskData] = useState<PatientTodo>({
    patient_todo_id: 0,
    mrn: "",
    task_detail: "",
    created_by: userEmail || "",
    assign_to: "",
    patientName: "",
    category_id: 0,
    due_date: null,
    comment: "",
    status_id: 0,
    site: "",
    team: "",
    therapy: "",
  });

  const [invalidFields, setInvalidFields] = useState<{ [key: string]: string }>(
    {
      mrn: "",
      task_detail: "",
      assign_to: "",
      category_id: "",
      due_date: "",
      comment: "",
    }
  );

  useEffect(() => {
    if (action === "edit" && editData) {
      setTaskData({
        patient_todo_id: editData.patient_todo_id || 0,
        mrn: editData.mrn || "",
        task_detail: editData.task_detail || "",
        created_by: userEmail || "",
        assign_to: `${editData.assign_to}` || "",
        patientName: editData.patient_first_name || "",
        category_id: Number(editData.category_id) || 0,
        due_date: new Date(editData.due_date) || null,
        comment: editData.comment || "",
        status_id: editData.status_id || 0,
        site: editData.site_name || "",
        team: editData.teams || "",
        therapy: editData.therapy_type || "",
      });
    }
  }, [action, editData, userEmail]);

  useEffect(() => {
    if (!visible) {
      resetForm();
      setIsValidationTriggered(false);
      setAction(null);
    }
    setSelectedPatient(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (isValidationTriggered) {
      validateFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskData, isValidationTriggered]);

  const resetForm = () => {
    setTaskData({
      patient_todo_id: 0,
      mrn: "",
      patientName: "",
      task_detail: "",
      created_by: userEmail || "",
      assign_to: "",
      category_id: 0,
      due_date: null,
      comment: "",
      status_id: 0,
      site: "",
      team: "",
      therapy: "",
    });

    setInvalidFields({
      mrn: "",
      task_detail: "",
      patientName: "",
      site: "",
      assign_to: "",
      category_id: "",
      due_date: "",
      comment: "",
    });
  };

  const validateField = (field: string): string => {
    switch (field) {
      case "mrn":
        return taskData.mrn ? "" : "MRN is required";
      case "task_detail":
        return taskData.task_detail ? "" : "Task detail is required";
      case "assign_to":
        return taskData.assign_to ? "" : "Assigned to is required";
      case "category_id":
        return taskData.category_id ? "" : "Category is required";
      case "due_date":
        return taskData.due_date ? "" : "Due date is required";
      case "comment":
        return taskData.comment ? "" : "Comment is required";
      case "patientName":
        return taskData.patientName ? "" : "Patient Name is required";
      case "site":
        return taskData.site ? "" : "Site is required";
      default:
        return "";
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setTaskData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const validateFields = (): boolean => {
    let isValid = true;
    const updatedInvalidFields: { [key: string]: string } = {};
    for (let field in taskData) {
      const errorMessage = validateField(field);
      updatedInvalidFields[field] = errorMessage;
      if (errorMessage) {
        isValid = false;
      }
    }

    setInvalidFields(updatedInvalidFields);
    return isValid;
  };

  const handleSave = async () => {
    setIsValidationTriggered(true);
    if (!validateFields()) return;
    
    setVisible(false);

    const taskPayload = {
      patient_todo_id: taskData.patient_todo_id || 0,
      mrn: taskData.mrn,
      task_detail: taskData.task_detail,
      assign_to: taskData.assign_to,
      created_by: taskData.created_by,
      category_id: taskData.category_id,
      due_date: taskData.due_date
        ? new Date(taskData.due_date).toLocaleDateString("en-CA")
        : null,
      comment: taskData.comment,
      status_id: action === "edit" ? taskData.status_id : 3,
    };

    try {
      await dispatch(
        addTask({
          updateData: taskPayload,
          callback: (res: any) => {
            if (res.data.success) {
              displayToast({
                msg:
                  action === "edit"
                    ? "Task updated successfully"
                    : "Task added successfully",
                type: "success",
              });
              dispatch(getPatientToDoList({ SearchString: filterQuery }));
            } else {
              displayToast({
                msg: res?.data?.message || "Something went wrong",
                type: "error",
              });
            }
          },
        })
      );
    } catch (error) {
      displayToast({
        msg: "Error occurred while saving the task",
        type: "error",
      });
    }
  };

  const searchPatient = (query: string) => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Operation canceled due to a new request.");
    }
    cancelTokenRef.current = axios.CancelToken.source();

    dispatch(
      getSearchPatientList({
        query,
        mail: userEmail,
        cancelToken: cancelTokenRef.current.token,
      })
    )
      .unwrap()
      .then((response: any) => {
        setFilteredPatients(response || []);
      })
      .catch((error) => {
        console.error("Error fetching patients:", error);
      });
  };

  const onPatientSelect = (e: any) => {
    setTaskData((prev) => ({
      ...prev,
      mrn: e.value?.mrn,
      patientName: e.value?.full_name,
      site: e.value?.site,
      team: e.value?.patient_team,
      therapy: e.value?.therapy,
    }));
  };

  return (
    <div>
      <Dialog
        draggable={false}
        header={action === "add" ? "New Task" : "Edit Task"}
        visible={visible}
        style={{ minWidth: "60vw", height: "70vh" }}
        className="task-dialog"
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div>
          <div className="flex align-items-center p-1 mb-3">
            <label className="font-semibold">Search Patient</label>
            <div className="relative w-6 p-1 ml-4">
              <i className="pi pi-search  absolute z-2 search-icon" />
              <AutoComplete
                value={selectedPatient}
                suggestions={filteredPatients}
                completeMethod={(e) => searchPatient(e.query)}
                field="first_name"
                onSelect={onPatientSelect}
                onChange={(e) => setSelectedPatient(e.value)}
                panelClassName="patient-search-panel taslistMrn_Field"
                placeholder="Search with MRN, Patient Name"
                className="MRN-Search w-8"
                itemTemplate={(item) => (
                  <div className="d-flex align-items-center gap-2">
                    {item?.first_name} {item?.last_name} - {item?.mrn}
                  </div>
                )}
                disabled={action !== "add"}
              />
            </div>
          </div>
          <div className="grid Input-fields">
            <div className="col-6">
              <div className="">
                <Input
                  label="MRN"
                  type="text"
                  id="mrn"
                  value={taskData.mrn}
                  labelclassName="col-4 label-lg"
                  handleChange={handleChange}
                  inputclassName="col-6 Input-field"
                  disabled
                  invalid={invalidFields.mrn}
                  required={true}
                />
              </div>
              <div className="">
                <Input
                  label="Patient Name"
                  type="text"
                  id="patientName"
                  value={taskData.patientName}
                  labelclassName="col-4 label-lg"
                  handleChange={handleChange}
                  inputclassName="col-6 Input-field"
                  disabled
                  invalid={invalidFields.patientName}
                  required={true}
                />
              </div>
              <div className="">
                <Input
                  label="Site"
                  type="text"
                  value={taskData.site}
                  id="site"
                  labelclassName="col-4 label-lg"
                  handleChange={handleChange}
                  inputclassName="col-6 Input-field"
                  disabled
                  invalid={invalidFields.site}
                  required={true}
                />
              </div>
              <div className="">
                <Input
                  label="Team"
                  type="text"
                  value={taskData.team}
                  id="team"
                  labelclassName="col-4 label-lg"
                  handleChange={handleChange}
                  inputclassName="col-6 Input-field"
                  disabled
                />
              </div>
              <div className="">
                <Input
                  label="Therapy"
                  type="text"
                  id="therapy"
                  value={taskData.therapy}
                  labelclassName="col-4 label-lg"
                  handleChange={handleChange}
                  inputclassName="col-6 Input-field"
                  disabled
                />
              </div>
              <div className="">
                <Input
                  label="Assigned By"
                  type="text"
                  value={taskData.created_by}
                  disabled
                  id="created_by"
                  labelclassName="col-4 label-lg"
                  inputclassName="col-6 Input-field"
                />
              </div>
              <div>
                <InputDropdown
                  options={assignTo}
                  label="Assigned To"
                  optionLabel="full_name"
                  optionValue="employee_id"
                  type="text"
                  id="assign_to"
                  value={Number(taskData.assign_to)}
                  dropdownclassName="col-6 Input-field task-list-dropdown"
                  handleChange={handleChange}
                  labelclassName="col-4 label-lg"
                  invalid={invalidFields.assign_to}
                  filter
                  requiredRight={true}
                />
              </div>
              <div>
                <InputDropdown
                  options={category}
                  label="Category"
                  type="text"
                  id="category_id"
                  optionLabel="description"
                  optionValue="drug_category_id"
                  value={taskData.category_id}
                  handleChange={handleChange}
                  dropdownclassName="col-6 Input-field task-list-dropdown"
                  labelclassName="col-4 label-lg"
                  invalid={invalidFields.category_id}
                  filter
                  requiredRight={true}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="input-area">
                <InputDate
                  id="due_date"
                  value={taskData.due_date}
                  handleChange={handleChange}
                  placeholder="Select a date"
                  labelclassName="col-4 label-lg"
                  inputclassName="col-7 TaskListCalender"
                  label="Due Date"
                  invalid={invalidFields.due_date}
                  requiredRight={true}
                />
              </div>
              <div className="text-area pt-3">
                <InputTextArea
                  label="Task Details"
                  value={taskData.task_detail}
                  rows={6}
                  id="task_detail"
                  handleChange={handleChange}
                  labelclassName="col-4 label-lg"
                  inputclassName="col-7"
                  placeholder=""
                  invalid={invalidFields.task_detail}
                  requiredRight={true}
                />
              </div>
              <div className="text-area pt-1">
                <InputTextArea
                  label="Comments"
                  value={taskData.comment}
                  rows={6}
                  labelclassName="col-4 label-lg"
                  inputclassName="col-7 "
                  handleChange={handleChange}
                  id="comment"
                  invalid={invalidFields.comment}
                  placeholder=""
                  requiredRight={true}
                />
              </div>
              {action === "edit" && (
                <div>
                  <InputDropdown
                    options={statusList}
                    label="Status"
                    type="text"
                    id="status_id"
                    optionValue="status_id"
                    optionLabel="status_description"
                    placeholder="Select status"
                    value={taskData.status_id}
                    dropdownclassName="col-7 Input-field task-list-dropdown"
                    handleChange={handleChange}
                    labelclassName="col-4 label-lg"
                  />
                </div>
              )}
            </div>
            <div className="col-offset-10 p-2">
              <Button
                label={action === "add" ? "Add Task" : "Update"}
                className="custom-button  "
                onClick={handleSave}
                style={{ marginLeft: "10px" }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddNewTask;
