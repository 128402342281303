import { Badge } from "primereact/badge";
import { Card, CardProps } from "primereact/card";
import React, { ReactNode } from "react";

const ResponsiveCardView = ({
  cardHeader,
  cardContent,
  cardFooter,
}: {
  cardHeader: React.ReactNode | ((props: CardProps) => React.ReactNode);
  cardContent: React.ReactNode;
  cardFooter: React.ReactNode | ((props: CardProps) => React.ReactNode);
}) => {
  return (
    <>
      <Card className="text-sm" children={cardContent} footer={cardFooter} header={cardHeader} />
    </>
  );
};

export default ResponsiveCardView;
