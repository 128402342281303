import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Column } from "primereact/column";
import { SortDecre, SortIncre } from "../../../assets/images/Icon";

const InsuranceDialogBox = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) => {

  const { insuranceLoading, insuranceList } = useSelector(
    (state: RootState) => state.arManagerReducer
  );

  const progressColumns = [
    {
      field: "organization",
      header: "Organization",
      sortable: true, 
    },
    {
      field: "contract_status",
      header: "Contract Status",
      sortable: true,
    },
    {
      field: "identifier",
      header: "Identifier",
      sortable: true,
    },
    {
      field: "invoice_type",
      header: "Invoice Type",
      sortable: true,
    },
    { field: "phone", header: "Phone", sortable: true },
    {
      field: "payor_type",
      header: "Payor Type",
      sortable: true,
    },
  ];

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  return (
    <>
      <Dialog
        style={{ width: "1200px", zIndex: 1111 }}
        draggable
        header="Insurance"
        resizable
        visible={visible}
        onHide={() => setVisible(false)}
      >
          <DataTable
            value={insuranceList}
            emptyMessage="No Data Found"
            scrollable
            removableSort
            scrollHeight="612px"
            sortIcon={sortIcon}
            loading={insuranceLoading}
          >
            {progressColumns.map((column, index) => (
              <Column
                key={index}
                field={column.field}
                header={column.header}
                sortable={column.sortable}
              />
            ))}
          </DataTable>
      </Dialog>
    </>
  );
};

export default InsuranceDialogBox;
