import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import {
  getNurseList,
  getAgencyList,
  getinfusionList,
  AddUpdateNurse,
  AddUpdateAgency,
  AddUpdateSuite,
  getZipcodeList,
  getStateList,
  getCityList,
  getCountyList,
  getAddressMappingByCity,
  getAddressMappingByState,
} from "../../../redux/store/NurseAgencyReducer";

import { AppDispatch } from "../../../redux/store";
import Input from "../../formElement/Input";
import InputTextArea from "../../formElement/InputTextArea";
import "../../../assets/css/agency.css";
import InputDropdown from "../../formElement/InputDropdown";
import { Button } from "primereact/button";
import { DropdownChangeEvent } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import { displayToast } from "../../Toast/ToastMessage";
import InputCheck from "../../formElement/InputCheck";
import { InputSwitch } from "primereact/inputswitch";
import { RadioButton } from "primereact/radiobutton";
import InputNum from "../../formElement/InputNumber";
import { number, string } from "yup";

const colors = [
  { colorname: "Dark Red", colorcode: "rgba(139,0,0,1)" },
  { colorname: "Dark Green", colorcode: "rgba(0,100,0,1)" },
  { colorname: "Dark Blue", colorcode: "rgba(0,0,139,1)" },
  { colorname: "Dark Magenta", colorcode: "rgba(139,0,139,1)" },
  { colorname: "Dark Cyan", colorcode: "rgba(0,139,139,1)" },
  { colorname: "Dark Orange", colorcode: "rgba(255,140,0,1)" },
  { colorname: "Dark Yellow", colorcode: "rgba(204,204,0,1)" },
  { colorname: "Dark Purple", colorcode: "rgba(75,0,130,1)" },
  { colorname: "Dark Pink", colorcode: "rgba(255,20,147,1)" },
  { colorname: "Dark Brown", colorcode: "rgba(101,67,33,1)" },
  { colorname: "Dark Olive", colorcode: "rgba(85,107,47,1)" },
  { colorname: "Dark Slate Blue", colorcode: "rgba(72,61,139,1)" },
  { colorname: "Dark Slate Gray", colorcode: "rgba(47,79,79,1)" },
  { colorname: "Dark Turquoise", colorcode: "rgba(0,206,209,1)" },
  { colorname: "Dark Violet", colorcode: "rgba(148,0,211,1)" },
  { colorname: "Dark Salmon", colorcode: "rgba(233,150,122,1)" },
  { colorname: "Dark Orchid", colorcode: "rgba(153,50,204,1)" },
  { colorname: "Dark Khaki", colorcode: "rgba(189,183,107,1)" },
  { colorname: "Dark Sea Green", colorcode: "rgba(143,188,143,1)" },
  { colorname: "Dark Goldenrod", colorcode: "rgba(184,134,11,1)" },
  { colorname: "Dark Gray", colorcode: "rgba(169,169,169,1)" },
  { colorname: "Dark Crimson", colorcode: "rgba(220,20,60,1)" },
  { colorname: "Dark Coral", colorcode: "rgba(205,91,69,1)" },
  { colorname: "Dark Tomato", colorcode: "rgba(255,99,71,1)" },
  { colorname: "Dark Chocolate", colorcode: "rgba(210,105,30,1)" },
  { colorname: "Dark Rosy Brown", colorcode: "rgba(188,143,143,1)" },
  { colorname: "Dark Sandy Brown", colorcode: "rgba(244,164,96,1)" },
  { colorname: "Dark Tan", colorcode: "rgba(210,180,140,1)" },
  { colorname: "Dark Gold", colorcode: "rgba(255,215,0,1)" },
  { colorname: "Dark Periwinkle", colorcode: "rgba(204,204,255,1)" },
  { colorname: "Dark Peach", colorcode: "rgba(255,218,185,1)" },
  { colorname: "Dark Mint", colorcode: "rgba(189,252,201,1)" },
  { colorname: "Dark Teal", colorcode: "rgba(0,128,128,1)" },
  { colorname: "Dark Spring Green", colorcode: "rgba(0,255,127,1)" },
  { colorname: "Dark Forest Green", colorcode: "rgba(34,139,34,1)" },
  { colorname: "Dark Lime", colorcode: "rgba(50,205,50,1)" },
  { colorname: "Dark Aqua", colorcode: "rgba(0,255,255,1)" },
  { colorname: "Dark Sky Blue", colorcode: "rgba(135,206,235,1)" },
  { colorname: "Dark Steel Blue", colorcode: "rgba(70,130,180,1)" },
  { colorname: "Dark Light Blue", colorcode: "rgba(173,216,230,1)" },
  { colorname: "Dark Powder Blue", colorcode: "rgba(176,224,230,1)" },
  { colorname: "Dark Royal Blue", colorcode: "rgba(65,105,225,1)" },
  { colorname: "Dark Lavender", colorcode: "rgba(230,230,250,1)" },
  { colorname: "Dark Plum", colorcode: "rgba(221,160,221,1)" },
  { colorname: "Dark Thistle", colorcode: "rgba(216,191,216,1)" },
  { colorname: "Dark Orchid", colorcode: "rgba(218,112,214,1)" },
  { colorname: "Dark Hot Pink", colorcode: "rgba(255,105,180,1)" },
  { colorname: "Dark Deep Pink", colorcode: "rgba(255,20,147,0.8)" }

];

const NurseAgencyDialogBox = ({
  editData,
  visible,
  setVisible,
  action,
  selected,
  userEmail,
}: {
  editData?: any[];
  visible: boolean;
  setVisible: any;
  action: string;
  selected: string;
  userEmail: string | undefined;
}): React.ReactElement => {
  interface ZipcodeOption {
    zipcode_id: number;
    zipcode: string;
  }

  interface GeneralTaskData {
    statusList: string;
    agency_name: string;
    first_name: string;
    address: string;
    city: string;
    zip_code: string;
    phone: string;
    fax: string;
    Comments: string;
    Type: string; // Adjust if `selected` is a different type (e.g., number, enum)
    last_name: string;
    state: string;
    county: string;
    email: string;
    initial_Visit_Rate_2hr_IV: string;
    visit_Rate_2hr_V: string;
    hourly_Rate_H: string;
    weekend_Holiday_Rate_WH: string;
    travel_Rate_per_hour_Tr: string;
    name_of_suite: string;
    suite_type: string;
    no_of_chair: number | undefined;
    color_code: string;
    suite_id: number | undefined;
    company_id: number | undefined;
    nurse_id: number | undefined;
    userEmail: string | undefined;
  }

  const {
    StatusOptionList,
    CityOptionList,
    ZipcodeOptionList,
    StateOptionList,
    CountyOptionList,
  } = useSelector((state: RootState) => state.NurseAgencyReducer);

  const [generalTaskData, setGeneralTaskData] = useState<GeneralTaskData>({
    statusList: "",
    agency_name: "",
    first_name: "",
    address: "",
    city: "",
    zip_code: "",
    phone: "",
    fax: "",
    Comments: "",
    Type: selected,
    last_name: "",
    state: "",
    county: "",
    email: "",
    initial_Visit_Rate_2hr_IV: "",
    visit_Rate_2hr_V: "",
    hourly_Rate_H: "",
    weekend_Holiday_Rate_WH: "",
    travel_Rate_per_hour_Tr: "",
    name_of_suite: "",
    suite_type: "",
    no_of_chair: undefined,
    color_code: "",
    suite_id: undefined,
    company_id: undefined,
    nurse_id: undefined,
    userEmail: userEmail,
  });

  const [selectedOption, setSelectedOption] = useState<boolean>(false);

  const handleRedioChange = (value: boolean) => {
    setSelectedOption((prev) => !prev);
  };

  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean | string; // Allow fields to be either boolean or string
  }>({
    statusList: false,
    city: false,
    zip_code: false,
    email: false,
    state: false,
    ...((generalTaskData.Type === "Nurse" ||
      generalTaskData.Type === "Agency") && { agency_name: false }),
    ...(generalTaskData.Type === "Infusion Suite" && {
      color_code: false,
      name_of_suite: false,
    }),
  });

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCountyList());
    dispatch(getCityList());
    dispatch(getStateList());
    dispatch(getZipcodeList());
  }, []);

  useEffect(() => {
    setGeneralTaskData({
      ...generalTaskData,
      Type: selected,
      userEmail: userEmail,
    });
    if (action === "edit" && editData) {
      setGeneralTaskData({
        ...generalTaskData,
        statusList: editData[0]?.status,
        agency_name: editData[0]?.agency_name,
        first_name: editData[0]?.first_name,
        address: editData[0]?.address,
        city: editData[0]?.city,
        zip_code: editData[0]?.zip_code,
        phone: editData[0]?.phone,
        fax: editData[0]?.fax,
        Comments:
          selected === "Infusion Suite"
            ? editData[0]?.comment
            : editData[0]?.notes,
        Type: selected,
        last_name: editData[0]?.last_name,
        state: editData[0]?.state,
        county: editData[0]?.county,
        email: editData[0]?.email,
        initial_Visit_Rate_2hr_IV: editData[0]?.initial_Visit_Rate_2hr_IV,
        visit_Rate_2hr_V: editData[0]?.visit_Rate_2hr_V,
        hourly_Rate_H: editData[0]?.hourly_Rate_H,
        weekend_Holiday_Rate_WH: editData[0]?.weekend_Holiday_Rate_WH,
        travel_Rate_per_hour_Tr: editData[0]?.travel_Rate_per_hour_Tr,
        name_of_suite: editData[0]?.name_of_suite,
        suite_type: editData[0]?.suite_type,
        no_of_chair: editData[0]?.no_of_chair,
        color_code: editData[0]?.color_code,
        suite_id: editData[0]?.suite_id,
        company_id: editData[0]?.company_id,
        nurse_id: editData[0]?.nurse_id,
      });

      if (editData[0]?.is_home_health) {
        setSelectedOption(editData[0]?.is_home_health);
      }
    }

    if (!visible) {
      resetFormData();
    }

    setInvalidFields({
      statusList: false,
      city: false,
      zip_code: false,
      state: false,
      email: false,
      ...((generalTaskData.Type === "Nurse" ||
        generalTaskData.Type === "Agency") && { agency_name: false }),
      ...(generalTaskData.Type === "Infusion Suite" && {
        color_code: false,
        name_of_suite: false,
      }),
    });
  }, [visible]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setGeneralTaskData((prev) => ({ ...prev, [id]: value }));

    if (id === "email") {
      if (value) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        setInvalidFields((prevInvalidFields) => ({
          ...prevInvalidFields,
          [id]: isValidEmail ? false : "",
        }));
      } else {
        setInvalidFields((prevInvalidFields) => ({
          ...prevInvalidFields,
          [id]: false,
        }));
      }
    } else {
      setInvalidFields((prevInvalidFields) => ({
        ...prevInvalidFields,
        [id]: value ? false : prevInvalidFields[id],
      }));
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setGeneralTaskData((prev) => ({
      ...prev,
      [id]: value,
    }));
    if (value) {
      setInvalidFields((prevInvalidFields) => ({
        ...prevInvalidFields,
        [id]: false,
      }));
    }
  };

  const handleDropdownChange = (e: DropdownChangeEvent) => {
    const {
      value,
      target: { id },
    } = e;

    setGeneralTaskData((prev) => ({
      ...prev,
      [id]: value,
    }));
    if (value) {
      setInvalidFields((prevInvalidFields) => ({
        ...prevInvalidFields,
        [id]: false,
      }));
    }
  };

  const OnSubmit = async () => {
    const updatedInvalidFields = {
      statusList: !generalTaskData.statusList,
      city: !generalTaskData.city,
      zip_code: !generalTaskData.zip_code,
      state: !generalTaskData.state,
      email:
        generalTaskData.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(generalTaskData.email)
          ? " "
          : false,
      ...((generalTaskData.Type === "Nurse" ||
        generalTaskData.Type === "Agency") && {
        agency_name: !generalTaskData.agency_name.trim(),
      }),
      ...(generalTaskData.Type === "Infusion Suite" && {
        color_code: !generalTaskData?.color_code,
        name_of_suite: !generalTaskData?.name_of_suite.trim(),
      }),
    };

    const hasInvalidFields = Object.values(updatedInvalidFields).some(
      (isInvalid) => isInvalid
    );

    setInvalidFields(updatedInvalidFields);

    if (hasInvalidFields) {
      return;
    }

    if (selected === "Nurse") {
      const data = {
        nurse_id: generalTaskData.nurse_id,
        agency_name: generalTaskData.agency_name,
        first_name: generalTaskData.first_name,
        last_name: generalTaskData.last_name,
        address: generalTaskData.address,
        zip_code: generalTaskData.zip_code,
        city: generalTaskData.city,
        state: generalTaskData.state,
        county: generalTaskData.county,
        phone: String(generalTaskData.phone),
        email: generalTaskData.email,
        fax: generalTaskData.fax,
        notes: generalTaskData.Comments,
        status: generalTaskData.statusList,
        nurse_type: generalTaskData.Type,
        created_by: generalTaskData.userEmail,
      };

      await dispatch(
        AddUpdateNurse({
          updateData: data,
          callback: (res: any) => {
            if (res.data.success) {
              displayToast({
                msg: res?.data?.data || "Nurse have been added successfully.",

                type: "success",
              });
              dispatch(getNurseList());
            } else {
              displayToast({
                msg: res?.data?.data || "Something went wrong",
                type: "error",
              });
            }
          },
        })
      );
    }

    if (selected === "Agency") {
      const data = {
        company_name: generalTaskData.agency_name,
        initial_Visit_Rate_2hr_IV:  String(generalTaskData.initial_Visit_Rate_2hr_IV),
        visit_Rate_2hr_V:  String(generalTaskData.visit_Rate_2hr_V),
        hourly_Rate_H:  String(generalTaskData.hourly_Rate_H),
        weekend_Holiday_Rate_WH:String(generalTaskData.weekend_Holiday_Rate_WH),
        travel_Rate_per_hour_Tr:String(generalTaskData.travel_Rate_per_hour_Tr),
        notes: generalTaskData.Comments,
        status: generalTaskData.statusList,
        created_by: generalTaskData.userEmail,
        address: generalTaskData.address,
        zip_code: generalTaskData.zip_code,
        city: generalTaskData.city,
        state: generalTaskData.state,
        first_name: generalTaskData.first_name,
        last_name: generalTaskData.last_name,
        county: generalTaskData.county,
        phone: String(generalTaskData.phone),
        email: generalTaskData.email,
        fax: generalTaskData.fax,
        company_id: generalTaskData.company_id,
        is_home_health: selectedOption,
      };

      await dispatch(
        AddUpdateAgency({
          updateData: data,
          callback: (res: any) => {
            if (res.data.success) {
              displayToast({
                msg: res?.data?.data || "Agency have been added successfully.",
                type: "success",
              });
              dispatch(getAgencyList());
            } else {
              displayToast({
                msg: res?.data?.message || "Something went wrong",
                type: "error",
              });
            }
          },
        })
      );
    }

    if (selected === "Infusion Suite") {
      const data = {
        suite_id: generalTaskData.suite_id,
        company_id: 0,
        timezone_id: 0,
        name_of_suite: generalTaskData.name_of_suite,
        working_hours_from: "",
        working_hours_to: "",
        agency_name: generalTaskData.agency_name,
        created_by: generalTaskData.userEmail,
        no_of_chair: Number(generalTaskData.no_of_chair),
        comment: generalTaskData.Comments,
        address: generalTaskData.address,
        zip: generalTaskData.zip_code,
        city: generalTaskData.city,
        state: generalTaskData.state,
        county: generalTaskData.county,
        phone: String(generalTaskData.phone),
        email: generalTaskData.email,
        fax: generalTaskData.fax,
        status: generalTaskData.statusList,
        suite_type: generalTaskData.suite_type,
        color_code: generalTaskData.color_code,
      };

      await dispatch(
        AddUpdateSuite({
          updateData: data,
          callback: (res: any) => {
            if (res.data.success) {
              displayToast({
                msg:
                  res?.data?.message ||
                  "Infusion Suite have been added successfully.",
                type: "success",
              });

              dispatch(getinfusionList());
            } else {
              displayToast({
                msg: res?.data?.message || "Something went wrong",
                type: "error",
              });
            }
          },
        })
      );
    }
    resetFormData();
    setVisible(false);
  };

  const resetFormData = () => {
    setGeneralTaskData({
      ...generalTaskData,
      statusList: "",
      agency_name: "",
      first_name: "",
      address: "",
      city: "",
      zip_code: "",
      phone: "",
      fax: "",
      Comments: "",
      Type: "",
      last_name: "",
      state: "",
      county: "",
      email: "",
      initial_Visit_Rate_2hr_IV: "",
      visit_Rate_2hr_V: "",
      hourly_Rate_H: "",
      weekend_Holiday_Rate_WH: "",
      travel_Rate_per_hour_Tr: "",
      name_of_suite: "",
      suite_type: "",
      no_of_chair: undefined,
      color_code: "",
      suite_id: 0,
      company_id: 0,
      nurse_id: 0,
    });
    setSelectedOption(false);
  };
  const TypeOtions = [
    {
      id: 1,
      name: "Nurse",
    },
    {
      id: 2,
      name: "Agency",
    },
    {
      id: 3,
      name: "Infusion Suite",
    },
  ];
  const modalOtions = [
    {
      id: 1,
      name: "InfuCare Rx Managed",
    },
    {
      id: 2,
      name: "Dispense & Agency Nursing",
    },
    {
      id: 3,
      name: "Dispense Only",
    },
  ];

  const Footer = (
    <div className="col-offset-4  md:col-offset-6 lg:col-offset-8 mr-2">
      <div className="flex gap-4 h-10">
        <Button
          label="Cancel"
          className="w-8rem  border-solid border-blue-800 text-black"
          onClick={() => {
            resetFormData();
            setVisible(false);
          }}
        />

        <Button
          label={action === "add" ? "Save" : "Update"}
          className="w-8rem"
          onClick={() => OnSubmit()}
        />
      </div>
    </div>
  );

  return (
    <div className="">
      <Dialog
        draggable={false}
        header={action === "add" ? `Add ${selected}` : `Edit ${selected}`}
        visible={visible}
        style={{
          minHeight: "95vh",
        }}
        position="right"
        className="NurseAgencyDialogBox mt-8"
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
        footer={Footer}
      >
        <p className="flex justify-content-end text-sm">
          <span className="Agency-text-danger mr-1">*</span>
          Denoted fields are required
        </p>
        <div className="customGrid">
          <div className="custom-col">
            {selected === "Infusion Suite" && (
              <>
                <div className="text-area pt-1">
                  <Input
                    label="Infusion Suite Name"
                    type="text"
                    id="name_of_suite"
                    value={generalTaskData.name_of_suite}
                    labelclassName="col-4 label-lg"
                    handleChange={handleInputChange}
                    inputclassName="col-7 Input-field"
                    required={true}
                    invalid={invalidFields.name_of_suite}
                  />
                </div>
                <div className="">
                  <InputDropdown
                    options={modalOtions.map((item) => {
                      return {
                        label: item.name,
                        value: item.name,
                      };
                    })}
                    label="Model"
                    optionLabel="label"
                    type="text"
                    id="suite_type"
                    value={generalTaskData.suite_type}
                    handleChange={handleDropdownChange}
                    dropdownclassName="col-7 Input-field task-list-dropdown"
                    labelclassName="col-4 label-lg"
                    filter
                  />
                </div>
              </>
            )}
            <div className="">
              <InputDropdown
                options={StatusOptionList.map((item) => {
                  return {
                    label: item.status_description,
                    value: item.status_description,
                  };
                })}
                label="Status"
                type="text"
                id="statusList"
                optionLabel="label"
                placeholder="Select status"
                value={generalTaskData.statusList}
                dropdownclassName="col-7 Input-field task-list-dropdown"
                handleChange={handleDropdownChange}
                labelclassName="col-4 label-lg"
                requiredRight={true}
                invalid={invalidFields.statusList}
              />
            </div>
            {(selected === "Nurse" || selected === "Agency") && (
              <>
                <div className="">
                  <Input
                    label="Agency Name"
                    type="text"
                    id="agency_name"
                    value={generalTaskData.agency_name}
                    labelclassName="col-4 label-lg"
                    handleChange={handleInputChange}
                    inputclassName="col-7 "
                    required={true}
                    invalid={invalidFields.agency_name}
                  />
                </div>
                <div className="">
                  <Input
                    label="First Name"
                    type="text"
                    value={generalTaskData.first_name}
                    id="first_name"
                    labelclassName="col-4 label-lg"
                    handleChange={handleInputChange}
                    inputclassName="col-7 Input-field"
                    required={selected === "Nurse"? true : false}
                    invalid={selected === "Nurse"? invalidFields.agency_name : ""}
                  />
                </div>
              </>
            )}

            <div className="">
              <Input
                label="Address"
                type="text"
                value={generalTaskData.address}
                id="address"
                labelclassName="col-4 label-lg"
                handleChange={handleInputChange}
                inputclassName="col-7 Input-field"
              />
            </div>
            <div className="">
              <InputDropdown
                options={CityOptionList.map((item) => {
                  return {
                    label: item.city_name,
                    value: item.city_name,
                  };
                })}
                label="City"
                optionLabel="label"
                type="text"
                id="city"
                value={generalTaskData.city}
                handleChange={handleDropdownChange}
                dropdownclassName="col-7 Input-field task-list-dropdown"
                labelclassName="col-4 label-lg"
                invalid={invalidFields.city}
                virtualScrollerOptions={{ itemSize: 30 }}
                filter
                requiredRight={true}
              />
            </div>
            <div className="">
              <InputDropdown
                options={ZipcodeOptionList.map((item) => ({
                  label: item.zipcode,
                  value: item.zipcode,
                }))}
                label="Zip Code"
                optionLabel="label"
                type="number"
                id="zip_code"
                value={generalTaskData.zip_code}
                handleChange={(e) => {
                  handleDropdownChange(e);
                }}
                dropdownclassName="col-7 Input-field task-list-dropdown"
                labelclassName="col-4 label-lg"
                filter
                requiredRight={true}
                virtualScrollerOptions={{ itemSize: 30 }}
                invalid={invalidFields.zip_code}
              />
            </div>
            <div>
              <InputNum
                label="Phone"
                id="phone"
                value={
                  generalTaskData.phone ? Number(generalTaskData.phone) : null
                } 
                labelclassName="col-4 label-lg"
                onChange={handleInputChange}
                inputClassName="col-7 Input-field"
                min={0}
                maxLength={10}
                useGrouping={false}
              />
            </div>
            <div>
              <Input
                label="Fax Number"
                type="tel"
                value={generalTaskData.fax}
                id="fax"
                labelclassName="col-4 label-lg"
                handleChange={handleInputChange}
                inputclassName="col-7 Input-field"

                // invalid={invalidFields.First Name}
              />
            </div>
            <div>
              <InputTextArea
                label="Comments"
                value={generalTaskData.Comments}
                rows={1}
                id="Comments"
                handleChange={handleTextAreaChange}
                labelclassName="col-4 label-lg"
                inputclassName="col-7"
                placeholder=""
              />
            </div>

            {selected === "Agency" && (
              <>
                <div className="grid col-12">
                  <div className="col-5">
                    <label htmlFor="Home Health Agency">
                      Home Health Agency
                    </label>
                  </div>
                  <div className=" grid col-7">
                    <div className="col-6">
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="ingredient2"
                          name="HomeHealthAgency"
                          value="false"
                          onChange={() => handleRedioChange(false)}
                          checked={selectedOption === false}
                        />
                        <label htmlFor="ingredient2" className="ml-2">
                          No
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <RadioButton
                        inputId="ingredient1"
                        name="HomeHealthAgency"
                        value="true"
                        onChange={() => handleRedioChange(true)}
                        checked={selectedOption === true}
                      />
                      <label htmlFor="ingredient1" className="ml-2">
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="custom-col">
            <div className="input-area ">
              <InputDropdown
                options={TypeOtions.map((item) => {
                  return {
                    label: item.name,
                    value: item.name,
                  };
                })}
                label="Type"
                optionLabel="label"
                type="text"
                id="Type"
                value={generalTaskData.Type}
                handleChange={handleDropdownChange}
                dropdownclassName="col-7 Input-field task-list-dropdown nurse-agency-type-dropdown"
                labelclassName="col-4 label-lg"
                filter
                disabled={true}
              />
            </div>
            {(selected === "Nurse" || selected === "Agency") && (
              <>
                <div className="text-area">
                  <Input
                    label="Last Name"
                    type="text"
                    value={generalTaskData.last_name}
                    id="last_name"
                    labelclassName="col-4 label-lg"
                    handleChange={handleInputChange}
                    inputclassName="col-7 Input-field"

                    // invalid={invalidFields.LasName}
                    //   required={true}
                  />
                </div>
              </>
            )}
            <div className="text-area pt-1">
              <InputDropdown
                options={StateOptionList.map((item) => {
                  return {
                    label: item.state_code,
                    value: item.state_code,
                  };
                })}
                label="State"
                optionLabel="label"
                type="text"
                id="state"
                value={generalTaskData.state}
                handleChange={handleDropdownChange}
                dropdownclassName="col-7 Input-field task-list-dropdown"
                labelclassName="col-4 label-lg"
                invalid={invalidFields.state}
                filter
                requiredRight={true}
              />
            </div>
            <div className="text-area pt-1">
              <InputDropdown
                options={CountyOptionList.map((item) => {
                  return {
                    label: item.county_name,
                    value: item.county_name,
                  };
                })}
                label="County"
                optionLabel="label"
                type="text"
                id="county"
                value={generalTaskData.county}
                handleChange={handleDropdownChange}
                dropdownclassName="col-7 Input-field task-list-dropdown"
                labelclassName="col-4 label-lg"
                filter
              />
            </div>
            <div className="text-area pt-1">
              <Input
                label="Email"
                type="email"
                id="email"
                value={generalTaskData.email}
                labelclassName="col-4 label-lg"
                handleChange={handleInputChange}
                inputclassName="col-7 Input-field"
                invalid={invalidFields.email}
              />
            </div>

            {selected === "Agency" && (
              <>
                <div className="text-area pt-1">
                  <InputNum
                    label="Initial Visit Rate (2hr)"
                    id="initial_Visit_Rate_2hr_IV"
                    value={Number(generalTaskData.initial_Visit_Rate_2hr_IV ? Number(generalTaskData.initial_Visit_Rate_2hr_IV) : null)} 
                    labelclassName="col-4 label-lg"
                    onChange={handleInputChange}
                    inputClassName="col-7 Input-field"
                    min={0}
                    useGrouping={false}
                  />
                </div>
                <div className="text-area pt-1">
                    <InputNum
                  label="Visit Rate"
                    id="visit_Rate_2hr_V"
                    value={Number(generalTaskData.visit_Rate_2hr_V ? Number(generalTaskData.visit_Rate_2hr_V) : null)} 
                    labelclassName="col-4 label-lg"
                    onChange={handleInputChange}
                    inputClassName="col-7 Input-field"
                    min={0}
                    useGrouping={false}
                  />
                </div>
                <div className="text-area pt-1">
                   <InputNum
                   label="Hourly Rate"
                   id="hourly_Rate_H"
                     value={Number(generalTaskData.hourly_Rate_H ? Number(generalTaskData.hourly_Rate_H) : null)} 
                    labelclassName="col-4 label-lg"
                    onChange={handleInputChange}
                    inputClassName="col-7 Input-field"
                    min={0}
                    useGrouping={false}
                  />
                </div>
                <div className="text-area pt-1">
                   <InputNum
                    label="Weekend Holiday Rate"
                   id="weekend_Holiday_Rate_WH"
                    value={Number(generalTaskData.weekend_Holiday_Rate_WH ? Number(generalTaskData.weekend_Holiday_Rate_WH) : null)} 
                    labelclassName="col-4 label-lg"
                    onChange={handleInputChange}
                    inputClassName="col-7 Input-field"
                    min={0}
                    useGrouping={false}
                  />
                </div>
                <div className="text-area pt-1">
                <InputNum
                    label="Travel Rate (per hour) "
                  id="travel_Rate_per_hour_Tr"
                    value={Number(generalTaskData.travel_Rate_per_hour_Tr ? Number(generalTaskData.travel_Rate_per_hour_Tr) : null)} 
                    labelclassName="col-4 label-lg"
                    onChange={handleInputChange}
                    inputClassName="col-7 Input-field"
                    min={0}
                    useGrouping={false}
                  />
                </div>
              </>
            )}

            {selected === "Infusion Suite" && (
              <>
                <div className="text-area pt-1">
                  <InputNum
                    label="No of Chairs "
                    id="no_of_chair"
                    value={generalTaskData.no_of_chair}
                    labelclassName="col-4 label-lg"
                    onChange={handleInputChange}
                    inputClassName="col-7 Input-field"
                    min={0}
                    disabled={action === "edit"}
                    useGrouping={false}  
                  />
                </div>
                <div className="text-area pt-1">
                  <InputDropdown
                    options={colors.map((item) => {
                      return {
                        label: item.colorname,
                        value: item.colorcode,
                      };
                    })}
                    label="Color"
                    optionLabel="label"
                    type="text"
                    id="color_code"
                    value={generalTaskData.color_code}
                    handleChange={handleDropdownChange}
                    dropdownclassName="col-7 Input-field task-list-dropdown"
                    labelclassName="col-4 label-lg"
                    invalid={invalidFields.color_code}
                    filter
                    requiredRight={true}
                    itemTemplate={(option: any) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "200px",
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {option?.label}
                        </span>
                        <span
                          style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: option.value,
                            marginLeft: "auto",
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default NurseAgencyDialogBox;
