// ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../../utils/api/index";

const baseUrl = process.env.REACT_APP_BASEURL;

interface Status {
  status_id: number;
  status_description: string;
}

// GET Method For Prescriber
export const getPrescriber = createAsyncThunk(
  "Census/getPrescriber",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Census/getPrescriber`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Sales Representative
export const getSalesPerson = createAsyncThunk(
  "Census/getSalesPerson",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Census/getSalesPerson`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Intake Report
export const getIntakeReport = createAsyncThunk(
  "Census/getIntakeReport",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Census/getIntakeReport`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Case Manager
export const getCaseManager = createAsyncThunk(
  "Census/getCaseManager",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Census/getCaseManager`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Patient Rx Invoice
export const getPatientRxInvoice = createAsyncThunk(
  "Census/getPatientRxInvoice",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Census/getPatientRxInvoice`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// GET Method For Status
export const getStatusOptionList = createAsyncThunk(
  "tasklist/getStatusOptionList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ToDo/GetStatusDescription`;

      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error;
    }
  }
);

// GET Method For Zip Code
export const getAddressMappingByCity = createAsyncThunk(
  "PhysicianPortal/getAddressMappingByCity",
  async ({ state_id, city_id }: { state_id: any; city_id: any }) => {
    try {
      const endpoint = `${baseUrl}/api/PhysicianPortal/getAddressMappingByCity?state_id=${state_id}&city_id=${city_id}`;

      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error;
    }
  }
);

// GET Method For State and City from Zip Code
export const getAddressMappingByZipcode = createAsyncThunk(
  "Census/getAddressMappingByZipcode",
  async ({ zipcode  }: { zipcode : any }) => {
    try {
      const endpoint = `${baseUrl}/api/Census/GetAddressMappingByZipCode/${zipcode}`;

      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error;
    }
  }
);

// POST Method For Search Rx/Invoice
export const getPatinetRxInvoice = createAsyncThunk(
  "Census/getPatinetRxInvoice",
  async ({ searchData }: { searchData: any }) => {
    try {
      const endpoint = `${baseUrl}/api/Census/getPatinetRxInvoice`;

      const response = await axios.post(endpoint, searchData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error;
    }
  }
);

// POST Method For Create New Patient
export const addNewPatientFromReferral = createAsyncThunk(
  "PhysicianPortal/AddNewPatientFromReferral",
  async ({
    newPatientData,
    callback,
  }: {
    newPatientData: any;
    callback: any;
  }) => {
    try {
      const endpoint = `${baseUrl}/api/PhysicianPortal/AddNewPatientFromReferral`;

      const response = await axios.post(endpoint, newPatientData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data.success) {
        callback(response);
      }

      const data = await response.data;
      return data;
    } catch (error: any) {
      console.error("Error fetching line item parent", error);
      callback(error.response);
    }
  }
);

// POST Method For Health Measurement Data
export const getHealthMeasurement = createAsyncThunk(
  "Census/getHealthMeasurement",
  async ({ healthData, callback }: { healthData: any; callback: any }) => {
    try {
      const endpoint = `${baseUrl}/api/Census/getHealthMeasurement`;

      const response = await axios.post(endpoint, healthData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data.success) {
        callback(response);
      }

      const data = await response.data.data;
      return data;
    } catch (error) {
      console.error("Error fetching line item parent", error);
      throw error;
    }
  }
);

// POST Method For get filter census data
export const censusSearchPatient = createAsyncThunk(
  "Census/CensusSearchPatient",
  async ({ updateData }: { updateData: any }, apiThunk) => {
    try {
      const endpoint = `${baseUrl}/api/Census/CensusSearchPatient`;
      const response = await axios.post(endpoint, updateData, {
        headers: {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
        },
      });

      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      return response.data.data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const GetCustomFilterList = createAsyncThunk(
  "arManager/GetCustomFilterList",
  async ({ updateData }: { updateData: any }, apiThunk) => {
    try {
      const endpoint = `${baseUrl}/api/ArManager/GetCustomFilterList`;
      const response = await axios.post(endpoint, updateData, {
        headers: {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
        },
      });

      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      return response.data;

      // return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const AddNewCustomFilter = createAsyncThunk(
  "arManager/AddNewCustomFilter",
  async (updateData: any, apiThunk) => {

    try {
      const endpoint = `${baseUrl}/api/ArManager/AddNewCustomFilter`;
      const response = await axios.post(endpoint, updateData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

// ArManager Slice
export const censusSlice = createSlice({
  name: "census",
  initialState: {
    statusList: [],
    prescriberList: [],
    salesPersonList: [],
    intakeReport: [],
    caseManagerList: [],
    censusData: [],
    customFilterList: [],
    patientRxInvoice: [],
    healthMeasurement: [],
    zipcodeByCity: [],
    stateCityByZipcode: [],
    newPatient: [],
    errorMessage: "",
    loading: false,
    censusLoading: false,
    filterLoading: false,
    filterloading: false,
    zipLoading: false,
    stateByZipLoading: false,
    invoiceLoading: false,
    healthMeasurementLoading: false,
    newPatientLoading: false,
  },
  reducers: {
    resetFilter: (state) => {
      state.censusData = [];
    },
    resetSearchRxInvoice: (state) => {
      state.patientRxInvoice = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrescriber.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getPrescriber.fulfilled, (state, action) => {
        state.prescriberList = action.payload?.data;
        state.filterLoading = false;
      })
      .addCase(getPrescriber.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      })
      .addCase(getSalesPerson.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getSalesPerson.fulfilled, (state, action) => {
        state.salesPersonList = action.payload?.data;
        state.filterLoading = false;
      })
      .addCase(getSalesPerson.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      })
      .addCase(getIntakeReport.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getIntakeReport.fulfilled, (state, action) => {
        state.intakeReport = action.payload?.data;
        state.filterLoading = false;
      })
      .addCase(getIntakeReport.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      })
      .addCase(getCaseManager.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getCaseManager.fulfilled, (state, action) => {
        state.caseManagerList = action.payload?.data;
        state.filterLoading = false;
      })
      .addCase(getCaseManager.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      })
      .addCase(getAddressMappingByCity.pending, (state, action) => {
        state.zipLoading = true;
      })
      .addCase(getAddressMappingByCity.fulfilled, (state, action) => {
        state.zipcodeByCity = action.payload;
        state.zipLoading = false;
      })
      .addCase(getAddressMappingByCity.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.zipLoading = false;
      })
      .addCase(getAddressMappingByZipcode.pending, (state, action) => {
        state.stateByZipLoading = true;
      })
      .addCase(getAddressMappingByZipcode.fulfilled, (state, action) => {
        state.stateCityByZipcode = action.payload;
        state.stateByZipLoading = false;
      })
      .addCase(getAddressMappingByZipcode.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.stateByZipLoading = false;
      })
      .addCase(getPatinetRxInvoice.pending, (state, action) => {
        state.invoiceLoading = true;
      })
      .addCase(getPatinetRxInvoice.fulfilled, (state, action) => {
        state.patientRxInvoice = action.payload?.data;
        state.invoiceLoading = false;
      })
      .addCase(getPatinetRxInvoice.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.invoiceLoading = false;
      })
      .addCase(addNewPatientFromReferral.pending, (state, action) => {
        state.newPatientLoading = true;
      })
      .addCase(addNewPatientFromReferral.fulfilled, (state, action) => {
        state.newPatient = action.payload?.data;
        state.newPatientLoading = false;
      })
      .addCase(addNewPatientFromReferral.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.newPatientLoading = false;
      })
      .addCase(getHealthMeasurement.pending, (state, action) => {
        state.healthMeasurementLoading = true;
      })
      .addCase(getHealthMeasurement.fulfilled, (state, action) => {
        state.healthMeasurement = action.payload;
        state.healthMeasurementLoading = false;
      })
      .addCase(getHealthMeasurement.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.healthMeasurementLoading = false;
      })
      .addCase(censusSearchPatient.pending, (state, action) => {
        state.censusLoading = true;
      })
      .addCase(censusSearchPatient.fulfilled, (state, action) => {
        const filterData = action.payload?.map((val: any) => ({
          ...val,
          referral_date: new Date(val.referral_date).toISOString(),
          date_of_birth: new Date(val.date_of_birth).toISOString(),
        }));
        state.censusData = filterData;
        state.censusLoading = false;
      })
      .addCase(censusSearchPatient.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.censusLoading = false;
      })
      .addCase(GetCustomFilterList.pending, (state, action) => {
        state.filterloading = true;
      })
      .addCase(GetCustomFilterList.fulfilled, (state, action) => {
        state.customFilterList = action.payload?.data;
        state.filterloading = false;
      })
      .addCase(GetCustomFilterList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterloading = false;
      })
      .addCase(getStatusOptionList.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getStatusOptionList.fulfilled, (state, action) => {
        const filteredStatuses = action.payload.filter((status: Status) =>
          [
            "Active" /*2*/,
            "Cancelled" /*4*/,
            "Inactive" /*9*/,
            "On Hold" /*10*/,
            "Pending" /*11*/,
          ].includes(status.status_description)
        );

        state.statusList = filteredStatuses;
        state.filterLoading = false;
      })
      .addCase(getStatusOptionList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterLoading = false;
      });
  },
});

export const { resetFilter, resetSearchRxInvoice } = censusSlice.actions;
export default censusSlice.reducer;
