// import React from 'react';
// import moment from 'moment';

// // Generate time slots from 8:30 AM to 5:30 PM (30-minute intervals)
// const generateTimeSlots = () => {
//   const times = [];
//   let hour = 8;
//   let minute = 30;
//   for (let i = 0; i < 18; i++) {
//     times.push(`${hour}:${minute === 0 ? '00' : minute} ${hour >= 12 ? 'PM' : 'AM'}`);
//     minute = minute === 30 ? 0 : 30;
//     if (minute === 0) hour++;
//   }
//   return times;
// };

// const timeSlots = generateTimeSlots();

// // Lighten color function
// const lightenColor = (rgbColor) => {
//   const regex = /rgba?\((\d+),\s*(\d+),\s*(\d+)/;
//   const result = regex.exec(rgbColor);
//   if (!result) return rgbColor;

//   let [r, g, b] = result.slice(1).map(Number);
//   r = Math.min(255, r + 20);
//   g = Math.min(255, g + 20);
//   b = Math.min(255, b + 20);

//   return `rgb(${r}, ${g}, ${b})`;
// };

// const Day = ({ date, events }) => {
//   const formattedDate = date.toISOString().split('T')[0];
//   const eventsForTheDay = events.filter(
//     (event) => new Date(event.start).toDateString() === new Date(date).toDateString()
//   );

//   const convertTimeToMinutes = (timeString) => {
//     const time = moment(timeString, "hh:mm A");
//     return time.isValid() ? time.minutes() + time.hours() * 60 : 0;
//   };

//   const isBooked = (event, timeSlot) => {
//     const eventStart = convertTimeToMinutes(event.in_time);
//     const eventEnd = convertTimeToMinutes(event.out_time);
//     const slotTime = convertTimeToMinutes(timeSlot);
//     return slotTime >= eventStart && slotTime < eventEnd;
//   };

//   return (
//     <div style={{ padding: '20px' }}>
//       <h2>Daily Schedule: {date.toDateString()}</h2>
//       <div style={{ overflowX: 'auto' }}>
//         <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//           <thead>
//             <tr>
//               <th>Chair</th>
//               {timeSlots.map((time, index) => (
//                 <th key={index}>{time}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {eventsForTheDay.map((event) => {
//               return (
//                 <tr key={event.nurse}>
//                   <td style={{ fontWeight: 'bold' }}>{event.chair}</td>
//                   {timeSlots.map((time, index) => {
//                     const booked = isBooked(event, time);
//                     const color = event.color_code || "#e9ecef";

//                     // Only render one td with colSpan when the event starts
//                     if (booked && index === timeSlots.findIndex(t => isBooked(event, t))) {
//                       const colspan = timeSlots.findIndex(t => convertTimeToMinutes(t) === convertTimeToMinutes(event.out_time)) - index + 1;
//                       return (
//                         <td
//                           key={`${event.nurse}-${time}`}
//                           colSpan={colspan}
//                           style={{
//                             backgroundColor: lightenColor(color),
//                             border: "1px solid #ccc",
//                             textAlign: "center",
//                           }}
//                         >
//                           {event.patient} <br /> {time}
//                         </td>
//                       );
//                     }
//                     if (booked) return null;

//                     return (
//                       <td
//                         key={`${event.nurse}-${time}`}
//                         style={{ border: "1px solid #ccc" }}
//                       ></td>
//                     );
//                   })}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// Day.title = (date) => `Day View: ${date.toDateString()}`;
// Day.navigate = (date, action) => {
//   switch (action) {
//     case 'PREV': return new Date(date.setDate(date.getDate() - 1));
//     case 'NEXT': return new Date(date.setDate(date.getDate() + 1));
//     default: return date;
//   }
// };
// Day.range = (date) => [date];

// export default Day;

// import React, { useMemo } from 'react';
// import moment from 'moment';

// // Generate time slots from 8:30 AM to 5:30 PM (30-minute intervals)
// const generateTimeSlots = () => {
//   const times = [];
//   let hour = 8;
//   let minute = 30;
//   for (let i = 0; i < 18; i++) {
//     times.push(`${hour}:${minute === 0 ? '00' : minute} ${hour >= 12 ? 'PM' : 'AM'}`);
//     minute = minute === 30 ? 0 : 30;
//     if (minute === 0) hour++;
//   }
//   return times;
// };

// const timeSlots = generateTimeSlots();

// // Lighten color function with hex and RGB support
// const lightenColor = (color) => {
//   let r, g, b, a = 1;
//   const rgbaMatch = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/.exec(color);
//   if (rgbaMatch) {
//     r = parseInt(rgbaMatch[1], 10);
//     g = parseInt(rgbaMatch[2], 10);
//     b = parseInt(rgbaMatch[3], 10);
//     if (rgbaMatch[4]) a = parseFloat(rgbaMatch[4]);
//   } else {
//     return color;
//   }

//   // Increase brightness by 40 units
//   r = Math.min(255, r + 40);
//   g = Math.min(255, g + 40);
//   b = Math.min(255, b + 40);

//   return `rgba(${r}, ${g}, ${b}, ${a})`;
// };

// const Day = ({ date, events }) => {
//   const formattedDate = date.toISOString().split('T')[0];
//   const eventsForTheDay = useMemo(
//     () => events.filter((event) => new Date(event.start).toDateString() === new Date(date).toDateString()),
//     [date, events]
//   );

//   const convertTimeToMinutes = (timeString) => {
//     const time = moment(timeString, 'hh:mm A');
//     return time.isValid() ? time.minutes() + time.hours() * 60 : 0;
//   };

//   const isBooked = (event, timeSlot) => {
// console.log("event",event)
//     const eventStart = convertTimeToMinutes(event.in_time);
//     console.log("event start",eventStart)
//     const eventEnd = convertTimeToMinutes(event.out_time);
//     console.log("event end",eventEnd)
//     const slotTime = convertTimeToMinutes(timeSlot);
//     console.log("slot",slotTime)
//     return slotTime >= eventStart && slotTime < eventEnd;
//   };

//   // Precompute the background colors for events
//   const eventsWithBgColor = useMemo(() => {
//     return eventsForTheDay.map((event) => {
//       const color = event.color_code || "#e9ecef";
//       event.lightenedColor = lightenColor(color);
//       return event;
//     });
//   }, [eventsForTheDay]);

//   return (
//     <div style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
//       <h4 style={{ color: '#343a40' }}>{date.toDateString()}</h4>
//       <div style={{ overflowX: 'auto' }}>
//         <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: '#ffffff' }}>
//           <thead>
//             <tr>
//               <th style={{ backgroundColor: '#f1f3f5' }}>Chair</th>
//               {timeSlots.map((time, index) => (
//                 <th key={index} style={{ backgroundColor: '#f1f3f5' }}>{time}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {eventsWithBgColor.map((event) => (
//               <tr key={event.nurse}>
//                 <td style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>{event.chair}</td>
//                 {timeSlots.map((time, index) => {
//                   const booked = isBooked(event, time);
//                   const bgColor = booked ? event.lightenedColor : '#f8f9fa'; // Lighter background for free slots

//                   if (booked && index === timeSlots.findIndex((t) => isBooked(event, t))) {
//                     const colspan =
//                       timeSlots.findIndex(
//                         (t) => convertTimeToMinutes(t) === convertTimeToMinutes(event.out_time)
//                       ) - index + 1;

//                     return (
//                       <td
//                         key={`${event.nurse}-${time}`}
//                         colSpan={colspan}
//                         style={{
//                           border: '1px solid #ccc',
//                           textAlign: 'center',
//                           color: '#495057', // Text color adjusted for readability
//                           padding: "5px"
//                         }}
//                       >
//                         <div
//                           style={{
//                             minWidth: "225px",
//                             boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
//                             borderRadius: 4,
//                             backgroundColor: bgColor,
//                             wordWrap: 'break-word',
//                             wordBreak: 'break-word',
//                             whiteSpace: 'normal',
//                             padding: '3px 4px',
//                           }}
//                         >
//                           {event.patient}  {time}
//                         </div>
//                       </td>
//                     );
//                   }

//                   if (booked) return null; // Skip non-booked slots

//                   return (
//                     <td
//                       key={`${event.nurse}-${time}`}
//                       style={{ backgroundColor: '#f8f9fa', border: '1px solid #ccc' }}
//                     ></td>
//                   );
//                 })}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// Day.title = (date) => `Day View: ${date.toDateString()}`;
// Day.navigate = (date, action) => {
//   switch (action) {
//     case 'PREV':
//       return new Date(date.setDate(date.getDate() - 1));
//     case 'NEXT':
//       return new Date(date.setDate(date.getDate() + 1));
//     default:
//       return date;
//   }
// };
// Day.range = (date) => [date];

// export default Day;



// import React, { useMemo } from 'react';
// import moment from 'moment';

// // Generate time slots from 8:00 AM to 5:30 PM (30-minute intervals)
// const generateTimeSlots = () => {
//   const times = [];
//   let hour = 8;
//   let minute = 0; // Start from 8:00 AM
//   for (let i = 0; i < 19; i++) { // Including the 5:30 PM slot
//     times.push(`${hour}:${minute === 0 ? '00' : minute} ${hour >= 12 ? 'PM' : 'AM'}`);
//     minute = minute === 0 ? 30 : 0; // Alternating between 0 and 30 minutes
//     if (minute === 0) hour++; // Move to next hour after 30 minutes
//   }
//   return times;
// };

// const timeSlots = generateTimeSlots();

// // Lighten color function with hex and RGB support
// const lightenColor = (color) => {
//   let r, g, b, a = 1;
//   const rgbaMatch = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/.exec(color);
//   if (rgbaMatch) {
//     r = parseInt(rgbaMatch[1], 10);
//     g = parseInt(rgbaMatch[2], 10);
//     b = parseInt(rgbaMatch[3], 10);
//     if (rgbaMatch[4]) a = parseFloat(rgbaMatch[4]);
//   } else {
//     return color;
//   }

//   // Increase brightness by 40 units
//   r = Math.min(255, r + 40);
//   g = Math.min(255, g + 40);
//   b = Math.min(255, b + 40);

//   return `rgba(${r}, ${g}, ${b}, ${a})`;
// };

// const Day = ({ date, events }) => {
//   const formattedDate = date.toISOString().split('T')[0];
//   const eventsForTheDay = useMemo(
//     () => events.filter((event) => new Date(event.start).toDateString() === new Date(date).toDateString()),
//     [date, events]
//   );

//   const convertTimeToMinutes = (timeString) => {
//     const time = moment(timeString, 'hh:mm A');
//     return time.isValid() ? time.minutes() + time.hours() * 60 : 0;
//   };

//   // Adjust in_time and out_time to match 30-minute intervals (handle 5-minute case)
//   const adjustToNearestSlot = (timeString) => {
//     const time = moment(timeString, 'hh:mm');
//     const minutes = time.minutes();
//     if (minutes >= 15 && minutes < 45) {
//       return time.minutes(30).format('hh:mm A'); // Round to the nearest 30 minutes
//     }
//     return time.minutes(0).format('hh:mm A'); // Round down to the hour
//   };

//   const isBooked = (event, timeSlot) => {
//     const eventStart = convertTimeToMinutes(adjustToNearestSlot(event.in_time));
//     const eventEnd = convertTimeToMinutes(adjustToNearestSlot(event.out_time));
//     const slotTime = convertTimeToMinutes(timeSlot);
//     return slotTime >= eventStart && slotTime < eventEnd;
//   };

//   // Precompute the background colors for events
//   const eventsWithBgColor = useMemo(() => {
//     console.log("events for day",eventsForTheDay)
//     return eventsForTheDay.map((event) => {
//       const color = event.color_code || "#e9ecef";

//       event.lightenedColor = lightenColor(color);
//       return event;
//     });
//   }, [eventsForTheDay]);

//   return (
//     <div style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
//       <h4 style={{ color: '#343a40' }}>{date.toDateString()}</h4>
//       <div style={{ overflowX: 'auto' }}>
//         <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: '#ffffff' }}>
//           <thead>
//             <tr>
//               <th style={{ backgroundColor: '#f1f3f5' }}>Chair</th>
//               {timeSlots.map((time, index) => (
//                 <th key={index} style={{ backgroundColor: '#f1f3f5' }}>{time}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {eventsWithBgColor.map((event) => (
                
//               <tr key={event.nurse}>
//                 <td style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>{event.chair}</td>
//                 {timeSlots.map((time, index) => {
//                   const booked = isBooked(event, time);
//                   const bgColor = booked ? event.lightenedColor : '#f8f9fa'; // Lighter background for free slots

//                   if (booked && index === timeSlots.findIndex((t) => isBooked(event, t))) {
//                     const colspan =
//                       timeSlots.findIndex(
//                         (t) => convertTimeToMinutes(t) === convertTimeToMinutes(adjustToNearestSlot(event.out_time))
//                       ) - index + 1;

//                     return (
//                       <td
//                         key={`${event.nurse}-${time}`}
//                         colSpan={colspan}
//                         style={{
//                           border: '1px solid #ccc',
//                           textAlign: 'center',
//                           color: '#495057', // Text color adjusted for readability
//                           padding: "5px"
//                         }}
//                       >
//                         <div
//                           style={{
//                             minWidth: "225px",
//                             boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
//                             borderRadius: 4,
//                             backgroundColor: bgColor,
//                             wordWrap: 'break-word',
//                             wordBreak: 'break-word',
//                             whiteSpace: 'normal',
//                             padding: '3px 4px',
//                           }}
//                         >
//                           {event.patient}  {time}
//                         </div>
//                       </td>
//                     );
//                   }

//                   if (booked) return null; // Skip non-booked slots

//                   return (
//                     <td
//                       key={`${event.nurse}-${time}`}
//                       style={{ backgroundColor: '#f8f9fa', border: '1px solid #ccc' }}
//                     ></td>
//                   );
//                 })}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// Day.title = (date) => `Day View: ${date.toDateString()}`;
// Day.navigate = (date, action) => {
//   switch (action) {
//     case 'PREV':
//       return new Date(date.setDate(date.getDate() - 1));
//     case 'NEXT':
//       return new Date(date.setDate(date.getDate() + 1));
//     default:
//       return date;
//   }
// };
// Day.range = (date) => [date];

// export default Day;





import React, { useMemo } from 'react';
import moment from 'moment';

// Generate time slots from 8:00 AM to 5:30 PM (30-minute intervals)
const generateTimeSlots = () => {
  const times = [];
  let hour = 8;
  let minute = 0; // Start from 8:00 AM
  for (let i = 0; i < 19; i++) { // Including the 5:30 PM slot
    times.push(`${hour}:${minute === 0 ? '00' : minute} ${hour >= 12 ? 'PM' : 'AM'}`);
    minute = minute === 0 ? 30 : 0; // Alternating between 0 and 30 minutes
    if (minute === 0) hour++; // Move to next hour after 30 minutes
  }
  return times;
};

const timeSlots = generateTimeSlots();

// Lighten color function with hex and RGB support
const lightenColor = (color) => {
  let r, g, b, a = 1;
  const rgbaMatch = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/.exec(color);
  if (rgbaMatch) {
    r = parseInt(rgbaMatch[1], 10);
    g = parseInt(rgbaMatch[2], 10);
    b = parseInt(rgbaMatch[3], 10);
    if (rgbaMatch[4]) a = parseFloat(rgbaMatch[4]);
  } else {
    return color;
  }

  // Increase brightness by 40 units
  r = Math.min(255, r + 40);
  g = Math.min(255, g + 40);
  b = Math.min(255, b + 40);

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

const Day = ({ date, events }) => {
  const formattedDate = date.toISOString().split('T')[0];
  const eventsForTheDay = useMemo(
    () => events.filter((event) => new Date(event.start).toDateString() === new Date(date).toDateString()),
    [date, events]
  );

  const convertTimeToMinutes = (timeString) => {
    const time = moment(timeString, 'hh:mm A');
    return time.isValid() ? time.minutes() + time.hours() * 60 : 0;
  };

  // Adjust the time to the nearest 30-minute slot
  const adjustToNearestSlot = (timeString) => {
    const time = moment(timeString, 'hh:mm');
    const minutes = time.minutes();
    
    if (minutes >= 15 && minutes < 45) {
      return time.minutes(30).format('hh:mm A'); // Round to the nearest 30 minutes
    } else if (minutes >= 45) {
      return time.add(1, 'hour').minutes(0).format('hh:mm A'); // Round up to next hour
    } else {
      return time.minutes(0).format('hh:mm A'); // Round down to the hour
    }
  };

  const isBooked = (event, timeSlot) => {
    const eventStart = convertTimeToMinutes(adjustToNearestSlot(event.in_time));
    const eventEnd = convertTimeToMinutes(adjustToNearestSlot(event.out_time));
    const slotTime = convertTimeToMinutes(timeSlot);
    return slotTime >= eventStart && slotTime < eventEnd;
  };

  // Precompute the background colors for events
  const eventsWithBgColor = useMemo(() => {
    return eventsForTheDay.map((event) => {
      const color = event.color_code || "#e9ecef";
      event.lightenedColor = lightenColor(color);
      return event;
    });
  }, [eventsForTheDay]);

  // Calculate how many slots the event should span
  const getEventColspan = (event) => {
    const eventStart = convertTimeToMinutes(adjustToNearestSlot(event.in_time));
    const eventEnd = convertTimeToMinutes(adjustToNearestSlot(event.out_time));
    const firstSlotIndex = timeSlots.findIndex((slot) => convertTimeToMinutes(slot) === eventStart);
    const lastSlotIndex = timeSlots.findIndex((slot) => convertTimeToMinutes(slot) === eventEnd);

    // Handle cases where the event time is just before or after a time slot (e.g., 9:59 or 8:10)
    if (lastSlotIndex === -1) {
      // if the end time doesn't match any time slot, consider the last time slot for the event
      return timeSlots.length - firstSlotIndex;
    }

    return lastSlotIndex - firstSlotIndex + 1; // Calculate colspan
  };

  return (
    <div style={{ padding: '20px'}}>
      <h4 style={{ color: '#343a40' }}>{date.toDateString()}</h4>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: '#ffffff' }}>
          <thead>
            <tr>
              <th style={{ backgroundColor: '#f1f3f5' }}>Chair</th>
              {timeSlots.map((time, index) => (
                <th key={index} style={{ backgroundColor: '#f1f3f5' }}>{time}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {eventsWithBgColor.map((event) => (
              <tr key={event.therapy_id}>
                <td style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>{event.chair}</td>
                {timeSlots.map((time, index) => {
                  const booked = isBooked(event, time);
                  const bgColor = booked ? event.lightenedColor : '#f8f9fa'; // Lighter background for free slots

                  if (booked && index === timeSlots.findIndex((t) => isBooked(event, t))) {
                    const colspan = getEventColspan(event);

                    return (
                      <td
                        key={`${event.therapy_id}-${time}`}
                        colSpan={colspan}
                        style={{
                          border: '1px solid #ccc',
                          textAlign: 'center',
                          color: '#495057', // Text color adjusted for readability
                          padding: "5px"
                        }}
                      >
                        <div
                          style={{
                            minWidth: "225px",
                            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
                            borderRadius: 4,
                            backgroundColor: bgColor,
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            padding: '3px 4px',
                            color:"black"
                          }}
                        >
                          {event.patient} - {event.title}
                        </div>
                      </td>
                    );
                  }

                  if (booked) return null; // Skip non-booked slots

                  return (
                    <td
                      key={`${event.therapy_id}-${time}`}
                      style={{ border: '1px solid #ccc' }}
                    ></td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Day.title = (date) => `Day View: ${date.toDateString()}`;
Day.navigate = (date, action) => {
  switch (action) {
    case 'PREV':
      return new Date(date.setDate(date.getDate() - 1));
    case 'NEXT':
      return new Date(date.setDate(date.getDate() + 1));
    default:
      return date;
  }
};
Day.range = (date) => [date];

export default Day;
