import * as React from "react";
import { InputNumber } from "primereact/inputnumber";

const InputNum = ({
  value,
  label,
  id,
  labelclassName,
  inputClassName,
  onChange,
  maxLength,
  invalid,
  min,
  useGrouping,
  disabled
}: {
  value?: number | null;
  label: string;
  id: string;
  labelclassName?: string;
  inputClassName?: string;
  onChange?: any;
  maxLength?: number;
  invalid?: boolean | string;
  min?:number
  useGrouping ?: boolean
  disabled ?: boolean
}): React.ReactElement => {

  const inputClasses = label ? (inputClassName ? inputClassName : 'col-6') : 'col-12';

  return (
    <>
      <div className="grid m-0 align-items-center">
        {label && (
          <div className={`${labelclassName ? `${labelclassName}` : "col-6"}`}>
            <label className="font-medium text-sm" htmlFor={id}>
              {label}
            </label>
          </div>
        )}
        <div className={`${inputClasses}`}>
          <InputNumber
            value={value}
            id={id}
            onValueChange={onChange}
            maxLength={maxLength}
            invalid={invalid ? true : false}
            className="w-full"
            min={min}
            useGrouping= {useGrouping}
            disabled={disabled}
          />
          <div>
            <span className="text-red-500 text-xs">{invalid}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputNum;
