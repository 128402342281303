import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

const DeleteDialogBox = ({
  visible,
  setVisible,
  rowdata,
}: {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  rowdata: any;
}) => {
  // Dialog Footer
  const Footer = (
    <div className="buttons flex gap-2 justify-content-end">
      <Button label="Cancel" outlined onClick={() => setVisible(false)} />
      <Button
        label="Delete" //onClick={"handleUpdateOwnerName"}
      />
      {/* <Button label="Save" onClick={AddAssignTo}/> */}
    </div>
  );
  return (
    <div>
      <Dialog
        style={{ width: "700px" }}
        draggable
        header="Delete Prescribers Order"
        footer={Footer}
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <div className="grid m-0 align-items-center">
          <div className={`col-8`}>
            <label className="font-medium text-sm">
              Are you sure you want to Delete Prescribers Order?
            </label>
          </div>
          {/* <div className={`col-6`}>{faxNumber}</div> */}
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteDialogBox;
