import { MultiSelect } from "primereact/multiselect";
import { DataTableFilterMeta } from "primereact/datatable";
import { VirtualScrollerProps } from "primereact/virtualscroller";
import { ReactNode } from "react";

interface Option {
  [key: string]: any; // Include any other properties of the option object
}

interface StatusOption {
  status_description: string; // Add other fields if the status object has more properties
}

const MultiSelectFilter = ({
  value,
  filterValue,
  filters,
  data,
  id,
  label,
  itemTemplate,
  placeholder,
  onChange,
  invalid,
  className,
  virtualScrollerOptions,
  disabled,
  required,
  showSelectAll,
}: {
  value?: any;
  filterValue: string;
  filters?: DataTableFilterMeta;
  data?: Option[];
  id?: string;
  label: string;
  itemTemplate?: (option: any) => ReactNode;
  placeholder?: string;
  onChange?: any;
  className?: string;
  virtualScrollerOptions?: VirtualScrollerProps;
  disabled?: any;
  invalid?: boolean | string;
  required?: boolean;
  showSelectAll?: boolean;
}) => {
  // Create options from data dynamically
  const options = data?.map((option: Option) => ({
    label: option[label],
    value: option[filterValue],
  }));

  // Reorder options to place selected ones first
  const reorderedOptions = [...(options || [])].sort((a, b) => {
    if (value?.includes(a.value) && !value?.includes(b.value)) {
      return -1;
    }
    if (!value?.includes(a.value) && value?.includes(b.value)) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="multiselect">
      <MultiSelect
        className={`${className} ${invalid ? "invalid-field" : ""}`}
        value={value}
        options={reorderedOptions}
        onChange={onChange}
        itemTemplate={itemTemplate}
        placeholder={placeholder}
        optionLabel="label"
        maxSelectedLabels={1}
        virtualScrollerOptions={virtualScrollerOptions}
        filter
        resetFilterOnHide
        id={id}
        disabled={disabled}
        required={required}
        showSelectAll={showSelectAll ?? true}
      />
      <div>
        <span className="text-red-500 text-xs">{invalid}</span>
      </div>
    </div>
  );
};

export default MultiSelectFilter;
