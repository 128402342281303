import React, { useState } from 'react';
import "../../../assets/css/calander.css"
import { startOfWeek, addDays, format, addWeeks, subWeeks } from 'date-fns';
import { Dialog } from 'primereact/dialog'; // Assuming you have PrimeReact
import moment from 'moment';
import appointmentlogo from "../../../assets/images/appointmentlogo.svg"
import durationlogo from "../../../assets/images/duration_logo.svg"

const Week = ({ events, date }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false); // Modal visibility state
  const [selectedEvent, setSelectedEvent] = useState(null); // Store the selected event

  const start = startOfWeek(date, { weekStartsOn: 0 }); // Week starts on Sunday
  const days = Array.from({ length: 7 }).map((_, i) => addDays(start, i));

  // Function to handle event click and open the modal
  const openModal = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
  };

  return (
    <div
      style={{
        overflowX: 'auto', // Allow horizontal scrolling
      }}
    >
      {/* Wrapper for both Header and Content */}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {/* Header Row */}
        <div
          style={{
            display: 'flex',
            borderBottom: '1px solid #ccc',
          }}
        >
          {days.map((day, index) => (
            <div
              key={index}
              style={{
                flex: '1 0 120px', // Fixed min width for consistency
                textAlign: 'center',
                padding: '5px',
                fontWeight: 'bold',
                boxSizing: 'border-box',
                whiteSpace: 'nowrap', // Prevent text wrapping
              }}
            >
              {format(day, 'MM/dd (EEE)')}
            </div>
          ))}
        </div>

        {/* Content Rows */}
        <div style={{ display: 'flex' }}>
          {days.map((day, index) => (
            <div
              key={index}
              style={{
                flex: '1 0 120px', // Match the width with header columns
                border: '1px solid #ccc',
                padding: '10px',
                boxSizing: 'border-box',
              }}
            >
              {events
                .filter(
                  (event) =>
                    new Date(event.start).toDateString() === day.toDateString()
                )
                .map((event, idx) => (
                  <div className="content-week" key={idx} onClick={() => openModal(event)}>
                    <strong className="word-week">{event.title}</strong>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>

      {/* Dialog to display the appointment details */}
      {selectedEvent && (
        <Dialog
          header={
            <>
              <div className='header-appointment'>
                <img
                  style={{ height: "22px", paddingLeft: "10px" }}
                  className='appoint-logo'
                  src={appointmentlogo}
                  alt="appointment logo"
                />
                <span>Appointment Details</span>
              </div>
              <div
                style={{
                  color: 'rgba(45.69, 45.69, 45.69, 0.60)',
                  paddingLeft: "20px",
                  fontSize: 12,
                  fontWeight: '500',
                  textTransform: 'capitalize',
                  wordWrap: 'break-word',
                }}
              >
                Stay Informed About Your Upcoming Consultations
              </div>
            </>
          }
          visible={modalIsOpen}
          onHide={closeModal}
          style={{
            width: '600px',
            borderRadius: '35px',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div style={{ marginBottom: '15px' }}>
            <div
              style={{
                textAlign: 'justify',
                color: '#908E8E',
                fontSize: 13.13,
                fontWeight: '500',
                textTransform: 'capitalize',
                wordWrap: 'break-word',
              }}
            >
              Patient:
              <span
                style={{
                  textAlign: 'justify',
                  color: '#4F4C4C',
                  fontSize: 13.13,
                  fontWeight: '500',
                  textTransform: 'capitalize',
                  wordWrap: 'break-word',
                }}
              >
                {selectedEvent.patient}
              </span>
            </div>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <div
              style={{
                textAlign: 'justify',
                color: '#908E8E',
                fontSize: 13.13,
                fontWeight: '500',
                textTransform: 'capitalize',
                wordWrap: 'break-word',
              }}
            >
              Nurse:
              <span
                style={{
                  textAlign: 'justify',
                  color: '#4F4C4C',
                  fontSize: 13.13,
                  fontWeight: '500',
                  textTransform: 'capitalize',
                  wordWrap: 'break-word',
                }}
              >
                {selectedEvent.nurse}
              </span>
            </div>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <p>
              <strong>
                <i className='pi pi-calendar' />
              </strong>{' '}
              {moment(selectedEvent.start).format('MMMM Do, YYYY')}
            </p>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <p>
              <strong>
                <i className='pi pi-clock' />
              </strong>{' '}
              {moment(selectedEvent.start_Time).format('HH:mm')} - {moment(selectedEvent.end).format('HH:mm')}
            </p>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <p style={{ display: 'inline-flex', alignItems: 'center', gap: '5px' }}>
              <img
                style={{ height: "20px", objectFit: "contain" }}
                src={durationlogo}
                alt="Duration"
              />
              {selectedEvent.duration}
            </p>
          </div>
        </Dialog>
      )}
    </div>
  );
};

// Static method for the view title
Week.title = (date) => {
  const start = startOfWeek(date, { weekStartsOn: 0 });
  const end = addDays(start, 6);
  return `Week of ${format(start, 'MMM d')} - ${format(end, 'MMM d')}`;
};

// Static method for navigation between weeks
Week.navigate = (date, action) => {
  switch (action) {
    case 'PREV':
      return subWeeks(date, 1); // Navigate to the previous week
    case 'NEXT':
      return addWeeks(date, 1); // Navigate to the next week
    default:
      return date; // Stay on the current week
  }
};

export default Week;
