import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { Column } from "primereact/column";
import {
  RolePrivileges,
  SortDecre,
  SortIncre,
} from "../../../assets/images/Icon";
import { FormatDate } from "../../../utils/formatDate";
import Header from "../../../layouts/Header/Header";
import { getDeliveryTicketItemList } from "../../../redux/store/ARManagerReducer";
import { formatCurrency } from "../../../utils/formatCurrency";
import InputDropdown from "../../formElement/InputDropdown";
import { data } from "../../../types/IHealthAppTypes";
import InputCheck from "../../formElement/InputCheck";
import { Button } from "primereact/button";

const AddNewSpecialDialogBox = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) => {
  const options = ["Deny", "Read", "Add", "Modify", "Delete"];
  const allOption = "All";

  const customHeader = () => {
    return (
      <>
        <div className="dialog-header flex gap-2">
          <div className="header-icon mr-2">
            <img className="mt-2" src={RolePrivileges} alt="Role Privileges" />
          </div>
          <div className="header-title">
            <div className="text-xl">Special User Permissions</div>
            <div className="text-sm font-medium header-subtitle">
              To register new privileges at the section level
            </div>
          </div>
        </div>
      </>
    );
  };

  const customFooter = () => {
    return (
      <div className="outlined flex justify-content-end gap-2">
        <Button
          label="Cancel"
          outlined
          className="w-7rem"
          onClick={() => setVisible(false)}
        />
        <Button label="Save" className="w-7rem" />
      </div>
    );
  };

  return (
    <>
      <Dialog
        style={{ width: "30vw", zIndex: 1111 }}
        draggable
        header={customHeader}
        footer={customFooter}
        resizable
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <div className=" ml-2">
          <div className="pt-2">
            <label className="ml-3 text-sm">User</label>
            <div className="col-6 pt-0">
              <InputDropdown
                id="Allrole"
                type="text"
                labelclassName="custom-inputwd"
                placeholder="Search User"
                // // value={selectedRoleId || ""}
                // options={permissionsList.map((role: data) => ({
                //     label: role.role_name,
                //     value: role.role_id,
                // }))}
                // handleChange={(event: any) => setSelectedRoleId(event.target.value)}
              />
            </div>
          </div>
          <div className="pt-2">
            <label className="ml-3 text-sm">Page</label>
            <div className="col-6 pt-0">
              <InputDropdown
                id="AllPage"
                type="text"
                labelclassName=""
                // value={selectedPageId || ""}
                // options={pages.map((allpage: data) => ({
                //     label: allpage.page_name,
                //     value: allpage.page_id,
                // }))}
                // // Update the selectedPageId when a page is selected
                // handleChange={(event: any) => setSelectedPageId(event.target.value)}
              />
            </div>
          </div>
          <div className="pt-2">
            <label className="ml-3 text-sm">Section</label>
            <div className="col-6 pt-0">
              <InputDropdown
                id="AllSection"
                type="text"
                labelclassName=""
                // value={selectedSectionName}
                // options={filteredSections.map((AllSection: data) => ({
                //     label: AllSection.section_name || "",
                //     value: AllSection.section_name || "",
                // }))}
                // handleChange={(event) => setSelectedSectionName(event.target.value)}

                // disabled={!selectedPageId}
              />
            </div>
          </div>
          <div className="pt-3 ml-2">
            <label className="ml-2 text-sm">Privileges</label>
          
            <div className="flex flex-wrap gap-1">
              {/* All Checkbox */}
              <div className="col-3">
                <InputCheck
                  className="cheak"
                  labelclassName="col-6"
                  checkboxclassName="col-3"
                  id={allOption}
                  // checked={selectedOptions.length === options.length + 1}
                  // handleChange={() => handleCheckboxChange(allOption)}
                  label={allOption}
                />
              </div>

              {/* Individual Checkboxes */}
              {options.map((option) => (
                <div key={option} className="col-3">
                  <InputCheck
                    className="cheak"
                    labelclassName="col-6"
                    checkboxclassName="col-3"
                    id={option}
                    // checked={selectedOptions.includes(option)}
                    // handleChange={() => handleCheckboxChange(option)}
                    label={option}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddNewSpecialDialogBox;
