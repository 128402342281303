import * as React from "react";
import { InputTextarea } from "primereact/inputtextarea";

const InputTextArea = ({
  value,
  label,
  id,
  labelclassName,
  inputclassName,
  rows,
  disabled,
  handleChange,
  placeholder,
  autoResize,
  handleClick,
  invalid,
  required,
  requiredRight,
}: {
  value?: string | number;
  label?: string;
  id: string;
  rows?: number;
  labelclassName?: string;
  inputclassName?: string;
  handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleClick?: (e: React.MouseEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  autoResize?: boolean;
  invalid?: boolean | string;
  required?: boolean;
  requiredRight?: boolean;
  disabled?: boolean;
}): React.ReactElement => {
  const stringValue = value !== undefined ? String(value) : "";

  const inputClasses = label ? (inputclassName ? inputclassName : 'col-6') : 'col-12';
  return (
    <>
      <div className="grid m-0">
        {label && (
          <div className={`${labelclassName ? `${labelclassName}` : "col-6"}`}>
            <label className="font-medium text-sm" htmlFor={id}>
              {required && <span className="text-red-500">*</span>} {label}
              {requiredRight ? <span className="text-danger">*</span> : null}
            </label>
          </div>
        )}
        <div className={`${inputClasses}`}>
          <InputTextarea
            autoResize={autoResize}
            rows={rows}
            value={stringValue}
            id={id}
            className={`p-2 ${invalid ? "invalid-field" : ""}`}
            onChange={handleChange}
            onClick={handleClick}
            placeholder={placeholder}
            disabled={disabled}
          />
          <div>
            <span className="text-red-500 text-xs">{invalid}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputTextArea;
