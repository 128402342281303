import { Button } from "primereact/button";
import React, { useEffect } from "react";
import { Completed, Pending, Refreshbutton } from "../../../assets/images/Icon";
import "../../../assets/css/PrescribersWorkflow.css";
import InputField from "../../formElement/InputField";
import { MultiSelect } from "primereact/multiselect";
import { DataView } from "primereact/dataview";
import InputDate from "../../formElement/InputDate";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import MultiSelectFilter from "../../formElement/MultiSelect";
import {
  getCategoryList,
  getSentViaList,
  getTeamList,
} from "../../../redux/store/PrescribersWorkflowReducer";
import CustomTooltip from "../../Tooltip/CustomTooltip";

const PrescribersWorkflowFilter = ({ Alldata }: { Alldata: any }) => {
  const dispatch = useDispatch<AppDispatch>();

  //get data all sP

  // Dispatch method to render the filter data
  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getTeamList());
    dispatch(getSentViaList());

  }, [dispatch]);

  const { CategoryList, TeamList,SentViaList } = useSelector(
    (state: RootState) => state.prescriberworkflowReducer
  );

  const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Pending", value: "Pending" },
    { label: "On Hold", value: "On Hold" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Inactive", value: "Inactive" },
  ];

  return (
    <>
    
      <div className="flex button-container">
      <CustomTooltip target=".p-button-rounded" position="bottom" />
        {/* Pending Button */}
        <Button 
        className="p-button-rounded PrescribersWorkflow-custom-button"
        data-pr-tooltip="Pending"
        >
          <span className="custom-label">
            <span className="tag-count border-right-1 px-3 text-sm font-bold">
              123
            </span>
            <span className="status-text pl-3 text-sm font-bold">Pending</span>
          </span>
          
        </Button>

        {/* Completed Button */}
        <Button
         className="p-button-rounded PrescribersWorkflow-custom-button"
         data-pr-tooltip="Completed"
        >
          <span className="custom-label">
            <span className="tag-count border-right-1 px-3 text-sm font-bold">
              123
            </span>
            <span className="status-text pl-3 text-sm font-bold">
              Completed
            </span>
          </span>
        </Button>
      </div>

      {/* Second row*/}
      <div className="flex flex-wrap align-items-center justify-content-between w-full">
      <CustomTooltip target=".Tooltip" position="bottom" />
        {/* Other components or filters */}
        <div className="flex flex-grow-1 flex-wrap">
          <div className="lg:col-2 md:col-3 sm:col-12 pt-1 pl-0 pr-1">
            <InputField 
            placeholder="Search" 
            id="patientSearch"
            className="Tooltip"
            data-pr-tooltip="Search"
            />
          </div>
          <div className="lg:col-2 md:col-3 sm:col-12 p-1 " style={{ width: "11.5%" }}>
            <MultiSelectFilter
              filterValue="value"
              id="collectorIds"
              label="label"
              data={statusOptions}
              placeholder="Patient Status"
              className="Tooltip"
              data-pr-tooltip="Patient status"
            />
          </div>
          <div className="lg:col-2 md:col-3 sm:col-12 p-1 " style={{ width: "11.5%" }}>
            <MultiSelectFilter
              filterValue="value"
              id="collectorIds"
              label="description"
              data={SentViaList}
              placeholder="Sent via"
              className="Tooltip"
              data-pr-tooltip="Sent via"
            />
          </div>
          <div className="lg:col-2 md:col-3 sm:col-12 p-1 " style={{ width: "11.5%" }}>
            <MultiSelectFilter
              filterValue="team_name"
              id="collectorIds"
              label="team_name"
              data={TeamList}
              placeholder="Teams"
              className="Tooltip"
              data-pr-tooltip="Teams"
            />
          </div>
          <div className="lg:col-2 md:col-3 sm:col-12 p-1 "  style={{ width: "11.5%" }}>
            <MultiSelectFilter
              filterValue="description"
              id="collectorIds"
              label="description"
              data={CategoryList}
              placeholder="Category"
              className="Tooltip"
              data-pr-tooltip="Category"

            />
          </div>
          <div className="lg:col-2 md:col-3 sm:col-12 p-0" style={{ width: "11.5%" }}>
            <InputDate  
            id=""
             placeholder="From Date" 
              />
          </div>
          <div className="lg:col-2 md:col-3 sm:col-12 p-0" style={{ width: "11.5%" }}>
            <InputDate id="" placeholder="To Date" />
          </div>
          <div className="p-1">
            <Button label="Reset"
             outlined id="SearchFaxDirectory" 
             className="Tooltip"
             data-pr-tooltip="Reset"
             
             />
          </div>
          <div className="p-1">
            <Button
             label="Apply Filter" 
             className="Tooltip"
             data-pr-tooltip="Apply Filter"
             />
          </div>
        </div>
        {/* Right-aligned Refresh Button */}
        <div className="flex justify-content-end lg:col-3 md:col-12 sm:col-12 p-1">
          <Button 
          //label=""
          className="Tooltip bg-white border-transparent"
          data-pr-tooltip="Refresh Data"
          icon={() => <img className="mr-2 bg-white" src={Refreshbutton} />} />
        </div>
      </div>
    </>
  );
};

export default PrescribersWorkflowFilter;
