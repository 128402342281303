import React, { useState } from "react";
import InputField from "../../formElement/InputField";
import InputDropdown from "../../formElement/InputDropdown";
import { Button } from "primereact/button";
import AddNewUserDialogBox from "../../Dialog/PermissionDialogBox/AddNewUserDialogBox";
import AddNewPrivilegesDialogBox from "../../Dialog/PermissionDialogBox/AddNewPrivilegesDialogBox";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
type data = {
  role_id: string;
  role_name: string;
  page_name: string;
  page_id: string;
  section_name: string;
};

const PermissionNew = ({
  activeTab,
}: {
  activeTab: "user role" | "role Privileges";
}) => {
  const [addNewUserDialogVisible, setAddNewUserDialogVisible] = useState(false);
  const [addNewPrivilegesDialogVisible, setAddNewPrivilegesDialogVisible] =
    useState(false);

  const handleDialogVisiblity = () => {
    if (activeTab === "user role") {
      setAddNewUserDialogVisible(true);
    } else if (activeTab === "role Privileges") {
      setAddNewPrivilegesDialogVisible(true);
    }
  };
 
  const { permissionsList, pages, section, loading, errorMessage } =
    useSelector((state: RootState) => state.permissionsReducer);
    const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
     const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
     const [selectedSectionName, setSelectedSectionName] = useState<string>("");
     const filteredSections = section.filter(
       (item: data) => item.page_id === selectedPageId
      );

  return (
    <>
      <div className="grid align-items-center my-1 justify-content-between">
        <div className="flex">
          <div className="col-5 pt-3">
            <InputField
              placeholder="Search By Role"
              id="role"
              className="custom-searchbar"
            />
          </div>
          <div className="col-4">
            <InputDropdown
              id="allroles"
              type="text"
              placeholder="All Roles"
              labelclassName="custom-fontwt"
              value={selectedRoleId || ""}
              options={permissionsList.map((role: data) => ({
                label: role.role_name,
                value: role.role_id,
              }))}
              handleChange={(event: any) => setSelectedRoleId(event.target.value)}
            />
          </div>
          <div className="col-4">
            <InputDropdown
              id="AllCensus"
              type="text"
              placeholder="All Census"
              labelclassName=""
              value={selectedPageId || ""}
              options={pages.map((allpage: data) => ({
                label: allpage.page_name,
                value: allpage.page_id,
              }))}
              // Update the selectedPageId when a page is selected
              handleChange={(event: any) => setSelectedPageId(event.target.value)}
            />
          </div>
          <div className="col-4">
            <InputDropdown
              id="AllSection"
              type="text"
              placeholder="AllSection"
              labelclassName=""
              value={selectedSectionName}
              options={filteredSections.map((AllSection: data) => ({
                label: AllSection.section_name || "",
                value: AllSection.section_name || "",
              }))}
              handleChange={(event) => setSelectedSectionName(event.target.value)}
              disabled={!selectedPageId}
            />
          </div>
        </div>
        <div className="flex justify-end mr-2">
          <Button
            className="text-sm w-7rem pl-3"
            onClick={() => handleDialogVisiblity()}
          >
            + ADD NEW
          </Button>
        </div>
      </div>
      <AddNewUserDialogBox
        visible={addNewUserDialogVisible}
        setVisible={setAddNewUserDialogVisible}
      />
      <AddNewPrivilegesDialogBox
        visible={addNewPrivilegesDialogVisible}
        setVisible={setAddNewPrivilegesDialogVisible}
      />
    </>
  );
};

export default PermissionNew;
