import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { Column } from "primereact/column";
import { SortDecre, SortIncre } from "../../../assets/images/Icon";
import { FormatDate } from "../../../utils/formatDate";
import Header from "../../../layouts/Header/Header";
import { getDeliveryTicketItemList } from "../../../redux/store/ARManagerReducer";
import { useState } from "react";
import DeliveryTicketItemListDialogBox from "./DeliveryTicketItemDialogBox";

const DeliveryTicketDialogBox = ({
  onStatusUpdateLoading,
  onStatusUpdated,
  visible,
  setVisible,
}: {
  onStatusUpdateLoading: (isLoading: boolean) => void;
  onStatusUpdated: () => void;
  visible: boolean;
  setVisible: any;
}) => {
  const { deliveryTicketLoading, deliveryTicketList } = useSelector(
    (state: RootState) => state.arManagerReducer
  );
  const [deliveryTicketItemVisible, setDeliveryItemVisible] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const ActionBody = (rowData: any) => {
    return (
      <>
        <i
          style={{ cursor: "pointer" }}
          className="pi pi-address-book"
          onClick={() => {
            setDeliveryItemVisible(true);
            dispatch(
              getDeliveryTicketItemList({ DeliveryTicket: rowData.dt_number })
            );
          }}
          title="Additional options"
        ></i>
      </>
    );
  };

  const progressColumns = [
    {
      field: "dt_number",
      header: "D.T.#",
      sortable: true,
    },
    {
      field: "invoice_number",
      header: "Inv.#",
      sortable: true,
    },
    {
      field: "start_date",
      header: "Start",
      sortable: true,
      body: (rowData: any) => FormatDate(rowData.start_date),
    },
    {
      field: "ship_date",
      header: "Ship Date",
      sortable: true,
      body: (rowData: any) => FormatDate(rowData.ship_date),
    },
    {
      field: "conf_date",
      header: "Conf Date",
      sortable: true,
      body: (rowData: any) => FormatDate(rowData.conf_date),
    },
    {
      field: "therapy_type",
      header: "Therapy Type",
      sortable: true,
    },
    { field: "action", header: "Action", body: ActionBody },
  ];

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  return (
    <>
      <Dialog
        style={{ width: "1200px", zIndex: 1111 }}
        draggable
        header="Delivery Ticket"
        resizable
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <DataTable
          value={deliveryTicketList}
          emptyMessage="No Data Found"
          scrollable
          removableSort
          scrollHeight="612px"
          sortIcon={sortIcon}
          loading={deliveryTicketLoading}
        >
          {progressColumns.map((column, index) => (
            <Column
              key={index}
              field={column.field}
              header={column.header}
              sortable={column.sortable}
              body={column.body}
            />
          ))}
        </DataTable>
      </Dialog>
      <DeliveryTicketItemListDialogBox
        visible={deliveryTicketItemVisible}
        setVisible={setDeliveryItemVisible}
      />
    </>
  );
};

export default DeliveryTicketDialogBox;
