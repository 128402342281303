import { Checkbox } from "primereact/checkbox";
import InputDropdown from "../../../formElement/InputDropdown";
import Input from "../../../formElement/Input";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import React, { useState } from "react";
import AddContractLinkDialogBox from "../AddContractLinkDialogBox";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import InputTextArea from "../../../formElement/InputTextArea";
import { useUserPermission } from "../../../../hooks/useUserPermission";

interface Site {
  id: number;
  site: string;
  company_site_id?: number;
}

const GeneralBillingTabView = ({
  generalBillData,
  contractLink,
  setGeneralBillData,
  setContractLink,
  permission,
  errorMessage,
}: {
  contractLink: any;
  generalBillData: any;
  permission: boolean
  setGeneralBillData: React.Dispatch<React.SetStateAction<any>>;
  setContractLink: React.Dispatch<React.SetStateAction<any>>;
  errorMessage: any;
}) => {
  const {
    arManagerPayorTypeList,
    siteList,
    contractStatusList,
    payorPricingList,
    insuranceCompanyBillingOrganizationList,
    billingQualifier,
    billingInvoiceType,
  } = useSelector((state: RootState) => state.insuranceReducer);
  const [visible, setVisible] = useState(false);

  const handleChange = (e: any) => {
    const { id, type, value, checked } = e.target;
    setGeneralBillData((prevData: any) => ({
      ...prevData,
      [id]: type === "checkbox" ? (checked ? 1 : 0) : value,
    }));
  };

  const modifiedSiteList = siteList.map((site: Site) => ({
    site_id: site.id,
    site_name: site.site,
  }));

  const statusTypes = [
    { label: "ACTIVE", value: "Active" },
    { label: "INACTIVE", value: "Inactive" },
  ];

  return (
    <>
      <div className="grid">
        <div className="col-6 p-0">
          <Input
            value={generalBillData?.organization}
            label="Organization"
            placeholder="Organization"
            handleChange={handleChange}
            type="text"
            id="organization"
            invalid={errorMessage.organization}
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <Input
            value={generalBillData?.identifier}
            label="Identifier"
            placeholder="Identifier"
            type="text"
            handleChange={handleChange}
            id="identifier"
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <InputDropdown
            value={generalBillData?.payor_type}
            label="Payor Type"
            type="text"
            handleChange={handleChange}
            id="payor_type"
            placeholder="Payor Type"
            options={arManagerPayorTypeList.map(
              (payor: any) => payor?.demographic_value
            )}
            invalid={errorMessage.payor_type}
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <div className="grid m-0 align-items-center">
            <div className="col-6">
              <label className="font-medium text-sm" htmlFor="site_name">
                Site
              </label>
            </div>
            <div className="col-6">
              <MultiSelect
                id="site_name"
                placeholder="Select Site"
                value={generalBillData?.site_name.map((site: any) => ({
                  site_id: site.site_id,
                  site_name: site.site_name,
                }))}
                onChange={handleChange}
                optionLabel="site_name"
                maxSelectedLabels={1}
                virtualScrollerOptions={{ itemSize: 50 }}
                filter
                resetFilterOnHide
                options={modifiedSiteList}
                disabled={!permission}
              />
            </div>
          </div>
        </div>
        <div className="col-6 p-0">
          <InputDropdown
            value={generalBillData?.contract_status_id}
            label="Contract Status"
            type="text"
            id="contract_status_id"
            handleChange={handleChange}
            optionLabel="label"
            placeholder="Contract Status"
            disabled={!permission}
            options={contractStatusList.map((status: any) => ({
              label: status.description,
              value: status.value_data_id,
            }))}
          />
        </div>
        <div className="col-6 p-0">
          <Input
            value={generalBillData?.commercial_name}
            label="DBA/Commercial Name"
            placeholder="DBA/Commercial Name"
            type="text"
            handleChange={handleChange}
            id="commercial_name"
            disabled={!permission}
          />
        </div>
      </div>
      <div className="grid mt-0">
        <div className="col-6 p-0">
          <InputDropdown
            value={generalBillData?.contract_status}
            label="Payor Status"
            type="text"
            id="contract_status"
            optionLabel="label"
            invalid={errorMessage.contract_status}
            handleChange={handleChange}
            placeholder="Payor Status"
            options={statusTypes}
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <div className="flex p-2 justify-content-end">
            <Button
              onClick={() => setVisible(true)}
              className="w-16rem py-2 px-4"
              outlined
              label="Add contract link"
            />
          </div>
        </div>
      </div>
      <div className="text-center text-xl font-bold m-4">Billing Info</div>
      <div className="grid">
        <div className="col-6 p-0">
          <InputDropdown
            value={generalBillData?.billing_organization}
            label="Organization"
            type="text"
            virtualScrollerOptions={{ itemSize: 50 }}
            handleChange={handleChange}
            filter
            id="billing_organization"
            placeholder="Organization"
            options={insuranceCompanyBillingOrganizationList.map(
              (organisation: any) => organisation?.company_description
            )}
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <InputDropdown
            value={generalBillData?.Payor_Pricing}
            label="Payor Pricing"
            type="text"
            id="Payor_Pricing"
            optionLabel="label"
            handleChange={handleChange}
            placeholder="Payor Pricing"
            options={payorPricingList.map((pricing: any) => ({
              label: pricing.description,
              value: pricing.description,
            }))}
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <Input
            value={generalBillData?.provider_number}
            label="Provide Number"
            placeholder="Provide Number"
            type="text"
            handleChange={handleChange}
            id="provider_number"
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <Input
            value={generalBillData?.sbcontact}
            label="Attn"
            placeholder="Attn"
            type="text"
            id="sbcontact"
            handleChange={handleChange}
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <InputDropdown
            value={generalBillData?.qualifier}
            label="Qualifier"
            type="text"
            id="qualifier"
            handleChange={handleChange}
            placeholder="Qualifier"
            options={billingQualifier.map((qualifier: any) => qualifier?.code)}
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <Input
            value={generalBillData?.webmd_medical}
            label="WebMD Medical#"
            placeholder="WebMD Medical#"
            type="text"
            id="webmd_medical"
            handleChange={handleChange}
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <Input
            value={generalBillData?.naic_payor_id}
            label="NAIC Payor ID"
            placeholder="NAIC Payor ID"
            type="text"
            id="naic_payor_id"
            handleChange={handleChange}
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <Input
            value={generalBillData?.hpid}
            label="HPID"
            placeholder="HPID"
            type="text"
            id="hpid"
            handleChange={handleChange}
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <Input
            value={generalBillData?.ocna}
            label="OCPA"
            placeholder="OCPA"
            type="text"
            id="ocna"
            handleChange={handleChange}
            disabled={!permission}
          />
        </div>
        <div className="col-6 p-0">
          <InputDropdown
            value={generalBillData?.invoice_type}
            label="Requires Invoice Type"
            type="text"
            id="invoice_type"
            handleChange={handleChange}
            placeholder="Requires Invoice Type"
            optionLabel="label"
            options={billingInvoiceType.map((invoice: any) => ({
              label: invoice.description?.trim(),
              value: invoice.description?.trim(),
            }))}
            disabled={!permission}
          />
        </div>
        <div className="col-6 pl-2 my-1 flex align-items-center gap-3">
          <div className="flex align-items-center">
            <Checkbox
              id="populate_box_24J"
              name="check"
              onChange={handleChange}
              checked={generalBillData?.populate_box_24J == "1"}
              disabled={!permission}
            />
            <label htmlFor="check1" className="ml-2 text-sm">
              Populate box 24j?
            </label>
          </div>
          <div className="flex align-items-center">
            <Checkbox
              inputId="check2"
              id="use_grt_npi_for_box_24J"
              name="check"
              onChange={handleChange}
              checked={generalBillData?.use_grt_npi_for_box_24J == 1}
              disabled={!permission}
            />
            <label htmlFor="check2" className="ml-2 text-sm">
              Use GRT NPI for box 24j
            </label>
          </div>
        </div>
      </div>
      <div className="w-12 my-3">
        <InputTextArea
          label="Notes"
          id="note"
          value={generalBillData?.note}
          labelclassName="col-1 p-0"
          inputclassName="col-11 p-0"
          placeholder="Notes"
          handleChange={handleChange}
          rows={5}
          disabled={!permission}
        />
      </div>
      <AddContractLinkDialogBox
        setVisible={setVisible}
        visible={visible}
        permission={permission}
        contractLink={contractLink}
        setContractLink={setContractLink}
      />
    </>
  );
};

export default GeneralBillingTabView;
