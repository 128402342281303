import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASEURL;


// Define a type for a single permission
export interface data {
    role_id: string;
    role_name: string;
    page_id: string;
    page_name: string;
    section_name:string;
    user_id: string;
    user_name:string
    
}


// Define the state type
interface PermissionsState {
    permissionsList: data[];
    pages: data[];
    section:data[];
    UserList:data[];
    loading: boolean;
    errorMessage: string;
}

// Initial state
const initialState: PermissionsState = {
    permissionsList: [],
    pages: [],
    section:[],
    UserList:[],
    loading: false,
    errorMessage: "",
};

// Async Thunk to fetch permissions list
export const getPermissionsList = createAsyncThunk(
    "permission/getRoleList",
    async () => {
        try {
            const endpoint = `${baseUrl}/api/Permission/GetRoleList`;
            const response = await axios.get(endpoint);
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.data;
            return data;
        } catch (error) {
            console.error("Error fetching user details", error);
        }
    }
);
//Get All Census
export const GetPageList = createAsyncThunk(
    "permission/GetPageList",
    async () => {
        try {
            const endpoint = `${baseUrl}/api/Permission/GetPageList`;
            const response = await axios.get(endpoint);
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.data;
            return data;
        } catch (error) {
            console.error("Error fetching user details", error);
        }
    }
);

//Get All Sections
export const GetSectionList = createAsyncThunk(
    "permission/GetSectionList",
    async () => {
        try {
            const endpoint = `${baseUrl}/api/Permission/GetSectionList`;
            const response = await axios.get(endpoint);
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.data;
            return data;
        } catch (error) {
            console.error("Error fetching user details", error);
        }
    }
);

//Get userlist
export const GetUserList = createAsyncThunk(
    "ToDo/GetUserList",
    async () => {
        try {
            const endpoint = `${baseUrl}/api/ToDo/GetUserList`;
            const response = await axios.get(endpoint);
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.data;
            return data;
        } catch (error) {
            console.error("Error fetching user details", error);
        }
    }
);
// Async Thunk to update permissions
export const updatePermissions = createAsyncThunk<
    data,
    { permissionId: number; updatedData: Partial<data> },
    { rejectValue: string }
>(
    "permissions/updatePermissions",
    async ({ permissionId, updatedData }, { rejectWithValue }) => {
        try {
            const endpoint = `${baseUrl}/api/Permissions/Update/${permissionId}`;

            const response = await axios.put(endpoint, updatedData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            return response.data; // Assuming this returns the updated permission
        } catch (error: any) {
            console.error("Error updating permission:", error);
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);

// Permissions slice
const permissionsSlice = createSlice({
    name: "permissions",
    initialState,
    reducers: {
        resetPermissions: (state) => {
            state.permissionsList = [];
            state.errorMessage = "";
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle getPermissionsList
            .addCase(getPermissionsList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPermissionsList.fulfilled, (state, action) => {
                state.permissionsList = action.payload.data || [];
                state.loading = false;

            })
            .addCase(getPermissionsList.rejected, (state, action) => {
                state.errorMessage = typeof action.payload === 'string' ? action.payload : 'Failed to fetch permissions';
                state.loading = false;
            })

            // Handle GetPageList
            .addCase(GetPageList.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetPageList.fulfilled, (state, action) => {
                state.loading = false;
                if (Array.isArray(action.payload.data)) {
                    state.pages = action.payload.data;
                } else {
                    console.error("Unexpected data format for pages:", action.payload);
                }
            })

            .addCase(GetPageList.rejected, (state, action) => {
                state.loading = false;
                state.errorMessage = "Failed to fetch pages";
            })
            // Handle GetSectionList
            .addCase(GetSectionList.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetSectionList.fulfilled, (state, action) => {
                state.loading = false;
                if (Array.isArray(action.payload.data)) {
                    state.section = action.payload.data;
                } else {
                    console.error("Unexpected data format for pages:", action.payload);
                }
            })

            .addCase(GetSectionList.rejected, (state, action) => {
                state.loading = false;
                state.errorMessage = "Failed to fetch pages";
            })


             // Handle GetUserList
             .addCase(GetUserList.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetUserList.fulfilled, (state, action) => {
                state.loading = false;
                if (Array.isArray(action.payload.data)) {
                    state.UserList = action.payload.data;
                } else {
                    console.error("Unexpected data format for pages:", action.payload);
                }
            })

            .addCase(GetUserList.rejected, (state, action) => {
                state.loading = false;
                state.errorMessage = "Failed to fetch pages";
            })
            // Handle updatePermissions
            .addCase(updatePermissions.pending, (state) => {
                state.loading = true;
            })
            .addCase(updatePermissions.fulfilled, (state, action) => {
                const updatedPermission = action.payload;
                state.permissionsList = state.permissionsList.map((permission) =>
                    permission.role_id === updatedPermission.role_id ? updatedPermission : permission
                );
                state.loading = false;
            })
            .addCase(updatePermissions.rejected, (state, action) => {
                state.errorMessage = action.payload || "Failed to update permission";
                state.loading = false;
            })


    },
});

export const { resetPermissions } = permissionsSlice.actions;
export default permissionsSlice.reducer;

