import React, { useEffect, useState } from "react";
import ShipmentFilter from "../../components/Filter/ShipmentFilter/ShipmentFilter";
import ShipmentDataTable from "../../components/DataTable/ShipmentDataTable";
import { getShipmentData } from "../../redux/store/ShipmentReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import Loader from "../../components/loader/Loader";
import { FilterMatchMode } from "primereact/api";
import { DataTableFilterMeta } from "primereact/datatable";
import ShipmentDocument from "../document/ShipmentDocumentview";


const Shipment = () => {
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const { item, loading } = useSelector(
    (state: RootState) => state.shipmentReducer
  );

  const dispatch = useDispatch<AppDispatch>();
  const location = window.location.pathname;
  useEffect(() => {
    const actions = [getShipmentData()];

    actions.forEach((action) => dispatch(action));
  }, [dispatch]);


//status dropdown code
  const statusData = Array.from(
    new Set(item.map((shipment: any) => (shipment.status)))
  ).map((status) => ({
    label: status,
    value: status,
  }));

// //RXType dropdown code
// const RxtypeData = Array.from(
//   new Set(item.map((shipment: any) => (shipment.rx_type)))
// ).map((rx_type) => ({
//   label: rx_type,
//   value: rx_type,
// }));



interface RXTypeOption {
  label: string;
  value: string;
}

const RxtypeData: RXTypeOption[] = Array.from(
  new Set(
    item
      .map((shipment: any) => shipment.rx_type) // Extract unique rx_type values
      .filter((rx_type: null | undefined) => rx_type !== null && rx_type !== undefined) // Filter out null or undefined
  )
)
  .map((rx_type) => ({
    label: rx_type as string, // Explicitly cast to string
    value: rx_type as string, // Explicitly cast to string
  }))
  .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically by label




//ShipMethod dropdown code
const shipmethodData = Array.from(
  new Set(item.map((shipment: any) => (shipment.shipping_method)))
).map((shipping_method) => ({
  label: shipping_method,
  value: shipping_method,
}));




// //Team dropdown code
// const TeamData = Array.from(
//   new Set(item.map((shipment: any) => (shipment.team)))
// ).map((team) => ({
//   label: team,
//   value: team,
// })) ;


interface TeamOption {
  label: string;
  value: string;
  
}

const TeamData: TeamOption[] = Array.from(
  new Set(
    item
      .map((shipment: any) => shipment.team) // Extract unique team names
      .filter((team: null | undefined) => team !== null && team !== undefined) // Filter out null or undefined
  )
)
  .map((team) => ({
    label: team as string, // Explicitly cast to string
    value: team as string, // Explicitly cast to string
  }))
  .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically by label




  return (
    <>
      <div>
        <ShipmentFilter
          filters={filters}
          setFilters={setFilters}
          statusData={statusData}
          rxtype={RxtypeData}
          shipmethod={shipmethodData}
          team={TeamData}
          alldata={item}
        />
      </div>
      <div>
        {loading ? (
          <Loader location={location} path="/shipment" label="Shipment" />
        ) : (
          <div className= "Shipment-table">
          <ShipmentDataTable
            data={item}
            filters={filters}
            name={undefined}
            search_string={""}

          />
          </div>
        )}
      </div>
     
    </>
  );
};

export default Shipment;
