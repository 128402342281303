import { useContext } from "react";
import { UserPermissionContext } from "../auth/PrivateRoute";
import { PermissionPropsTypes } from "../types/IHealthAppTypes";


export const useUserPermission = (pageName: string, sectionName?: string) => {
    const context = useContext(UserPermissionContext);
    if (!context) {
        throw new Error("useUserPermission must be used within a UserPermissionProvider");
    }

    let permissions: PermissionPropsTypes | undefined;

    if (sectionName) {
        permissions = context[pageName]?.[sectionName] || context[pageName]?.["All Section"]
    } else {
        permissions = context[pageName]?.["All Section"]
    }

    if (!permissions || permissions.deny === "Y") {
        return {
            read: false,
            write: false,
            delete: false,
            modify: false,
            page: permissions.page || "",
            role: permissions.role || "",
        };
    }

    const hasAllPermissions = permissions.all === "N";

    return {
        read: hasAllPermissions ? permissions.read === "Y" : true,
        write: hasAllPermissions ? permissions.write === "Y" : true,
        delete: hasAllPermissions ? permissions.delete === "Y" : true,
        modify: hasAllPermissions ? permissions.modify === "Y" : true,
        page: permissions.page || "",
        role: permissions.role || "",
    };
};
