import { Checkbox } from "primereact/checkbox";

const InputCheck = ({
  label,
  id,
  className,
  labelclassName,
  checkboxclassName,
  checked,
  handleChange,
  disabled,
}: {
  label: string;
  id: string;
  className: string;
  labelclassName?: string;
  checkboxclassName?: string;
  checked?: boolean;
  handleChange?: any;
  disabled?: boolean;
}) => {
  return (
    <div className="grid m-0 align-items-center">
      <div className={`${labelclassName ? `${labelclassName}` : "col-6"}`}>
        <label className="font-medium text-sm" htmlFor={id}>
          {label}
        </label>
      </div>
      <div className={`${checkboxclassName ? `${checkboxclassName}` : "col-6"}`}>
        <Checkbox
          className={className}
          id={id}
          onChange={handleChange}
          checked={checked || false}
          disabled={disabled}
        ></Checkbox>
      </div>
    </div>
  );
};

export default InputCheck;
