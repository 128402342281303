// import React, { createContext,useEffect, useState } from "react";
// import Sidebar from "./layouts/Sidebar/Sidebar";
// import Header from "./layouts/Header/Header";
// import { ScrollTop } from "primereact/scrolltop";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
// } from "react-router-dom";
// import { Provider } from "react-redux";
// import { useMsal, useMsalAuthentication } from "@azure/msal-react";
// import { InteractionType } from "@azure/msal-browser";
// import Main from "./router/Router";
// import { store } from "./redux/store";
// import Insurance from "./pages/Insurance/Insurance";
// import ARManager from "./pages/ARManager/ARManager";
// import Home from "./pages/Home/Home";
// import { scrollTop } from "./assets/images/Icon";
// import TaskList from "./pages/TaskList/TaskList";
// import Pricing from "./pages/Pricing/Pricing";
// import FaxDirectory from "./pages/FaxDirectory/FaxDirectory";
// import Inventory from "./pages/Inventory/Inventory";
// import FaxScreen from "./pages/Fax/FaxScreen";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'primeicons/primeicons.css';
// import JunkFaxScreen from "./pages/junk-fax/JunkFaxesScreen";
// import ArchiveFax from "./pages/archive/ArchiveFax";
// import UserDocument from "./pages/document/UserDocument";

// function Layout({ userEmail, name }: { userEmail?: any, name?: any }) {

//   return (
//     <>

//       <Header name={name} />
//       {/* <Container className="custom-container"> */}
//       <div className="sidebar">
//         <Sidebar userEmail={userEmail} />
//       </div>
//       <div className="main-content">
//         <Main />
//       </div>
//       <ScrollTop threshold={100} style={{ backgroundColor: "#1B3D6D" }} className="w-2rem h-2rem border-round-md" icon={() => <img src={scrollTop} alt="ScrollTop" />} />
//       {/* </Container> */}
//     </>
//   );
// }

// const App: React.FC = () => {

//   useMsalAuthentication(InteractionType.Redirect);
//   const [m_strUser, setm_strUser] = useState<string | undefined>("");
//   const [name, setName] = useState<string | undefined>("");
//   // const [loading, setLoading] = useState(true);
//   const { accounts } = useMsal();
//   // const [showSpinner, setShowSpinner] = useState(true);

//   function Render() {
//     const { accounts } = useMsal();

//     try {
//       setm_strUser(accounts[0].username);
//       setName(accounts[0].name);
//     } catch (e) { }
//   }

//   useEffect(() => {
//     if (accounts.length > 0) {
//       setm_strUser(accounts[0].username);
//       setName(accounts[0].name);
//       // setLoading(false);
//       // setTimeout(() => {
//       //   setShowSpinner(false);
//       // }, 5000);
//     }
//   }, [accounts]);
//   // if (loading || showSpinner) {
//   //   return <><ComponentSpinner /></>;
//   // }

//   const [selectedMrn, setSelectedMrn] = useState(null); // State to store the MRN
//   if (m_strUser !== "") {
//     return (
//       <>
//         <Provider store={store}>
//           <Router>
//             <Routes>
//               <Route
//                 path="/"
//                 element={<Layout name={name} userEmail={m_strUser} />}
//               >
//                 <Route path="/" element={<Home />} />
//                 <Route path="/insurance" element={<Insurance />} />
//                 <Route path="/ar-manager" element={<ARManager name={name} userEmail={m_strUser} />} />
//                 <Route path="/pricing" element={<Pricing />} />
//                 <Route path="/tasklist" element={<TaskList />} />
//                 <Route path="/inventory" element={<Inventory />} />
//                 <Route path="/faxdirectory" element={<FaxDirectory name={name} userEmail={m_strUser} />} />
//                 <Route path="/fax" element={<FaxScreen />} />
//                 <Route path="/junk-fax" element={<JunkFaxScreen name={name} userEmail={m_strUser}  />} />
//                 <Route path="/archive-fax" element={<ArchiveFax />} />
//                 <Route path="/user-document/:id" element={<UserDocument userEmail={m_strUser}  />} />
//               </Route>
//             </Routes>
//           </Router>
//         </Provider>
//       </>
//     );
//   } else {
//     return (
//       <>
//         {Render()}

//         <Provider store={store}>
//           <Router>
//             <Routes>
//               <Route path="/" element={<Layout />}>
//                 Authenticating User...
//               </Route>
//             </Routes>
//           </Router>
//         </Provider>
//       </>
//     );
//   }
// }

// export default App

import React, { createContext, useEffect, useState } from "react";
import Sidebar from "./layouts/Sidebar/Sidebar";
import Header from "./layouts/Header/Header";
import { ScrollTop } from "primereact/scrolltop";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Main from "./router/Router";
import { store } from "./redux/store";
import Insurance from "./pages/Insurance/Insurance";
import ARManager from "./pages/ARManager/ARManager";
// import Home from "./pages/Home/Home";
import { scrollTop } from "./assets/images/Icon";
import TaskList from "./pages/TaskList/TaskList";
import Pricing from "./pages/Pricing/Pricing";
import FaxDirectory from "./pages/FaxDirectory/FaxDirectory";
import Inventory from "./pages/Inventory/Inventory";
import FaxScreen from "./pages/Fax/FaxScreen";
import "primeicons/primeicons.css";
import JunkFaxScreen from "./pages/junk-fax/JunkFaxesScreen";
import ArchiveFax from "./pages/archive/ArchiveFax";
import UserDocument from "./pages/document/UserDocument";
import { PatientProvider } from "./pages/Fax/Patientcontex";
import PrivateRoute from "./auth/PrivateRoute";
import Error from "./pages/Error/Error";
import Shipment from "./pages/Shipment/Shipment";
import NotesManager from "./pages/NotesManager/NotesManager";
import Census from "./pages/Census/Census";
import Prescribersorder from "./pages/Prescribersorder/PrescribersorderWorkflow";
import NurseAgency from "./pages/Nurse-Agency/NurseAgency";
import Appointments from "./pages/Appointments/Appointments";
import AddNewPatient from "./pages/Appointments/AddNewPatient";
import PatientProfileLayout from "./pages/Census/PatientProfile/PatientProfileLayout";
import PatientHome from "./pages/Census/PatientProfile/PatientProfilePages/PatientHome";
import PatientPrescribersOrder from "./pages/Census/PatientProfile/PatientProfilePages/PatientPrescribersOrder";
import PatientMedication from "./pages/Census/PatientProfile/PatientProfilePages/PatientMedication";
import PatientOrders from "./pages/Census/PatientProfile/PatientProfilePages/PatientOrders";
import PatientAppointments from "./pages/Census/PatientProfile/PatientProfilePages/PatientAppointments";
import PatientCareplan from "./pages/Census/PatientProfile/PatientProfilePages/PatientCareplan";
import PatientDocuments from "./pages/Census/PatientProfile/PatientProfilePages/PatientDocuments";
import PatientBilling from "./pages/Census/PatientProfile/PatientProfilePages/PatientBilling";
import PatientScheduling from "./pages/Census/PatientProfile/PatientProfilePages/PatientScheduling";
import PatientPreference from "./pages/Census/PatientProfile/PatientProfilePages/PatientPreference";
import PatientHistory from "./pages/Census/PatientProfile/PatientProfilePages/PatientHistory";
import PatientDemographics from "./pages/Census/PatientProfile/PatientProfilePages/PatientDemographics";
import PatientChat from "./pages/Census/PatientProfile/PatientProfilePages/PatientChat";
import Permissions from "./pages/Permissions/Permissions";

function Layout({ userEmail, name }: { userEmail?: any; name?: any }) {
  return (
    <>
      <Header name={name} />
      <div className="sidebar">
        <Sidebar userEmail={userEmail} />
      </div>
      <div className="main-content">
        <Main />
      </div>
      <ScrollTop
        threshold={100}
        style={{ backgroundColor: "#1B3D6D" }}
        className="w-2rem h-2rem border-round-md"
        icon={() => <img src={scrollTop} alt="ScrollTop" />}
      />
    </>
  );
}

interface UserDetailType {
  userEmail?: string;
  userName?: string;
  designation?: string;
}

export const UserDetail = createContext<UserDetailType>({
  userEmail: "",
  userName: "",
  designation: ""
});


const App: React.FC = () => {
  useMsalAuthentication(InteractionType.Redirect);
  const [userDetail, setUserDetail] = useState<UserDetailType>({
    userEmail: "",
    userName: "",
    designation: ""
  });
  const [m_strUser, setm_strUser] = useState<string | undefined>("");
  const [name, setName] = useState<string | undefined>("");
  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      setm_strUser(accounts[0].username);
      setName(accounts[0].name);
      setUserDetail({
        userEmail: accounts[0].username || "",
        userName: accounts[0].name || "",
      });
    }
  }, [accounts]);

  return (
    <Provider store={store}>
      <UserDetail.Provider value={userDetail}>
      <PatientProvider>
        {" "}
        {/* Wrap your app with PatientProvider */}
        <Router>
          <Routes>
            <Route
              path="/"
              element={<Layout name={name} userEmail={m_strUser} />}
            >
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/access-denied" element={<Error />} />
              <Route path="/insurance" element={<PrivateRoute element={<Insurance />} />} />
              <Route path="/ar-manager" element={<PrivateRoute element={<ARManager name={name} userEmail={m_strUser} />} userEmail={m_strUser}/>} />
              <Route path="/census" element={<PrivateRoute element={<Census name={name} userEmail={m_strUser} />} userEmail={m_strUser}/>} />
              <Route path="/pricing" element={<PrivateRoute element={<Pricing />} userEmail={m_strUser}/>} />
              <Route path="/tasklist" element={<PrivateRoute element={<TaskList userDetail={userDetail} />} userEmail={m_strUser}/>} />
              <Route path="/inventory" element={<PrivateRoute element={<Inventory />} userEmail={m_strUser} />} />
              <Route path="/nurse-agency" element={<PrivateRoute element={<NurseAgency  userEmail={m_strUser} />} userEmail={m_strUser} />} />
              <Route path="/notes-manager" element={<PrivateRoute element={<NotesManager name={name} userEmail={m_strUser} />} userEmail={m_strUser} />} />
              <Route path="/faxdirectory" element={<PrivateRoute element={<FaxDirectory name={name} userEmail={m_strUser} />}  userEmail={m_strUser}/>} />
              <Route path="/fax" element={<PrivateRoute element={<FaxScreen />} userEmail={m_strUser} />} />
              <Route path="/fax/junk-fax" element={<PrivateRoute element={<JunkFaxScreen name={name} userEmail={m_strUser}/>} userEmail={m_strUser} />} />
              <Route path="/fax/archive-fax" element={<PrivateRoute element={<ArchiveFax />} userEmail={m_strUser} />} />
              <Route path="/fax/user-document/:id" element={<PrivateRoute element={<UserDocument userEmail={m_strUser} />} userEmail={m_strUser} />} />
              <Route path="/shipment" element={<PrivateRoute element={<Shipment />} userEmail={m_strUser} />} />
              <Route path="/schedule-management" element={<PrivateRoute element={<Appointments name={name} userEmail={m_strUser} />} userEmail={m_strUser}/>} />
              <Route path="/appointments" element={<PrivateRoute element={<AddNewPatient name={name} userEmail={m_strUser} />} userEmail={m_strUser}/>} />
              <Route path="/prescribers-order" element={<PrivateRoute element={<Prescribersorder/>} userEmail={m_strUser} />} />
              <Route path="/permissions" element={<PrivateRoute element={<Permissions/>} userEmail={m_strUser} />} />
              
              {/* The sub-routes will be nested here */}
              <Route
                path="/census/patient-profile/:id"
                element={
                  <PrivateRoute
                    element={<PatientProfileLayout />}
                    userEmail={m_strUser!}
                  />
                }
              >
                <Route
                  path="patient-home"
                  element={
                    <PrivateRoute
                      element={<PatientHome />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-prescribers-order"
                  element={
                    <PrivateRoute
                      element={<PatientPrescribersOrder />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-medication"
                  element={
                    <PrivateRoute
                      element={<PatientMedication />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-orders"
                  element={
                    <PrivateRoute
                      element={<PatientOrders />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-appointments"
                  element={
                    <PrivateRoute
                      element={<PatientAppointments />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-careplan"
                  element={
                    <PrivateRoute
                      element={<PatientCareplan />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-documents"
                  element={
                    <PrivateRoute
                      element={<PatientDocuments />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-billing"
                  element={
                    <PrivateRoute
                      element={<PatientBilling />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-scheduling"
                  element={
                    <PrivateRoute
                      element={<PatientScheduling />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-preferences"
                  element={
                    <PrivateRoute
                      element={<PatientPreference />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-history"
                  element={
                    <PrivateRoute
                      element={<PatientHistory />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-demographics"
                  element={
                    <PrivateRoute
                      element={<PatientDemographics />}
                      userEmail={m_strUser!}
                    />
                  }
                />
                <Route
                  path="patient-chat"
                  element={
                    <PrivateRoute
                      element={<PatientChat />}
                      userEmail={m_strUser!}
                    />
                  }
                />
              </Route>
            </Route>
          </Routes>
        </Router>
      </PatientProvider>
      </UserDetail.Provider>
    </Provider>
  );
};

export default App;
