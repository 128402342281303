import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { Column } from "primereact/column";
import { FormatDate } from "../../../utils/formatDate";
import {
  DiagnosisReport,
  SearchInvoice,
  SortDecre,
  SortIncre,
} from "../../../assets/images/Icon";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {
  getPatinetRxInvoice,
  resetSearchRxInvoice,
} from "../../../redux/store/CensusReducer";

const SearchInvoiceDialogBox = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) => {

  const powerappUrl = process.env.REACT_APP_CENSUS_LINK;

  
  const [selectedOption, setSelectedOption] = useState();
  const [searchValue, setSearchValue] = useState("");
  const searchOptions = [
    { label: "Rx", value: 1 },
    { label: "Invoice", value: 2 },
  ];

  const { patientRxInvoice, invoiceLoading } = useSelector(
    (state: RootState) => state.CensusReducer
  );

  const handleRowClick = (event: any, rowData: any) => {
    window.location.href = (
      `${powerappUrl}&uid=${Number(rowData.mrn).toString(16).toUpperCase()}`
    );
  };

  const dispatch = useDispatch<AppDispatch>();

  const progressColumns = [
    { field: "mrn", header: "MRN", sortable: true },
    { field: "firsT_NAME", header: "First Name", sortable: true },
    { field: "lasT_NAME", header: "Last Name", sortable: true },
  ];

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  const SearchRxInvoice = () => {
    dispatch(
      getPatinetRxInvoice({
        searchData: {
          SearchValues: searchValue,
          SearchType: selectedOption,
          UserType: 1,
        },
      })
    );
  };

  const customHeader = () => {
    return (
      <>
        <div className="dialog-header flex gap-2">
          <div className="header-icon mr-2">
            <img
              className="mt-2"
              src={SearchInvoice}
              alt="Search Rx/Invoice"
            />
          </div>
          <div className="header-title">
            <div className="text-2xl">Search Rx/Invoice</div>
            <div className="text-sm font-medium header-subtitle">
              Streamlined Lookup for Quick Access to Records
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog
        // style={{ width: "1000px", zIndex: 1111 }}
        className="w-10 md:w-9 lg:w-7 xl:w-5"
        draggable
        header={customHeader}
        resizable
        visible={visible}
        onHide={() => {
          setVisible(false);
          setSearchValue("");
          setSelectedOption(undefined);
          dispatch(resetSearchRxInvoice());
        }}
      >
        <div className="grid">
          <div className="col-2">
            <Dropdown
              className="w-full"
              value={selectedOption}
              optionLabel="label"
              onChange={(e) => setSelectedOption(e.value)}
              placeholder="Select Rx/Invoice"
              options={searchOptions}
            />
          </div>
          <div className="col-3">
            <InputText
              value={searchValue}
              disabled={!selectedOption}
              className="pl-1"
              placeholder={`${
                selectedOption === 1
                  ? "Search Rx"
                  : selectedOption === 2
                  ? "Search Invoice"
                  : "Select a Rx or Invoice"
              }`}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className="col-2">
            <Button
              label="Search"
              onClick={SearchRxInvoice}
              disabled={!searchValue}
            />
          </div>
        </div>
        <DataTable
          value={patientRxInvoice}
          emptyMessage="No Data Found"
          scrollable
          removableSort
          scrollHeight="250px"
          sortIcon={sortIcon}
          loading={invoiceLoading}
          onRowClick={(e) => handleRowClick(e, e.data)}
        >
          {progressColumns.map((column, index) => (
            <Column
              key={index}
              field={column.field}
              header={column.header}
              sortable={column.sortable}
            />
          ))}
        </DataTable>
      </Dialog>
    </>
  );
};

export default SearchInvoiceDialogBox;
