import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable'
import { SortDecre, SortIncre } from '../../../assets/images/Icon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useState } from 'react';
import { AddNurseDetail } from '../../../redux/store/AppointmentsReducer';
import { Checkbox } from 'primereact/checkbox';

type AddNurseDatatableProps = {
  filters: any;
  globalFilterFields: string[];
};

const AddNurseDatatable = ({ filters, globalFilterFields }: AddNurseDatatableProps) => {

  const { addNurseDetail } = useSelector((state: RootState) => state.AppointmentsReducer);

  const [rowClick, setRowClick] = useState(false);
  const [checked, setChecked] = useState<AddNurseDetail[]>([]);

  const onRowSelectChange = (e: { value: AddNurseDetail[] }) => {
    setChecked(e.value.length > 0 ? [e.value[0]] : []); // Allow only one row to be checked
  };
  const isCheckboxDisabled = (rowData: AddNurseDetail) => {
    return (
      checked.length > 0 &&
      !checked.some((selected) => selected.nurse_id === rowData.nurse_id)
    );
  };
  // const dispatch = useAppDispatch();

  // const location = useLocation();
  // // get nurse details
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const mrn = searchParams.get('mrn');  // Extract the 'mrn' value
  //   dispatch(getAddNurseDetail({ mrn: mrn }));
  // }, []);

  const pageOptions = [5, 10, 25, 50, 75, 100, 125];
  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };
  const columns = [
    { field: "first_name", header: "Nurse", sortable: true },
    { field: "agency_name", header: "Agency", sortable: true },
    // { field: "mrn", header: "Phone", sortable: true },
    // { field: "patient_name", header: "Distance", sortable: true }
  ];

  return (<>
    <div className='add-agency'>
      <DataTable
        // value={addNurseDetail}
        // showGridlines
        // scrollable
        // removableSort
        // sortIcon={sortIcon}
        // scrollHeight="580px"
        // virtualScrollerOptions={{ itemSize: 50 }}
        // // paginator
        // rowsPerPageOptions={pageOptions}
        // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        // rows={25}
        // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        // globalFilterFields={columns.map((column) => column.field)}
        // emptyMessage="No Data Found"
        // rowHover
        // onRowClick={(e) => { }} // Handle row click
        
        scrollable
        removableSort
        scrollHeight="580px"
        value={addNurseDetail}
        sortIcon={sortIcon}
        selection={checked}
        onSelectionChange={onRowSelectChange}
        selectionMode={rowClick ? null : "checkbox"}
        virtualScrollerOptions={{ itemSize: 50 }}
        filters={filters}
        globalFilterFields={globalFilterFields}
        emptyMessage="No Records found."
      // onRowSelect={(e) => onRowSelect(e.data)}


      >
        {/* {columns.map((column, index) => (
        <Column
          key={index}
          field={column.field}
          header={column.header}
          sortable={column.sortable}
        />
      ))} */}
        <Column
          body={(rowData) => (
            <Checkbox
              checked={checked.some((selected) => selected.nurse_id === rowData.nurse_id)}
              onChange={(e: any) => onRowSelectChange({ value: e.checked ? [rowData] : [] })}
              disabled={isCheckboxDisabled(rowData)}
            />
          )}
          headerStyle={{ width: "3rem" }}
        />
        <Column field='first_name' sortable header="Nurse" />
        <Column field='agency_name' sortable header="Agency" />
        <Column field='phone' sortable header="Phone" />
        <Column field='distance' sortable header="Distance" />
      </DataTable>
    </div>
  </>
  )
}

export default AddNurseDatatable
