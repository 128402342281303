import React from "react";
import { Dialog } from "primereact/dialog";
import { DiagnosisReport } from "../../../assets/images/Icon";

const report = process.env.REACT_APP_NURSE_AGENCY_POWERBI_REPORT;

const AgencyMapViewDialogBox = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) => {

  const customHeader = () => {
    return (
      <>
        <div className="dialog-header flex gap-2">
          <div className="header-icon mr-2">
            <img
              className="mt-2"
             src={DiagnosisReport}
              alt="Map View"
            />
          </div>
          <div className="header-title">
            <div className="text-xl">Map View</div>
            <div className="text-sm font-medium header-subtitle">
              Advanced Health Insights Driven by AI Technology
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Dialog
      style={{ width: "1000px", zIndex: 1111, height:"500px" }}
      className="map-view"
      header={customHeader}
      visible={visible}
      draggable={false}
      onHide={() => setVisible(false)}
    >
      <iframe
        title="Power BI Report"
        src={report}
        style={{
          width: "100%",
          border: "none",
        }}
        allowFullScreen
      />
    </Dialog>
  );
};

export default AgencyMapViewDialogBox;