import { Chart } from "primereact/chart";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { formatCurrency } from "../../../utils/formatCurrency";
import { SortDecre, SortIncre } from "../../../assets/images/Icon";

type ItemType = {
  payor?: string;
  patient_name?: string;
  payor_counts: number;
  isSelected?: boolean;
};

const ArManagerCountDialog = ({
  visible,
  setVisible,
  dataType,
}: {
  visible: boolean;
  setVisible: React.Dispatch<SetStateAction<boolean>>;
  dataType: string | null;
}) => {
  const { payorCountData, patientCountData } = useSelector(
    (state: RootState) => state.arManagerReducer
  );

  const displayData: ItemType[] =
    dataType === "payor" ? payorCountData : patientCountData;

  const [selectedItems, setSelectedItems] = useState<ItemType[]>(displayData);

  useEffect(() => {
    setSelectedItems(displayData);
  }, [displayData]);

  const colors = selectedItems.map(
    (data: any) => `hsl(${(data.count * 137.5) % 360}, 70%, 70%)`
  );

  const chartData = {
    labels: selectedItems
      .filter((item) => !item.isSelected)
      .map((item) => (dataType === "payor" ? item.payor : item.patient_name)),
    datasets: [
      {
        label: "Counts",
        data: selectedItems
          .filter((item) => !item.isSelected)
          .map((item) => item.payor_counts),
        backgroundColor: colors.filter(
          (data: any) => !selectedItems[data.count]?.isSelected
        ),
        fill: true,
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  const handleRowClick = (e: any) => {
    const rowData = e.data;
    const updatedItems = selectedItems.map((item) =>
      item === rowData ? { ...item, isSelected: !item.isSelected } : item
    );
    setSelectedItems(updatedItems);
  };

  return (
    <Dialog
      style={{ width: "70%" }}
      header={dataType === "payor" ? "Summary of Payor" : "Summary of Patients"}
      resizable={false}
      draggable={false}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <div className="flex gap-2 justify-content-between">
        <div className="w-7">
          <Chart
            type="doughnut"
            width="753px"
            height="584px"
            data={chartData}
            options={chartOptions}
          />
        </div>

        <div className="w-5">
          <DataTable
            value={selectedItems}
            scrollable
            sortIcon={sortIcon}
            scrollHeight="584px"
            className="p-datatable-dialog"
            onRowClick={handleRowClick}
          >
            <Column
              field="count"
              header="Legend"
              body={(e) => (
                <span
                  style={{
                    display: "inline-block",
                    width: "15px",
                    height: "15px",
                    backgroundColor: colors[e.count],
                  }}
                />
              )}
              sortable
            />
            <Column
              field={dataType === "payor" ? "payor" : "patient_name"}
              header={dataType === "payor" ? "Payor" : "Patient Name"}
              body={(rowData: ItemType) => (
                <span
                  style={{
                    textDecoration: rowData.isSelected
                      ? "line-through"
                      : "none",
                    cursor: "pointer",
                  }}
                >
                  {dataType === "payor" ? rowData.payor : rowData.patient_name}
                </span>
              )}
              sortable
            />
            <Column field="payor_counts" header="Payor Counts" sortable />
            {dataType === "payor" && (
              <Column
                field="total_balance"
                header="Total Balance"
                body={(rowData: any) => (
                  <span>{formatCurrency(rowData.total_balance)}</span>
                )}
                sortable
              />
            )}
          </DataTable>
        </div>
      </div>
    </Dialog>
  );
};

export default ArManagerCountDialog;
