import React, { useState } from "react";
import InputDropdown from "../formElement/InputDropdown";
import InputTextArea from '../formElement/InputTextArea'
import Input from "../formElement/Input";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

 
interface AddInventoryDialogBoxProps {
  header2: string;
  visible2: boolean;
  onHide: () => void;
}
 
const AddChangeTransferDialogbox: React.FC<AddInventoryDialogBoxProps> = ({
  header2,
  visible2,
  onHide,
  
}) => {

  const { tasklist } = useSelector((state: RootState) => state.tasklistReducer);
  // Form state
  const [formData, setFormData] = useState({
    purchaseDate: "",
    cost: "",
    lotNumber: "",
    expDate: "",
    quantity: "",
    site: "",
    serialNumber: "",
    suppliers: "",
      notes:''
  });
  
 
  const sites = [
    { label: "Site A", value: "Site A" },
    { label: "Site B", value: "Site B" },
  ];
 
  const suppliers = [
    { label: "Supplier 1", value: "Supplier 1" },
    { label: "Supplier 2", value: "Supplier 2" },
  ];
 
  // Reset Form
  const resetForm = () => {
    setFormData({
      purchaseDate: "",
      cost: "",
      lotNumber: "",
      expDate: "",
      quantity: "",
      site: "",
      serialNumber: "",
      suppliers: "",
      notes:''
    });
  };
 
  // Handle Save Button
  const handleSave = () => {
    onHide(); // Close dialog after save
  };
 
  return (
    <Dialog
      className="Add-inventory-dialog"
      header={header2}
      visible={visible2}
      onHide={onHide}
      style={{ width: "40vw" }}
      draggable={false} 
    >
      {header2 === "Change Quantity" ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="p-fluid" style={{ width: "20vw" }}>
              {/* Purchase Date */}
              <div className="p-field changeRantal">
                <Input
                  id="Current on-hand quantity"
                  label="Current on-hand quantity"
                  type="text"
                   labelclassName="Add-inventoryplus-lable col-6"
                  value={formData.purchaseDate}
                  handleChange={(e) =>
                    setFormData({ ...formData, purchaseDate: e.target.value })
                  }
                />
              </div>
 
              {/* Cost */}
              <div className="p-field changeRantal">
                <Input
                  id="Amount to be added/ subtracted"
                  label="Amount to be added/ subtracted"
                  type="text"
                   labelclassName="Add-inventoryplus-lable col-6"
                  value={formData.cost}
                  handleChange={(e) =>
                    setFormData({ ...formData, cost: e.target.value })
                  }
                />
              </div>
 
              {/* Lot# */}
              <div className="p-field changeRantal">
                <Input
                  id="Reason for change"
                  label="Reason for change"
                  type="text"
                   labelclassName="Add-inventoryplus-lable col-6"
                  value={formData.lotNumber}
                  handleChange={(e) =>
                    setFormData({ ...formData, lotNumber: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div
            className="Inventry-chip"
            style={{ padding: "10px", width: "35vw" }}
          >
            <div>
              <strong>AWP/Pack:</strong> $2.39
            </div>
            <div>
              <strong>AWP/Each:</strong> $0.10
            </div>
            <div>
              <strong>Cost/Pack:</strong> $1.74
            </div>
            <div>
              <strong>Cost/Each:</strong> $0.07
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="grid  changeRantalTop  inventory-grid">
            <div className="col-12">
              <label>
                <span className="changeRantalTop">Source Lot Information:</span>
              </label>
            </div>
            <div className="col-6">
              <div className="flex justify-content-evenly">
                <label className="transfer-lable">Lot number:</label>
                <span>NAACD25</span>
              </div>
            </div>
            <div className="col-6">
              <div className="flex justify-content-evenly">
                <label>Quantity:</label>
                <span>200.0000</span>
              </div>
            </div>
            <div className="col-6">
              <div className="flex justify-content-evenly">
                <label>Expiration date:</label>
                <span>04/30/2026</span>
              </div>
            </div>
            <div className="col-6">
              <div className="flex  justify-content-evenly">
                <label>Site:</label>
                <span>Cumberland</span>
              </div>
            </div>
          </div>
          <div className="p-fluid">
            <hr />
            <div className="p-field changeRantal">
              <InputDropdown
                id="How many unite would you like to transfer?"
                label="How many unite would you like to transfer?"
                type="text"
                 labelclassName="Add-inventoryplus-lable col-6"
                options={sites}
                value={formData.site}
                handleChange={(e) =>
                   setFormData({ ...formData, site: e.target.value })
                }
                dropdownclassName="col-4 "
              />
            </div>
            <div className="p-field changeRantal ">
              <InputDropdown
                id="What site would you like to transfer them to?"
                label="What site would you like to transfer them to?"
                type="text"
                 labelclassName="Add-inventoryplus-lable col-6"
                options={suppliers}
                value={formData.suppliers}
                handleChange={(e) =>
                  setFormData({ ...formData, suppliers: e.target.value })
                }
                dropdownclassName="col-4 "
              />
            </div>
            <div className="p-field changeRantal">
               <InputTextArea
                 id="Transfer memo"
                 label="Transfer memo (100 characters max):"
                  labelclassName="Add-inventoryplus-lable col-12"
                  inputclassName="Add-inventoryplus-lable col-12"
                
                 value={formData.notes}
                rows={3}
                
                //maxLength={100}
                placeholder="Enter memo"
                handleChange={(e) =>
                  setFormData({ ...formData,notes: e.target.value })
                }
                
                
              />
            </div>
          </div>
        </>
      )}
 
      {/* Buttons */}
      <div className="  inventory-grid2 m-0 align-items-center">
        <Button
          label="Clear"
          className={`inventory-button-secondary  m-2  col-4  `}
          onClick={resetForm}
        />
 
        <Button
          label="Save"
          className={`inventory-foter-button   m-2  col-4 `}
          onClick={handleSave}
        />
      </div>
    </Dialog>
  );
};
 
export default AddChangeTransferDialogbox;