// // ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../../utils/api/index";
import { GetCustomFilterList } from "./ARManagerReducer";

const baseUrl = process.env.REACT_APP_BASEURL;
// interface TaskSite {
//     id: number;
//     site: string;
//   }

export type Task = {
  assign_to: number; // Assuming this is always a string representation of a number
  assigned_by_name: string;
  assigned_to_name: number;
  category: string;
  category_id: string;
  comment: string;
  created_by: string;
  created_date: string;
  due_date: string;
  is_current: boolean;
  is_delete: boolean;
  is_exported_to_pn: boolean | null;
  mrn: string;
  patient_first_name: string;
  patient_full_name: string;
  patient_id: number;
  patient_last_name: string;
  patient_todo_id: number;
  site_id: number;
  site_name: string;
  status_description: string;
  status_id: number;
  task_detail: string;
  teams: string;
  therapy_type: string;
};

interface TaskState {
  tasklist: any;
  totalRecord: number;
  favouriteFilters: any;
  errorMessage: string;
  loading: boolean;
  siteList: any;
  stateListInsuranceListing: any;
  sites: Array<{
    id: number;
    site: string;
  }>;
  sitesOptTask: any;
  statusList: any;
  searchResults: any;
  category: any;
  teams: any;
  assignTo: any;
  assignBy: any;
  TaskFilterList: any;
  filterloading: boolean;
}

interface Status {
  status_id: number;
  status_description: string;
}

const favouriteFilters = [
  {
    id: 1,
    name: "active status",
    filters: { global: "100", status: [{ label: "Active", value: "Active" }] },
  },
  {
    id: 2,
    name: "category filter",
    filters: {
      global: "s",
      category: [
        { label: "ABC", value: "ABC" },
        { label: "DEF", value: "DEF" },
      ],
    },
  },
  {
    id: 3,
    name: "team search",
    filters: {
      team: [
        { label: "JKL", value: "JKL" },
        { label: "STU", value: "STU" },
      ],
    },
  },
];

const initialState: TaskState = {
  tasklist: [],
  totalRecord: 0,
  favouriteFilters: favouriteFilters,
  errorMessage: "",
  loading: false,
  siteList: [],
  stateListInsuranceListing: [],
  sites: [],
  sitesOptTask: [],
  statusList: [],
  searchResults: [],
  category: [],
  teams: [],
  assignTo: [],
  assignBy: [],
  TaskFilterList: [],
  filterloading: false,
};

export const getPatientToDoList = createAsyncThunk(
  "tasklist/getPatientToDoList",
  async ({ SearchString }: { SearchString: any }) => {
    try {
      const endpoint = `${baseUrl}/api/ToDo/GetPatientToDoList`;

      const requestBody = {
        jsonInput: JSON.stringify(SearchString.jsonString || ""),
        pageNumber: `${(SearchString?.pageNumber || 0) + 1}`,
        pageSize: `${SearchString?.pageSize}` || "20",
        sortBy: SearchString?.sortBy || "",
        sortDirection: SearchString?.sortDirection || "",
      };

      const response = await axios.post(endpoint, requestBody);

      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getSiteListTask = createAsyncThunk(
  "insurance/getSiteListTask",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/getSiteList`;

      const response = await axios.get(endpoint);

      return response.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GetCategory = createAsyncThunk(
  "tasklist/GetCategory",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ToDo/GetCategory`;

      const response = await axios.get(endpoint);

      return response.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const Getteams = createAsyncThunk("tasklist/Getteams", async () => {
  try {
    const endpoint = `${baseUrl}/api/ToDo/GetTeams`;
    const response = await axios.get(endpoint);

    return response.data?.data;
  } catch (error) {
    throw error;
  }
});

export const GetassignTo = createAsyncThunk(
  "tasklist/GetassignTo",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ToDo/GetAssignTo`;
      const response = await axios.get(endpoint);

      return response.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GetassignBy = createAsyncThunk(
  "tasklist/GetassignBy",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ToDo/GetUserList`;
      const response = await axios.get(endpoint);

      return response.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getStatusOptionList = createAsyncThunk(
  "tasklist/getStatusOptionList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/ToDo/GetStatusDescription`;

      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data.data;
      return data;
    } catch (error) {
      //  console.error("Error fetching line item parent", error);
      throw error;
    }
  }
);

// Patient search
export const searchPatients = createAsyncThunk(
  "tasklist/searchPatients",
  async (query: string) => {
    try {
      const endpoint = `${baseUrl}/api/FaxModule/getSearchPatienList?json=${query}`;
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
        },
        credentials: "same-origin",
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      return data.patients;
    } catch (error) {
      throw error;
    }
  }
);

export const addTask = createAsyncThunk(
  "tasklist/addTask",
  async (
    { updateData, callback }: { updateData: any; callback: any }
  ) => {
    try {
      const endpoint = `${baseUrl}/api/ToDo/AddUpdateToDoPatient`;
      const response = await axios.post(endpoint, updateData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data) {
        callback(response);
      }
    } catch (error: any) {
      console.error("Error fetching user details", error);
      callback(error.response)
    }
  }
);

export const tasklistSlice = createSlice({
  name: "tasklist",
  initialState,
  reducers: {
    resetTasklist: (state) => {
      state.tasklist = [];
      state.totalRecord = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchPatients.pending, (state) => {
        state.loading = true;
        state.errorMessage = "";
      })
      .addCase(
        searchPatients.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.searchResults = action.payload; // Store the search results
        }
      )
      .addCase(searchPatients.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage =
          action.error.message || "Failed to fetch patient data";
      })

      .addCase(getSiteListTask.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSiteListTask.fulfilled, (state, action) => {
        state.siteList = action.payload; // Corrected this line
        state.sitesOptTask = action.payload; // Corrected this line
        state.loading = false;
      })
      .addCase(getSiteListTask.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getStatusOptionList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getStatusOptionList.fulfilled, (state, action) => {
        const filteredStatuses = action.payload.filter((status: Status) =>
          ["Assigned" /*3*/, "Completed" /*5*/, "Pending" /*11*/].includes(
            status.status_description
          )
        );

        state.statusList = filteredStatuses;
        state.loading = false;
      })
      .addCase(getStatusOptionList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })

      .addCase(getPatientToDoList.pending, (state) => {
        state.loading = true;
        state.errorMessage = "";
      })
      .addCase(getPatientToDoList.fulfilled, (state, action) => {
        state.loading = false;
        state.tasklist = action.payload?.data.listDTO || [];
        state.totalRecord = action.payload?.data.recordCount || [];
      })
      .addCase(getPatientToDoList.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage =
          action.error.message || "Failed to fetch task list";
      })
      /// for category
      .addCase(GetCategory.pending, (state) => {
        state.loading = true; // Set loading to true when the request starts
        state.errorMessage = ""; // Clear any previous error message
      })
      .addCase(GetCategory.fulfilled, (state, action) => {
        state.loading = false; // Request completed
        state.category = action.payload || []; // Assign the fetched data to tasklist
      })
      .addCase(GetCategory.rejected, (state, action) => {
        state.loading = false; // Request completed
        state.errorMessage =
          action.error.message || "Failed to fetch task list"; // Set error message
      })

      /// for temas
      .addCase(Getteams.pending, (state) => {
        state.loading = true; // Set loading to true when the request starts
        state.errorMessage = ""; // Clear any previous error message
      })
      .addCase(Getteams.fulfilled, (state, action) => {
        state.loading = false; // Request completed
        state.teams = action.payload || []; // Assign the fetched data to tasklist
      })
      .addCase(Getteams.rejected, (state, action) => {
        state.loading = false; // Request completed
        state.errorMessage =
          action.error.message || "Failed to fetch task list"; // Set error message
      })

      /// for assign to
      .addCase(GetassignTo.pending, (state) => {
        state.loading = true; // Set loading to true when the request starts
        state.errorMessage = ""; // Clear any previous error message
      })
      .addCase(GetassignTo.fulfilled, (state, action) => {
        state.loading = false; // Request completed
        state.assignTo = action.payload || []; // Assign the fetched data to tasklist
      })
      .addCase(GetassignTo.rejected, (state, action) => {
        state.loading = false; // Request completed
        state.errorMessage =
          action.error.message || "Failed to fetch task list";
      })

      /// for assign to
      .addCase(GetassignBy.pending, (state) => {
        state.loading = true;
        state.errorMessage = "";
      })
      .addCase(GetassignBy.fulfilled, (state, action) => {
        state.loading = false; // Request completed
        state.assignBy = action.payload || []; // Assign the fetched data to tasklist
      })
      .addCase(GetassignBy.rejected, (state, action) => {
        state.loading = false; // Request completed
        state.errorMessage =
          action.error.message || "Failed to fetch task list"; // Set error message
      })
      .addCase(GetCustomFilterList.pending, (state, action) => {
        state.filterloading = true;
      })
      .addCase(GetCustomFilterList.fulfilled, (state, action) => {
        state.TaskFilterList = action.payload?.data;
        state.filterloading = false;
      })
      .addCase(GetCustomFilterList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.filterloading = false;
      })
      .addCase(addTask.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addTask.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addTask.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      });
  },
});

export const { resetTasklist } = tasklistSlice.actions
export default tasklistSlice.reducer;