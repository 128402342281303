import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch } from '../../redux/store';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
    splitAndAssign,
    getSearchPatientList,
    getDocumentCatogory,
    getDocumentDescription,
    getFaxDocDrugList,
    getSites,
    makeAssignFaxListEmpty,
} from '../../redux/store/FaxSliceReducer';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import "../../assets/css/form.css";
import { AutoComplete } from 'primereact/autocomplete';
import { usePatientContext } from '../Fax/Patientcontex';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { useRef } from "react";
import axios from "axios";

function DocumentUserModal({
    checkList,
    setCheckList,
    selectedPatientDetails,
    setSelectedPatientDetails,
    faxDetail,
    setIsUserModal,
    // selectedIndex,
    userEmail,
    selectedPatientm,
    setSelectedPatientm,
    setIsEmailModal,
    description,
    setDescription,
    selectedCategory,
    setSelectedCategory,
    notes,
    setNotes,
    selectedPatientData,
    setSelectedPatientData,
    emailData,
    setEmailData,
    isSendEmail,
    setIsSendEmail,
    fromAndTo,
    setFromAndTo,
    setErrorFields,
}) {
    // const { selectedPatientmrn, setSelectedPatientmrn } = usePatientContext();

    const [filteredPatients, setFilteredPatients] = useState([]);

    const handleDescriptionChange = (e) => {
        setDescription(e.value); // Update local state
    };

    const cancelTokenRef = useRef(null);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    // const { address, documentItem } = useSelector((state) => state.FaxSliceReducer);
    const { DocumentDescription, documentItem, categoryOptions, assign_fax, assignLoading, assignFaxLoading } = useSelector((state) => state.FaxSliceReducer);

    // useEffect(() => {
    //     dispatch(GetAddressMasterInfo());  // Dispatch the thunk
    // }, [dispatch]);

    const toggle = (rowData) => {
        setIsUserModal(false);
    };

    useEffect(() => {
        // Dispatch action to fetch categories on component mount
        const { data } = dispatch(getDocumentCatogory());
    }, [dispatch]);

    useEffect(() => {
        const { data } = dispatch(getDocumentDescription())
    }, [dispatch]);

    useEffect(() => {
        if (!selectedPatientDetails) {
            dispatch(makeAssignFaxListEmpty());
        }
    }, [selectedPatientDetails]);

    const validationSchema = Yup.object({
        // mrn: Yup.string().required("Select Patient"),
        // description: Yup.string().required("Select Description"),
        // notes: Yup.string(),
        // fileName: Yup.string(),
        // firstName: Yup.string().required("First Name is required"),
        // lastName: Yup.string().required("Last Name is required"),
        // ssn: Yup.string().required("SSN is required"),
        // dob: Yup.string().required("DOB is required"),
        // state: Yup.string().required("State is required"),
        // site: Yup.string().required("Site is required"),
        // city: Yup.string().required("City is required"),
        // zip: Yup.string().required("Zip is required"),
    });

    const initialValues = {
        // from: 1,
        // to: numPages,
        mrn: documentItem?.mrn || "",
        category: documentItem?.category || "",
        description: description,
        notes: documentItem?.notes || "",
        fileName: documentItem?.fileName || "",
        firstName: "",
        lastName: "",
        ssn: "",
        dob: "",
        state: "",
        site: "",
        city: "",
        zip: "",
        note: ""
    };

    const renderError = (message) => <small className="help text-danger m-0">{message}</small>;

    const changeCheckBox = (ind) => {

        const newData = assign_fax.map((_, index) => {
            if (ind === index) {
                return true;
            } else {
                return false;
            }
        });
        setCheckList(newData);
    };

    const checkbox = (_, ind) => {
        return (
            <Checkbox checked={checkList[ind.rowIndex]} onChange={() => changeCheckBox(ind.rowIndex)} />
        );
    };

    const dropdownOptions = useMemo(() => {
        return [...DocumentDescription]
            .sort((a, b) => a.document_description.trim().localeCompare(b.document_description.trim()))
            .map(Description => ({
                label: Description.document_description.trim(),
                id: Description.document_id,
                formid: Description.form_id
            }));
    }, [DocumentDescription]);

    const getBackground = (status) => {
        switch (status) {
            case "Inactive": return "red";
            case "Cancelled": return "orange";
            case "Active": return "green";
            case "On Hold": return "blue";
            case "Pending": return "yellow";
            default: return "transparent";
        }
    };

    // useEffect(() => {
    //     dispatch(getSearchPatientList())
    // }, [selectedPatientm]);

    const searchPatients = (query) => {

        if (cancelTokenRef.current) {
            cancelTokenRef.current?.cancel("Operation canceled due to a new request.");
        }

        // if (!query || query?.length < 3) return;

        cancelTokenRef.current = axios.CancelToken.source();

        dispatch(getSearchPatientList({ query, mail: userEmail, cancelToken: cancelTokenRef.current?.token }))
            .unwrap()
            .then((response) => {
                // Check if response contains an array of patients
                if (Array.isArray(response)) {
                    // Map the data to the required format for the AutoComplete
                    const formattedPatients = response?.map((patient) => ({
                        mrn: patient.mrn, // Ensure these properties exist in your response
                        first_name: patient.first_name,
                    }));
                    setFilteredPatients(response); // Use the array response
                } else {
                    setFilteredPatients([]); // Default to empty array if not an array
                }
            })
            .catch((error) => {
                console.error('Error fetching patients:', error);
            });
    };

    const onPatientSelect = (e) => {

        // const item = fulldata.filter((data) => data.mrn === e.value.mrn)

        setSelectedPatientDetails(`${e.value?.full_name} - ${e.value?.mrn}`);

        // Update the MRN and Name in state
        // const currentDescription = selectedDescription;
        // setSelectedPatientmrn({
        //     mrn: e.value.mrn,
        //     name: e.value.first_name,
        //     desc: currentDescription,
        //     pat_first_name: e.value.first_name
        // });
        setSelectedPatientData(e.value);
        // Dispatch action to get fax document list
        dispatch(getFaxDocDrugList(e.value.mrn));

        // Set the input field value to the selected patient's name
        setSelectedPatientm(e.value.first_name); // Update this line to keep the selected name in the input

    };

    const handleEmailCheckboxChange = (e) => {
        setIsSendEmail(e.checked);
        if (e.checked) {
            setIsEmailModal(true);
            setEmailData((prev) => ({
                ...prev,
                body: `Patient: ${selectedPatientData?.last_name}, ${selectedPatientData?.first_name}`,
                subject: `${selectedPatientData?.mrn}: ${description?.label}`
            }));
            setErrorFields((prev) => ({ ...prev, subject: false }));
        } else {
            setEmailData((prev) => ({
                ...prev,
                body: ``,
                subject: ``
            }));
        }
    };

    const handleSubmitFunction = (data) => {
        let isValid = true;
        setErrorFields({ to: false, subject: false });
        const findSelectedIndex = checkList.findIndex((val) => val === true);

        const selectedListItem = assign_fax[findSelectedIndex];
        // const newToEmail = isSendEmail ? emailData?.to?.map((val) => val.mail) : [];
        const newToEmail = isSendEmail && (emailData?.to?.length > 0) ? emailData?.to?.map((val) => val.mail) : [];

        if (isSendEmail) {
            if (!newToEmail || newToEmail.length === 0) {
                isValid = false;
                setErrorFields((prev) => ({ ...prev, to: true }));
            }
            if (!emailData.subject) {
                isValid = false;
                setErrorFields((prev) => ({ ...prev, subject: true }));
            }
            if (!isValid) {
                setIsEmailModal(true);
                return;
            }
        }

        const newBody = `
            <div>
                ${emailData.body}
                <br />
                <br />
                <div class="footer" style="text-align: left; font-size: 14px; border-top: 2px solid #d2d2d2; margin-top: 10px;">
                    <i>This email is sent from an unmonitored mailbox. Please do not reply.</i>
                    <p class="disclaimer" style="margin-top: 20px; font-size: 7.5pt; color: #666;">
                        The information included in this email is confidential. It is intended for the use of the individual or entity addressed to above. If you are not the intended recipient or an agent of the intended recipient, you are hereby notified that any distribution or copying of this information is strictly prohibited. If you have received this email in error, please notify InfuCare Rx immediately at 1-877-828-3940 to arrange for the return of the original document as well as deleting the information.
                    </p>
                </div>
            </div>
        `
        const newCcEmail = isSendEmail && (emailData?.cc?.length > 0) ? emailData?.cc?.map((val) => val.mail) : [];

        const documentData = {
            document_description: description?.label, // Dropdown description label
            document_type: "FAX", // Static
            doc_key: selectedListItem?.no ? `${selectedListItem?.no}` : "0", // From selected checkbox of list
            mrn: selectedPatientData.mrn, // From selected patient
            pat_first_name: selectedPatientData?.first_name, // From selected patient
            pat_last_name: selectedPatientData?.last_name, // From selected patient
            notes: notes, // From notes field
            site_no: selectedPatientData?.site_no, // From selected patient
            original_filePath: faxDetail?.file_path, // From fax detail
            doc_status: "9", // Static
            category: selectedCategory?.document_category, // From selected dropdown
            faxsrc: "RX", //Static
            faxsite: "0", // Static
            doc_no: selectedListItem?.ph_No ? `${selectedListItem.ph_No}` : "0", // Select ph_no from list and if not then pass 0
            email_address: userEmail, // Logged in user email
            folder: faxDetail?.fax_folder_name, // From fax detail
            pageRange: `${fromAndTo.from}-${fromAndTo.to}`, // From selected pages of PDF
            remaining_DOC_count: "0", // Static
            doC_NAME: faxDetail?.file_name, // From fax detail
            fax_folder_id: `${faxDetail?.fax_folder_id}`, // From fax detail
            type: "2", // Static
            detail_id: `${faxDetail?.fax_detail_id}`,
            send_mail: isSendEmail, // From checkbox of 'Check to Send email'
            sendMailAssign: {
                To: isSendEmail ? newToEmail?.join(",") : "", // Value from email fields
                CC: isSendEmail ? newCcEmail?.join(",") : "", // Value from email fields
                Subject: isSendEmail ? emailData.subject : "", // Value from email fields
                Folder: faxDetail?.fax_folder_name, // From fax detail
                File: "", // From fax detail
                EmailBody: isSendEmail ? newBody : "", // Value from email fields
                SplitPath: "1" // Static
            }
        };
        dispatch(splitAndAssign({ data: documentData, callback: () => navigate("/fax") }));
    };

    const getUserFromMrn = () => {
        dispatch(getSearchPatientList({ query: faxDetail.mrn, mail: userEmail }))
            .unwrap()
            .then((response) => {
                if (Array.isArray(response)) {
                    setSelectedPatientDetails(`${response[0]?.full_name} - ${response[0]?.mrn}`);
                    setSelectedPatientData(response[0]);
                    dispatch(getFaxDocDrugList(response[0]?.mrn));
                    setSelectedPatientm(response[0]?.first_name);
                }
            })
            .catch((error) => {
                console.error('Error fetching patients:', error);
            });
    };

    useEffect(() => {
        if (faxDetail.fax_line === "Prescriber Portal") {
            getUserFromMrn();
        }
    }, [faxDetail]);

    return (
        <div className="user-document-modal shadow">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    handleSubmitFunction(values);
                    resetForm();
                    // setSelectedDescription(values.description); // Update the description in the parent state
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="custom-form email-form fax-form">
                            <div className="grid align-items-center">
                                <div className="lg:col-3 xs:col-4 col-12">
                                    {/* <div className="grid field mb-0">
                                        Selected Pages:
                                    </div>
                                    <div className="grid field mb-0">
                                        <Field
                                            placeholder="Selected pages"
                                            value={selectedIndex.join(", ")}
                                            name="selected"
                                            type="text"
                                            className="form-control"
                                            readOnly
                                        />
                                        {selectedPageErr && <small className="help text-danger m-0">{selectedPageErr}</small>}
                                    </div> */}
                                    <div className="grid align-items-center mb-2">
                                        <div className="lg:col-4 col-12 pr-1">
                                            <div className="field mb-0 text-end">
                                                From:
                                            </div>
                                        </div>
                                        <div className="lg:col-8 col-12 ps-0">
                                            <div className="field mb-0 ">
                                                <InputText
                                                    placeholder="From"
                                                    value={fromAndTo.from}
                                                    type="number"
                                                    className="from-and-to mb-0"
                                                    min="1"
                                                    // max={fromAndTo?.to}
                                                    onChange={(e) => {
                                                        setFromAndTo((prev) => ({
                                                            ...prev,
                                                            from: e.target.value,
                                                        }));
                                                    }}
                                                />
                                                {/* {selectedPageErr && <small className="help text-danger m-0">{selectedPageErr}</small>} */}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="grid align-items-center">
                                        <div className="lg:col-4 col-12 pe-1">
                                            <div className="field mb-0 text-end">
                                                To:
                                            </div>
                                        </div>
                                        <div className="lg:col-8 col-12 ps-0">
                                            <div className="field mb-0 text-end">
                                                <InputText
                                                    placeholder="To"
                                                    value={fromAndTo.to}
                                                    type="number"
                                                    className="from-and-to mb-0"
                                                    min={fromAndTo?.from}
                                                    // max={faxDetail?.pages}
                                                    onChange={(e) => {
                                                        setFromAndTo((prev) => ({
                                                            ...prev,
                                                            to: e.target.value,
                                                        }));
                                                    }}
                                                />
                                                {/* {selectedPageErr && <small className="help text-danger m-0">{selectedPageErr}</small>} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:col-9 xs:col-8 col-12">
                                    {/* <div className="row align-items-center">
                                        <div className="">
                                            Patient Name - MRN
                                        </div>
                                    </div> */}
                                    <div className="grid align-items-center">
                                        <div className="md:col-4 col-12 mb-2" md="6">
                                            <div className="">
                                                Patient Name - MRN
                                            </div>
                                        </div>
                                        <div className="md:col-8 col-12">
                                            {selectedPatientDetails ? (
                                                <div style={{ marginBottom: '10px', fontSize: "14px" }}>
                                                    {selectedPatientDetails}
                                                </div>
                                            ) : (
                                                <div style={{ marginBottom: '10px', fontSize: "14px" }}>
                                                    -
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="grid mt-2">
                                        <div className="md:col-4">
                                        </div>
                                        <div className="md:col-8">
                                            <AutoComplete
                                                value={selectedPatientm} // Bind to state
                                                suggestions={filteredPatients}
                                                completeMethod={(e) => {
                                                    // if (!e.query || e.query?.length < 3) return;
                                                    searchPatients(e.query);
                                                }}
                                                field="first_name" // The field to display in dropdown
                                                onSelect={onPatientSelect} // Triggered when a patient is selected
                                                onChange={(e) => setSelectedPatientm(e.value)} // For typing in the input
                                                className="patient-search"
                                                panelClassName="patient-search-panel"
                                                placeholder="Search with MRN, Patient Name"
                                                style={{ width: "100%" }}
                                                dropdownIcon
                                                itemTemplate={(item) => (
                                                    <div className="flex align-items-center gap-2">
                                                        <div style={{ background: getBackground(item?.patient_status), borderRadius: "50%", height: "8px", width: "8px" }} />
                                                        {item?.first_name} {item?.last_name} - {item?.mrn} - {item?.site}
                                                    </div>
                                                )}
                                                disabled={faxDetail.fax_line === "Prescriber Portal"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="mb-2">
                                    <b>Assign fax to one of the following Orders</b>
                                </div>
                                <div className="assign-fax mb-0">
                                    <DataTable
                                        value={assign_fax}
                                        scrollable
                                        scrollHeight="140px"
                                        className="assign-fax-orders"
                                        loading={assignFaxLoading}
                                    >
                                        <Column body={checkbox} header="" />
                                        <Column field="rank" header="Rank" />
                                        <Column field="descrip" header="Description" />
                                        <Column field="listid" header="List Id" />
                                    </DataTable>
                                </div>
                            </div>
                            <div>
                                <div className="grid mt-3">
                                    <div className="col-12 py-0">
                                        <div className="grid align-items-center">
                                            <div className="xl:col-3 lg:col-3 md:col-3 col-12">
                                                <label className="label mb-0" htmlFor="description">
                                                    Description
                                                </label>
                                            </div>
                                            <div className="xl:col-9 lg:col-9 md:col-9 col-12">
                                                <div className="control">
                                                    <Dropdown
                                                        placeholder="Select Description"
                                                        options={dropdownOptions}
                                                        value={description} // Bind to the value of selected option
                                                        onChange={(e) => {
                                                            setFieldValue("description", e.value);
                                                            handleDescriptionChange(e);
                                                        }} // Handle change
                                                        // optionLabel='label'
                                                        // optionValue='value'
                                                        filter
                                                        className="form-control w-full"
                                                        panelClassName="small-dropdown-options"
                                                        filterPlaceholder="Search description"
                                                    />
                                                    <ErrorMessage name="description" render={renderError} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 py-0">
                                        <div className="grid align-items-center">
                                            <div className="xl:col-3 lg:col-3 md:col-3 col-12">
                                                <label className="label mb-0" htmlFor="category">
                                                    Category
                                                </label>
                                            </div>
                                            <div className="xl:col-9 lg:col-9 md:col-9 col-12">
                                                <div className="control">

                                                    <Dropdown
                                                        placeholder="Select Category"
                                                        options={[...categoryOptions]
                                                            .sort((a, b) => a.document_category.trim().localeCompare(b.document_category.trim())) // Sort the array
                                                            .map(category => ({
                                                                document_category: category.document_category.trim(),
                                                                document_category_id: category.document_category_id
                                                            }))
                                                        }
                                                        value={selectedCategory}
                                                        onChange={(e) => {
                                                            setSelectedCategory(e.value);
                                                            setFieldValue("category", e.value);
                                                        }}
                                                        optionLabel="document_category"
                                                        filter
                                                        className="form-control w-full"
                                                        panelClassName="small-dropdown-options"
                                                        filterPlaceholder="Search category"
                                                    />

                                                    <ErrorMessage name="category" render={renderError} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 py-0">
                                        <div className="grid align-items-center">
                                            <div className="xl:col-3 lg:col-3 md:col-3 col-12">
                                                <label className="label mb-0" htmlFor="notes">
                                                    Notes
                                                </label>
                                            </div>
                                            <div className="xl:col-9 lg:col-9 md:col-9 col-12">
                                                <div className="control">
                                                    <Field
                                                        placeholder="Enter Notes"
                                                        name="notes"
                                                        type="text"
                                                        className="form-control p-2 w-full"
                                                        value={notes}
                                                        onChange={(e) => {
                                                            setNotes(e.target.value);
                                                        }}
                                                    />
                                                    <ErrorMessage name="notes" render={renderError} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 py-0">
                                        <div className="grid align-items-center">
                                            <div className="xl:col-3 lg:col-3 md:col-3 col-12">
                                                <label className="label" htmlFor="fileName">
                                                    File Name
                                                </label>
                                            </div>
                                            <div className="xl:col-9 lg:col-9 md:col-9 col-12">
                                                <div className="control">
                                                    <Field
                                                        placeholder="Enter File Name"
                                                        name="fileName"
                                                        type="text"
                                                        className="form-control p-2 w-full"
                                                        value={faxDetail?.file_name}
                                                        disabled
                                                    />
                                                    <ErrorMessage name="fileName" render={renderError} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap mt-2 border-top-1 surface-border pt-3 mt-3 justify-content-end gap-3">
                                <button type="button" className="custom-fax-button" onClick={toggle}>
                                    Cancel
                                </button>
                                {/* <button type="submit" className="d-flex align-items-center border-none hover-text-color-theme bg-transparent">
                                    Split and Assign
                                </button> */}
                                <Button
                                    type="submit"
                                    style={{ height: "30px" }}
                                    disabled={
                                        selectedPatientData === null ||
                                        fromAndTo?.from < 1 ||
                                        // fromAndTo.to > faxDetail?.pages ||
                                        fromAndTo.from > fromAndTo.to
                                    }
                                    loading={assignLoading}
                                >
                                    {assignLoading ? "Wait..." : "Split and Assign"}
                                </Button>
                                <div className="flex align-items-center">
                                    <Checkbox
                                        className="mr-2"
                                        checked={isSendEmail}
                                        onChange={handleEmailCheckboxChange}
                                        // onClick={() => setIsEmailModal(true)}
                                        disabled={selectedPatientData === null}
                                    />
                                    <div className="">
                                        Check to send email
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>


        </div>
    );
}

export default DocumentUserModal;






