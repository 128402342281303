import React, { useEffect, useRef, useState } from "react";
import InputField from "../../components/formElement/InputField";
import { Button } from "primereact/button";
import "../../assets/css/inventory.css";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import MultiSelectFilter from "../../components/formElement/MultiSelect";
import { Inventorydata } from "../../Data/InventoryList";
import icon from "../../assets/images/add-btn.svg"; // Adjust the path to your SVG file
import { FilterMatchMode } from "primereact/api";
import AddInventoryDialogBox from "../../components/Dialog/AddInventoryDialogBox";
import AddNonInvontery from "../../components/Dialog/AddNonInvontery";
import AddRentalInventory from "../../components/Dialog/AddRentalInventory";
import Loader from "../../components/loader/Loader";

import { Inventorytype } from "../../redux/store/InventoryReducer";

interface SelectOption {
  label: string;
  value: string | number;
  [key: string]: string | number;
}

const Inventory = () => {
  const [showTable, setShowTable] = useState(Inventorydata);

  const [filteredData, setFilteredData] = useState<Inventorytype[]>([]);
  const [selectedOption, setSelectedOption] = useState<Inventorytype | null>(
    null
  );
  const [tempinv, setTempinv] = useState<{ label: string; value: string }[]>(
    []
  );
  const [tempmanu, setTempmanu] = useState<{ label: string; value: string }[]>(
    []
  );
  const [tempSupplier, setTempSupplier] = useState<
    { label: string; value: string }[]
  >([]);
  const [tempHCPC, setTempHCPC] = useState<{ label: string; value: string }[]>(
    []
  );
  const [tempSite, setTempSite] = useState<{ label: string; value: string }[]>(
    []
  );
  const [tempLocation, setTempLocation] = useState<
    { label: string; value: string }[]
  >([]);
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValueItem, setGlobalFilterValueItem] = useState("");
  const [globalFilterValueReorder, setGlobalFilterValueReorder] = useState("");

  // State to control modal visibility and selected row
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNonInventoryModalVisible, setIsNonInventoryModalVisible] =
    useState(false);
  const [isRentalInventoryModalVisible, setIsRentalInventoryModalVisible] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [isDisable,setIsDisable] = useState(true);
  

  
  
  

  //pathName ref of loader
  const location = window.location.pathname;

  //global search fileds
  const onGlobalFilterChangeItem = (e: any) => {
    const value = e.target.value;

    setGlobalFilterValueItem(value);
  };

  const onGlobalFilterChangeReorder = (e: any) => {
    const value = e.target.value;
    setGlobalFilterValueReorder(value);
  };

  // for open diffrent modal on row click
  const onRowClick = (e: any) => {

    if (e.data.TPN==="Pending") {
      // Only open modal if it's the first row
      setSelectedRow(e.data);
      setIsModalVisible(true);
    }

    if (e.data.TPN==="Approved") {
      setSelectedRow(e.data);
      setIsNonInventoryModalVisible(true);
    }
    if (e.data.TPN==="Rejected") {
      setSelectedRow(e.data);
      setIsRentalInventoryModalVisible(true);
    }
  };

  const options: Inventorytype[] = [...showTable];

  const getUniqueValues = <T extends Record<string, any>>(
    data: T[],
    field: keyof T
  ): SelectOption[] => {
    return Array.from(new Set(data.map((item) => item[field]))).map(
      (value) => ({
        label: value as string,
        value,
      })
    );
  };

  //  filter when Apply Filter is clicked
  const handleApplyFilter =  () => {
    setLoading((prev) => !prev);

    let filtered: Inventorytype[] = showTable;

    if (tempinv.length) {
      const newArray = tempinv.map((item) => item.label);
     
      // @ts-ignore
      filtered = filtered.filter((task) => newArray.includes(task.Inventory));
    }

    if (tempmanu.length) {
      // @ts-ignore
      const newArray = tempmanu.map((item) => item.label?.toLowerCase());
      filtered = filtered.filter((task) =>
        newArray.includes(task.Manufacturer?.toLowerCase())
      );
    }

    if (tempSupplier.length) {
      // @ts-ignore
      const newArray = tempSupplier.map((item) => item.label?.toLowerCase());
      filtered = filtered.filter((task) =>
        newArray.includes(task.Supplier?.toLowerCase())
      );
    }

    if (tempHCPC.length) {
      // @ts-ignore
      const newArray = tempHCPC.map((item) => item.label?.toLowerCase());
      filtered = filtered.filter((task) =>
        newArray.includes(task.HCPC?.toLowerCase())
      );
    }
    if (tempSite.length) {
      // @ts-ignore
      const newArray = tempSite.map((item) => item.label?.toLowerCase());
      filtered = filtered.filter((task) =>
        newArray.includes(task.Site?.toLowerCase())
      );
    }
    if (tempLocation.length) {
      const newArray = tempLocation.map((item) => item.label?.toLowerCase());
      filtered = filtered.filter((task) =>
        newArray.includes(task.Location?.toLowerCase())
      );
    }
    if (globalFilterValueReorder.length) {
      const value = globalFilterValueReorder;
      
      setFilters((prev) => ({ ...prev, global: { ...prev.global, value } }));
    }
    if (globalFilterValueItem.length) {
      const value = globalFilterValueItem;

      setFilters((prev) => ({ ...prev, global: { ...prev.global, value } }));
    }

     setFilteredData(filtered);
    setLoading((prev) => !prev);
  };

  // Handle Reset button click
  const handleReset = () => {
    setSelectedOption(null);
    setTempinv([]);
    setTempmanu([]);
    setTempSupplier([]);
    setTempHCPC([]);
    setTempSite([]);
    setTempLocation([]);
    setGlobalFilter("");
    setGlobalFilterValueReorder("");
    setGlobalFilterValueItem("");
    setFilteredData([]);
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = "";
    setFilters(_filters);


  };
  
  
  
  

 

  useEffect(() => {
    const hasFilters =
      tempinv.length > 0 ||
      tempmanu.length > 0 ||
      tempSupplier.length > 0 ||
      tempHCPC.length > 0 ||
      tempSite.length > 0 ||
      tempLocation.length > 0 ||
      globalFilterValueItem.length > 0 ||
      globalFilterValueReorder.length > 0;
     
    setIsDisable(!hasFilters);

  
   
    if (!globalFilterValueItem.length) {
      let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = "";
    setFilters(_filters);
    }

   if (!hasFilters) {
    setFilteredData([]);
    setGlobalFilterValueReorder("");
    setGlobalFilterValueItem("");
   
    
    
   }

  }, [
    tempinv,
    tempmanu,
    tempSupplier,
    tempHCPC,
    tempSite,
    tempLocation,
    globalFilterValueItem,
    globalFilterValueReorder,
    
  ]);




  return (
    <div>
      {/* Header Section */}
      <div className="filter">
        {/* Search and Filter Fields */}
        <div className="input-container">
          <InputField
            className=" search-text"
            placeholder="Search List name"
            id="Search List name"
            globalFilterValue={globalFilterValueItem}
            onGlobalFilterChange={onGlobalFilterChangeItem}
          />
        </div>
        <div className="input-container">
          <InputField
            className=" search-text"
            placeholder="Recorder#"
            id="search-insurance"
            globalFilterValue={globalFilterValueReorder}
            onGlobalFilterChange={onGlobalFilterChangeReorder}
          />
        </div>
        <div>
          <Button
            className="add-btn"
            icon={
              <img
                src={icon}
                alt="My Icon"
                style={{ width: "20px", height: "20px" }}
              />
            }
            style={{ display: "flex", alignItems: "center" }}
          />
        </div>
      </div>

      <div className="sub-filter">
        <div className="container-multi">
          <MultiSelectFilter
            filterValue="label"
            data={options}
            onChange={(e: any) => setTempinv(e.value)}
            value={tempinv}
            className="multiselectors inventory-search"
            label="Inventory"
            placeholder="Inv Type"
          />
        </div>
        <div className="container-multi">
          <MultiSelectFilter
            data={options}
            filterValue="lable"
            onChange={(e: any) => setTempmanu(e.value)}
            value={tempmanu}
            className="multiselectors inventory-search"
            label="Manufacturer"
            placeholder="Manufacturer"
            
          />
        </div>
        <div className="container-multi ">
          <MultiSelectFilter
            data={options}
            filterValue="label"
            onChange={(e: any) => setTempSupplier(e.value)}
            value={tempSupplier}
            className="multiselectors inventory-search"
            label="Supplier"
            placeholder="Supplier"
          />
        </div>
        <div className="container-multi">
          <MultiSelectFilter
            data={options}
            filterValue="label"
            value={tempHCPC}
            className="multiselectors inventory-search"
            label="HCPC"
            onChange={(e: any) => setTempHCPC(e.value)}
            placeholder="HCPC"
          />
        </div>
        <div className="container-multi">
          <MultiSelectFilter
            filterValue="label"
            data={options}
            value={tempSite}
            className="multiselectors inventory-search"
            label="Site"
            onChange={(e: any) => setTempSite(e.value)}
            placeholder="Site"
          />
        </div>
        <div className="container-multi">
          <MultiSelectFilter
            filterValue="label"
            data={options}
            value={tempLocation}
            className="multiselectors inventory-search"
            label="Location"
            onChange={(e: any) => setTempLocation(e.value)}
            placeholder="Location"
          />
        </div>

        <div className="btn-container ">
          <Button
            style={{}}
            label="Reset"
             className="filter-btn"
            outlined
            onClick={handleReset}
          />
        </div>
        <div className="btn-container">
          <Button
            style={{}}
            label="Apply Filter"
            className="inventory-btn"
            onClick={handleApplyFilter}
            disabled={isDisable}
           
          />
        </div>
      </div>
      <div>
        {loading ? (
          <Loader location={location} path="/inventory" label="inventory" />
        ) : filteredData.length > 0 ? (
          <div className="">
            {/* DataTable section */}
            <DataTable
              className="inventoryDatatable custom-table"
              globalFilter={globalFilter}
              filters={filters}
              value={filteredData}
              globalFilterFields={["Item", "Inventory"]}
              paginator
              rows={10}
              rowsPerPageOptions={[10,15,20]}
              onRowClick={onRowClick}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
             
              <Column
                field="Item"
                header="Item"
                headerStyle={{ fontSize: "16px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="Qty"
                header="Qty"
                headerStyle={{ fontSize: "16px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="Inventory"
                header="Inventory"
                headerStyle={{ fontSize: "16px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="Manufacturer"
                header="Manufacturer"
                headerStyle={{ fontSize: "16px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="Supplier"
                header="Supplier"
                headerStyle={{ fontSize: "16px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="TPN"
                header="TPN "
                headerStyle={{ fontSize: "16px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="NDC"
                header="NDC"
                headerStyle={{ fontSize: "16px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="HCPC"
                header="HCPC"
                headerStyle={{ fontSize: "16px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="Site"
                header="Site"
                headerStyle={{ fontSize: "16px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="Location"
                header="Location"
                headerStyle={{ fontSize: "16px", fontWeight: "bold" }}
              ></Column>

             
            </DataTable>

            <AddInventoryDialogBox
              header="Row Details"
              visible={isModalVisible}
              onHide={() => setIsModalVisible(false)}
            />
            <AddNonInvontery
              noninventoryHeader="Non-Inventory Billable Item"
              noninventoryVisible={isNonInventoryModalVisible}
              onHide={() => setIsNonInventoryModalVisible(false)}
            />
            <AddRentalInventory
              rentalInventoryVisible={isRentalInventoryModalVisible}
              onHide={() => setIsRentalInventoryModalVisible(false)}
            />
          </div>
        ) : (
          <div className="apply-filter">
            <div className="h1-container">
              <h3>Apply the filter !!</h3>
            </div>
            <div className="">
              <p>Once search criteria is entered, click on "Apply filter"</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Inventory;