import React from "react";
import { Dialog } from "primereact/dialog";
import { DiagnosisReport, MapView } from "../../../assets/images/Icon";

const report = process.env.REACT_APP_CENSUS_POWERBI_REPORT;

const MapViewDialogBox = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) => {
  const customHeader = () => {
    return (
      <>
        <div className="dialog-header flex gap-2">
          <div className="header-icon mr-2">
            <img className="mt-2" src={MapView} alt="Map View" />
          </div>
          <div className="header-title">
            <div className="text-xl">Map View</div>
            <div className="text-sm font-medium header-subtitle">
              Seamlessly Integrated with Power BI for Enhanced Workflow
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Dialog
      // style={{ width: "1000px", zIndex: 1111, height: "500px" }}
      className="map-view w-10 md:w-9 lg:w-7 xl:w-5 h-30rem"
      header={customHeader}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <iframe
        title="Power BI Report"
        src={report}
        style={{
          width: "100%",
          border: "none",
        }}
        allowFullScreen
      />
    </Dialog>
  );
};

export default MapViewDialogBox;
