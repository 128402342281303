import React from "react";
import {
  Appointments,
  ProfileIcon,
  SortDecre,
  SortIncre,
} from "../../../../assets/images/Icon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const PatientAlertDataTable = () => {
  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  const columns = [
    {
      field: "diagnosis",
      header: "Diagnosis",
      sortable: true,
    },
    {
      field: "category",
      header: "Category",
      sortable: true,
    },
    {
      field: "therapyType",
      header: "Therapy Type",
      sortable: true,
    },
  ];

  return (
    <DataTable
      value={[]}
      sortIcon={sortIcon}
      emptyMessage="No Data Found"
      removableSort
      scrollable
      scrollHeight="100px"
    >
      {columns.map((column, index) => (
        <Column
          key={index}
          field={column.field}
          header={column.header}
          sortable={column.sortable}
        />
      ))}
    </DataTable>
  );
};

const PatientProfileInfo = () => {
  const patientInfo = [
    {
      section: "location",
      icon: Appointments,
      details: "1051 N Canton Center Rd, Canton, MI, 71360",
    },
    {
      section: "phone",
      icon: Appointments,
      details: "526-705-1014, 526-705-1014",
    },
    { section: "marital-status", icon: Appointments, details: "June 18, 2009" },
    { section: "language", icon: Appointments, details: "English" },
    { section: "gender", icon: Appointments, details: "Male" },
  ];

  const careTeamInfo = [
    {
      section: "agency",
      icon: Appointments,
      details: "5 STAR BROWARD",
    },
    {
      section: "case-manager",
      icon: Appointments,
      details: "Andrew Wee",
    },
    { section: "sales-person", icon: Appointments, details: "Albert Norris" },
    { section: "intake", icon: Appointments, details: "Alex Dias, AD" },
    {
      section: "billing-report",
      icon: Appointments,
      details: "Abedallah Darwish",
    },
    { section: "teams", icon: Appointments, details: "Vivitrol" },
  ];

  return (
    <div className="grid gap-3 mb-3">
      {/* Profile Information Column */}
      <div className="xl:col-3 md:col-12 lg:col-3 sm:col-12 profile-card flex-evenly">
        <div className="w-full flex flex-column gap-1">
          <img className="w-6 mb-3" alt="profile" src={ProfileIcon} />
          <h6 className="font-bold mb-0 text-sm">CHRISTOPER AADAM</h6>
          <div className="font-semibold text-blue-600 text-sm">MRN: 213454</div>
          <div className="font-bold text-xs">***-***-***</div>
          <div className="font-semibold text-xs">Test@noemail.com</div>
          <div className="font-semibold text-xs">NOLA(LA)</div>
        </div>
        <div className="w-full">
          {patientInfo.map((info) => (
            <div className="mb-3 flex align-items-start" key={info.section}>
              <img className="w-1" alt={info.section} src={info.icon} />
              <div className="text-xs ml-2 line-height-2 font-semibold text-600">
                {info.section === "phone"
                  ? info.details
                      .split(",")
                      .map((part, index) => (
                        <div key={index}>{part.trim()}</div>
                      ))
                  : info.details}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Patient Alert Section */}
      <div className="xl:col md:col-12 lg:col sm:col-12 profile-card p-0">
        <div className="px-3 py-2">
          <div className="mb-2 flex gap-1">
            <div className="font-bold mb-0">Patient Alert</div>
            <img alt="" src="" />
          </div>
          <div className="h-5rem p-2 border-round-xl surface-100">
            <div className="h-full overflow-y-scroll">Hello</div>
          </div>
        </div>
        <PatientAlertDataTable />
      </div>

      {/* Care Team Section */}
      <div className="xl:col-3 md:col-12 lg:col-3 sm:col-12 profile-card flex-evenly">
        <div className="h-full flex flex-column justify-content-evenly">
          {careTeamInfo.slice(0, 3).map((info) => (
            <div className="flex gap-2 align-items-center" key={info.section}>
              <img className="w-2rem" alt={info.section} src={info.icon} />
              <div className="text-xs line-height-2 font-semibold text-600">
                {info.details}
              </div>
            </div>
          ))}
        </div>
        <div className="h-full flex flex-column justify-content-evenly">
          {careTeamInfo.slice(3).map((info) => (
            <div className="flex gap-2 align-items-center" key={info.section}>
              <img className="w-2rem" alt={info.section} src={info.icon} />
              <div className="text-xs line-height-2 font-semibold text-600">
                {info.details}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PatientProfileInfo;
