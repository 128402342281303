import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toggleEmailModal, sendEmail, getUsersAndGroups } from '../../redux/store/FaxSliceReducer';
import "../../assets/css/form.css";
import { InputSwitch } from 'primereact/inputswitch';
import { Editor } from 'primereact/editor';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';

function EmailModal({
    setIsEmailModal,
    selectedPatientmrn,
    emailData,
    setEmailData,
    isSendEmail,
    setIsUserModal,
    faxDetail,
    isGroupChecked,
    setIsGroupChecked,
    errorFields,
    setErrorFields,
}) {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { emailLoading } = useSelector((state) => state.FaxSliceReducer);

    const toggle = () => {
        dispatch(toggleEmailModal({ sendEmailModal: false }));
        setIsEmailModal(false);
    };

    const validationSchema = Yup.object({
        // to: Yup.string().email("Enter valid email").required("Enter Email"),
        // cc: Yup.string().email("Enter valid email"),
        // subject: Yup.string().required("Enter Subject"),
        // body: Yup.string().required("Enter Body"),
    });

    const initialValues = {
        to: "",
        cc: "",
        subject: selectedPatientmrn ? selectedPatientmrn.mrn : "", // Set subject to MRN
        body: selectedPatientmrn ? `Patient Name: ${selectedPatientmrn.name}` : "", // Set initial body with patient name
    };

    const onSubmit = (values) => {
        try {
        } catch (error) {
            console.error(error);
        }
    };

    const renderError = (message) => <small className="help text-danger m-0">{message}</small>;

    const handleSendEmail = () => {
        let isValid = true;
        setErrorFields({ to: false, subject: false });
        // const newToEmail = emailData.to?.map((val) => val.mail);
        const newToEmail = (emailData?.to?.length > 0) ? emailData?.to?.map((val) => val.mail) : [];
        if (!newToEmail || newToEmail.length === 0) {
            isValid = false;
            setErrorFields((prev) => ({ ...prev, to: true }));
        }
        if (!emailData.subject) {
            isValid = false;
            setErrorFields((prev) => ({ ...prev, subject: true }));
        }
        if (!isValid) return;
        // const newCcEmail = emailData.cc?.map((val) => val.mail);
        const newCcEmail = (emailData?.cc?.length > 0) ? emailData?.cc?.map((val) => val.mail) : [];

        const newBody = `
            <div>
                ${emailData.body}
                <br />
                <br />
                <div class="footer" style="text-align: left; font-size: 14px; border-top: 2px solid #d2d2d2; margin-top: 10px;">
                    <i>This email is sent from an unmonitored mailbox. Please do not reply.</i>
                    <p class="disclaimer" style="margin-top: 20px; font-size: 7.5pt; color: #666;">
                        The information included in this email is confidential. It is intended for the use of the individual or entity addressed to above. If you are not the intended recipient or an agent of the intended recipient, you are hereby notified that any distribution or copying of this information is strictly prohibited. If you have received this email in error, please notify InfuCare Rx immediately at 1-877-828-3940 to arrange for the return of the original document as well as deleting the information.
                    </p>
                </div>
            </div>
        `
        const data = {
            ...emailData,
            to: newToEmail?.join(","),
            cc: newCcEmail?.join(","),
            EmailBody: newBody,
            folder: faxDetail?.fax_folder_name,
            file: faxDetail?.file_name,
            splitPath: "0",
        }
        dispatch(sendEmail({ data, callback: () => navigate("/fax") }));
    };

    const handleSaveClick = () => {
        let isValid = true;
        setErrorFields({ to: false, subject: false });
        const newToEmail = emailData.to?.map((val) => val.mail);
        if (!newToEmail || newToEmail.length === 0) {
            isValid = false;
            setErrorFields((prev) => ({ ...prev, to: true }));
        }
        if (!emailData.subject) {
            isValid = false;
            setErrorFields((prev) => ({ ...prev, subject: true }));
        }
        if (!isValid) return;
        setIsUserModal(true);
    };

    // useEffect(() => {
    //     setEmailData((prev) => ({
    //         ...prev,
    //         to: [],
    //         cc: [],
    //     }));
    // }, [isGroupChecked]);

    const [filteredUsers, setFilteredUsers] = useState([]); // Filtered options
    const debounceTimeout = useRef(null);

    const fetchUsers = (query) => {
        dispatch(getUsersAndGroups({ query: query, isUser: !isGroupChecked, }))
            .unwrap()
            .then((response) => {
                if (Array.isArray(response)) {
                    setFilteredUsers(response); // Update filtered options
                } else {
                    setFilteredUsers([]); // Handle no results case
                }
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
                setFilteredUsers([]); // In case of error, clear the options
            });
    };

    const searchUsers = (event) => {
        const query = event.filter;
        // Example API call (replace with your actual API dispatch)
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            if (query) {
                fetchUsers(query); // Only call API if there is a query
            } else {
                setFilteredUsers([]);
            }
        }, 500);
    };

    return (
        <div className="user-email-modal shadow">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    onSubmit(values);
                    resetForm();
                }}
                validateOnChange={true}
                validateOnBlur={true}
            >
                {() => (
                    <Form>
                        <div>
                            <div className='grid'>
                                <div className="col-2">
                                    <label className="label" htmlFor="to">To</label>
                                </div>
                                <div className="md:col-6 sm:col-10 col-12">
                                    <div className="control dropdown-filter">
                                        <MultiSelect
                                            value={emailData.to}
                                            options={filteredUsers}
                                            onChange={(e) => {
                                                setEmailData((prev) => ({
                                                    ...prev,
                                                    to: e.target.value
                                                }));
                                                setErrorFields((prev) => ({ ...prev, to: false }));
                                            }}
                                            itemTemplate={(item) => (
                                                <div>{item.displayName} ({item.mail})</div>
                                            )}
                                            placeholder="To"
                                            optionLabel="displayName"
                                            className={`email-select-dropdown ${errorFields.to && "invalid-field"}`}
                                            panelClassName="dropdown-filter-panel custom-dropdown-panel email-dropdown-panel"
                                            // display="chip"
                                            filter
                                            filterBy="displayName"
                                            onFilter={searchUsers}
                                            filterPlaceholder="Search user or group"
                                            resetFilterOnHide
                                        />
                                        <ErrorMessage name="to" render={renderError} />
                                    </div>
                                </div>
                                <div className="md:col-4 col-12">
                                    <div className="email-switch-group">
                                        <div>Users</div>
                                        <InputSwitch
                                            className="email-switch"
                                            checked={isGroupChecked}
                                            onChange={(e) => {
                                                setIsGroupChecked(e.value);
                                                setFilteredUsers([]);
                                            }}
                                        />
                                        <div>Groups</div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-2">
                                    <label className="label" htmlFor="cc">Cc</label>
                                </div>
                                <div className="sm:col-10 col-12">
                                    <div className="control dropdown-filter">
                                        <MultiSelect
                                            value={emailData.cc}
                                            options={filteredUsers}
                                            onChange={(e) => {
                                                setEmailData((prev) => ({
                                                    ...prev,
                                                    cc: e.target.value
                                                }));
                                            }}
                                            itemTemplate={(item) => (
                                                <div>{item.displayName} ({item.mail})</div>
                                            )}
                                            placeholder="Cc"
                                            optionLabel="displayName"
                                            className="email-select-dropdown"
                                            panelClassName="dropdown-filter-panel custom-dropdown-panel"
                                            // display="chip"
                                            filter
                                            filterBy="displayName"
                                            onFilter={searchUsers}
                                            filterPlaceholder="Search user or group"
                                            resetFilterOnHide
                                        />
                                        <ErrorMessage name="cc" render={renderError} />
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-2">
                                    <label className="label" htmlFor="subject">Subject</label>
                                </div>
                                <div className="sm:col-10 col-12">
                                    <div className="control">
                                        <InputText
                                            placeholder="Subject"
                                            name="subject"
                                            type="text"
                                            className={`email-subject ${errorFields.subject && "invalid-field"}`}
                                            value={emailData.subject}
                                            onChange={(e) => {
                                                setEmailData((prev) => ({
                                                    ...prev,
                                                    subject: e.target.value
                                                }));
                                                setErrorFields((prev) => ({ ...prev, subject: false }));
                                            }}
                                        />

                                        <ErrorMessage name="subject" render={renderError} />
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12">
                                    <div className="control">
                                        <Editor
                                            style={{ height: '320px' }}
                                            value={emailData.body}
                                            onTextChange={(e) => {
                                                setEmailData((prev) => ({
                                                    ...prev,
                                                    body: e.htmlValue
                                                }))
                                            }}
                                        />
                                        <ErrorMessage name="body" render={renderError} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-2 border-top-1 surface-border pt-3 mt-3 justify-content-between">
                            {isSendEmail ? (
                                <Button type="button" className="" onClick={handleSaveClick}>Save</Button>
                            ) : (
                                <Button loading={emailLoading} disabled={emailLoading} type="button" className="" onClick={handleSendEmail}>Send</Button>
                            )}
                            <div className="flex align-items-center cursor-pointer" onClick={toggle}>
                                <i className="pi pi-trash" />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default EmailModal;

