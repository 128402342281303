import React, { useState, useEffect, useMemo } from "react";
import { FilterMatchMode } from "primereact/api";
import Filterdata from "../../components/Filter/FaxFilter/FilterData";
import { useAppDispatch } from '../../redux/store';
import FaxDataTable from "../../components/DataTable/FaxDataTable";
import DateRangeFilter from "../../components/Filter/FaxFilter/DateRangeFilter";
import { RootState } from "../../redux/store";
import { fetchFaxData, updateFaxOwnerName } from "../../redux/store/FaxSliceReducer";
import { useSelector } from "react-redux";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { useMsal } from "@azure/msal-react";
import InputField from "../../components/formElement/InputField";
import "../../../src/assets/css/fax.css"
import { MultiSelect } from "primereact/multiselect";
import '../../assets/css/style.css'
import edit from '../../assets/images/editicon.svg'

// Define the structure for filter metadata
type FilterMeta = {
    value: string | null | (string | null)[]; // Accept arrays or strings
    matchMode: FilterMatchMode;
};

// Define the structure for FaxItem
export type FaxItem = {
    fax_detail_id: number;
    fax_line: string;
    fax_number: string;
    fax_folder_name: string | null;
    file_name: string | null;
    date: string;
    caller: string;
    pages: number; // Ensure pages is a number
    assignment_status: string;
};

const FaxScreen: React.FC = () => {
    const { loading, faxData, allFaxData } = useSelector((state: RootState) => state.FaxSliceReducer);
    const dispatch = useAppDispatch();

    // Memoize the data to prevent unnecessary re-renders
    const data: FaxItem[] = useMemo(
        () => faxData?.map(item => ({
            fax_detail_id: item.fax_detail_id,
            fax_line: item.fax_line,
            fax_number: item.fax_number,
            fax_folder_name: item.fax_folder_name,
            file_name: item.file_name,
            date: item.date,
            caller: item.caller,
            pages: Number(item.pages),
            assignment_status: item.assignment_status
        })),
        [faxData]
    );

    // State for toggle window and owner's name
    const [isOwnerNameModalOpen, setIsOwnerNameModalOpen] = useState(false);
    const [ownerName, setOwnerName] = useState('');

    const [selectedFaxRecord, setSelectedFaxRecord] = useState<any>(null);

    const handleOpenOwnerNameModal = (rowData: any) => {
        setSelectedFaxRecord(rowData); // Set the selected fax record
        setIsOwnerNameModalOpen(true); // Open the modal
    };

    const undoBody = (rowData: any) => {
        if (!rowData.fax_record_id) return null;
        return (
            <div className="d-flex justify-content-center" >
                <div className="archive-btn edit-icon" onClick={(e) => {
                    e.stopPropagation();
                    handleOpenOwnerNameModal(rowData)
                }}>
                    {/* <i className="pi pi-pencil" /> */}
                    <img src={edit} alt="edit" />
                </div>
            </div>
        )
    };

    useEffect(() => {
        // if (allFaxData?.length === 0) {
        dispatch(fetchFaxData());
        // }

        // Set an interval to dispatch the action every 5 minutes (300000 ms)
        const interval = setInterval(() => {
            dispatch(fetchFaxData());
        }, 300000);

        // Cleanup function to clear interval on component unmount
        return () => clearInterval(interval);
    }, [dispatch]);

    const [filters, setFilters] = useState<{
        global: FilterMeta;
        fax_line: FilterMeta;
        fax_number: FilterMeta;
        caller: FilterMeta;
        pages: FilterMeta;
        date: FilterMeta;
        assignment_status: FilterMeta;
    }>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        fax_line: { value: [], matchMode: FilterMatchMode.IN },
        fax_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
        caller: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pages: { value: null, matchMode: FilterMatchMode.CONTAINS },
        assignment_status: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [filteredData, setFilteredData] = useState<FaxItem[]>(faxData);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const { accounts } = useMsal();

    // Update the filtered data whenever the start/end dates change
    useEffect(() => {
        const filteredByDate = faxData?.filter((item) => {
            const itemDate = new Date(item.date_and_time);
            const isAfterStartDate = startDate ? itemDate >= new Date(startDate) : true;
            const isBeforeEndDate = endDate ? itemDate <= new Date(endDate) : true;

            return isAfterStartDate && isBeforeEndDate;
        });
        setFilteredData(filteredByDate);
    }, [startDate, endDate, faxData]);

    // Handle global filter changes
    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilters((prev) => ({
            ...prev,
            global: { ...prev.global, value }
        }));
    };

    useEffect(() => {
        document.body.classList.add('overflow-scroll-body');
        return () => {
            document.body.classList.remove('overflow-scroll-body');
        };
    }, []);

    useEffect(() => {
        if (!isOwnerNameModalOpen) {
            setOwnerName("")
        }
    }, [isOwnerNameModalOpen]);

    useEffect(() => {
        const filtered = data.filter((item) => {
            const itemFaxLine = item.fax_line === null ? "_blank" : item.fax_line;
            const selectedFaxLines = filters.fax_line.value as (string | null)[];

            const inDateRange =
                (!startDate || new Date(item.date) >= new Date(startDate)) &&
                (!endDate || new Date(item.date) <= new Date(endDate));

            // Ensure filters.global.value is a string
            const globalFilterValue = filters.global.value;

            const matchesGlobalFilter =
                typeof globalFilterValue === "string" && // Check if globalFilterValue is a string
                globalFilterValue !== null && // Check if it's not null
                Object.values(item).some(val => {
                    // Check if val is a string
                    if (typeof val === "string") {
                        return val.toLowerCase().includes(globalFilterValue.toLowerCase());
                    }

                    // Check if val is an array
                    if (Array.isArray(val)) {
                        return val.some(arrayItem =>
                            typeof arrayItem === "string" &&
                            arrayItem.toLowerCase().includes(globalFilterValue.toLowerCase())
                        );
                    }

                    // If val is neither a string nor an array, return false
                    return false;
                });

            // Combine the checks: both inDateRange and matchesGlobalFilter must be true
            return inDateRange && matchesGlobalFilter;
        });

        // Do something with the filtered data, e.g., setting it to state
        // setFilteredData(filtered);

    }, [data, filters, startDate, endDate]); // Dependencies array

    // const faxLineOptions = useMemo(() => {
    //     const uniqueLines = Array.from(new Set(faxData?.map(item => item.fax_line)));
    //     return uniqueLines.map(line => ({
    //         label: line === null ? "Blank" : line,
    //         value: line === null ? "_blank" : line
    //     }));

    // }, [faxData]);

    const faxLineOptions = useMemo(() => {
        const uniqueLines = Array.from(new Set(faxData?.map(item => item.fax_line)));
        return uniqueLines.map(line => ({
            label: line === null ? "Blank" : line,
            value: line === null ? line : line
        }));
    }, [faxData]);

    const handleSubmit = async () => {
        if (!selectedFaxRecord || !selectedFaxRecord.fax_record_id) {
            console.error("No fax record selected or fax_record_id is missing!");
            return;
        }
        const fax_record_id = selectedFaxRecord.fax_record_id;
        const created_by = accounts[0].username;

        try {
            setBtnLoading(true);
            const resultAction = await dispatch(updateFaxOwnerName({
                fax_record_id,
                fax_owner_name: ownerName,
                created_by,
                delete: 0,
            }));


            if (updateFaxOwnerName.fulfilled.match(resultAction)) {
                setOwnerName("");
                setIsOwnerNameModalOpen(false);
                dispatch(fetchFaxData());
                setBtnLoading(false);
            } else {
                setBtnLoading(false);
                console.error("Update failed:", resultAction.error.message);
            }
        } catch (error) {
            setBtnLoading(false);
            console.error("Error during fetch:", error);
        }
    };

    const handleonfaxclick = () => {
        // Resetting the start and end dates
        setStartDate(undefined); // Reset start date
        setEndDate(undefined); // Reset end date

        // Optionally reset any other filters you might have here
        if (setFilters) {
            setFilters((prev: any) => ({
                ...prev,
                global: { ...prev.global, value: null },
                fax_line: { ...prev.fax_line, value: null },
            }));
        }
    };

    const onFaxFilterChange = (e: { value: (string | null)[] }) => {
        const value = e.value;
        // Map "_blank" back to null for storing in the filters
        const updatedValues = value.includes("_blank")
            ? value.map(v => (v === "_blank" ? null : v)) // Convert "_blank" back to null
            : value;

        // Update the filters state with the new fax line values
        setFilters(prev => ({
            ...prev,
            fax_line: { ...prev.fax_line, value: updatedValues }
        }));
    };

    return (
        <div>
            {/* <Filterdata
                filtersCompo={
                    <div className="position-relative w-75">
                        <div className="row dataflex filter-header">
                            <div className="col-md-3 pe-1">
                                <div className="search-bar">
                                    <InputField
                                        placeholder="Search Fax Line, Fax Number, Caller"
                                        id="search"
                                        globalFilterValue={typeof filters.global?.value === "string" ? filters.global.value : ""}
                                        onGlobalFilterChange={onGlobalFilterChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 pe-1">
                                <DateRangeFilter
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                />
                            </div>
                            <div className="col-md-2  pe-1 dropdown-filter">
                                <MultiSelect
                                    value={filters.fax_line?.value || []} // Ensure it's an array or fallback to empty
                                    options={faxLineOptions}
                                    onChange={onFaxFilterChange}
                                    placeholder="Select Fax Lines"
                                    style={{ width: '100%' }}
                                    maxSelectedLabels={1}
                                    panelClassName="dropdown-filter-panel custom-dropdown"
                                    filter
                                // display="chip"
                                />
                            </div>
                            <div className="col-auto">
                                <Button onClick={handleonfaxclick} className="fax-reset-btn">Reset</Button>
                            </div>
                        </div>
                    </div>
                }
                setFilters={setFilters}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            /> */}
            <Filterdata
                filtersCompo={
                    <div className="relative w-12">
                        <div className="grid align-items-center filter-header">
                            <div className="col-12 sm:col-3 pr-1">
                                <div className="search-bar">
                                    <InputField
                                        placeholder="Search Fax Line, Fax Number, Caller"
                                        id="search"
                                        globalFilterValue={typeof filters.global?.value === "string" ? filters.global.value : ""}
                                        onGlobalFilterChange={onGlobalFilterChange}
                                    />
                                </div>
                            </div>
                            <div className="col-12 sm:col-3 pr-1">
                                <DateRangeFilter
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                />
                            </div>
                            <div className="col-12 sm:col-2 pr-1 dropdown-filter">
                                <MultiSelect
                                    value={filters.fax_line?.value || []}
                                    options={faxLineOptions}
                                    onChange={onFaxFilterChange}
                                    placeholder="Select Fax Lines"
                                    style={{ width: '100%' }}
                                    maxSelectedLabels={1}
                                    panelClassName="dropdown-filter-panel custom-dropdown"
                                    filter
                                />
                            </div>
                            <div className="col-auto">
                                <Button onClick={handleonfaxclick} className="fax-reset-btn">Reset</Button>
                            </div>
                        </div>
                    </div>
                }
                setFilters={setFilters}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />

            <div className="card border-0 mt-0">
                <div className="table-responsive fax-table" style={{ overflowX: "auto" }}>
                    <FaxDataTable
                        data={filteredData}
                        filters={filters}
                        loading={loading}
                        undoBody={undoBody}
                    />
                </div>
            </div>

            {/* Owner Name Modal */}
            {isOwnerNameModalOpen && (
                <Dialog
                    visible={isOwnerNameModalOpen}
                    onHide={() => setIsOwnerNameModalOpen(false)}
                    header="Fax Details"
                    modal
                    style={{ width: '40vw', overflow: "hidden" }}
                    draggable={false}
                    className="owner-name-modal"
                >
                    <div className="content-fax">

                        <div className="grid mb-3">
                            <div className="md:col-3">
                                <h4 style={{ margin: 0, fontSize: "14px" }}>Owner Name:</h4>
                            </div>
                            <div className="md:col-9">
                                <input
                                    type="text"
                                    placeholder="Owner Name"
                                    value={ownerName}
                                    className="form-control"
                                    onChange={(e) => setOwnerName(e.target.value)}
                                    style={{ width: '100%', padding: '5px', height: "30px", fontSize: "12px", border: '1px solid #ccc', borderRadius: '4px' }}
                                />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="md:col-3">
                                {selectedFaxRecord && (
                                    <h4 style={{ margin: 0, fontSize: "14px" }}>
                                        Fax Number:
                                    </h4>
                                )}
                            </div>
                            <div className="md:col-9">
                                {selectedFaxRecord.fax_number}
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", flexShrink: 0 }}>
                            <Button
                                label="Update"
                                onClick={handleSubmit}
                                style={{ margin: '10px', width: "90px", backgroundColor: "white", color: "black" }}
                                loading={btnLoading}
                                disabled={!ownerName}
                            />
                            <Button
                                label="Close"
                                onClick={() => setIsOwnerNameModalOpen(false)}
                                style={{ margin: '10px', width: "90px", backgroundColor: "white", color: "black" }}
                            />
                        </div>
                    </div>
                </Dialog>
            )}

        </div>
    );
};

export default FaxScreen;