import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useState } from "react";
import InputTextArea from "../formElement/InputTextArea";
import Input from "../formElement/Input";
import ShipmentDocument from "../../pages/document/ShipmentDocumentview";

const ViewDocumentonShipment = ({
    visible,
    setVisible,
    FilePathforDocumentView,
  
  }: {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    FilePathforDocumentView: any;
   
  }) => {



    const [Shipmentdetailsforview, setShipmentdetailsforview] = useState({});
    const [isDisabled, setIsDisabled] = useState(true);

// Dialog Footer
const Footer = (
    <div className="buttons flex gap-2 justify-content-end">
      <Button label="Cancel" outlined onClick={() => setVisible(false)} />
      {/* <Button label="Save" onClick={handleUpdateOwnerName} /> */}
      {/* <Button label="Save" onClick={AddAssignTo}/> */}
    </div>
  );


  return (
    <div>
       <Dialog
      style={{ width: "1200px" }}
      draggable
      header="PDF View"
    //   footer={Footer}
      visible={visible}
      onHide={() => setVisible(false)}
    >
     
     <div>
      <ShipmentDocument
       allShipmentdata={FilePathforDocumentView}
       filename={""}
       setIsDisabled={setIsDisabled}
         //faxDetail={""}
    // pdf={"https://prod-167.westus.logic.azure.com/workflows/0ff31ffa16824bf698f8045c2f230a2a/triggers/manual/paths/invoke/Folder1/HAPP_Fax/Folder2/ec/FileName/ct18666800870-20241016090909090-122-03.pdf?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=N3RU9GwmWFqXQahtRbNutq6um_zNoztQMqQezlHTElg"}
      
      />
      </div>

    </Dialog>
    </div>
  )
}

export default ViewDocumentonShipment
