import React, { useEffect, useState } from "react";
import PermissionsFilter from "../../components/Filter/PermissionsFilter/PermissionsFilter";
import RoleManagementFilter from "../../components/Filter/PermissionsFilter/RoleManagementFilter";
import UserManagementFilter from "../../components/Filter/PermissionsFilter/UserManagementFilter";
import { useDispatch, useSelector } from "react-redux";
import { getPermissionsList } from "../../redux/store/PermissionsReducer";
import { AppDispatch, RootState } from "../../redux/store";

const Permissions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { permissionsList, loading, errorMessage } = useSelector(
    (state: RootState) => state.permissionsReducer
  );
  const [activeComponent, setActiveComponent] = useState<"role" | "user">(
    "role"
  );

  return (
    <div className="permission">
      <PermissionsFilter
        setActiveComponent={setActiveComponent}
        activeComponent={activeComponent}
      />

      <div className="component-container mt-3">
        {activeComponent === "role" && <RoleManagementFilter />}
        {activeComponent === "user" && <UserManagementFilter />}
      </div>
    </div>
  );
};

export default Permissions;
