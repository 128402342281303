import React, { useState } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import InputField from "../../components/formElement/InputField";
import icon from "../../assets/images/add-btn.svg";
import AddEditRentalInventory from "./AddEditRentalInventory";
import { useSelector,useDispatch } from "react-redux";
import  {RentalDataType}  from "../../redux/store/InventoryReducer";
import {AppDispatch, RootState } from "../../redux/store";
import { FilterMatchMode } from "primereact/api";




interface RentalInventoryDialogBoxProps {
  rentalInventoryVisible: boolean;
  onHide: () => void;
}


const AddRentalInventory: React.FC<RentalInventoryDialogBoxProps> = ({
  onHide,
  rentalInventoryVisible,
}) => {

  // Global filter states 
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [filters, setFilters] = useState<DataTableFilterMeta>({
     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
//states for add-Edit  modal 
  const [headers, setHeader] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [globalFilterValueItem, setGlobalFilterValueItem] = useState("");
  const [editData, setEditData] = useState<RentalDataType | null>(null);


  const dispatch = useDispatch<AppDispatch>();
  const { rentalDrug } = useSelector(
    (state: RootState) => state.inventoryReducer
  );


//global filter Handler
  const onGlobalFilterChangeItem = (e: any) => {
    const value = e.target.value;
    setGlobalFilterValueItem(value);
  
    
  };


  const onFilterApply = () => {
    const value=globalFilterValueItem.toLocaleLowerCase()
    setFilters((prev) => ({...prev, global: { ...prev.global, value}}));
    
  };

  const clearData = () => {
    setGlobalFilterValueItem("")
     setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
 });
       
    
  };


   const onModalOpenClick= (headerText: string, e:any)=>{
    const data=e.data
    setEditData(data);
   setHeader(headerText);

   setIsModalVisible(true);
   }

  const openDialog = (headerText: string): void => {
    setHeader(headerText);

    setIsModalVisible(true);
    
  }
  const resetFilter = () => {
    setGlobalFilterValueItem("")
     setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
 });
  };

  return (
    <Dialog
      header="Rental: Specific Rental Equipment for Curlin 6000 Pump"
      visible={rentalInventoryVisible}
      onHide={()=>{
        onHide()
        resetFilter()
      }
        }
      style={{ width: "80vw" }}
      className="rental-inventory-dialog"
      draggable={false} 
    >
      <div className="grid rentalgrid">
        <div className=" md:col-3 filter  ">
          <div className="text-center input-container p-3 border-round-sm font-bold">
            <InputField placeholder="Search Serial #" id="Search Serial #" 
              globalFilterValue={globalFilterValueItem}
              onGlobalFilterChange={onGlobalFilterChangeItem}
            />
          </div>
        </div>
        <div className=" md:col-2 filter ">
          <div className="text-center p-3 border-round-sm  font-bold ">
            <Button
              label="Apply Filter"
              onClick={onFilterApply}
              className="p-button-sm inventory-btn"
            />
          </div>
        </div>
        <div className=" md:col-6"></div>
        <div className=" md:col-1">
          <div className="text-end p-3 border-round-sm font-bold ">
            <Button
              className="add-Rental-btn"
              tooltip="add item"
              tooltipOptions={{ position: "bottom" }}
              icon={
                <img
                  src={icon}
                  alt="My Icon"
                  style={{ width: "20px", height: "20px" }}
                />
              }
              style={{
                color: "rgba(0, 0, 0, 1)",
                backgroundColor: "white",
                border: "none",
              }}
              onClick={() => openDialog("Add Inventory")}
            />
          </div>
        </div>
      </div>

      <DataTable
        style={{
          width: "inherit",
          border: "1px solid rgba(27, 61, 109, 0.21)",
        }}
        globalFilter={globalFilter}
        filters={filters}
       
        globalFilterFields={['serialNumber']}
        value={rentalDrug}
        onRowClick={(e) => {

          onModalOpenClick("Edit data for item: Curlin 6000 Pump",e)
        }}
        className=" inventoryDatatable rentalInventory-datatable"
      >
        <Column field="serialNumber" header="Serial #"  filterMatchMode="contains" />
        <Column field="or" header="O/R" />
        <Column field="status" header="Status" />
        <Column field="inUseBy" header="In Use By" />
        <Column field="expiryDate" header="Expiry Date" />
        <Column field="dispens" header="Dispens." />
        <Column field="lastPM" header="Last PM" />
        <Column field="nextPM" header="Next PM" />
        <Column field="value" header="Value" />
        <Column field="placedInSvc" header="Placed in Svc." />
        <Column field="outOfSvc" header="Out of Svc" />
        <Column field="site" header="Site" />
      </DataTable>

      <AddEditRentalInventory
        header={headers}
        visibleNew={isModalVisible}
        editData={editData}
        onHide={() => setIsModalVisible(false)}
      />

      <div style={{ textAlign: "center" }}>
        <Button
          label="Clear"
          className="inventory-button-secondary rental-button "
          style={{
           
           
          }}
          onClick={clearData}
        />
        <Button
          label="Save"
          className="inventory-foter-button  rental-button  "
          style={{
           
          }}
        />
      </div>
    </Dialog>
  );
};

export default AddRentalInventory;