import React from "react";
import { Outlet } from "react-router-dom";
import PatientProfile from "./PatientProfile";
import "./PatientProfile.css";

const PatientProfileLayout = () => {
  return (
    <div className="pt-2">
      <PatientProfile />
      <div className="patient-content">
        <Outlet />
      </div>
    </div>
  );
};

export default PatientProfileLayout;
