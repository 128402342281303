import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import "react-toastify/dist/ReactToastify.css";
import AgencyFilter from "../../components/Filter/NurseAgencyFilter/NurseAgencyFilter";
import { ToastContainer } from "react-toastify";

const NurseAgency = ({ userEmail }: { userEmail: string | undefined }) => {
  const { arManagerData, loading: arManagerloading } = useSelector(
    (state: RootState) => state.arManagerReducer
  );
  const location = window.location.pathname;
  const [loading, setLoading] = useState(false);

  const handleStatusUpdateLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  return (
    <div className="NurseAgency">
      <ToastContainer />
      <AgencyFilter userEmail={userEmail} />
    </div>
  );
};

export default NurseAgency;
