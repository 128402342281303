import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useState } from "react";
import InputTextArea from "../formElement/InputTextArea";
import Input from "../formElement/Input";
import { UpdateFaxOwnername } from "../../redux/store/FaxDirectoryReducer";

const UpdateOwnerNameDialogBox = ({
  visible,
  setVisible,
  faxNumber,
  faxRecordid,
  userEmail,
  search_string,
}: {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  faxNumber: string;
  faxRecordid: number;
  userEmail?: any;
  search_string: string;
}) => {
  const { FaxDirectoryData } = useSelector(
    (state: RootState) => state.FaxDirectoryReducer
  );

  // const [assign, setAssign] = useState<FaxDirectoryData | undefined>();
  const dispatch = useDispatch<AppDispatch>();
  const [addOwnerName, setOwnerName] = useState({
    fax_record_id: 0,
    fax_owner_name: "",
    created_by: "",
    delete: 0,
  });
  const handleUpdateOwnerName = () => {
    // const data = addOwnerName;
    // const newData = `\"${data.replace(/"/g, '\\"')}\"`;
    // setFilterUpdate(FaxDirectoryFilters.search_string);

    dispatch(UpdateFaxOwnername({ data: addOwnerName, search_string: search_string }));
  };

  // Dialog Footer
  const Footer = (
    <div className="buttons flex gap-2 justify-content-end">
      <Button label="Cancel" outlined onClick={() => setVisible(false)} />
      <Button label="Save" onClick={handleUpdateOwnerName} />
      {/* <Button label="Save" onClick={AddAssignTo}/> */}
    </div>
  );

  const handleChangeOwnername = (e: any) => {
    const { value } = e.target;

    setOwnerName((prevState) => ({
      ...prevState,
      fax_owner_name: value,
      fax_record_id: Number(faxRecordid),
      created_by: userEmail,
      delete: 0,
    }));
  };

  return (
    <Dialog
      style={{ width: "700px" }}
      draggable={false}
      header="Fax Details"
      footer={Footer}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <Input
        value={addOwnerName.fax_owner_name}
        id="ownerName"
        label="Owner Name"
        type="text"
        handleChange={handleChangeOwnername}
      />
      <div className="grid m-0 align-items-center">
        <div className={`col-6`}>
          <label className="font-medium text-sm">Fax Number</label>
        </div>
        <div className={`col-6`}>{faxNumber}</div>
      </div>
    </Dialog>
  );
};

export default UpdateOwnerNameDialogBox;
