import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Input from "../formElement/Input";
import InputDropdown from "../formElement/InputDropdown";
import InputTextArea from "../formElement/InputTextArea";
import {
  addRental,
  deleteRental,
  RentalDataType,
  updateRental,
} from "../../redux/store/InventoryReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import InputDate from "../formElement/InputDate";

interface AddInventoryDialogBoxProps {
  header: string;
  visibleNew: boolean;
  editData: RentalDataType | null;
  onHide: () => void;
}

const AddEditRentalInventory: React.FC<AddInventoryDialogBoxProps> = ({
  header,
  visibleNew,
  editData,
  onHide,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { rentalDrug } = useSelector(
    (state: RootState) => state.inventoryReducer
  );

  const [formData, setFormData] = useState<RentalDataType>({
    serialNumber: "",
    or: "",
    status: "",
    inUseBy: "",
    expiryDate: "",
    dispens: "",
    lastPM: "",
    nextPM: "",
    value: "",
    placedInSvc: "",
    outOfSvc: "",
    site: "",
    barcode: "",
    residualValue: "",
    dispensedTo: "",
    dispensedOn: "",
    notes: "",
  });

  const ownOrRentOptions = ["Own", "Rent", "Lease", "Loan"];
  const cprStatusOptions = ["Active", "Inactive", "Pending", "Complete"];
  const placedInServiceOptions = [
    "2021-01-01",
    "2022-01-01",
    "2023-01-01",
    "2024-01-01",
  ];
  const outOfServiceOptions = [
    "2023-01-01",
    "2023-06-01",
    "2024-01-01",
    "Not applicable",
  ];

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof RentalDataType
  ) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleDropdownChange = (
    e: { value: any },
    field: keyof RentalDataType
  ) => {
    setFormData({ ...formData, [field]: e.value });
  };

  const handleDateChange = (
    e: { value: Date | null },
    field: keyof RentalDataType
  ) => {
    setFormData({ ...formData, [field]: e.value });
  };

  const handleSave = async () => {
    if (header === "Edit data for item: Curlin 6000 Pump") {

      const newArray = [...rentalDrug];

      const data = {
        ...formData,
      };

      await dispatch(updateRental({ data: data }));
    } else {
      const newArray = [...rentalDrug];

      const data = {
        ...formData,
      };

      await dispatch(addRental({ data: data }));
    }

    onHide();
  };

  const deleteHandle = async () => {
    const data = { ...formData };

    await dispatch(deleteRental({ data: data }));
    onHide();
  };

  useEffect(() => {
    if (visibleNew) {
      if (header === "Edit data for item: Curlin 6000 Pump" && editData) {
        // Set formData from editData
        setFormData(editData);
      } else {
        // Reset formData for new entry
        resetform();
      }
    }
  }, [visibleNew, editData]);

  const resetform = () => {
    setFormData({
      serialNumber: "",
      or: "",
      status: "",
      inUseBy: "",
      expiryDate: "",
      dispens: "",
      lastPM: "",
      nextPM: "",
      value: "",
      placedInSvc: "",
      outOfSvc: "",
      site: "",
      barcode: "",
      residualValue: "",
      dispensedTo: "",
      dispensedOn: "",
      notes: "",
    });
  };

  return (
    <Dialog
      header={header}
      visible={visibleNew}
      style={{ width: "40vw" }}
      onHide={onHide}
      draggable={false}
    >
      <div className=" grid  rental-grid inventory-form-control">
        <div className="col-6 rentaCol">
          <Input
            id="serialNumber"
            label="serial#"
            type="text"
            labelclassName="Add-rental-lable col-6"
            inputclassName="col-6"
            value={formData.serialNumber}
            handleChange={(e: any) => handleInputChange(e, "serialNumber")}
          />
        </div>
        <div className="col-6 rentaCol">
          <InputDropdown
            id="Placed in service"
            label="Placed in service"
            type="text"
            labelclassName="Add-rental-lable col-6"
            options={placedInServiceOptions}
            value={formData.placedInSvc}
            handleChange={(e: any) => handleInputChange(e, "placedInSvc")}
          />
        </div>
        <div className="col-6 rentaCol">
          <InputDropdown
            id="Own or Rent"
            label="Own or Rent"
            type="text"
            labelclassName="Add-rental-lable col-6"
            options={ownOrRentOptions}
            value={formData.or}
            handleChange={(e: any) => handleInputChange(e, "or")}
          />
        </div>
        <div className="col-6 rentaCol">
          <InputDate
            value={new Date(formData.outOfSvc)}
            id="Out of service"
            label="Out of service"
            labelclassName="Add-rental-lable col-6"
            inputclassName="col-6"
            handleChange={(e: any) => handleInputChange(e, "outOfSvc")}
          />
        </div>
        <div className="col-6 rentaCol">
          <InputDropdown
            id="CPR+ status"
            label="CPR+ status"
            type="text"
            labelclassName="Add-rental-lable col-6"
            options={cprStatusOptions}
            value={formData.status}
            handleChange={(e: any) => handleInputChange(e, "status")}
          />
        </div>
        <div className=" col-6 rentaCol">
          <Input
            id="Acquisition cost"
            label="Acquisition cost"
            type="text"
            labelclassName="Add-rental-lable col-6"
            inputclassName="col-6 "
            value={formData.inUseBy}
            handleChange={(e: any) => handleInputChange(e, "inUseBy")}
          />
        </div>
        <div className=" col-6 rentaCol">
          <InputDropdown
            id="Dispensed to"
            label="Dispensed to"
            type="text"
            labelclassName="Add-rental-lable col-6 "
            options={placedInServiceOptions}
            value={formData.dispensedTo}
            handleChange={(e: any) => handleInputChange(e, "dispensedTo")}
          />
        </div>

        <div className=" col-6 rentaCol">
          <Input
            id="Asset #"
            label="Asset #"
            type="text"
            labelclassName="Add-rental-lable col-6"
            inputclassName="col-6"
            value={formData.value}
            handleChange={(e: any) => handleInputChange(e, "value")}
          />
        </div>
        <div className=" col-6 rentaCol">
          <InputDropdown
            id="Dispensed on"
            label="Dispensed on"
            type="text"
            labelclassName="Add-rental-lable col-6"
            options={outOfServiceOptions}
            value={formData.dispensedOn}
            handleChange={(e: any) => handleInputChange(e, "dispensedOn")}
          />
        </div>
        <div className=" col-6 rentaCol">
          <InputDropdown
            id="Total hrs in service"
            label="Total hrs in service"
            type="text"
            labelclassName="Add-rental-lable col-6"
            options={placedInServiceOptions}
            value={formData.dispens}
            handleChange={(e: any) => handleInputChange(e, "dispens")}
          />
        </div>
        <div className=" col-6 rentaCol">
          <InputDropdown
            id="Last PM"
            label="Last PM"
            type="text"
            labelclassName="Add-rental-lable col-6"
            options={outOfServiceOptions}
            value={formData.lastPM}
            handleChange={(e: any) => handleInputChange(e, "lastPM")}
          />
        </div>
        <div className=" col-6 rentaCol">
          <Input
            id="Barcode"
            label="Barcode"
            type="text"
            labelclassName="Add-rental-lable col-6"
            inputclassName="col-6"
            value={formData.barcode}
            handleChange={(e: any) => handleInputChange(e, "barcode")}
          />
        </div>
        <div className=" col-6 rentaCol">
          <InputDropdown
            id="Next PM"
            label="Next PM"
            type="text"
            labelclassName="Add-rental-lable col-6"
            options={outOfServiceOptions}
            value={formData.nextPM}
            handleChange={(e: any) => handleInputChange(e, "nextPM")}
          />
        </div>
        <div className=" col-6 rentaCol">
          <InputDropdown
            id="Site"
            label="Site"
            type="text"
            labelclassName="Add-rental-lable col-6"
            options={ownOrRentOptions}
            value={formData.site}
            handleChange={(e: any) => handleInputChange(e, "site")}
          />
        </div>
        <div className=" col-6 rentaCol">
          <InputDropdown
            id="Next check"
            label="Next check"
            type="text"
            labelclassName="Add-rental-lable col-6"
            options={outOfServiceOptions}
            value={formData.expiryDate}
            handleChange={(e: any) => handleInputChange(e, "expiryDate")}
          />
        </div>
        <div className=" col-6 rentaCol">
          <Input
            id="Residual value"
            label="Residual value"
            type="text"
            labelclassName="Add-rental-lable col-6"
            inputclassName="col-6"
            value={formData.residualValue}
            handleChange={(e: any) => handleInputChange(e, "residualValue")}
          />
        </div>
        <div className=" col-12 rentaCol">
          <InputTextArea
            id="Notes"
            label="Notes"
            labelclassName="Add-rental-lable col-5 "
            inputclassName="Add-rental-lable col-7"
            value={formData.notes}
            rows={2}
            //maxLength={100}
            placeholder="Enter memo"
            handleChange={(e: any) => handleInputChange(e, "notes")}
          />
        </div>
      </div>

      <div
        className={`  ${
          header === "Edit data for item: Curlin 6000 Pump"
            ? " inventory-grid"
            : " inventory-grid2"
        }  rental-inventory-grid `}
      >
        <Button
          label="Clear"
          className={` inventory-button-secondary    col-4  ${
            header === "Edit data for item: Curlin 6000 Pump"
              ? "edit-active"
              : "inactive"
          } `}
          onClick={resetform}
        />

        <Button
          label="Save"
          className={`inventory-foter-button     col-4  ${
            header === "Edit data for item: Curlin 6000 Pump"
              ? "edit-active "
              : "inactive  "
          } `}
          onClick={() => {
            handleSave();
          }}
        />
        {header === "Edit data for item: Curlin 6000 Pump" && (
          <Button
            label="Delete"
            className={`inventory-foter-button    col-4 ${
              header === "Edit data for item: Curlin 6000 Pump"
                ? "edit-active  "
                : "inactive  col-6"
            } `}
            onClick={deleteHandle}
          />
        )}
      </div>
    </Dialog>
  );
};

export default AddEditRentalInventory;
