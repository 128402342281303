import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import MultiSelectFilter from "../../formElement/MultiSelect";
import InputField from "../../formElement/InputField";
import RoleManagement from "../../DataTable/PermissionsDataTable/RoleManagement";
import { Dialog } from "primereact/dialog";
import InputDropdown from "../../formElement/InputDropdown";
import InputCheck from "../../formElement/InputCheck";
import UserManagement from "../../DataTable/PermissionsDataTable/UserManagement";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { values } from "pdf-lib";
import PermissionNew from "./PermissionNew";
import PermissionDataTable from "../../DataTable/PermissionsDataTable/PermissionDataTable";

type data = {
  role_id: string;
  role_name: string;
  page_name: string;
  page_id: string;
  section_name: string;
};

const RoleManagementFilter = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<boolean>(false);
  const { permissionsList, pages, section, loading, errorMessage } =
    useSelector((state: RootState) => state.permissionsReducer);

//   const handleRegisterPl = () => {
//     setIsModalOpen(true);
//   };
//   const handleClose = () => {
//     setIsModalOpen(false);
//   };

//   const AllCensus = ["patient", "provider"];
//   const handleRedioChange = (value: boolean) => {
//     setSelectedOption((prev) => !prev);
//   };
  const [activeTab, setActiveTab] = useState<
    "user role" | "role Privileges"
  >("user role");
  const handleSubComponentChange = (
    component: "user role" | "role Privileges"
  ) => {
    setActiveTab(component);
  };

  const columns = [
    {
        field: "role",
        header: "Role",
        sortable: true,
    },
    {
        field: "page name",
        header: "page name",
        sortable: true,
    },
    {
        field: "section name",
        header: "section name",
        sortable: true,
    },
    {
        field: "read",
        header: "read",
        sortable: true,
    },
    {
        field: "modify",
        header: "modify",
        sortable: true,
    },
    {
        field: "add",
        header: "add",
        sortable: true,
    },
    {
        field: "delete",
        header: "delete",
        sortable: true,
    },
    {
        field: "all",
        header: "all",
        sortable: true,
    },
    {
        field: "deny",
        header: "deny",
        sortable: true,
    },
    {
        field: "action",
        header: "action",
    },

];

  return (
    <>
      <div className="roles flex gap-4 pt-2">
        <Button
          className={`custom-subrole ${
            activeTab === "user role" ? "activesubrole" : ""
          }`}
          onClick={() => handleSubComponentChange("user role")}
        >
          user role
        </Button>
        <Button
          className={`custom-subrole ${
            activeTab === "role Privileges" ? "activesubrole" : ""
          }`}
          onClick={() => handleSubComponentChange("role Privileges")}
        >
          role Privileges
        </Button>
      </div>
      <div className="subcomponent-content">
        {activeTab === "user role" && (
          <div className="user-role-component"></div>
        )}
        {activeTab === "role Privileges" && (
          <div className="role-privileges-component"></div>
        )}
      </div>
      <div className="line"></div>
      <PermissionNew activeTab={activeTab}/>
      <PermissionDataTable activeTab={activeTab} columns={columns}/>
      {/* <div className="grid align-items-center my-1 justify-content-between">
                <div className='flex'>
                    <div className="col-5 pt-3">
                        <InputField
                            placeholder="Search By Role"
                            id="role"
                            className='custom-searchbar'
                        />
                    </div>
                    <div className="col-4">
                        <InputDropdown
                            id="allroles"
                            type="text"
                            placeholder='All Roles'
                            labelclassName="custom-fontwt"
                            value={selectedRoleId || ""}
                            options={permissionsList.map((role: data) => ({
                                label: role.role_name,
                                value: role.role_id,
                            }))}
                            handleChange={(event: any) => setSelectedRoleId(event.target.value)}
                        />
                        {loading && <p>Loading data...</p>}
                        {errorMessage && <p className="error">{errorMessage}</p>}

                    </div>
                    <div className="col-4">
                        <InputDropdown
                            id="AllCensus"
                            type="text"
                            placeholder='All Census'
                            labelclassName=""
                            value={selectedPageId || ""}
                            options={pages.map((allpage: data) => ({
                                label: allpage.page_name,
                                value: allpage.page_id,
                            }))}
                            // Update the selectedPageId when a page is selected
                            handleChange={(event: any) => setSelectedPageId(event.target.value)}
                        />
                        {loading && <p>Loading data...</p>}
                        {errorMessage && <p className="error">{errorMessage}</p>}

                    </div>
                    <div className="col-4">
                        <InputDropdown
                            id="AllSection"
                            type="text"
                            placeholder="AllSection"
                            labelclassName=""
                            value={selectedSectionName}
                            options={filteredSections.map((AllSection: data) => ({
                                label: AllSection.section_name || "",
                                value: AllSection.section_name || "",
                            }))}
                            handleChange={(event) => setSelectedSectionName(event.target.value)}

                            disabled={!selectedPageId}
                        />
                        {loading && <p>Loading data...</p>}
                        {errorMessage && <p className="error">{errorMessage}</p>}

                    </div>

                </div>
                <div className='flex justify-end mr-2'>
                    <Button className='text-sm w-7rem pl-3'
                        onClick={handleRegisterPl}>+ ADD NEW</Button>
                </div>
            </div> */}
      {/* <Dialog
                header=
                // ""
                {<div>
                    <div className="custom-dialog-header ml-3">
                        <img src={RolePrivileges} alt="RolePrivileges" className="role-icon" />
                        <span className='ml-2'>Register Privileges</span>
                    </div>
                    <p className="dialog-content ml-5">To register new privileges at the section level</p>

                </div>}
                visible={isModalOpen}
                onHide={handleClose}
                style={{ width: '30vw' }}
            >
                <div className=' ml-2'>

                    <div className='pt-2'>
                        <label className='ml-3 text-sm'>Role</label>
                        <div className='col-6 pt-0'>
                            <InputDropdown
                                id="Allrole"
                                type="text"
                                labelclassName="custom-inputwd"
                                value={selectedRoleId || ""}
                                options={permissionsList.map((role: data) => ({
                                    label: role.role_name,
                                    value: role.role_id,
                                }))}
                                handleChange={(event: any) => setSelectedRoleId(event.target.value)}
                            />
                            {loading && <p>Loading data...</p>}
                            {errorMessage && <p className="error">{errorMessage}</p>}
                        </div>
                    </div>
                    <div className='pt-2'>
                        <label className='ml-3 text-sm'>Page</label>
                        <div className='col-6 pt-0'>
                            <InputDropdown
                                id="AllPage"
                                type="text"
                                labelclassName=""
                                value={selectedPageId || ""}
                                options={pages.map((allpage: data) => ({
                                    label: allpage.page_name,
                                    value: allpage.page_id,
                                }))}
                                // Update the selectedPageId when a page is selected
                                handleChange={(event: any) => setSelectedPageId(event.target.value)}
                            />
                            {loading && <p>Loading data...</p>}
                            {errorMessage && <p className="error">{errorMessage}</p>}



                        </div>
                    </div>
                    <div className='pt-2'>
                        <label className='ml-3 text-sm'>Section</label>
                        <div className='col-6 pt-0'>
                            <InputDropdown
                                id="AllSection"
                                type="text"
                                labelclassName=""
                                value={selectedSectionName}
                                options={filteredSections.map((AllSection: data) => ({
                                    label: AllSection.section_name || "",
                                    value: AllSection.section_name || "",
                                }))}
                                handleChange={(event) => setSelectedSectionName(event.target.value)}

                                disabled={!selectedPageId}
                            />
                            {loading && <p>Loading data...</p>}
                            {errorMessage && <p className="error">{errorMessage}</p>}


                        </div>
                    </div>
                    <div className="pt-3 ml-2">
                        <label className='ml-2 text-sm'>Privileges</label>
                        <div className="flex flex-wrap gap-1">
                            <div className="col-3">
                                <InputCheck
                                    className="cheak"
                                    labelclassName="col-6"
                                    checkboxclassName="col-3"
                                    id={allOption}
                                    checked={selectedOptions.length === options.length + 1}
                                    handleChange={() => handleCheckboxChange(allOption)}
                                    label={allOption}
                                />
                            </div>
                            {options.map((option) => (
                                <div key={option} className="col-3">
                                    <InputCheck
                                        className="cheak"
                                        labelclassName="col-6"
                                        checkboxclassName="col-3"
                                        id={option}
                                        checked={selectedOptions.includes(option)}
                                        handleChange={() => handleCheckboxChange(option)}
                                        label={option}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="outlined flex justify-content-end gap-2">
                    <Button label="Cancel" outlined className="w-7rem" onClick={handleClose} />
                    <Button label="Save" className="w-7rem" onClick={handleClose} />
                </div>
            </Dialog> */}
      {/* <RoleManagement /> */}
    </>
  );
};
export default RoleManagementFilter;
