import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { Logo } from "../../assets/images/Icon";
import { InsuranceDataType } from "../../types/IHealthAppTypes";

type FooterProps = {
  pageNumber: number;
};

type InsurancePdfDocumentType = {
  data: InsuranceDataType[][];
  selectedState: string[];
};

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 11,
  },
  header: {
    textAlign: "left",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderColor: "#0a3e71",
    marginBottom: 20,
  },
  image: {
    width: 100,
    marginBottom: 5,
  },
  footer: {
    fontSize: 10,
    marginTop: 20,
    position: "absolute",
    bottom: 10,
    width: "90%",
    borderTop: "1px solid #0a3e71",
    paddingTop: 10,
  },
  title: { fontWeight: "extrabold", marginBottom: 6 },
  footerText: { fontWeight: "extralight" },
  label: {
    fontWeight: "hairline",
  },
  table: {
    width: "auto",
    marginBottom: 20,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e5e7eb",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeader: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#e5e7eb",
    borderStyle: "solid",
    fontSize: 11,
    maxHeight: 60,
    backgroundColor: "#f3f8fe",
    padding: 6,
    fontWeight: "extrabold",
    textAlign: "center",
  },
  tableCell: {
    flex: 1,
    padding: 6,
    borderWidth: 1,
    borderColor: "#e5e7eb",
    fontSize: 10,
    maxHeight: 60,
    borderStyle: "solid",
    textAlign: "center",
    fontWeight: "hairline",
  },
  tableCellEven: {
    backgroundColor: "#f9f9f9",
  },
  disclaimer: {
    fontSize: 10,
    marginTop: 20,
    paddingHorizontal: 20,
    textAlign: "justify",
  },
  pageNumber: {
    position: "absolute",
    right: 40,
    fontSize: 10,
  },
});

const Header: React.FC = () => (
  <View style={styles.header}>
    <Image style={styles.image} src={Logo} />
  </View>
);

const Footer: React.FC<FooterProps> = ({ pageNumber }) => (
  <View style={styles.footer}>
    <Text style={styles.footerText}>
      &copy; Copyright 2023. InfuCareRx. All Rights Reserved.
    </Text>
    <Text style={styles.pageNumber}>{pageNumber}</Text>
  </View>
);

const Disclaimer: React.FC = () => (
  <View style={styles.disclaimer}>
    <Text>
      Disclaimer: The list of payers provided is for your convenience and may
      not reflect the most current status of our contracts. This list is for
      informational purposes only and does not guarantee participation, payment,
      or any specific level of benefits. It is the responsibility of the
      healthcare provider to verify member benefits and coverage for services.
      For the most up-to-date information on payer contracts and coverage
      details, please contact us directly.
    </Text>
  </View>
);

const FilterList: React.FC<{ selectedState: string[] }> = ({
  selectedState,
}) => (
  <View>
    <Text style={styles.title}>
      Insurance List:{" "}
      {selectedState.map((state, index) => (
        <Text key={index} style={styles.label}>
          {state}
          {index < selectedState.length - 1 ? ", " : ""}
        </Text>
      ))}
    </Text>
  </View>
);

export const paginateData = (
  data: InsuranceDataType[],
  rowsPerPage: number
): InsuranceDataType[][] => {
  const pages: InsuranceDataType[][] = [];
  for (let i = 0; i < data.length; i += rowsPerPage) {
    pages.push(data.slice(i, i + rowsPerPage));
  }
  return pages;
};

const InsurancePdfConverter: React.FC<InsurancePdfDocumentType> = ({
  data,
  selectedState,
}) => {
  return (
    <Document>
      {data.map((record, pageIndex) => (
        <Page size="A4" style={styles.page} key={pageIndex}>
          <Header />

          {pageIndex === 0 && <FilterList selectedState={selectedState} />}

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableHeader}>Organisation</Text>
              <Text style={styles.tableHeader}>Identifier</Text>
              <Text style={styles.tableHeader}>Payor Type</Text>
            </View>

            {record.map((row, rowIndex) => (
              <View
                style={[
                  styles.tableRow,
                  rowIndex % 2 === 0 ? styles.tableCellEven : {},
                ]}
                key={rowIndex}
              >
                <Text style={styles.tableCell}>{row.organization || ""}</Text>
                <Text style={styles.tableCell}>{row.identifier || ""}</Text>
                <Text style={styles.tableCell}>{row.payor_type || ""}</Text>
              </View>
            ))}
          </View>

          <Footer pageNumber={pageIndex + 1} />

          {pageIndex === data.length - 1 && <Disclaimer />}
        </Page>
      ))}
    </Document>
  );
};

export default InsurancePdfConverter;
