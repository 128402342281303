import { Calendar } from "primereact/calendar";
import React from "react";
import InputDate from "../../formElement/InputDate";
import { Calender } from "../../../assets/images/Icon";

const DateRangeFilter = ({
    setEndDate,
    startDate,
    setStartDate,
    endDate
}: {
    setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
    startDate?: Date,
    setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
    endDate?: Date
}) => {
    return (
        <div className="flex row position-relative">
            <div className="col-12 sm:col-6 pe-1">
                <Calendar
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.value || undefined)}
                    className="form-control overflow-hidden pe-0 mb-0"
                    showIcon
                    maxDate={endDate}
                    panelClassName="date-range-filter-panel"
                    icon={() => <img src={Calender} alt="Calender" />}
                />
            </div>
            <div className="col-12 sm:col-6 dateinput pl-2">
                <Calendar
                    placeholder="End Date"
                    value={endDate}
                    onChange={(e) => {
                        if (e.value) {
                            const newDate = new Date(e.value)
                            newDate.setHours(23);
                            newDate.setMinutes(59);
                            newDate.setSeconds(59);
                            setEndDate(newDate);
                        } else {
                            setEndDate(undefined);
                        }
                    }}
                    className="form-control overflow-hidden pe-0 mb-0"
                    showIcon
                    minDate={startDate}
                    panelClassName="date-range-filter-panel"
                    icon={() => <img src={Calender} alt="Calender" />}
                />
            </div>
            {/* <div className="col-12 md:col">
                <InputDate
                    id="startDate"
                    label="From:"
                    labelclassName="col-2"
                    inputclassName="col-10"
                    // handleChange={(e: { value: Date | undefined }) => setStartDate(e.value || undefined)}
                    handleChange={(e: { value: Date | undefined }) => setStartDate(e.value || undefined)}
                    value={startDate}
                />
            </div>
            <div className="col-12 md:col">
                <InputDate
                    id="endDate"
                    label="To:"
                    labelclassName="col-2"
                    inputclassName="col-10"
                    // handleChange={(e: { value: Date | undefined }) => setEndDate(e.value || undefined)}
                    handleChange={(e: { value: Date | undefined }) => {
                        if (e.value) {
                            const newDate = new Date(e.value)
                            newDate.setHours(23);
                            newDate.setMinutes(59);
                            newDate.setSeconds(59);
                            setEndDate(newDate);
                        } else {
                            setEndDate(undefined);
                        }
                    }}
                    value={endDate}
                />
            </div> */}
        </div>
    );
};

export default DateRangeFilter;