import { AppDispatch } from '../../redux/store'
import React, { useState, useEffect, useMemo } from "react";
import { DataTableFilterMeta } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import Filterdata from "../../components/Filter/FaxFilter/FilterData";
import { useDispatch, useSelector } from "react-redux";
import { toggleHistoryModal, fetchFaxData, markJunkFax, getFaxHistory } from "../../redux/store/FaxSliceReducer"; // Import all at once
import JunkFaxDataTable from "./JunkFaxDataTable";
import HistoryModal from "./History-modal";
import GlobalFaxFilter from "../../components/Filter/FaxFilter/GlobalFilter";
import FaxLineFilter from "../../components/Filter/FaxFilter/FaxLineFilter";
import DateRangeFilter from "../../components/Filter/FaxFilter/DateRangeFilter";
import { RootState } from "../../redux/store";
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';

// Define the FaxItem interface
export type FaxItem = {
    fax_detail_id: number;
    fax_line: string;
    fax_number: string;
    date: string;
    caller: string;
    pages: number;
    assignment_status: string;
    file_name: string;
};

// Define the filters interface with an index signature
interface Filters extends DataTableFilterMeta {
    global: {
        value: string | null;
        matchMode: FilterMatchMode;
    };
    fax_line: {
        value: (string | null)[]; // Update to allow null values
        matchMode: FilterMatchMode;
    };
    fax_number: {
        value: string | null;
        matchMode: FilterMatchMode;
    };
    caller: {
        value: string | null;
        matchMode: FilterMatchMode;
    };
    pages: {
        value: string | null;
        matchMode: FilterMatchMode;
    };
    assignment_status: {
        value: string | null;
        matchMode: FilterMatchMode;
    };
    [key: string]: any; // Adding index signature
}


const JunkFaxScreen: React.FC<{ name?: string; userEmail?: string }> = ({ name, userEmail }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { loading, junkFaxData, allFaxData } = useSelector((state: RootState) => state.FaxSliceReducer);

    useEffect(() => {
        if (allFaxData?.length === 0) {
            dispatch(fetchFaxData());
        }
    }, [dispatch]);

    const data: FaxItem[] = useMemo(() => {
        return junkFaxData.map(item => ({
            ...item,
            fax_detail_id: item.fax_detail_id,
            fax_line: item.fax_line,
            fax_number: item.fax_number,
            date: item.date_and_time,
            caller: item.caller,
            pages: Number(item.pages),
            assignment_status: item.assignment_status,
        }));
    }, [junkFaxData]);

    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [filteredData, setFilteredData] = useState<FaxItem[]>(data);


    const [filters, setFilters] = useState<Filters>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        fax_line: { value: [], matchMode: FilterMatchMode.IN }, // Change null to empty array
        fax_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
        caller: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pages: { value: null, matchMode: FilterMatchMode.CONTAINS },
        assignment_status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });


    const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

    const [faxFilterValue, setFaxFilterValue] = useState<string>(""); // Keep it as string


    useEffect(() => {
        const filtered = data.filter((item) => {
            const itemDate = new Date(item.date);
            const inDateRange = startDate && endDate ? itemDate >= new Date(startDate) && itemDate <= new Date(endDate) : true;

            const matchesGlobalFilter = !filters.global.value || (filters.global.value && item.fax_line && item.fax_line.includes(filters.global.value));
            const matchesFaxLineFilter =
                !filters.fax_line?.value?.length ||
                filters.fax_line?.value?.some(line => {
                    if (line === null) {
                        return item.fax_line === null || item.fax_line === ""; // Check for blank
                    }
                    return item.fax_line && item.fax_line.includes(line);
                });


            return inDateRange && matchesGlobalFilter && matchesFaxLineFilter;
        });

        setFilteredData(filtered);
    }, [startDate, endDate, data, filters]);

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilters((prev) => ({
            ...prev,
            global: { ...prev.global, value }
        }));
        setGlobalFilterValue(value);
    };

    const onFaxFilterChange = (e: { value: (string | null)[] }) => {
        const value = e.value;

        // Map "_blank" back to null for storing in the filters
        const updatedValues = value.includes("_blank")
            ? value.map(v => (v === "_blank" ? null : v)) // Convert "_blank" back to null
            : value;

        // Update the filters state with the new fax line values
        setFilters(prev => ({
            ...prev,
            fax_line: { ...prev.fax_line, value: updatedValues }
        }));
    };

    const faxLineOptions = useMemo(() => {
        const uniqueLines = Array.from(new Set(junkFaxData?.map(item => item.fax_line)));
        return uniqueLines.map(line => ({
            label: line === null ? "Blank" : line, // Show "Blank" for null values
            value: line === null ? line : line // Map null values to "_blank"
        }));
    }, [junkFaxData]);

    const undoBody = (rowData: FaxItem) => (
        <div className="flex justify-content-center">
            <div className="archive-btn edit-icon" onClick={() => {
                dispatch(getFaxHistory(rowData.fax_detail_id));
                dispatch(toggleHistoryModal({ historyModal: true, historyModalHeader: rowData.file_name }));
            }}>
                <i className="pi pi-history" />
            </div>
            <div className="archive-btn edit-icon" onClick={() => dispatch(markJunkFax({
                action_type: rowData.assignment_status,
                description: "",
                fax_detail_id: rowData.fax_detail_id,
                is_junk: false,
                status: "",
                LoginEmail: userEmail || "", // Ensure a default value
            }))}>
                Undo
            </div>
        </div>
    );
    const handleResetFilters = () => {
        // Resetting the start and end dates
        setStartDate(undefined); // Reset start date
        setEndDate(undefined); // Reset end date

        // Resetting filters
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            fax_line: { value: [], matchMode: FilterMatchMode.IN }, // Reset fax line to an empty array for MultiSelect
            fax_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
            caller: { value: null, matchMode: FilterMatchMode.CONTAINS },
            pages: { value: null, matchMode: FilterMatchMode.CONTAINS },
            assignment_status: { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        // Clear global filter value in state
        setGlobalFilterValue(""); //
    };

    return (
        <div>
            {/* <Filterdata
                filtersCompo={
                    <div className="position-relative w-75">
                        <div className="row dataflex filter-header">
                            <div className="col-md-3 pe-1">
                                <div className="search-bar">
                                    <GlobalFaxFilter
                                        globalFilterValue={globalFilterValue}
                                        onGlobalFilterChange={onGlobalFilterChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 pe-1">
                                <DateRangeFilter
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                />
                            </div>

                            <div className="col-md-2  pe-1 dropdown-filter">
                                <MultiSelect
                                    value={filters.fax_line?.value || []} // Ensure it's an array or fallback to empty
                                    options={faxLineOptions}
                                    onChange={onFaxFilterChange}
                                    placeholder="Select Fax Lines"
                                    style={{ width: '100%' }}
                                    maxSelectedLabels={1}
                                    panelClassName="dropdown-filter-panel"
                                    // display="chip"
                                    filter
                                />
                            </div>
                            <div className="col-auto">
                                <Button onClick={handleResetFilters} className="fax-reset-btn">Reset</Button>
                            </div>
                        </div>
                    </div>

                }
                setFilters={setFilters}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
            /> */}
            <Filterdata
                filtersCompo={
                    <div className="relative w-12">
                        <div className="grid align-items-center filter-header">
                            <div className="col-12 sm:col-3 pr-1">
                                <div className="search-bar">
                                    <GlobalFaxFilter
                                        globalFilterValue={globalFilterValue}
                                        onGlobalFilterChange={onGlobalFilterChange}
                                    />
                                </div>
                            </div>
                            <div className="col-12 sm:col-3 pr-1">
                                <DateRangeFilter
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                />
                            </div>
                            <div className="col-12 sm:col-2 pr-1 dropdown-filter">
                                <MultiSelect
                                    value={filters.fax_line?.value || []}
                                    options={faxLineOptions}
                                    onChange={onFaxFilterChange}
                                    placeholder="Select Fax Lines"
                                    style={{ width: '100%' }}
                                    maxSelectedLabels={1}
                                    panelClassName="dropdown-filter-panel"
                                    filter
                                />
                            </div>
                            <div className="col-auto">
                                <Button onClick={handleResetFilters} className="fax-reset-btn">Reset</Button>
                            </div>
                        </div>
                    </div>
                }
                setFilters={setFilters}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />
            <div className="card border-0 mt-0">
                <div className="table-responsive fax-table" style={{ overflowX: "auto" }}>
                    <JunkFaxDataTable
                        data={filteredData}
                        filters={filters}
                        loading={loading}
                        undoBody={undoBody}
                    />
                </div>
            </div>
            <HistoryModal />
        </div>
    );
};

export default JunkFaxScreen;