import { Dialog } from "primereact/dialog";
import InputDropdown from "../../formElement/InputDropdown";
import { statusList } from "../../../types/IHealthAppTypes";
import { Button } from "primereact/button";
import {
  getArManagerData,
  updatePatientClaimsStatus,
} from "../../../redux/store/ARManagerReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useState } from "react";
import { Toast } from "primereact/toast";
import { displayToast } from "../../Toast/ToastMessage";

const ChangeStatusDialogBox = ({
  userEmail,
  data,
  visible,
  setVisible,
  onStatusUpdateLoading,
  onStatusUpdated,
  filterUpdate,
}: {
  userEmail: string | undefined;
  data: string[];
  visible: boolean;
  setVisible: any;
  onStatusUpdateLoading: (isLoading: boolean) => void;
  onStatusUpdated: () => void;
  filterUpdate: string;
}) => {
  const { statusList } = useSelector(
    (state: RootState) => state.arManagerReducer
  );
  const [status, setStatus] = useState<statusList | undefined>();
  const dispatch = useDispatch<AppDispatch>();
  const newData = data.join(",");

  // Update the Row Status
  const UpdateStatus = async () => {
    onStatusUpdateLoading(true);
    if (status) {
      const updateStatus = {
        cpk_claims: newData,
        status_text: status.description,
        status_no: status.source_id,
        email: userEmail,
      };
      setVisible(false);
      await dispatch(
        updatePatientClaimsStatus({
          updateData: updateStatus,
          callback: (res: any) => {
            if (res.data.success) {
              displayToast({
                msg: "The status for the selected claims has been updated successfully.",
                type: "success",
              });
            } else {
              displayToast({
                msg: res?.data?.message || "Something went wrong",
                type: "error",
              });
            }
          },
        })
      );
      await dispatch(
        getArManagerData({ updateData: filterUpdate })
      );
      onStatusUpdated();
    }
  };

  // Dialog Footer
  const Footer = (
    <div className="buttons flex gap-2 justify-content-end">
      <Button label="Cancel" outlined onClick={() => setVisible(false)} />
      <Button label="Save" onClick={UpdateStatus} />
    </div>
  );

  const handleChange = (e: any) => {
    const selectedStatus = statusList.find(
      (item: statusList) => item.status_code_and_description === e.target.value
    );
    if (selectedStatus) {
      setStatus(selectedStatus);
    }
  };

  return (
    <>
      <Dialog
        style={{ width: "700px" }}
        draggable={false}
        header="Update Status for Selected Invoice(s):"
        footer={Footer}
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <InputDropdown
          value={status?.status_code_and_description || ""}
          handleChange={handleChange}
          label="Status"
          labelclassName="col-2"
          dropdownclassName="col-10"
          type="text"
          id="status_code_and_description"
          placeholder="Search Status Code, Status"
          filter
          options={statusList.map(
            (status: statusList) => status.status_code_and_description
          )}
        />
      </Dialog>
    </>
  );
};

export default ChangeStatusDialogBox;
