import "../../assets/css/index.css";
import { Link, useLocation } from "react-router-dom";
import {
  Home,
  Census,
  Nurse,
  Appointments,
  BillReview,
  PrescriberOrder,
  Documents,
  Fax,
  FaxDirectory,
  OutboundFaxLog,
  TextMessages,
  NewStarts,
  InventaryUpdates,
  NursingForms,
  ARManager,
  NotesManager,
  CarePlan,
  Permissions,
  PatientWorkflows,
  Reports,
  Signature,
  Insurance,
  Pricing,
  Tasklist,
  Inventory,
  Shipment,
} from "../../assets/images/Icon";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { getAssignRoleByUser } from "../../redux/store/RoutePermission";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useEffect, useState } from "react";
import { PermissionPropsTypes } from "../../types/IHealthAppTypes";

interface SidebarProps {
  userEmail: string;
}

const Sidebar: React.FC<SidebarProps> = ({ userEmail }) => {
  const powerApp = process.env.REACT_APP_POWERAPP_LINK;
  const formsURL = process.env.REACT_APP_FORMSURL_LINK;
  const scheduling = process.env.REACT_APP_SCHEDULING_LINK;
  const powerPagesURL = process.env.REACT_APP_POWERPAGESURL_LINK;
  const newStartURL = process.env.REACT_APP_NEWSTARTURL_LINK;

  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const { assignRoleByUser } = useSelector(
    (state: RootState) => state.routePermissionReducer
  );

  const [userPermission, setUserPermission] = useState<
    Record<string, { read: string; deny: string }> | undefined
  >();

  const [fromNavigation, setFromNavigation] = useState<string>("");

  useMsalAuthentication(InteractionType.Redirect);

  useEffect(() => {
    if (userEmail) {
      dispatch(getAssignRoleByUser());
    }
  }, [userEmail, dispatch]);

  useEffect(() => {
    if (assignRoleByUser.length > 0) {
      const permissions = assignRoleByUser.reduce((acc, { page, read, write, delete: del, modify, section, all, deny, role }) => {
        if (!acc[page]) {
          acc[page] = { read, write, page, delete: del, modify, section, all, deny, role };
        } else {
          if (role === "Exception") {
            acc[page] = { read, write, delete: del, modify, page, section, all, deny, role };
          } else {
            if (section === "All Section") {
              return acc;
            } else {
              acc[section] = { read, write, delete: del, page, modify, section, all, deny, role };
            }
          }
        }
        return acc;
      }, {} as Record<string, PermissionPropsTypes>);
  
      setUserPermission(permissions);
    }
  }, [assignRoleByUser]);

  useEffect(() => {
    const navigation = sidebarItems.filter(
      (items) => items.to === location.pathname
    );
    setFromNavigation(navigation[0]?.SchemaName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const sidebarItems = [
    {
      to: '/census',
      icon: Census,
      label: "Census",
      SchemaName: "census",
      isExternal: false,
      page: "Census",
    },
    {
      to: powerApp,
      icon: Nurse,
      SchemaName: "nurse-agency",
      isExternal: true,
      label: "Nurse/Agency",
      page: "Health Infusion Nurse/Agency",
    },
    {
      to: scheduling,
      icon: Appointments,
      label: "Appointments",
      SchemaName: "appointments",
      isExternal: true,
      page: "Schedule Management",
    },
    // {
    //   to: "/schedule-management",
    //   icon: Appointments,
    //   label: "Appointments React",
    //   SchemaName: "schedule-management",
    //   isExternal: false,
    //   page: "Schedule Management",
    // },
    // {
    //   to: "/appointments",
    //   icon: Appointments,
    //   label: "Appointments React",
    //   SchemaName: "appointments",
    //   isExternal: false,
    //   page: "Schedule Management",
    // },
    {
      to: powerApp,
      icon: BillReview,
      label: "Bill Review",
      SchemaName: "bill-review",
      isExternal: false,
      page: "Bill Review",
    },
    // {
    //   to: powerApp,
    //   icon: PrescriberOrder,
    //   label: "Prescribers Order",
    //   SchemaName: "prescribers-order",
    //   isExternal: true,
    //   page: "DWO Workflow",
    // },
    {
      to: powerApp,
      icon: PrescriberOrder,
      label: "Prescribers Order",
      SchemaName: "prescribers-order",
      isExternal: true,
      page: "DWO Workflow",
    },
    {
      to: powerApp,
      icon: Documents,
      label: "Documents",
      SchemaName: "documents",
      isExternal: false,
      page: "Enterprise Document",
    },
    {
      to: "/fax",
      icon: Fax,
      label: "Fax",
      SchemaName: "fax",
      isExternal: false,
      page: "Fax Module",
    },
    // {
    //   to: powerApp,
    //   icon: FaxDirectory,
    //   label: "Fax Directory",
    //   SchemaName: "fax-directory",
    //   isExternal: false,
    //   page: "Fax Directory",
    // },
    {
      to: powerApp,
      icon: OutboundFaxLog,
      label: "Outbound Fax Log",
      SchemaName: "outbound-fax-log",
      isExternal: false,
      page: "Outbound Fax Log",
    },
    {
      to: powerApp,
      icon: TextMessages,
      label: "Text Messages",
      SchemaName: "text-messages",
      isExternal: false,
      page: "Text Messages",
    },
    {
      to: newStartURL,
      icon: NewStarts,
      label: "New Starts",
      SchemaName: "new-starts",
      isExternal: false,
      page: "New Starts",
    },
    {
      to: powerApp,
      icon: InventaryUpdates,
      label: "Inventory Updates",
      SchemaName: "inventory-update",
      isExternal: false,
      page: "Inventory Update",
    },
    {
      to: formsURL,
      icon: NursingForms,
      label: "Nursing Forms",
      SchemaName: "nursing-forms",
      isExternal: false,
      page: "Nursing Forms",
    },
    {
      to: "/ar-manager",
      icon: ARManager,
      label: "A/R Manager",
      SchemaName: "ar-manager",
      isExternal: false,
      page: "AR Manager",
    },
    {
      to: "/notes-manager",
      icon: NotesManager,
      label: "Notes Manager",
      SchemaName: "notes-manager",
      isExternal: false,
      page: "Notes Manager",
    },
    {
      to: powerApp,
      icon: CarePlan,
      label: "Care Plan",
      SchemaName: "care-plan",
      isExternal: false,
      page: "Care Plan Template",
    },
    {
      to: powerApp,
      icon: Permissions,
      label: "Permissions",
      SchemaName: "permissions",
      isExternal: false,
      page: "User Privileges",
    },
    // {
    //   to: '/permissions',
    //   icon: Permissions,
    //   label: "Permissions React",
    //   SchemaName: "permissions",
    //   isExternal: false,
    //   page: "User Privileges",
    // },
    {
      to: powerPagesURL,
      icon: PatientWorkflows,
      label: "Forms",
      SchemaName: "patient-workflow",
      isExternal: false,
      page: "Patient Workflow",
    },
    {
      to: powerApp,
      icon: Reports,
      label: "Reports",
      SchemaName: "reports",
      isExternal: false,
      page: "BI Reports",
    },
    {
      to: powerApp,
      icon: Signature,
      label: "Signature",
      SchemaName: "signature",
      isExternal: false,
      page: "Signature",
    },

    {
      to: "/shipment",
      icon: Shipment,
      label: "Shipment",
      SchemaName: "shipment",
      isExternal: false,
      page: "Shipment",
    },
    {
      to: "/insurance",
      icon: Insurance,
      label: "Insurance",
      SchemaName: "insurance",
      isExternal: false,
      page: "Insurance",
    },
    {
      to: "/pricing",
      icon: Pricing,
      label: "Pricing",
      SchemaName: "pricing",
      isExternal: false,
      page: "Pricing",
    },
    {
      to: "/tasklist",
      icon: Tasklist,
      label: "Task List",
      SchemaName: "task-list",
      isExternal: false,
      page: "Task List",
    },
    {
      to: "/inventory",
      icon: Inventory,
      label: "Inventory",
      SchemaName: "inventory",
      isExternal: false,
      page: "Inventory",
    },
  ];

  const isActive = (path: string) => location.pathname === path;

  return (
    <div
      className={`${
        location.pathname === "/access-denied" ? "hidden" : ""
      } shape sidebar-shape`}
    >
      <ul className="menu-list">
        <Link
          to={`${powerApp}&toscr=home&fromscr=${fromNavigation}`}
          className={isActive("/") ? "active" : ""}
        >
          <div className="img-active">
            <img className="custom-width-height" src={Home} alt="Home" />
          </div>
          <span>Home</span>
        </Link>
        {sidebarItems.map((item) => {
          const permissions = userPermission?.[item.page];
          if (permissions?.deny === "Y") return null;

          const linkTo =
            item.page === "Notes Manager" ||
            item.page === "Insurance" ||
            item.page === "AR Manager" ||
            item.page === "Fax Module" ||
            item.page === "Pricing" ||
            item.page === "Inventory" ||
            item.page === "Task List" ||
            item.page === "Shipment" ||
            item.page === "Schedule Management" ||
            item.page === "Census"
            // item.page === "Prescriber Order"
            // item.page === "User Privileges"
            // item.page === "Health Infusion Nurse/Agency"  
            // || item.page === "DWO Workflow"
              ? item.to
              : `${item.to}&toscr=${item.SchemaName}&fromscr=${fromNavigation}`;

          if (!linkTo) return null;

          return (
            permissions?.read === "Y" && (
              <Link
                to={linkTo}
                key={item.label}
                className={isActive(linkTo) ? "active" : ""}
              >
                <div className="img-active">
                  <img
                    className="custom-width-height"
                    src={item.icon}
                    alt={item.label}
                  />
                </div>
                <span>{item.label}</span>
              </Link>
            )
          );
        })}
        {/* <Link to="/tasklist" className={isActive("/tasklist") ? "active" : ""}>
          <div className="img-active">
            <img
              className="custom-width-height"
              src={NotesManager}
              alt="Task List"
            />
          </div>
          <span>Task List</span>
        </Link>
        <Link to="/pricing" className={isActive("/pricing") ? "active" : ""}>
          <div className="img-active">
            <img
              className="custom-width-height"
              style={{ filter: "none" }}
              src={Pricing}
              alt="Pricing"
            />
          </div>
          <span>Pricing</span>
        </Link>
        <Link to="/inventory" className={isActive("/inventory") ? "active" : ""}>
          <div className="img-active">
            <img
              className="custom-width-height"
              src={FaxDirectory}
              alt="inventory"
            />
          </div>
          <span>Inventory</span>
        </Link> */}
      </ul>
    </div>
  );
};

export default Sidebar;