import { MouseEvent, useEffect, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import {
  DataTable,
  DataTableExpandedRows,
  DataTableValueArray,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { FormatDate } from "../../utils/formatDate";
import { formatCurrency } from "../../utils/formatCurrency";
import PricingDialogBox from "../Dialog/ARManagerDialogBox/PricingDialogBox";
import { getSpecialPriceListWithInventoryAndInsurance } from "../../redux/store/PricingReducer";
import { SortDecre, SortIncre } from "../../assets/images/Icon";

interface LineItemParent {
  inv_no: string;
  start_date: string;
  stop_date: string;
  description: string;
  hcpc: string;
  modifier: string;
  quantity: number;
  billed: number;
  expected: number;
  total_paid: number;
  total_adjusted: number;
  balance: number;
  pk_lineitems: number; // Assuming this is present
}

interface LineItemChild {
  claim_no: string;
  payor: string;
  date_posted: string;
  type: string;
  amount: number;
  code_check_no: string;
  trans_to_claim_no: string;
  pk_lineitems: number; // Assuming this is present
}

const ClaimLineItems = ({ op }: { op: any }) => {
  const [pricingVisible, setPricingVisible] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const handlePricing = (event: MouseEvent<HTMLSpanElement>, rowData: any) => {
    setPricingVisible(true)
    dispatch(
      getSpecialPriceListWithInventoryAndInsurance({
        inventoryId: rowData.inventory_id,
        insuranceId: rowData.ins_company_id,
      })
    );
  };

  const ActionBody = (rowData: any) => {
    return (
      <>
        <i
          style={{ cursor: "pointer" }}
          className="pi pi-dollar"
          onClick={(event) => handlePricing(event, rowData)}
          title="Pricing"
        ></i>
      </>
    );
  };

  // const dispatch = useDispatch<AppDispatch>();
  const [expandedRows, setExpandedRows] = useState<
    DataTableExpandedRows | DataTableValueArray | undefined
  >(undefined);
  const [rowCollapse, setRowCollapse] = useState<boolean>(true);

  const columns = [
    {
      field: "inv_no",
      header: "Inv.#",
      sortable: true,
    },
    {
      field: "start_date",
      header: "Start Date",
      sortable: true,
      body: (rowData: any) => <span>{FormatDate(rowData.start_date)}</span>,
    },
    {
      field: "stop_date",
      header: "Stop Date",
      sortable: true,
      body: (rowData: any) => <span>{FormatDate(rowData.stop_date)}</span>,
    },
    { field: "description", header: "Drug", sortable: true, },
    { field: "hcpc", header: "HCPC", sortable: true, },
    {
      field: "modifier",
      header: "Modifier",
      sortable: true,
    },
    { field: "quantity", header: "Quantity", sortable: true, },
    {
      field: "billed",
      header: "Billed",
      sortable: true,
      body: (rowData: any) => <span>{formatCurrency(rowData.billed)}</span>,
    },
    {
      field: "expected",
      header: "Expected",
      sortable: true,
      body: (rowData: any) => <span>{formatCurrency(rowData.expected)}</span>,
    },
    {
      field: "total_paid",
      header: "Total Paid",
      sortable: true,
      body: (rowData: any) => <span>{formatCurrency(rowData.total_paid)}</span>,
    },
    {
      field: "total_adjusted",
      header: "Total Adj",
      sortable: true,
      body: (rowData: any) => (
        <span>{formatCurrency(rowData.total_adjusted)}</span>
      ),
    },
    {
      field: "balance",
      header: "Balance",
      sortable: true,
      body: (rowData: any) => <span>{formatCurrency(rowData.balance)}</span>,
    },
    { field: "action", header: "Action", body: ActionBody },
  ];

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  const { lineItemParent, lineItemChild, claimLineLoading } = useSelector(
    (state: RootState) => state.arManagerReducer
  ) as {
    lineItemParent: LineItemParent[];
    lineItemChild: LineItemChild[];
    claimLineLoading: boolean;
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="Claim-line-Child px-3">
        <DataTable
          header="Postings"
          value={data}
          scrollable
          loading={claimLineLoading}
        >
          <Column field="claim_no" header="Claim No"></Column>
          <Column field="payor" header="Payor"></Column>
          <Column field="date_posted" header="Date Posted"></Column>
          <Column field="type" header="Type"></Column>
          <Column
            field="amount"
            header="Amount"
            body={(rowData: any) => (
              <span>{formatCurrency(rowData.amount)}</span>
            )}
          ></Column>
          <Column field="code_check_no" header="Code No"></Column>
          <Column field="trans_to_claim_no" header="Trans Claim No"></Column>
        </DataTable>
      </div>
    );
  };

  const allowExpansion = () => {
    return lineItemParent.length > 0;
  };

  useEffect(() => {
    if (lineItemChild.length > 0) {
      setExpandedRows(lineItemParent);
    }
  }, [lineItemParent, lineItemChild]);

  const getFilteredChildRecords = (pk_lineitems: number) => {
    return lineItemChild.filter((child) => child.pk_lineitems === pk_lineitems);
  };

  const expandAll = () => {
    setExpandedRows(lineItemParent);
    setRowCollapse(true);
  };

  const collapseAll = () => {
    setExpandedRows([]);
    setRowCollapse(false);
  };

  const headerTemplate = () => (
    <i
      className={`pi ${rowCollapse ? "pi-angle-down" : "pi-angle-right"}`}
      onClick={() => (rowCollapse ? collapseAll() : expandAll())}
      style={{ cursor: "pointer" }}
    ></i>
  );

  return (
    <>
      <OverlayPanel ref={op}>
        <DataTable
          value={lineItemParent}
          rows={15}
          emptyMessage="No Data Found"
          scrollable
          sortIcon={sortIcon}
          loading={claimLineLoading}
          scrollHeight="400px"
          rowExpansionTemplate={(data: LineItemParent) => {
            const filteredChildRecords = getFilteredChildRecords(
              data.pk_lineitems
            );
            return rowExpansionTemplate(filteredChildRecords);
          }}
          expandedRows={lineItemChild.length > 0 ? expandedRows : undefined}
          onRowToggle={(e) => setExpandedRows(e.data)}
        >
          {lineItemChild.length > 0 && (
            <Column
              header={headerTemplate}
              expander={allowExpansion}
              style={{ width: "5rem" }}
            />
          )}
          {columns.map((column, index) => (
            <Column
              key={index}
              field={column.field}
              header={column.header}
              body={column.body}
              sortable={column.sortable}
            />
          ))}
        </DataTable>
      </OverlayPanel>
      <PricingDialogBox
        visible={pricingVisible}
        setVisible={setPricingVisible}
      />
    </>
  );
};

export default ClaimLineItems;
