import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import InputTextArea from '../../../components/formElement/InputTextArea'
import Input from '../../../components/formElement/Input'
import InputDropdown from '../../../components/formElement/InputDropdown'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store'
import { string } from 'yup'
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton'

type AddSuiteAppointmentProps = {
    suite: string | null | undefined;
};

const AddSuiteAppointment: React.FC<AddSuiteAppointmentProps> = ({ suite }) => {

    // DropDown
    const [statusDropdown, setStatusDropdown] = useState<string | undefined>(undefined);
    const [repeatDropdown, setRepeatDropdown] = useState<string | undefined>(undefined);
    const [chairDropdown, setChairDropdown] = useState<string | undefined>(undefined);

    const [selectedOption, setSelectedOption] = useState<string>('');
    const [dropdownOptions, setDropdownOptions] = useState<any[]>([]);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState<
        string | number | { label: string; value: { ID: number; StartDate: Date; EndDate: Date } } | undefined
    >(undefined);

    const { TherapyList, addNurseDetail, addAgencyList, chairList } = useSelector((state: RootState) => state.AppointmentsReducer);
    const [therapy, setTherapy] = useState<string | null>();

    // Handler for radio button change
    const handleChange = (event: RadioButtonChangeEvent) => {
        const value = event.value; // Access value directly from event
        setSelectedOption(value);
        setSelectedDropdownValue(undefined);

        if (value === 'Nurse') {
            setDropdownOptions(
                addNurseDetail?.map((nurse: any) => ({
                    label: nurse.full_name,
                    value: nurse.nurse_id,
                }))
            );
        } else if (value === 'Agency') {
            setDropdownOptions(
                addAgencyList?.map((agency: any) => ({
                    label: agency.agency_name,
                    value: agency.company_id,
                }))
            );
        }
    };

    // array for Time Hour
    const optionTimeHour = Array.from({ length: 10 }, (_, i) => ({
        label: (i + 8).toString(),
        value: i + 8,
    }));
    // Array for Time minutes
    const optionTimeMinutes = Array.from({ length: 60 }, (_, i) => ({
        label: (i + 1).toString(),
        value: i + 1,
    }));
    // calculate the total time
    const [timeInHour, setTimeInHour] = useState<number | undefined>(undefined);
    const [timeInMinute, setTimeInMinute] = useState<number | undefined>(undefined);
    const [timeOutHour, setTimeOutHour] = useState<number | undefined>(undefined);
    const [timeOutMinute, setTimeOutMinute] = useState<number | undefined>(undefined);

    const calculateTotalTime = () => {
        if (
            timeInHour != null &&
            timeInMinute != null &&
            timeOutHour != null &&
            timeOutMinute != null
        ) {
            const timeInTotalMinutes = (timeInHour || 0) * 60 + (timeInMinute || 0);
            const timeOutTotalMinutes = (timeOutHour || 0) * 60 + (timeOutMinute || 0);

            const totalMinutes = timeOutTotalMinutes - timeInTotalMinutes;

            if (totalMinutes >= 0) {
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;
                return `${hours}h ${minutes}m`;
            } else {
                return 'Invalid Time Range';
            }
        }
        return 'Select TimeIn and TimeOut';
    };

    // Nurse/Agency Handler for dropdown change
    const handleDropdownChange = (selectedValue: any) => {
        setSelectedDropdownValue(selectedValue?.value || null); // Update the selected value
    };

    // Chair DropDown
    const [filteredChairs, setFilteredChairs] = useState<{ label: string; value: number }[]>([]);
    useEffect(() => {
        if (suite) {
            const filtered = chairList
                .filter((chair) => chair.suit_id === parseInt(suite))
                .map((chair) => ({
                    label: chair.description,
                    value: chair.chair_id,
                }));
            setFilteredChairs(filtered);
        } else {
            setFilteredChairs([]);
        }
    }, [suite, chairList]);

    return (
        <div>
            <div className="flex flex-column align-content-between">

                <div className="m-3">
                    {/* <div>Suite</div> */}
                    <div className="formgrid grid flex-wrap">
                        <div className="field col-8">
                            <InputDropdown
                                options={[
                                    { label: "Option 1", value: "Scheduled" },
                                    { label: "Option 2", value: "Infused" },
                                    { label: "Option 3", value: "Done" },
                                ]}
                                label="Status"
                                optionLabel="value"
                                type="text"
                                id="AssignedTo"
                                value={statusDropdown ?? undefined}
                                handleChange={(e) => setStatusDropdown((e.target.value))}
                                dropdownclassName="col-8 Input-field task-list-dropdown justify-content-end"
                                labelclassName="col-4 label-lg flex justify-content-start"
                                filter
                            />
                        </div>
                        <div className="field col-4">
                            <InputDropdown
                                options={[
                                    { label: "Option 1", value: "Don't Repeat" },
                                    { label: "Option 2", value: "Custom" },
                                ]}
                                label=""
                                optionLabel="value"
                                type="text"
                                id="AssignedTo"
                                value={repeatDropdown ?? undefined}
                                handleChange={(e) => setRepeatDropdown((e.target.value))}
                                dropdownclassName="col-12 Input-field task-list-dropdown"
                                labelclassName="col-0 label-lg justify-content-end"
                                // invalid={invalidFields.AssignedTo}
                                filter
                            // requiredRight={true}
                            />
                        </div>
                    </div>
                    <div className="formgrid grid ">
                        <div className="col col-offset-3">
                            <div className="formgroup-inline">
                                <div className="field-radiobutton flex align-content-center">
                                    <RadioButton
                                        type="radio"
                                        id="nurse"
                                        name="profession"
                                        value="Nurse"
                                        checked={selectedOption === 'Nurse'}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="nurse" className='font-medium'>Nurse</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton
                                        type="radio"
                                        id="agency"
                                        name="profession"
                                        value="Agency"
                                        checked={selectedOption === 'Agency'}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="agency" className='font-medium'>Agency</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <InputDropdown
                                options={dropdownOptions ?? undefined}
                                label="Nurse/Agency"
                                optionLabel="label"
                                type="text"
                                id="AssignedTo"
                                dropdownclassName="col-9 Input-field task-list-dropdown justify-content-end"
                                labelclassName="col-3 label-lg flex justify-content-start"
                                filter
                                value={selectedDropdownValue}
                                // onChange={handleDropdownChange} // Handle change event
                                handleChange={handleDropdownChange}
                            />
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <InputDropdown
                                options={filteredChairs}
                                label="Chair ID"
                                optionLabel="label"
                                type="text"
                                id="AssignedTo"
                                value={chairDropdown ?? undefined}
                                handleChange={(e) => setChairDropdown((e.target.value))}
                                dropdownclassName="col-9 Input-field task-list-dropdown justify-content-end"
                                labelclassName="col-3 label-lg flex justify-content-start"
                                filter
                            />
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <InputDropdown
                                options={TherapyList.map((item) => ({
                                    label: item.description,
                                    value: item.drug_category_id,
                                }))}
                                label="Therapy"
                                optionLabel="label"
                                type="text"
                                id="AssignedTo"
                                value={therapy ?? undefined}
                                handleChange={(e) => setTherapy((e.target.value))}
                                dropdownclassName="col-9 Input-field task-list-dropdown justify-content-end"
                                labelclassName="col-3 label-lg flex justify-content-start"
                                filter
                            />
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="col">
                            <Input
                                label="Appointment Count"
                                type="text"
                                id="MRN"
                                value=""
                                labelclassName="col-3 label-lg flex justify-content-start"
                                // handleChange={handleInputChange}
                                inputclassName="col-9 Input-field flex justify-content-end"
                            />
                        </div>
                    </div>
                    <div className="formgrid grid mt-3 flex justify-content-start col-6">
                        <div className="flex justify-content-start col-12">
                            <div className="col-6 align-start font-medium flex justify-content-start">Time In</div>
                            <InputDropdown
                                options={optionTimeHour.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                }))}
                                label=""
                                type="text"
                                id="timeInHour"
                                optionLabel="label"
                                placeholder="Select Hour"
                                value={timeInHour ?? undefined}
                                handleChange={(e) => setTimeInHour(Number(e.target.value))}
                                dropdownclassName="grid col-offset-1 col-11"
                                labelclassName=""
                            />
                            <InputDropdown
                                options={optionTimeMinutes.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                }))}
                                label=""
                                type="text"
                                id="timeInMinute"
                                optionLabel="label"
                                placeholder="Select Minute"
                                value={timeInMinute ?? undefined}
                                handleChange={(e) => setTimeInMinute(Number(e.target.value))}
                                dropdownclassName="grid col-11"
                                labelclassName=""
                            />
                        </div>
                    </div>
                    <div className="formgrid grid mt-3">
                        <div className="col-6">
                            <div className="flex justify-content-between col-12">
                                <span className="col-6 font-medium flex justify-content-start">Time Out</span>
                                <InputDropdown
                                    options={optionTimeHour.map((item) => ({
                                        label: item.label,
                                        value: item.value,
                                    }))}
                                    label=""
                                    type="text"
                                    id="timeOutHour"
                                    optionLabel="label"
                                    placeholder="Select Hour"
                                    value={timeOutHour ?? undefined}
                                    handleChange={(e) => setTimeOutHour(Number(e.target.value))}
                                    dropdownclassName="grid col-offset-1 col-11"
                                    labelclassName=""
                                />
                                <InputDropdown
                                    options={optionTimeMinutes.map((item) => ({
                                        label: item.label,
                                        value: item.value,
                                    }))}
                                    label=""
                                    type="text"
                                    id="timeOutMinute"
                                    optionLabel="label"
                                    placeholder="Select Minute"
                                    value={timeOutMinute ?? undefined}
                                    handleChange={(e) => setTimeOutMinute(Number(e.target.value))}
                                    dropdownclassName="grid col-11"
                                    labelclassName=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="formgrid grid mt-3">
                        <div className="col-12">
                            <div className="flex col-12">
                                <div className="col-2 font-medium justify-content-start">Total Time </div>
                                <div><span className='col-10 ml-4 pl-5 text-sm justify-content-start'>{calculateTotalTime()}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="formgrid grid mt-3">
                        <div className="col">
                            <InputTextArea
                                label="Task Details"
                                rows={6}
                                id="TaskDetails"
                                labelclassName="col-3 label-lg flex justify-content-start"
                                inputclassName="col-9"
                                placeholder=""
                            // requiredRight={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="">
                    <div className="flex justify-content-end align-self-end" style={{ display: "flex", marginTop: "10px", flexShrink: 0 }}>
                        <Button
                            label="Cancel"
                            // onClick={handleSubmit}
                            style={{ margin: '10px', width: "90px", backgroundColor: "white", color: "black" }}
                        // loading={btnLoading}
                        // disabled={!ownerName}
                        />
                        <Button
                            label="Save"
                            // onClick={() => setIsOwnerNameModalOpen(false)}
                            style={{ margin: '10px', width: "90px", backgroundColor: "white", color: "black" }}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AddSuiteAppointment
