import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import InputDate from "../../formElement/InputDate";
import InputTime from "../../formElement/InputTime";
import {
  AddUpdateHolidayList,
  getHolidayList,
} from "../../../redux/store/NurseAgencyReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { displayToast } from "../../Toast/ToastMessage";
import { InputTextarea } from "primereact/inputtextarea";

interface AddBlockSlotPopupProps {
  Addvisible: boolean;
  setAddVisible: any;
  action: string;
  editData: any[];
  actionId: number | undefined;
  userEmail: string | undefined;
}

interface GeneralTaskData {
  startDate: string | undefined;
  endDate: string | undefined;
  breakHourFrom: string | undefined;
  breakHourTo: string | undefined;
  holiday_list_id: number | undefined;
  suite_id: number | undefined;
  reason: string;
  userEmail: string | undefined;
}

const AddBlockSlotPopup: React.FC<AddBlockSlotPopupProps> = ({
  Addvisible,
  setAddVisible,
  action,
  editData,
  actionId,
  userEmail,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [generalTaskData, setGeneralTaskData] = useState<GeneralTaskData>({
    startDate: undefined,
    endDate: undefined,
    breakHourFrom: undefined,
    breakHourTo: undefined,
    reason: "",
    holiday_list_id: 0,
    suite_id: undefined,
    userEmail: userEmail,
  });

  useEffect(() => {
    if (actionId) {
      setGeneralTaskData((prevState) => ({
        ...prevState,
        suite_id: actionId,
      }));
    }

    if (action === "edit" && editData) {
      setGeneralTaskData({
        ...generalTaskData,
        startDate: editData[0]?.holidaystart_date,
        endDate: editData[0]?.holidayend_date,
        breakHourFrom: editData[0]?.break_hours_from,
        breakHourTo: editData[0]?.break_hours_to,
        reason: editData[0]?.description,
        holiday_list_id: editData[0]?.holiday_list_id,
        suite_id: editData[0]?.suite_id,
      });
    }

    if (!Addvisible) {
      resetFormData();
    }

    
    setInvalidFields({
      startDate: false,
      endDate: false,
      breakHourFrom: false,
      breakHourTo: false,  
    });
  }, [Addvisible]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (value) {
      const localDate = new Date(value);
      const normalizedDate = new Date(
        Date.UTC(
          localDate.getFullYear(),
          localDate.getMonth(),
          localDate.getDate(),
          0,
          0,
          0,
          0
        )
      );

      const utcDateString = normalizedDate.toISOString();

      setGeneralTaskData((prev) => ({
        ...prev,
        [id]: utcDateString,
      }));
    }
    if (value) {
      setInvalidFields((prevInvalidFields) => ({
        ...prevInvalidFields,
        [id]: false,
      }));
    }
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = e.target;

    const date = new Date(value);

    const formattedDate = `${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}/${String(date.getDate()).padStart(
      2,
      "0"
    )}/${date.getFullYear()} ${String(date.getHours()).padStart(
      2,
      "0"
    )}:${String(date.getMinutes()).padStart(2, "0")}:${String(
      date.getSeconds()
    ).padStart(2, "0")}`;
    setGeneralTaskData((prev) => ({
      ...prev,
      [id]: formattedDate,
    }));

    if (value) {
      setInvalidFields((prevInvalidFields) => ({
        ...prevInvalidFields,
        [id]: false,
      }));
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement> | any
  ): void => {
    const { id, value } = e.target; // PrimeReact uses `e.target` for value retrieval
    setGeneralTaskData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  
  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean  // Allow fields to be either boolean or string
  }>({
    startDate: false,
    endDate: false,
    breakHourFrom: false,
    breakHourTo: false,   
  });


  
  const OnSubmit = async () => {

    const updatedInvalidFields = {
      startDate: !generalTaskData.startDate,
      endDate: !generalTaskData.endDate,
      breakHourFrom: !generalTaskData.breakHourFrom,
      breakHourTo: !generalTaskData.breakHourTo, 
    };

    const hasInvalidFields = Object.values(updatedInvalidFields).some(
      (isInvalid) => isInvalid
    );

    setInvalidFields(updatedInvalidFields);

    if (hasInvalidFields) {
      return;
    }

    const data = {
      description: generalTaskData.reason,
      holidaystart_date: generalTaskData.startDate,
      holidayend_date: generalTaskData.endDate,
      break_hours_from: generalTaskData.breakHourFrom,
      break_hours_to: generalTaskData.breakHourTo,
      created_by: generalTaskData.userEmail,
      holiday_list_id: generalTaskData.holiday_list_id,
      suite_id: generalTaskData.suite_id,
    };

    await dispatch(
      AddUpdateHolidayList({
        updateData: data,
        callback: (res: any) => {
          if (res.data.success) {
            displayToast({
              msg:
                res?.data?.data || "Block Slot have been added successfully.",
              type: "success",
            });
            dispatch(getHolidayList());
          } else {
            displayToast({
              msg: res?.data?.message || "Something went wrong",
              type: "error",
            });
          }
        },
      })
    );
    resetFormData();
    setAddVisible(false);
  };
  const resetFormData = () => {
    setGeneralTaskData({
      startDate: undefined,
      endDate: undefined,
      breakHourFrom: undefined,
      breakHourTo: undefined,
      reason: "",
      holiday_list_id: undefined,
      suite_id: undefined,
      userEmail: userEmail,
    });
  };

  const footerCompont = (
    <div className="dialog-footer">
      <Button
        label="Cancel"
        onClick={() => {
          setAddVisible(false);
          resetFormData();
        }}
        className="p-button-text BlockSlotCancleBtn p-button p-button-outlined"
      />
      <Button label="Save" onClick={OnSubmit} className="p-button-primary" />
    </div>
  );

  return (
    <Dialog
      header={action === "edit" ? "Edit Block Slot" : "Add Block Slot"}
      visible={Addvisible}
      style={{ width: "40vw" }}
      onHide={() => {
        if (!Addvisible) return;
        setAddVisible(false);
        resetFormData();
      }}
      className="AddBlockSlot"
      draggable={false}
      footer={footerCompont}
    >
      <div className="blockSlotColumn">
        <div className="blockSlotColumnOne">
          <div className="p-field">
            <InputDate
              id="startDate"
              value={
                generalTaskData.startDate
                  ? new Date(generalTaskData.startDate)
                  : undefined
              }
              label="Start Date"
              placeholder="Select Start Date"
              handleChange={handleDateChange}
              invalid={invalidFields.startDate}
            />
          </div>

          <div className="p-field">
            <InputDate
              id="endDate"
              value={
                generalTaskData.endDate
                  ? new Date(generalTaskData.endDate)
                  : undefined
              }
              label="End Date"
              placeholder="Select End Date"
              handleChange={handleDateChange}
              invalid={invalidFields.endDate}
            />
          </div>
        </div>

        <div className="blockSlotColumnSecound">
          <InputTime
            value={
              generalTaskData.breakHourFrom
                ? new Date(generalTaskData.breakHourFrom)
                : undefined
            }
            label="Break Hour From"
            id="breakHourFrom"
            handleChange={handleTimeChange}
            placeholder="Choose a time"
            invalid={invalidFields.breakHourFrom}
            minDate={(() => {
              if (generalTaskData.breakHourFrom) {
                const minTime = new Date(generalTaskData.breakHourFrom);
                minTime.setHours(9, 0, 0, 0);

                return minTime;
              } else {
                const today = new Date();
                today.setHours(9, 0, 0, 0);
                return today;
              }
            })()}
            maxDate={(() => {
              if (generalTaskData.breakHourFrom) {
                const minTime = new Date(generalTaskData.breakHourFrom);
                minTime.setHours(17, 0, 0, 0);

                return minTime;
              } else {
                const today = new Date();
                today.setHours(17, 0, 0, 0);
                return today;
              }
            })()}
        
          />

          <InputTime
            value={
              generalTaskData.breakHourTo
                ? new Date(generalTaskData.breakHourTo)
                : undefined
            }
            label="Break Hour To"
            id="breakHourTo"
            handleChange={handleTimeChange}
            placeholder="Choose a time"
            invalid={invalidFields.breakHourTo}
            minDate={(() => {
              if (generalTaskData.breakHourTo) {
                if (generalTaskData.breakHourFrom) {
                  const breakFrom = new Date(generalTaskData.breakHourFrom);
                  const breakTo = new Date(generalTaskData.breakHourTo);

                  const isFromAfterTo =
                    breakFrom.getHours() > breakTo.getHours() ||
                    (breakFrom.getHours() === breakTo.getHours() &&
                      breakFrom.getMinutes() > breakTo.getMinutes());

                  if (isFromAfterTo) {
                    setGeneralTaskData((prev) => ({
                      ...prev,
                      breakHourTo: generalTaskData.breakHourFrom,
                    }));
                  }

                  const minTime = new Date(generalTaskData.breakHourFrom);
                  minTime.setHours(minTime.getHours() + 1);

                  return minTime;
                }
              }

              const today = new Date();
              today.setHours(9, 0, 0, 0);

              return today;
            })()}
            maxDate={(() => {
              if (generalTaskData.breakHourTo) {
                const minTime = new Date(generalTaskData.breakHourTo);
                minTime.setHours(17, 0, 0, 0);

                return minTime;
              } else {
                const today = new Date();
                today.setHours(17, 0, 0, 0);
                return today;
              }
            })()}
          />
        </div>
      </div>

      <div className="p-field ReasonFild">
        <label htmlFor="reason">Reason</label>
        <InputTextarea
          id="reason"
          className="p-inputtext p-component"
          value={generalTaskData.reason}
          onChange={handleInputChange}
          placeholder="Enter reason"
        />
      </div>
    </Dialog>
  );
};

export default AddBlockSlotPopup;
