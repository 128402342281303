import React, { useCallback, useEffect, useState, createContext } from "react";
import { AppDispatch, RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { getAssignRoleByUser } from "../redux/store/RoutePermission";
import {
  PagePermissions,
  PermissionPropsTypes,
} from "../types/IHealthAppTypes";

interface ProtectedRouteProps {
  element: React.ReactElement;
  userEmail?: string;
}

export const UserPermissionContext = createContext<PagePermissions | undefined>(
  undefined
);

const PrivateRoute = ({ element, userEmail }: ProtectedRouteProps) => {
  const [userPermission, setUserPermission] = useState<
    PagePermissions | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [fromNavigation, setFromNavigation] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const { assignRoleByUser } = useSelector(
    (state: RootState) => state.routePermissionReducer
  );
  const location = useLocation();

  useEffect(() => {
    if (userEmail) {
      setLoading(true);
      dispatch(getAssignRoleByUser());
    }
  }, [userEmail, dispatch]);

  useEffect(() => {
    if (assignRoleByUser.length > 0) {
      const permissions: PagePermissions = assignRoleByUser.reduce(
        (
          acc,
          { page, read, write, delete: del, modify, section, all, deny, role }
        ) => {
          if (role === "Exception") {
            if (!acc[page]) {
              acc[page] = {
                [section]: {
                  read,
                  write,
                  delete: del,
                  modify,
                  page,
                  section,
                  all,
                  deny,
                  role,
                },
              };
            } else {
              const pagePermissions = acc[page] as Record<
                string,
                PermissionPropsTypes
              >;

              if (section === "All Section") {
                pagePermissions[section] = {
                  read,
                  write,
                  delete: del,
                  modify,
                  page,
                  section,
                  all,
                  deny,
                  role,
                };
              } else {
                if (
                  !pagePermissions[section] ||
                  pagePermissions[section].role === "Super User" ||
                  pagePermissions[section].role === "Exception"
                ) {
                  pagePermissions[section] = {
                    read,
                    write,
                    delete: del,
                    modify,
                    page,
                    section,
                    all,
                    deny,
                    role,
                  };
                }
              }
            }
          } else {
            if (!acc[page]) {
              acc[page] = {
                [section]: {
                  read,
                  write,
                  delete: del,
                  modify,
                  page,
                  section,
                  all,
                  deny,
                  role,
                },
              };
            } else {
              const pagePermissions = acc[page] as Record<
                string,
                PermissionPropsTypes
              >;

              if (section === "All Section") {
                if (
                  !pagePermissions[section] ||
                  pagePermissions[section].role !== "Exception"
                ) {
                  pagePermissions[section] = {
                    read,
                    write,
                    delete: del,
                    modify,
                    page,
                    section,
                    all,
                    deny,
                    role,
                  };
                }
              } else {
                if (
                  !pagePermissions[section] ||
                  pagePermissions[section].role === "Super User"
                ) {
                  pagePermissions[section] = {
                    read,
                    write,
                    delete: del,
                    modify,
                    page,
                    section,
                    all,
                    deny,
                    role,
                  };
                }
              }
            }
          }
          return acc;
        },
        {} as PagePermissions
      );

      setUserPermission(permissions);
      setLoading(false);
    }
  }, [assignRoleByUser]);

  useEffect(() => {
    const currentPage = sidebarItems.find((item) =>
      location.pathname.startsWith(item.to)
    );
    if (currentPage) {
      setFromNavigation(currentPage.page);
    }
  }, [location.pathname]);

  const sidebarItems = [
    { to: "/fax", page: "Fax Module" },
    { to: "/schedule-management", page: "Schedule Management" },
    { to: "/appointments", page: "Schedule Management" },
    { to: "/census", page: "Census" },
    { to: "/ar-manager", page: "AR Manager" },
    { to: "/notes-manager", page: "Notes Manager" },
    { to: "/insurance", page: "Insurance" },
    { to: "/pricing", page: "Pricing" },
    { to: "/tasklist", page: "Task List" },
    { to: "/nurse-agency", page: "Health Infusion Nurse/Agency" },
    { to: "/inventory", page: "Inventory" },
    { to: "/shipment", page: "Shipment" },
    { to: "/prescribers-order", page: "Prescribers Workflow" },
    { to: "/permissions", page: "User Privileges" },
    { to: "/new-starts", page: "New Starts" },
    { to: "/no-go", page: "No Go" },
    { to: "/expiring-auth", page: "Expiring Authorizations" },
    { to: "/careplan-enterprise", page: "Care Plan - Enterprise Level" },
    { to: "/careplan-template", page: "Care Plan Template" },
    { to: "/outbound-fax-log", page: "Outbound Fax Log" },
    { to: "/power-bi", page: "BI Reports" },
    { to: "/enterprise-document", page: "Enterprise Document" },
  ];

  const render = useCallback(() => {
    if (loading || !userPermission) return null;

    const permissions =
      userPermission[fromNavigation]?.["All Section"] ||
      userPermission[fromNavigation]?.["Check Eligibility Permission"];

    if (!permissions || permissions.deny === "Y" || permissions.read !== "Y") {
      return (
        <Navigate
          to="/access-denied"
          state={{ from: location.pathname, page: fromNavigation }}
        />
      );
    }

    return (
      <UserPermissionContext.Provider value={userPermission}>
        {element}
      </UserPermissionContext.Provider>
    );
  }, [loading, userPermission, fromNavigation, location.pathname, element]);

  return <>{render()}</>;
};

export default PrivateRoute;
