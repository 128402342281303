import { Button } from 'primereact/button'
import React, { useEffect } from 'react'
import "../../../assets/css/permissionsfilter.css"
import roleIcon from '../../../assets/images/role.svg';
import userManage from '../../../assets/images/userManage.svg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { GetPageList, getPermissionsList, GetSectionList, GetUserList } from '../../../redux/store/PermissionsReducer';



const PermissionsFilter = ({activeComponent, setActiveComponent} : {activeComponent:'role' | 'user',  setActiveComponent: any}) => {
    // const [activeComponent, setActiveComponent] = useState<'role' | 'user'>('role');
    const handleComponentChange = (component: 'role' | 'user') => {
        setActiveComponent(component);
    }
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getPermissionsList());
        dispatch(GetPageList());
        dispatch(GetSectionList());
        dispatch(GetUserList());
        // Fetch permissions data on mount
    }, [dispatch])
    return (
        <>
            <div className='permissions-filter'>
                <div className='management flex gap-4'>
                    <Button
                        icon={<img src={roleIcon} alt="user role" className='' />}
                        onClick={() => handleComponentChange('role')}
                       className={`custom-rolebutton ${activeComponent === 'role' ? 'active' : ''}`}
                        >
                        Role Management
                    </Button>
                    <Button
                        icon={<img src={userManage} alt="user" className='' />}
                        onClick={() => handleComponentChange('user')}
                        className={`custom-rolebutton ${activeComponent === 'user' ? 'active' : ''}`}
                        >
                        User Management
                    </Button>
                </div>
            </div>

        </>
    )
}
export default PermissionsFilter















