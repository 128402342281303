import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Modal from 'react-modal';
import "../../../assets/css/calander.css"
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'primeicons/primeicons.css';
import image from "../../../assets/images/Group 2050.svg"
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import appointmentlogo from "../../../assets/images/appointmentlogo.svg"
import durationlogo from "../../../assets/images/duration_logo.svg"
import Week from './Week';
import Day from './Day';
const localizer = momentLocalizer(moment);




const Calender = ({ nurseList }) => {
  const [appointments, setAppointments] = useState([]);
  const [allAppointments, setAllAppointments] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [modaltable, setmodaltable] = useState(false);
  const [loading, setloading] = useState(false)
  const [onView, setOnView] = useState(['month']);
  const CustomToolbar = ({ label, onNavigate }) => {


    const handleViewChange = (view) => {
      // Change the calendar view when a button is clicked
      setOnView([view])
    };
    

    return (
      <div className="rbc-toolbar">
        {/* Left Arrow for Previous Month */}

        <div className='tabl-date'>
          <div className="date" style={{ width: '100%', height: '100%', color: '#252525', fontSize: 20, fontFamily: 'Poppins', fontWeight: '400', textTransform: 'capitalize', wordWrap: 'break-word', textAlign: "left" }}>3 june, 2024 </div>
        </div>
        <div>


          <div className='table-month'>
            <i
              className="pi pi-angle-double-left"
              onClick={() => onNavigate('PREV')}
              aria-label="Previous Month"
              style={{ cursor: 'pointer', fontSize: '20px', marginRight: '10px' }}
            ></i>


            <span className="rbc-toolbar-label">{label}</span>


            <i
              className="pi pi-angle-double-right "
              onClick={() => onNavigate('NEXT')}
              aria-label="Next Month"
              style={{ cursor: 'pointer', fontSize: '20px', marginLeft: '10px' }}
            ></i></div>



        </div>
        <div>

          <div className='option-view' style={{ width: '100%', height: '100%', background: '#F5F5F5', borderRadius: 6, border: '0.75px #DDDDDD solid' }} >
            <div className='option-view-today' style={{ color: 'black', fontSize: 15, fontFamily: 'Poppins', fontWeight: '400', textTransform: 'capitalize', wordWrap: 'break-word'  } }   onClick={() => handleViewChange('day')}>today</div>
            <div className='option-view-week' style={{ color: 'black', fontSize: 15, fontFamily: 'Poppins', fontWeight: '400', textTransform: 'capitalize', wordWrap: 'break-word', marginLeft: "10px" }}   onClick={() => handleViewChange('week')}>week</div>
            <div className='option-view-month' style={{ color: 'black', fontSize: 15, fontFamily: 'Poppins', fontWeight: '400', textTransform: 'capitalize', wordWrap: 'break-word', marginLeft: "10px" }}           onClick={() => handleViewChange('month')}>Month</div>
            <div>
            </div>

        </div>
      </div>
    </div>
  );
};

// Group events by date
const groupEventsByDate = (events) => {
  return events.reduce((acc, event) => {
    const eventDate = moment(event.start).format('YYYY-MM-DD'); // Group by date
    if (!acc[eventDate]) {
      acc[eventDate] = [];
    }
    acc[eventDate].push(event);
    return acc;
  }, {});
};

// Convert the nurseList to calendar events
const convertNurseListToAppointments = (nurseList) => {
  return nurseList.map((nurse) => {
    const start = moment(nurse.next_Infusion_Date, "MM/DD/YYYY HH:mm:ss");
    const duration = nurse.duration || "1 hour";
    const end = start.clone().add(moment.duration(duration));
    const validEnd = end.isValid() ? end.toDate() : start.toDate();

    // Create the base appointment object
    const appointment = {
      title: `${nurse.patient_Fname} ${nurse.patient_Lname} - ${nurse.nurse_Name}`,
      start: start.toDate(),
      start_Time: `${nurse.start_Time}`,
      end: validEnd,
      patient: `${nurse.patient_Fname} ${nurse.patient_Lname}`,
      nurse: nurse.nurse_Name,
      duration: duration,
      comments: nurse.comments,
      agency_Name: nurse.agency_Name,
      next_Infusion_Date: nurse.next_Infusion_Date,
      // Ensure all fields are passed, even if undefined
      color_code: nurse.color_code !== undefined ? nurse.color_code : undefined,
      chair: nurse.chair !== undefined ? nurse.chair : undefined,
      therapy_id: nurse.therapy_id !== undefined ? nurse.therapy_id : undefined,
      // Adding out_time and in_time
      out_time: nurse.out_time !== undefined ? nurse.out_time : undefined,
      in_time: nurse.in_time !== undefined ? nurse.in_time : undefined,
    };

    // Conditionally add properties if they are available


    return appointment;
  });
};


useEffect(() => {
  if (nurseList && nurseList.length > 0) {
    const events = convertNurseListToAppointments(nurseList);
    setAllAppointments(events);
  }
}, [nurseList]);

// Handle click on an event
const handleEventClick = (event) => {
  setSelectedEvent(event);
  setModalIsOpen(true);
};

const closeModal = () => {
  setModalIsOpen(false);
  setSelectedEvent(null);
};

// Group events by date
const eventsByDate = groupEventsByDate(allAppointments);


// Handle day selection from the calendar
const handleDayClick = (date) => {
  // Get the current month
  const currMonth = new Date(date).getMonth();

  // Filter appointments for the selected month
  const filteredAppointments = allAppointments.filter((val) => {
    const appointmentsDate = new Date(val.next_Infusion_Date).getMonth();
    return appointmentsDate === currMonth;
  });

  setAppointments(filteredAppointments);
  setSelectedDate(moment(date).format('YYYY-MM-DD'));

  // Hide the loader immediately
  setloading(false);

};


const handleMoreClick = (eventsInCell) => {

  // Check if eventsInCell is populated correctly and has events
  if (eventsInCell && eventsInCell.length > 0) {
    setmodaltable(true); // Show modal table if there are events
  } else {
    setmodaltable(false); // Ensure no modal is open if no events exist
  }
};



const renderEventDetailsForSelectedDate = () => {
  if (!selectedDate || !eventsByDate[selectedDate]) {
    return null;
  }

  const eventsForSelectedDay = eventsByDate[selectedDate];

  const CustomEvent = ({ event }) => {
    return (
      <div style={{ padding: '8px', backgroundColor: '#F6506E', color: 'white', borderRadius: '8px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{event.title}</div>
        <div style={{ fontSize: '12px' }}>{moment(event.start).format('HH:mm')}</div>
        <div style={{ fontSize: '12px' }}>{moment(event.end).format('HH:mm')}</div>
      </div>
    );
  };




  return (
    <Modal
      isOpen={modaltable}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9998, // Overlay should be behind the content but still above other elements
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)', // Centers the modal on the screen
          height:"55%",

          width: '75%',  // Modal width
          // padding: '30px',

          padding: "10px",


          backgroundColor: '#fff',  // White background
          zIndex: 9999,
          display: 'flex',  // Enables Flexbox layout
          flexDirection: 'column',  // Stack content vertically
          justifyContent: 'space-between', // Space between events and button
          alignItems: 'center', // Center content horizontally
        },
      }}
    >
      {/* <div className="event-details">
          <div className='header-div-event-detail'>

            <span style={{ fontSize: "18px" }}> <span style={{ backgroundColor: "#F6506E", color: "white", height: "29px", width: "10px", borderRadius: "5px", textAlign: "center", padding: "8px 8px" }}>{moment(selectedDate).format('MMMM Do, YYYY')}</span></span>
            <span onClick={() => setmodaltable(false)}><i className='pi pi-times'></i></span>
          </div>
          <table className="event-details-table" style={{ width: '100%', borderCollapse: 'collapse', borderRadius: "8px" }}>
            <thead>
              <tr className='event-details-row'>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Title</th>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Patient</th>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Nurse</th>
              </tr>
            </thead>
            <tbody>
              {eventsForSelectedDay.map((event, index) => (
                <tr key={index} className='col-table-remaining'>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd', width: "430px", height: "81px", fontWeight: "bold" }}>{event.title}</td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd', width: "430px", height: "81px" }}>{event.patient}</td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd', width: "430px", height: "81px" }}>{event.nurse}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div> */}
      <div className='header-more'>
        <span className='header-text' style={{ paddingLeft: "25px" }}>Health Infusion Nurses Appointment Details</span>
        <span onClick={() => setmodaltable(false)}><i className='pi pi-times'></i></span>

      </div>

      <div style={{ borderRadius: 8, width: "95%", marginBottom: "100px" }}>
        <div className='remaining-events' style={{ marginTop: "10px", marginBottom: "3px" }}>
          {eventsForSelectedDay.map((event, index) => (

            <div
              key={index} // Add the key prop
              className='content-main'
              style={{
                width: '100%',

                background: 'white',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.12)',
                borderRadius: 7,
              }}
            >

              <div className='event-title'>
                {event.title}
              </div>
              <div>
                <span style={{ textAlign: 'justify', color: '#908E8E', fontSize: 10.69, fontWeight: '500', textTransform: 'capitalize', wordWrap: 'break-word' }}>Patient:</span>  <span style={{ textAlign: 'justify', color: '#4F4C4C', fontSize: 10.69, fontWeight: '500', textTransform: 'capitalize', wordWrap: 'break-word' }}>{event.patient}</span>
              </div>

              <div>   <span style={{ textAlign: 'justify', color: '#908E8E', fontSize: 10.69, fontWeight: '500', textTransform: 'capitalize', wordWrap: 'break-word' }}>Nurse:</span>{event.nurse}</div>
              <div> <i className='pi pi-calendar' />date:{event.next_Infusion_Date}</div>
              <div>          <p><strong><i className='pi pi-clock' /></strong> {moment(event.start_Time).format('HH:mm')} - {moment(event.end).format('HH:mm')}</p></div>
              <div>
              <img style={{ height: "20px", objectFit: "contain" }} src={durationlogo} alt="Duration" />duration:{event.duration}</div>

              <div><img src={image} /></div>

            </div> // Properly closing the event div


          ))}

        </div>
      </div>




    </Modal>


  );

}; 
const convertToDate = (dateString) => {
  return new Date(dateString); // Convert from string to Date
};
const allEvents = appointments.map((event) => {
  const start = convertToDate(event.start_Time);
  const end = convertToDate(event.end);
  
  // Add a flag for all-day events
  const isAllDay = start.getHours() === 0 && start.getMinutes() === 0 && end.getHours() === 0 && end.getMinutes() === 0;
  
  return {
    ...event,
    start,
    end,
    isAllDay,
  };
});

return (
  <div style={{ height: '100vh' }} className="appointment-calander">
    {loading ? (
      <>hello....</> // Display the loader message when loading is true
    ) : (

      <Calendar
      localizer={localizer}
      events={allEvents}
      startAccessor="start"
      endAccessor="end"
      titleAccessor="title"
      views={{ month: true, week: Week ,day:Day }} // Use custom Week view
      view={onView[0]} // Default to month or week view as needed
      defaultView="month"
      showMultiDayTimes={false}
      onSelectEvent={handleEventClick}
      onNavigate={handleDayClick}
      onShowMore={handleMoreClick}
      style={{ height: '100%', border: '1px solid #ccc' }}
      components={{
        toolbar: CustomToolbar, // Optional custom toolbar
      }}
    />

    )}

    {modaltable && renderEventDetailsForSelectedDate()}

    {/* Modal to display event details */}
    {selectedEvent && (
      <Dialog
        header={<>
          <div className='header-appointment'>
            <img style={{ height: "22px", paddingLeft: "10px" }} className='appoint-logo' src={appointmentlogo} assets="apppointment" />
            <span>Appointment Details</span>
          </div>
          <div style={{ color: 'rgba(45.69, 45.69, 45.69, 0.60)', paddingLeft: "20px", fontSize: 12, fontWeight: '500', textTransform: 'capitalize', wordWrap: 'break-word' }}>Stay Informed About Your Upcoming Consultations</div>
        </>

        }
        visible={modalIsOpen}
        onHide={closeModal}
        style={{
          width: '600px',
          borderRadius: '35px',
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
        }}

      >
        <div style={{ textAlign: 'right' }}>

        </div>

        <div style={{ marginBottom: '15px' }}>
          <div style={{ textAlign: 'justify', color: '#908E8E', fontSize: 13.13, fontWeight: '500', textTransform: 'capitalize', wordWrap: 'break-word' }}>Patient:<span
            style={{ textAlign: 'justify', color: '#4F4C4C', fontSize: 13.13, fontWeight: '500', textTransform: 'capitalize', wordWrap: 'break-word' }}>
            {selectedEvent.patient}</span> </div>

        </div>
        <div style={{ marginBottom: '15px' }}>

          <div style={{ textAlign: 'justify', color: '#908E8E', fontSize: 13.13, fontWeight: '500', textTransform: 'capitalize', wordWrap: 'break-word' }}>Nurse:<span style={{ textAlign: 'justify', color: '#4F4C4C', fontSize: 13.13, fontWeight: '500', textTransform: 'capitalize', wordWrap: 'break-word' }}>{selectedEvent.nurse}</span></div>

        </div>
        <div style={{ marginBottom: '15px' }}>
          <p><strong> <i className='pi pi-calendar' /></strong> {moment(selectedEvent.start).format('MMMM Do, YYYY')}</p>
        </div>
        <div style={{ marginBottom: '15px' }}>
          <p><strong><i className='pi pi-clock' /></strong> {moment(selectedEvent.start_Time).format('HH:mm')} - {moment(selectedEvent.end).format('HH:mm')}</p>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <p style={{ display: 'inline-flex', alignItems: 'center', gap: '5px' }}>
            <img style={{ height: "20px", objectFit: "contain" }} src={durationlogo} alt="Duration" />
            {selectedEvent.duration}
          </p>

        </div>
      </Dialog>
    )}
  </div>

);
};

export default Calender;
