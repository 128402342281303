import React, { useState } from "react";
import ARManagerFilter from "../../components/Filter/ARManagerFilter";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Loader from "../../components/loader/Loader";
import FilterMessage from "../../components/FilterMessage/FilterMessage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ARManagerDataTable from "../../components/DataTable/ARManagerDataTable";

const ARManager = ({
  name,
  userEmail,
}: {
  name: string | undefined;
  userEmail: string | undefined;
}) => {
  const { arManagerData, arManagerloading, filterLoading, unappliedData } =
    useSelector((state: RootState) => state.arManagerReducer);
  const location = window.location.pathname;
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [filterUpdate, setFilterUpdate] = useState("");

  const handleStatusUpdateLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  return (
    <>
      <div className="ar-manager">
        {filterLoading || loading ? (
          <Loader location={location} path="/ar-manager" label="AR Manager" />
        ) : arManagerloading ? (
          <Loader location={location} path="/ar-manager" label="AR Manager" />
        ) : null}
        <ToastContainer />
        <ARManagerFilter
        setUnappliedChecked={setChecked}
          setNoData={setNoData}
          setVisible={setVisible}
          userEmail={userEmail}
          setFilterUpdate={setFilterUpdate}
        />

        {arManagerData.length > 0 ? (
          <ARManagerDataTable
            unappliedChecked={checked}
            setUnappliedChecked={setChecked}
            filterUpdate={filterUpdate}
            name={name}
            userEmail={userEmail}
            visible={visible}
            setVisible={setVisible}
            data={checked ? unappliedData : arManagerData}
            onStatusUpdateLoading={handleStatusUpdateLoading}
          />
        ) : !filterLoading && !loading && !arManagerloading ? (
          <FilterMessage noData={noData} />
        ) : null}
      </div>
    </>
  );
};

export default ARManager;
