import { UserPermissionType } from "../redux/store/RoutePermission";

export const checkPermissions = (assignRoleByUser: UserPermissionType[], page: string, section: string) => {
    const checkSections = assignRoleByUser.filter((val) => {
        return val.page === page && val.section === section;
    });
    if (checkSections.length > 0) {
        const newPermission = checkSections[0];
        const newData = {
            all: newPermission?.all === "Y",
            delete: newPermission?.deny === "Y" ? false : newPermission?.delete === "Y",
            deny: newPermission?.deny === "Y",
            modify: newPermission?.deny === "Y" ? false : newPermission?.modify === "Y",
            read: newPermission?.deny === "Y" ? false : newPermission?.read === "Y",
            write: newPermission?.deny === "Y" ? false : newPermission?.write === "Y",
        }
        return newData;
    } else {
        const checkAllSections = assignRoleByUser.filter((val) => {
            return val.page === page && val.section === "All Section";
        });
        const permissionData = checkAllSections.length > 0 ? checkAllSections[0] : undefined;
        const newData = {
            all: permissionData?.all === "Y",
            delete: permissionData?.deny === "Y" ? false : permissionData?.delete === "Y",
            deny: permissionData?.deny === "Y",
            modify: permissionData?.deny === "Y" ? false : permissionData?.modify === "Y",
            read: permissionData?.deny === "Y" ? false : permissionData?.read === "Y",
            write: permissionData?.deny === "Y" ? false : permissionData?.write === "Y",
        }
        return newData;
    }
};