import { PickList } from "primereact/picklist";
import InputDropdown from "../../formElement/InputDropdown";
import InputField from "../../formElement/InputField";
import { Dialog } from "primereact/dialog";
import { RolePrivileges } from "../../../assets/images/Icon";
import { Button } from "primereact/button";
const AddNewAssignDataDialogBox = ({
    visible,
    setVisible,
  }: {
    visible: boolean;
    setVisible: any;
  }) => {
    const options = ["Deny", "Read", "Add", "Modify", "Delete"];
    const allOption = "All";
  
const customHeader = () => {
    return (
      <>
        <div className="dialog-header flex gap-2">
          <div className="header-icon mr-2">
            <img className="mt-2" src={RolePrivileges} alt="Role Privileges" />
          </div>
          <div className="header-title">
            <div className="text-xl">Assign Data Permission</div>
            <div className="text-sm font-medium header-subtitle">
              To register new privileges at the section level
            </div>
          </div>
        </div>
      </>
    );
  };

  const customFooter = () => {
    return (
      <div className="outlined flex justify-content-end gap-2">
        <Button
          label="Cancel"
          outlined
          className="w-7rem"
          onClick={() => setVisible(false)}
        />
        <Button label="Save" className="w-7rem" />
      </div>
    );
  };

  return (
    <>
<Dialog
        style={{ width: "30vw", zIndex: 1111 }}
        draggable
        header={customHeader}
        footer={customFooter}
        resizable
        visible={visible}
        onHide={() => setVisible(false)}
      >
    <label className='ml-2'>User Name</label>
    <div className='col-6 pt-0 pl-0'>

        <InputDropdown
            id="username"
            type="text"
            placeholder='Name'
            labelclassName="mr-1"
            // value={selectedUserName}
            // options={UserList.map((users: data) => ({
            //     label: users.user_name,
            //     value: users.user_id,
            // }))}
            // handleChange={(event) => setselectedUserName(event.target.value)}


        />
        {/* {loading && <p>Loading data...</p>}
        {errorMessage && <p className="error">{errorMessage}</p>} */}



    </div>
    <label className='ml-2'>Data Level</label>
    <div className='col-6 pt-0 pl-0'>

        <InputDropdown
            id="allroles"
            type="text"
            placeholder='Fax line'
            labelclassName=""
           
        />
    </div>
    <label className='ml-2'>Select Fax Line</label>
    <div className="input">
        <div className="grid m-0 pl-0">
            <div className="col-12">
                <PickList dataKey="id" breakpoint="1280px"
                    sourceHeader={
                        <InputField
                            placeholder="Search By Fax Line"
                            id="role"
                        />
                    }
                    className='ml-0'
                    targetStyle={{ height: '100px' }}
                />
            </div>
        </div>
    </div>
    
</Dialog> 
</>
  );
};
export default AddNewAssignDataDialogBox;