import React, { useEffect, useState } from "react";
import { Edit, SortDecre, SortIncre } from "../../assets/images/Icon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { getPatientToDoList } from "../../redux/store/TasklistReducer";

const TaskListDataTable = ({
  data,
  setIntialQuery,
  totalRecords,
  setAction,
  filterQuery,
  setVisible,
  setSelectedData,
}: {
  data: any;
  totalRecords: number;
  setIntialQuery: any;
  setAction: any;
  setVisible: any;
  filterQuery: any;
  setSelectedData: any;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { pageNumber, pageSize, sortDirection, sortBy } = filterQuery;

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  }

  const actionBody = (data: any) => {
    return (
      <Button
        className="icon-button ml-2 border-none p-0"
        icon={() => <img src={Edit} alt="Edit Icon" width={20} height={20} />}
        tooltip={"Update"}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          setVisible(true);
          setAction("edit");
          setSelectedData(data);
        }}
      />
    );
  };

  const handlePageChange = async (event: any) => {
    const { rows, page } = event;
    await dispatch(
      getPatientToDoList({
        SearchString: {
          ...filterQuery,
          pageNumber: page,
          pageSize: rows,
        },
      })
    );

    setIntialQuery((prev: any) => ({
      ...prev,
      pageNumber: page,
      pageSize: rows,
    }));
  };

  const handleSort = async (event: any) => {
    const { sortField, sortOrder } = event;
    await dispatch(
      getPatientToDoList({
        SearchString: {
          ...filterQuery,
          sortBy: sortField || "",
          sortDirection:
            sortOrder === 1 ? "asc" : sortOrder === -1 ? "desc" : "",
        },
      })
    );

    setIntialQuery((prev: any) => ({
      ...prev,
      sortBy: sortField || "",
      sortDirection: sortOrder === 1 ? "asc" : sortOrder === -1 ? "desc" : "",
    }));
  };

  const columns = [
    {
      field: "patient_todo_id",
      header: "Task ID",
      sortable: true,
    },
    {
      field: "mrn",
      header: "MRN",
      sortable: true,
    },
    {
      field: "patient_first_name",
      header: "Patient Name",
      sortable: true,
    },
    {
      field: "site_name",
      header: "Site",
      sortable: true,
    },
    {
      field: "teams",
      header: "Team",
      sortable: true,
    },
    {
      field: "therapy_type",
      header: "Therapy",
      sortable: true,
    },
    {
      field: "assigned_to_name",
      header: "Assigned To",
      sortable: true,
    },
    {
      field: "assigned_by_name",
      header: "Assigned By",
      headerStyle: { height: "50px" },
      sortable: true,
    },
    {
      field: "category",
      header: "Category",
      sortable: true,
    },
    {
      field: "due_date",
      header: "Due Date",
      body: (data: any) => <div>{data.due_date.split(" ")[0]}</div>,
      sortable: true,
    },
    {
      field: "status_description",
      header: "Status",
      sortable: true,
    },
    {
      field: "task_detail",
      header: "Task Details",
      sortable: true,
    },
    {
      field: "comment",
      header: "Comments",
      sortable: true,
    },
    {
      field: "created_date",
      header: "Created",
      body: (data: any) => formatDate(data.created_date),
      sortable: true,
    },
    {
      field: "Actions",
      header: "Actions",
      body: actionBody,
    },
  ];
  
  return (
    <>
      <DataTable
        value={data}
        scrollHeight="590px"
        scrollable
        paginator
        onSort={handleSort}
        sortOrder={
          sortDirection === "asc" ? 1 : sortDirection === "desc" ? -1 : null
        }
        sortField={sortBy}
        lazy
        dataKey="patient_todo_id"
        totalRecords={totalRecords}
        first={pageNumber * pageSize}
        rows={pageSize}
        onPage={handlePageChange}
        currentPageReportTemplate="Showing {first}-{last} of {totalRecords} Results"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[5, 10, 20, 50]}
        paginatorClassName="fax-table-paginator"
        className="tasklist-datatable"
        sortIcon={sortIcon}
        removableSort
      >
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            body={column.body}
            sortable={column.sortable}
          />
        ))}
      </DataTable>
    </>
  );
};

export default TaskListDataTable;
