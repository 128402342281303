// // ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../../utils/api/index";



const baseUrl = process.env.REACT_APP_BASEURL;



export interface NurseRecord {
    address: string | null;
    agency_name: string;
    city: string;
    country: string | null;
    county: string;
    created_by: string;
    created_date: string;  // You may want to use a Date type if you parse the date
    email: string;
    fax: string;
    first_name: string;
    is_current: boolean;
    is_delete: boolean;
    last_name: string;
    latitutde: string | null;  // Assuming it's a string, or you can change to number if it's a coordinate
    longitude: string | null;  // Same as latitude, change to number if it's numeric
    modified_by: string | null;
    modified_date: string | null;  // You may want to use a Date type if you parse the date
    notes: string | null;
    nurse_cpr_id: string | null;
    nurse_id: number;
    nurse_type: string;
    old_nurse_id: number;
    phone: string;
    polygon: string | null;
    state: string;
    status: string;
    zip_code: string;
    suite_id:number
  }
  

 export interface HolidayList {
    holiday_list_id: number;
    suite_id: number;              
    holidaystart_date: string;      
    holidayend_date: string;        
    description: string;          
    break_hours_from: string;      
    break_hours_to: string;      
    created_by: string;             
    created_date: string;          
    modified_by: string | null;    
    modified_date: string | null;
  }
  

  
interface NurseState {
    NurseList: NurseRecord[];
  HolyDayList:HolidayList[],

  errorMessage: string;
  loading: boolean;
  CityOptionList:any [],
  StateOptionList:any [],
  ZipcodeOptionList:any [],
  CountyOptionList: any[],
  StatusOptionList: any[];
  searchResults: any[];
  Category: any[];
  teams: any[];
  assingTo: any[];
  assingBy: any[];
  TaskFilterList: any;
  filterloading: boolean;
  addressMappings: any[], 
  addressMappingsbyCity: any[],
}


interface Status {
  status_id: number;
  status_description: string;
}


const initialState: NurseState = {
  NurseList: [], // Initialized as an empty array to be filled by the API response
  HolyDayList:[],
  errorMessage: "",
  loading: false,
  CityOptionList: [],
  StateOptionList: [],
  ZipcodeOptionList: [],
  CountyOptionList: [],
  StatusOptionList: [],
  searchResults: [],
  Category: [],
  teams: [],
  assingTo: [],
  assingBy: [],
  TaskFilterList: [],
  filterloading: false,
  addressMappings: [],
  addressMappingsbyCity: [],
};






export const getNurseAgencyStatusOptionList = createAsyncThunk(
    "NurseAgency/ getNurseAgencyStatusOptionList",
    async () => {
      try {
        const endpoint = `${baseUrl}/api/ToDo/GetStatusDescription`;
  
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data.data;
      
        
        return data;
      } catch (error) {
        //  console.error("Error fetching line item parent", error);
        throw error;
      }
    }
  );


export const getNurseList = createAsyncThunk(
    "NurseAgency/ getNurseList",
    async () => {
      try {
        const endpoint = `${baseUrl}/api/AgencyNurse/getNurseList`;
  
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data.data;
      
      
        
        return data;
      } catch (error) {
        //  console.error("Error fetching line item parent", error);
        throw error;
      }
    }
  );

export const getAgencyList = createAsyncThunk(
    "NurseAgency/ getAgencyList",
    async () => {
      try {
        const endpoint = `${baseUrl}/api/AgencyNurse/getAgencyList`;
  
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data.data;
      
      
        
        return data;
      } catch (error) {
        //  console.error("Error fetching line item parent", error);
        throw error;
      }
    }
  );

export const getinfusionList = createAsyncThunk(
    "NurseAgency/ getinfusionList",
    async () => {
     
      try {
        const endpoint = `${baseUrl}/api/AgencyNurse/getSuitHolidayListSP`;
  
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data.data;
    
        
        return data;
      } catch (error) {
        //  console.error("Error fetching line item parent", error);
        throw error;
      }
    }
  );
export const getStateList = createAsyncThunk(
    "NurseAgency/ getStateList",
    async () => {
     
      try {
        const endpoint = `${baseUrl}/api/AgencyNurse/getStateList`;
  
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data.data;
      
      
        
        return data;
      } catch (error) {
        //  console.error("Error fetching line item parent", error);
        throw error;
      }
    }
  );
export const getCountyList = createAsyncThunk(
    "NurseAgency/ getCountyList",
    async () => {
     
      try {
        const endpoint = `${baseUrl}/api/AgencyNurse/getCountyList`;
  
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data.data;
      
      
        
        return data;
      } catch (error) {
        //  console.error("Error fetching line item parent", error);
        throw error;
      }
    }
  );
export const getCityList = createAsyncThunk(
    "NurseAgency/ getCityList",
    async () => {
     
      try {
        const endpoint = `${baseUrl}/api/AgencyNurse/getCityList`;
  
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data.data;
       
      
        
        return data;
      } catch (error) {
        //  console.error("Error fetching line item parent", error);
        throw error;
      }
    }
  );
export const getZipcodeList = createAsyncThunk(
    "NurseAgency/ getZipcodeList",
    async () => {
     
      try {
        const endpoint = `${baseUrl}/api/AgencyNurse/getZipcodeList`;
  
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data.data;
      
      
        
        return data;
      } catch (error) {
        //  console.error("Error fetching line item parent", error);
        throw error;
      }
    }
  );
  
export const getHolidayList = createAsyncThunk(
    "NurseAgency/ getHolidayList",
    async () => {
     
      try {
        const endpoint = `${baseUrl}/api/AgencyNurse/getHolidayList`;
  
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data.data;
      
      
        
        return data;
      } catch (error) {
        //  console.error("Error fetching line item parent", error);
        throw error;
      }
    }
  );


  export const getAddressMappingByState = createAsyncThunk(
    "NurseAgency/getAddressMappingByState",
    async ({state_id}:{state_id:number}, { rejectWithValue }) => {
      try {
        const endpoint = `${baseUrl}/api/PhysicianPortal/getAddressMappingByState/${state_id}`;
        const response = await axios.get(endpoint);
  
        if (response.status !== 200) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        return response.data; // Assuming API response returns the data directly
      } catch (error:any) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );
  
  export const getAddressMappingByCity = createAsyncThunk(
    "NurseAgency/getAddressMappingByCity",
    async ({state_id,city_id}:{state_id:number,city_id:number}, { rejectWithValue }) => {
      try {
        const endpoint = `${baseUrl}/api/PhysicianPortal/getAddressMappingByCity?state_id=${state_id}&city_id=${city_id}`;
        const response = await axios.get(endpoint);
  
        if (response.status !== 200) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        return response.data; // Assuming API response returns the data directly
      } catch (error:any) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );




export const AddUpdateNurse = createAsyncThunk(
  "NurseAgency/ AddUpdateNurse",
  async (
    { updateData, callback }: { updateData: any; callback: any }
  ) => {
    try {
      const endpoint = `${baseUrl}/api/AgencyNurse/AddUpdateNurse`;
      const response = await axios.post(endpoint, updateData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data) {
        callback(response);
      }
    } catch (error: any) {
      console.error("Error fetching user details", error);
      callback(error.response)
    }
  }
);


export const AddUpdateAgency = createAsyncThunk(
  "NurseAgency/ AddUpdateAgency",
  async (
    { updateData, callback }: { updateData: any; callback: any }
  ) => {
    try {
      const endpoint = `${baseUrl}/api/AgencyNurse/AddUpdateAgency`;
      const response = await axios.post(endpoint, updateData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data) {
        callback(response);
      }
    } catch (error: any) {
      console.error("Error fetching user details", error);
      callback(error.response)
    }
  }
);

export const AddUpdateSuite = createAsyncThunk(
  "NurseAgency/ AddUpdateSuite",
  async (
    { updateData, callback }: { updateData: any; callback: any }
  ) => {
    try {
      const endpoint = `${baseUrl}/api/AgencyNurse/AddUpdateSuite`;
      const response = await axios.post(endpoint, updateData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data) {
        callback(response);
      }
    } catch (error: any) {
      console.error("Error fetching user details", error);
      callback(error.response)
    }
  }
);
export const AddUpdateHolidayList = createAsyncThunk(
  "NurseAgency/ AddUpdateHolidayList",
  async (
    { updateData, callback }: { updateData: any; callback: any }
  ) => {
    try {
      const endpoint = `${baseUrl}/api/AgencyNurse/AddUpdateHolidayList`;
      const response = await axios.post(endpoint, updateData);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data) {
        callback(response);
      }
    } catch (error: any) {
      console.error("Error fetching user details", error);
      callback(error.response)
    }
  }
);

export const tasklistSlice = createSlice({
  name: "NurseAgency",
  initialState,

  reducers: {},
  extraReducers: (builder) => {
    builder

   

    .addCase( getNurseAgencyStatusOptionList.fulfilled, (state, action) => {
        const filteredStatuses = action.payload.filter((status: Status) =>
          ["Active" /*2*/, "Inactive" /*9*/, "Pending" /*11*/].includes(
            status.status_description
          )
        );

        state.StatusOptionList = filteredStatuses;
        state.loading = false;
      })

      .addCase(
        getZipcodeList.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          
          state.ZipcodeOptionList = action.payload; // Store the search results
         
          
        }
      )
     
      .addCase(
        getStateList.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.StateOptionList = action.payload; // Store the search results
         
          
        }
      )

      .addCase(
        getCountyList.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.CountyOptionList = action.payload; // Store the search results
         
          
        }
      )

      .addCase(
        getCityList.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.CityOptionList = action.payload; // Store the search results
         
          
        }
      )
     
      .addCase(
        getHolidayList.fulfilled,
        (state, action: PayloadAction<any[]>) => {
        
          state.HolyDayList = action.payload; // Store the search results
         
          
        }
      )
     
      .addCase(getNurseList.pending, (state) => { state.loading = true;
        state.errorMessage = "";
      })
      .addCase(
        getNurseList.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.NurseList = action.payload; // Store the search results
         
          
        }
      )
      .addCase(getNurseList.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage =
          action.error.message || "Failed to fetch patient data";
      })

      .addCase(getAgencyList.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage =
          action.error.message || "Failed to fetch patient data";
      })
      .addCase(getAgencyList.pending, (state) => { state.loading = true;
        state.errorMessage = "";
      })
      .addCase(
        getAgencyList.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.NurseList = action.payload; // Store the search results
         
          
        }
      )
      .addCase(getinfusionList.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage =
          action.error.message || "Failed to fetch patient data";
      })
      .addCase(getinfusionList.pending, (state) => { state.loading = true;
        state.errorMessage = "";
      })
      .addCase(
        getinfusionList.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.NurseList = action.payload; // Store the search results
         
          
        }
      )
 
      .addCase(getAddressMappingByState.fulfilled, (state, action) => {
        // state.loading = false;
        state.addressMappings = action.payload?.data; // Assuming payload is the desired data
      })
     
      .addCase(getAddressMappingByCity.fulfilled, (state, action) => {
        // state.loading = false;
        state.addressMappingsbyCity = action.payload?.data; // Assuming payload is the desired data
      })
      
      .addCase(AddUpdateNurse.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddUpdateNurse.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddUpdateNurse.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(AddUpdateAgency.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddUpdateAgency.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddUpdateAgency.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(AddUpdateSuite.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddUpdateSuite.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddUpdateSuite.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      
      

  },
});

export default tasklistSlice.reducer;
