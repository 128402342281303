import { DataTable } from "primereact/datatable";
import React from "react";
import { SortDecre, SortIncre } from "../../../assets/images/Icon";
import { Column } from "primereact/column";
import { Columns } from "../../../types/IHealthAppTypes";

const PermissionDataTable = ({
  activeTab,
  columns
}: {
  activeTab: "user role" | "role Privileges";
  columns: Columns[]
}) => {

    const sortIcon = (order: any) => {
        if (order.sortOrder === 1) {
          // Ascending
          return <img className="ml-1" src={SortIncre} alt="Increase" />;
        } else if (order.sortOrder === -1) {
          // Descending
          return <img className="ml-1" src={SortDecre} alt="Decrease" />;
        }
        return null;
      };

    //   const columns = [
    //     {
    //         field: "role",
    //         header: "Role",
    //         sortable: true,
    //     },
    //     {
    //         field: "page name",
    //         header: "page name",
    //         sortable: true,
    //     },
    //     {
    //         field: "section name",
    //         header: "section name",
    //         sortable: true,
    //     },
    //     {
    //         field: "read",
    //         header: "read",
    //         sortable: true,
    //     },
    //     {
    //         field: "modify",
    //         header: "modify",
    //         sortable: true,
    //     },
    //     {
    //         field: "add",
    //         header: "add",
    //         sortable: true,
    //     },
    //     {
    //         field: "delete",
    //         header: "delete",
    //         sortable: true,
    //     },
    //     {
    //         field: "all",
    //         header: "all",
    //         sortable: true,
    //     },
    //     {
    //         field: "deny",
    //         header: "deny",
    //         sortable: true,
    //     },
    //     {
    //         field: "action",
    //         header: "action",
    //     },

    // ];


  return (
    <DataTable sortIcon={sortIcon} className="custom-bordered-table">
      {columns.map((column: Columns, index) => (
        <Column
          key={index}
          field={column.field}
          header={column.header}
          sortable={column.sortable}
          headerClassName="custom-header"
        />
      ))}
    </DataTable>
  );
};

export default PermissionDataTable;
