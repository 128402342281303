import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AddBlockSlotPopup from "./AddBlockSlotDialogBox";
import "../../../assets/css/agency.css";
import edit from "../../../assets/images/editicon.svg";
import {
  getHolidayList,
  HolidayList,
} from "../../../redux/store/NurseAgencyReducer";
import { RootState, AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";

interface BlockSlotTableProps {
  visible: boolean;
  setVisible: any;
  actionId: number | undefined;
  userEmail: string | undefined;
}

const BlockSlotTable: React.FC<BlockSlotTableProps> = ({
  visible,
  setVisible,
  actionId,
  userEmail,
}) => {
  const [filteredData, setFilteredData] = useState<HolidayList[]>();
  const [addPopupVisible, setAddPopupVisible] = useState(false);
  const [editData, setEditData] = useState<HolidayList[]>([]);
  const [Addaction, setAddAction] = useState<string>("");

  const { HolyDayList } = useSelector(
    (state: RootState) => state.NurseAgencyReducer
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getHolidayList());
  }, []);

  useEffect(() => {
    const filtered = HolyDayList.filter((item) => item?.suite_id === actionId);

    setFilteredData(filtered);
  }, [visible, HolyDayList]);

  const actionBodyTemplate = () => (
    <Button
      icon="pi pi-pencil"
      className="p-button-text"
      style={{ background: "transparent", border: "none", color: "#0a3e71" }}
    />
  );

  const dialogHeader = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h4 style={{ margin: 0 }}>Block Slot</h4>
      <Button
        label="Add Block Slot"
        icon="pi pi-plus"
        className="p-button NurseAddBlockSlotBtn"
        onClick={() => {
          setAddPopupVisible(true);
          setAddAction("add");
        }}
        style={{ background: "transparent", color: "#0a3e71" }}
      />
    </div>
  );


  return (
    <div>
      <Dialog
        header={dialogHeader}
        visible={visible}
        style={{ minWidth: "50vw" }}
        onHide={() => setVisible(false)}
        draggable={false}
      >
        <DataTable
          value={filteredData}
         scrollable
         scrollHeight="400px"
        >

          <Column field="holidaystart_date" header="Start Date" />
          <Column field="holidayend_date" header="End Date" />
          <Column field="break_hours_from" header="Break Hour From" />
          <Column field="break_hours_to" header="Break Hour To" />
          <Column field="description" header="Reason" />
          <Column field="created_by" header="Created By" />
          <Column field="modified_by" header="Modified By" />
          <Column
            header=""
            body={(rowData) => (
              <img
                src={edit}
                alt="Edit Icon"
                width={20}
                height={20}
                onClick={() => {
                  setEditData(rowData);
                  setAddPopupVisible(true);
                  setAddAction("edit");
                }}
              />
            )}
          />
        </DataTable>

        <AddBlockSlotPopup
          Addvisible={addPopupVisible}
          setAddVisible={setAddPopupVisible}
          action={Addaction}
          editData={[editData]}
          actionId={actionId}
          userEmail={userEmail}
        />
      </Dialog>
    </div>
  );
};

export default BlockSlotTable;
