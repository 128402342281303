import React, { useEffect, useMemo, useRef, useState } from "react";

import {
    PdfViewerComponent,
    Toolbar,
    Magnification,
    Navigation,
    LinkAnnotation,
    BookmarkView,
    ThumbnailView,
    Print,
    TextSelection,
    TextSearch,
    Annotation,
    FormFields,
    FormDesigner,
    Inject,
    PageOrganizer
} from '@syncfusion/ej2-react-pdfviewer';
import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-notifications/styles/material.css';
import "../../../node_modules/@syncfusion/ej2-react-pdfviewer/styles/material.css";
import { useParams } from "react-router-dom";
import { fetchFaxData, getFaxFile, handleClearFaxFile } from "../../redux/store/FaxSliceReducer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";

const SyncFusionDemo = ({ setFaxDetail, faxDetail, pdf, selectedIndex, setSelectedIndex, isUserModal, isEmailModal, isDisabled, setIsDisabled }) => {

    const pdfViewerRef = useRef(null);

    const dispatch = useDispatch();

    const { allFaxData, faxFileData, faxFileLoading } = useSelector((state) => state.FaxSliceReducer);

    const params = useParams();

    useEffect(() => {
        const filteredData = allFaxData.filter((val) => {
            return val.fax_detail_id == params.id;
        });
        if (filteredData.length > 0) {
            setFaxDetail(filteredData[0]);
        }
    }, [allFaxData, params]);

    // useEffect(() => {
    //     const fetchBase64 = async (url) => {
    //         const response = await fetch(url);
    //         const blob = await response.blob();
    //         return new Promise((resolve) => {
    //             const reader = new FileReader();
    //             reader.onloadend = () => resolve(reader.result);
    //             reader.readAsDataURL(blob);
    //         });

    //     };

    //     fetchBase64(pdf).then(base64 => {
    //         if (pdfViewerRef.current) {
    //             const base64String = base64.startsWith('data:application/pdf;base64,')
    //                 ? base64
    //                 : `data:application/pdf;base64,${base64.split(',')[1]}`;
    //             pdfViewerRef.current.load(base64String);
    //         }
    //     });
    // }, [pdf]);

    // const documentPath = useMemo(() => {
    //     // DEV LINK
    //     return `https://prod-167.westus.logic.azure.com/workflows/0ff31ffa16824bf698f8045c2f230a2a/triggers/manual/paths/invoke/Folder1/${faxDetail?.fax_folder_name}/Folder2/ec/FileName/${faxDetail?.file_name}?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=N3RU9GwmWFqXQahtRbNutq6um_zNoztQMqQezlHTElg`

    //     // PROD LINK
    //     // return `https://prod-47.westus.logic.azure.com/workflows/9c4b5d61651b487d98aaeeabdc84c59c/triggers/manual/paths/invoke/FileName/${faxDetail?.file_name}/Folder/${faxDetail?.fax_folder_name}?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Z4Lsu73tyqSWQulwTFKvIa0yyiG2ANERtEUb6Sak1q8`
    // }, [faxDetail]);

    const documentPath = useMemo(() => {
        // Check if faxFileData is available and is base64-encoded
        if (faxFileData) {
            // Base64 should be prefixed with the appropriate mime type for PDF
            return `data:application/pdf;base64,${faxFileData}`;
        }
        return "";
    }, [faxFileData]);

    useEffect(() => {
        if (faxDetail.file_name) {
            dispatch(getFaxFile({ fileName: faxDetail.file_name }));
        } else {
            dispatch(handleClearFaxFile());
        }
    }, [faxDetail]);

    // useEffect(() => {
    //     if (pdfViewerRef.current && pdfViewerRef.current.pageOrganizerModule) {
    //         const originalOnSelectClick = pdfViewerRef.current.pageOrganizerModule.onSelectClick;

    //         pdfViewerRef.current.pageOrganizerModule.onSelectClick = (...args) => {
    //             const currentSelected = Number(args[0].event.target.id.replace("checkboxdiv_page_", ""));
    //             const isChecked = args[0].checked;
    //             setSelectedIndex((prevState) => {
    //                 if (isChecked) {
    //                     if (!prevState.includes(currentSelected)) {
    //                         const newArray = [...prevState, currentSelected];
    //                         return newArray.sort((a, b) => a - b);
    //                     }
    //                 } else {
    //                     return prevState.filter((index) => index !== currentSelected);
    //                 }
    //                 return prevState;
    //             });

    //             if (originalOnSelectClick) {
    //                 originalOnSelectClick.apply(pdfViewerRef.current.pageOrganizerModule, args);
    //             }
    //         };
    //     }
    // }, [selectedIndex, faxDetail]);


    useEffect(() => {
        // Custom CSS to hide 'Select All' option, And to hide checkbox of pages
        const style = document.createElement('style');
        style.textContent = `
            .e-pdfviewer .e-pv-main-container .e-pv-viewer-main-container .e-pv-toolbar  .e-toolbar-items .e-toolbar-left {
               right: 0 !important;
               left: unset !important;
            }
        `;
        // style.textContent = `
        //     .e-pdfviewer .e-pv-main-container .e-dlg-container .e-dlg-content .e-toolbar-items .e-toolbar-left .e-template {
        //         display: none !important;
        //     }
        //     .e-pdfviewer .e-pv-main-container .e-dlg-container .e-dlg-content .e-pv-organize-tile-view-wrapper .e-pv-organize-tile-view .e-pv-organize-tile .e-checkbox-wrapper {
        //         display: none !important;
        //     }
        //     .e-pdfviewer .e-pv-main-container .e-pv-viewer-main-container .e-pv-toolbar  .e-toolbar-item .e-tbar-btn  .e-pv-open-document-icon {
        //        display: none !important;
        //     }
        //     .e-toolbar-right .e-toolbar-item .e-tbar-btn .e-pv-annotation-icon {
        //         display: none !important;
        //     }
        //     .e-toolbar-right .e-toolbar-item .e-tbar-btn .e-pv-formdesigner-icon {
        //         display: none !important;
        //     }
        //     .e-footer-content .e-control {
        //         display: none !important
        //     }
        //     .e-pdfviewer .e-pv-main-container .e-pv-sidebar-toolbar .e-pv-organize-view-button {
        //         display: none !important
        //     }
        // `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    useEffect(() => {
        if (allFaxData?.length === 0) {
            dispatch(fetchFaxData());
        }
    }, []);

    if (faxFileLoading) {
        return (
            <div>
                <Loader label='fax' path='/fax' />
            </div>
        );
    }

    return (
        <div className="row h-100  pdf-h">
            {/* <button onClick={closePageOrganizer}>OK</button> */}
            <div className={`col-md-12`}>
                <div className={`control-section col-md-12`}>
                    {faxDetail?.file_name && faxFileData && (
                        <PdfViewerComponent
                            className="h-100"
                            id="container"
                            ref={pdfViewerRef}
                            documentPath={documentPath}
                            // serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
                            resourceUrl="https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib"
                            toolbarSettings={{
                                showTooltip: true,
                                toolbarItems: ["MagnificationTool"],
                                annotationToolbarItems: [],
                                formDesignerToolbarItems: []
                            }}
                            enablePageOrganizer={true}
                            pageOrganizerSettings={{
                                canDelete: false,
                                canInsert: false,
                                canRotate: true,
                                canCopy: false,
                                canRearrange: false,
                                canImport: false,
                            }}
                            style={{
                                transform: (isUserModal || isEmailModal) ? 'scale(0.75)' : 'scale(1)', // Scale down when any modal is open
                                transformOrigin: 'top left', // Set transform origin
                                transition: 'transform 0.3s ease', // Smooth transition for scaling
                                width: (isUserModal || isEmailModal) ? '133.33%' : '100%', // Adjust width when scaling
                                height: (isUserModal || isEmailModal) ? '133.33%' : '100%', // Adjust height when scaling
                                overflow: 'auto', // Allow scrolling if content overflows
                            }}
                            documentLoad={() => setIsDisabled(false)}
                        // pageOrganizerSaveAs={closePageOrganizer2}
                        // pageClick={handleActionBegin}
                        // toolbarClick={handleActionBegin}
                        // buttonFieldClick={handleActionBegin}
                        // onClick={handleActionBegin}
                        // bookmarkClick={handleActionBegin}
                        // formFieldClick={handleActionBegin}
                        // hyperlinkClick={handleActionBegin}
                        // thumbnailClick={handleActionBegin}
                        // formFieldDoubleClick={handleActionBegin}
                        // annotationDoubleClick={handleActionBegin}
                        // documentLoadFailed={() => { console.log("222"); setIsDisabled(false) }}
                        >
                            <Inject
                                services={[
                                    Toolbar,
                                    Magnification,
                                    Navigation,
                                    LinkAnnotation,
                                    BookmarkView,
                                    ThumbnailView,
                                    Print,
                                    TextSelection,
                                    TextSearch,
                                    Annotation,
                                    FormFields,
                                    FormDesigner,
                                    PageOrganizer,
                                ]}
                            />
                        </PdfViewerComponent>
                    )}
                    {(!faxDetail?.file_name && !isDisabled) && (
                        <p>No file found for this fax</p>
                    )}
                    {!faxFileData && (
                        <p>No file found for this fax</p>
                    )}
                </div>
            </div>
        </div>


    );
};

export default SyncFusionDemo;
