import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable'
import React from 'react'
import { SortDecre, SortIncre } from '../../../assets/images/Icon';

const AddInfusionSuiteDatatable = () => {
  const pageOptions = [5, 10, 25, 50, 75, 100, 125];
  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };
  const columns = [
    { field: "date", header: "Nurse", sortable: true },
    { field: "invoice_number", header: "Agency", sortable: true },
    { field: "mrn", header: "Phone", sortable: true },
    { field: "patient_name", header: "Distance", sortable: true }
  ];

  return (<>
    <div className='add-agency'>
      <DataTable
        value={[]}
        showGridlines
        scrollable
        removableSort
        sortIcon={sortIcon}
        scrollHeight="580px"
        // paginator
        rowsPerPageOptions={pageOptions}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rows={25}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        globalFilterFields={columns.map((column) => column.field)}
        emptyMessage="No Data Found"
        rowHover
        onRowClick={(e) => { }} // Handle row click
      >
        {/* {columns.map((column, index) => (
        <Column
          key={index}
          field={column.field}
          header={column.header}
          sortable={column.sortable}
        />
      ))} */}
        <Column field='patient_Fname' sortable header="Nurse" />
        <Column field='patient_Lname' sortable header="Agency" />
        <Column field='patient_Lname' sortable header="Phone" />
        <Column field='patient_Lname' sortable header="Distance" />
      </DataTable>
    </div>
  </>
  )
}

export default AddInfusionSuiteDatatable
