import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import Input from "../../formElement/Input";
import InputDate from "../../formElement/InputDate";
import InputDropdown from "../../formElement/InputDropdown";
import { getAddressMappingByState } from "../../../redux/store/FaxSliceReducer";
import {
  addNewPatientFromReferral,
  getAddressMappingByCity,
  getAddressMappingByZipcode,
} from "../../../redux/store/CensusReducer";
import { FormatDate } from "../../../utils/formatDate";
import { displayToast } from "../../Toast/ToastMessage";
import { AddPatient } from "../../../assets/images/Icon";
import { Dropdown } from "primereact/dropdown";

const censusPageUrl = process.env.REACT_APP_CENSUS_LINK;

const AddNewPatientDialogBox = ({
  userEmail,
  visible,
  setVisible,
  onStatusUpdateLoading,
  onStatusUpdated,
}: {
  userEmail: string | undefined;
  visible: boolean;
  setVisible: any;
  onStatusUpdateLoading: (isLoading: boolean) => void;
  onStatusUpdated: () => void;
}) => {
  const [newPatientData, setNewPatientData] = useState({
    first_name: "",
    last_name: "",
    ssn: "",
    dob: undefined,
    state: "",
    site: "",
    city: "",
    zip_code: "",
  });
  const [inputValue, setInputValue] = useState(undefined);
  const [inputValueVisible, setInputValueVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState<any>({
    first_name: "",
    last_name: "",
    site: "",
  });

  const { stateListInsuranceListing } = useSelector(
    (state: RootState) => state.insuranceReducer
  );
  const { city, sites, stateLoading } = useSelector(
    (state: RootState) => state.FaxSliceReducer
  );
  const { zipcodeByCity } = useSelector(
    (state: RootState) => state.CensusReducer
  );

  const dispatch = useDispatch<AppDispatch>();

  const requiredFields = [
    { field: "first_name", message: "First Name is required!" },
    { field: "last_name", message: "Last Name is required!" },
    { field: "site", message: "Site Name is required!" },
  ];

  const validateFields = (
    data: any,
    requiredFields: { field: string; message: string }[]
  ) => {
    let errors: any = {};

    requiredFields.forEach(({ field, message }) => {
      if (!data[field] || data[field] === "") {
        errors[field] = message;
      } else {
        errors[field] = "";
      }
    });

    return errors;
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;

    setNewPatientData((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    if (id === "state") {
      dispatch(getAddressMappingByState(value));
    }
    if (id === "city") {
      dispatch(
        getAddressMappingByCity({
          state_id: newPatientData.state,
          city_id: value,
        })
      ).then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          setInputValueVisible(false);
        }
      });
    }
  };

  const handleZipInputChange = (e: any) => {
    const { value } = e.target;
    setInputValue(value);
    if (value.length === 5) {
      dispatch(getAddressMappingByZipcode({ zipcode: value })).then((res) => {
        const stateData = res.payload[0];
        if (!stateData) {
          return;
        }
        setInputValueVisible(false);
        dispatch(getAddressMappingByState(stateData.state_id));
        dispatch(
          getAddressMappingByCity({
            state_id: stateData.state_id,
            city_id: stateData.city_id,
          })
        );
        setNewPatientData((prevState) => ({
          ...prevState,
          state: stateData.state_id,
          city: stateData.city_id,
          zip_code: stateData.zipcode,
        }));
      });
    }
  };

  const addNewPatient = async () => {
    const cityLabel =
      city.find((city: any) => newPatientData.city === city.city_id)
        ?.city_name || "";

    const stateLabel =
      stateListInsuranceListing.find(
        (state: any) => newPatientData.state === state.state_id
      )?.state_code || "";

    const validationErrors = validateFields(newPatientData, requiredFields);

    const filteredErrors = Object.fromEntries(
      Object.entries(validationErrors).filter(([key, value]) => value !== "")
    );

    if (Object.keys(filteredErrors).length > 0) {
      setErrorMessage(filteredErrors);
      return;
    }
    setVisible(false);
    setNewPatientData({
      first_name: "",
      last_name: "",
      ssn: "",
      dob: undefined,
      state: "",
      site: "",
      city: "",
      zip_code: "",
    });
    onStatusUpdateLoading(true);

    await dispatch(
      addNewPatientFromReferral({
        newPatientData: {
          firsT_NAME: newPatientData.first_name,
          lasT_NAME: newPatientData.last_name,
          zip: newPatientData.zip_code,
          phone: "",
          dob: `${FormatDate(newPatientData.dob)}`,
          ssn: newPatientData.ssn,
          siteno: Number(newPatientData.site),
          referral_id: 0,
          loginEmail: userEmail,
          city: cityLabel,
          state: stateLabel,
        },
        callback: (res: any) => {
          if (res.data.success === true) {
            displayToast({
              msg: res?.data?.message,
              type: "success",
              onClose: () => {
                // Navigate after the toast disappears
                window.location.href = `${censusPageUrl}&uid=${Number(
                  res.data.data[0].mrn
                )
                  .toString(16)
                  .toUpperCase()}`;
              },
            });
          } else {
            displayToast({
              msg: res?.data?.message || "Something went wrong",
              type: "error",
            });
          }
        },
      })
    );
    onStatusUpdated();
  };

  const CustomFooter = (
    <div className="flex gap-2 justify-content-end">
      <Button
        label="Cancel"
        onClick={() => {
          setVisible(false);
          setErrorMessage({});
          setInputValue(undefined);
          setInputValueVisible(true);
          setNewPatientData({
            first_name: "",
            last_name: "",
            ssn: "",
            dob: undefined,
            state: "",
            site: "",
            city: "",
            zip_code: "",
          });
        }}
      />
      <Button label="Save" onClick={addNewPatient} />
    </div>
  );

  const customHeader = () => {
    return (
      <>
        <div className="dialog-header flex gap-2">
          <div className="header-icon mr-2">
            <img className="mt-2" src={AddPatient} alt="Add New Patient" />
          </div>
          <div className="header-title">
            <div className="text-xl">Add New Patient</div>
            <div className="text-sm font-medium header-subtitle">
              Enter Details to Create a Patient Record
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog
        draggable={false}
        resizable={false}
        className="w-10 md:w-9 lg:w-7 xl:w-5"
        // style={{ width: "1000px", zIndex: 1111 }}
        header={customHeader}
        visible={visible}
        onHide={() => {
          setVisible(false);
          setErrorMessage({});
          setInputValue(undefined);
          setInputValueVisible(true);
          setNewPatientData({
            first_name: "",
            last_name: "",
            ssn: "",
            dob: undefined,
            state: "",
            site: "",
            city: "",
            zip_code: "",
          });
        }}
        footer={CustomFooter}
      >
        <div className="grid">
          <div className="sm:col-6 col-12 py-1">
            <Input
              value={newPatientData.first_name}
              label="First Name"
              type="text"
              id="first_name"
              labelclassName="col-3"
              inputclassName="col-9"
              handleChange={handleChange}
              invalid={errorMessage.first_name}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <Input
              value={newPatientData.last_name}
              label="Last Name"
              type="text"
              id="last_name"
              labelclassName="col-3"
              inputclassName="col-9"
              handleChange={handleChange}
              invalid={errorMessage.last_name}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <Input
              value={newPatientData.ssn}
              label="SSN"
              type="text"
              id="ssn"
              labelclassName="col-3"
              inputclassName="col-9"
              handleChange={handleChange}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputDate
              value={newPatientData.dob}
              label="DOB"
              id="dob"
              labelclassName="col-3"
              inputclassName="col-9"
              handleChange={handleChange}
              maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputDropdown
              value={newPatientData.state || ""}
              handleChange={handleChange}
              label="State"
              labelclassName="col-3"
              dropdownclassName="col-9"
              type="text"
              optionLabel="label"
              id="state"
              filter
              options={stateListInsuranceListing.map((state: any) => ({
                label: state.state_code,
                value: state.state_id,
              }))}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputDropdown
              value={newPatientData.site || ""}
              handleChange={handleChange}
              label="Site"
              labelclassName="col-3"
              dropdownclassName="col-9"
              type="text"
              optionLabel="label"
              id="site"
              filter
              options={sites.map((site: any) => ({
                label: site.site_name,
                value: site.site_no,
              }))}
              invalid={errorMessage.site}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <InputDropdown
              value={newPatientData.city || ""}
              handleChange={handleChange}
              label="City"
              labelclassName="col-3"
              dropdownclassName="col-9"
              type="text"
              optionLabel="label"
              id="city"
              filter
              options={city.map((site: any) => ({
                label: site.city_name,
                value: site.city_id,
              }))}
              disabled={!newPatientData.state}
              loading={stateLoading}
            />
          </div>
          <div className="sm:col-6 col-12 py-1">
            <div className="grid m-0 align-items-center">
              <div className="col-3">
                <label className="font-medium text-sm" htmlFor="zip_code">
                  Zip Code
                </label>
              </div>

              <div className="col-9">
                {!inputValueVisible ? (
                  <Dropdown
                    className="w-full"
                    id="zip_code"
                    type="text"
                    value={newPatientData.zip_code || ""}
                    onChange={handleChange}
                    options={zipcodeByCity.map((site: any) => ({
                      label: site.zipcode,
                      value: site.zipcode,
                    }))}
                    optionLabel="label"
                  />
                ) : (
                  <Input
                    value={inputValue}
                    id="zip_code_input"
                    type="number"
                    handleChange={handleZipInputChange}
                  />
                )}
              </div>
            </div>
            {/* <InputDropdown
              value={newPatientData.zip_code || ""}
              handleChange={handleChange}
              label="Zip Code"
              labelclassName="col-3"
              dropdownclassName="col-9"
              type="text"
              optionLabel="label"
              id="zip_code"
              options={zipcodeByCity.map((site: any) => ({
                label: site.zipcode,
                value: site.zipcode,
              }))}
              disabled={!newPatientData.city}
              loading={zipLoading}
            /> */}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddNewPatientDialogBox;
