import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useEffect } from "react";
import {
  DiagnosisReport,
  HeartRate,
  BloodPressure,
  OxygenSaturation,
  Temperature,
  RespiratoryRate,
  Weight,
  Height,
  Gastrointestinal,
  Genitourinary,
  Musculoskeletal,
} from "../../../assets/images/Icon";

interface HealthMeasurement {
  vital_name: string;
  normal_range: string;
  insight: string;
  value: string | number;
}

const DignosisReportDialogBox = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) => {
  const healthMeasurement = useSelector(
    (state: RootState) => state.CensusReducer.healthMeasurement
  ) as HealthMeasurement[];

  const customHeader = () => {
    return (
      <>
        <div className="dialog-header flex gap-2">
          <div className="header-icon mr-2">
            <img
              className="mt-2"
              src={DiagnosisReport}
              alt="Diagnosis Report"
            />
          </div>
          <div className="header-title">
            <div className="text-xl">Diagnosis Report</div>
            <div className="text-sm font-medium header-subtitle">
              Advanced Health Insights Driven by AI Technology
            </div>
          </div>
        </div>
      </>
    );
  };

  const summary = healthMeasurement?.[0]; // First item for the summary

  const reportCards = healthMeasurement?.slice(1);

  return (
    <>
      <Dialog
        style={{ width: "1400px", zIndex: 1111 }}
        draggable
        className="diagnosis-report"
        header={customHeader}
        resizable
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
      >
        <div className="report-content">
          <div className="summary flex gap-2">
            <span className="text-sm font-bold">Summary:</span>
            <div className="text-sm font-medium header-subtitle">
              {summary?.insight}
            </div>
          </div>
          <div className="report-cards grid mx-0 mt-3">
            {reportCards.map((item, index) => (
              <div className="col-6" key={index}>
                <div className="report-card">
                  <div className="card-header py-2 flex justify-content-between align-items-center">
                    <div className="flex">
                      <img
                        className="mr-2"
                        src={
                          item.vital_name === "Heart Rate"
                            ? HeartRate
                            : item.vital_name === "Blood Pressure"
                            ? BloodPressure
                            : item.vital_name === "Oxygen Saturation"
                            ? OxygenSaturation
                            : item.vital_name === "Respiratory Rate"
                            ? RespiratoryRate
                            : item.vital_name === "Temperature"
                            ? Temperature
                            : item.vital_name === "Gastrointestinal"
                            ? Gastrointestinal
                            : item.vital_name === "Genitourinary"
                            ? Genitourinary
                            : item.vital_name === "Musculoskeletal"
                            ? Musculoskeletal
                            : item.vital_name === "Weight"
                            ? Weight
                            : item.vital_name === "Height"
                            ? Height
                            : ""
                        }
                        alt="Diagnosis Report"
                      />
                      <div className="text-sm font-bold">{item.vital_name}</div>
                    </div>
                    <div className="flex">
                      <div className="text-sm font-bold header-subtitle mr-1">
                        Normal Range :
                      </div>
                      <div className="text-sm font-bold ">
                        {item.normal_range}
                      </div>
                    </div>
                  </div>
                  <div className="card-content flex">
                    <div className="text-sm card-information w-10 content-information">
                      {item.insight}
                    </div>
                    <div className="flex align-items-end justify-content-end card-value w-2 text-4xl font-bold">
                      {item.value}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DignosisReportDialogBox;
