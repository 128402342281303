import React from "react";
import { Tooltip } from "primereact/tooltip";

const CustomTooltip = ({
  className,//ClassName wants to use white tooltp than pass "white" otherwise dont pass the className
  target, //Pass the ClassName of your component wherever you wants to show the tooltip, so if you are using button than pass tha classname over there and that classname should be pass in the Target as a ".ClassName"
  position, //use any of these:"bottom" | "left" | "right" | "top" | "mouse" 
}: {
  className?: string;
  target: string;
  position: "bottom" | "left" | "right" | "top" | "mouse" | undefined;
}) => {
  return (
    <>
      <Tooltip target={target} position={position} className={className} />
    </>
  );
};

export default CustomTooltip;
