import * as React from "react";
import { Calendar } from "primereact/calendar";
import { Calender } from "../../assets/images/Icon";

const InputDate = ({
  value,
  label,
  id,
  labelclassName,
  inputclassName,
  handleChange,
  placeholder,
  invalid,
  requiredRight,
  disabled,
  maxDate,
}: {
  value?: Date | null;
  label?: string;
  id: string;
  labelclassName?: string;
  inputclassName?: string;
  handleChange?: any;
  placeholder?: string;
  invalid?: boolean | string;
  requiredRight?: boolean;
  disabled?: boolean;
  maxDate?: Date;
}): React.ReactElement => {

  const inputClasses = label ? (inputclassName ? inputclassName : 'col-6') : 'col-12';

  return (
    <>
      <div className="grid m-0 align-items-center">
      {label && (
        <div className={`${labelclassName ? `${labelclassName}` : "col-6"}`}>
          <label className="font-medium text-sm" htmlFor={id}>
            {label}{" "}
            {requiredRight ? <span className="text-red-500">*</span> : null}
          </label>
        </div>
      )}
        <div className={`${inputClasses}`}>
          <Calendar
            value={value}
            id={id}
            className={`w-full ${invalid ? "invalid-field" : ""}`}
            showIcon
            icon={() => <img src={Calender} alt="Calender" />}
            onChange={handleChange}
            placeholder={placeholder}
            disabled={disabled}
            maxDate={maxDate}
          />
          <div>
            <span className="text-red-500 text-xs">{invalid}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputDate;