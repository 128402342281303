import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Column } from "primereact/column";
import { FormatDate } from "../../../utils/formatDate";
import { SortDecre, SortIncre } from "../../../assets/images/Icon";

const HistoryDialogBox = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) => {

  const { transactionHistory, historyLoading } = useSelector(
    (state: RootState) => state.arManagerReducer
  );

  const progressColumns = [
    {
      field: "date_changed",
      header: "Date",
      sortable: true,
      body: (rowData: any) => <span>{FormatDate(rowData.date_changed)}</span>,  
    },
    {
      field: "edited_by",
      header: "Edited By",
      sortable: true,
    },
    {
      field: "field_was",
      header: "Status Was",
      sortable: true,
    },
    { field: "field_is", header: "Status Is", sortable: true },
    {
      field: "field_name",
      header: "Field",
      sortable: true,
    },
  ];

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  return (
    <>
      <Dialog
        style={{ width: "1000px", zIndex: 1111 }}
        draggable
        header="Transaction History"
        resizable
        visible={visible}
        onHide={() => setVisible(false)}
      >
          <DataTable
            value={transactionHistory}
            emptyMessage="No Data Found"
            scrollable
            removableSort
            scrollHeight="612px"
            sortIcon={sortIcon}
            loading={historyLoading}
          >
            {progressColumns.map((column, index) => (
              <Column
                key={index}
                field={column.field}
                header={column.header}
                sortable={column.sortable}
                body={column.body}
              />
            ))}
          </DataTable>
      </Dialog>
    </>
  );
};

export default HistoryDialogBox;
