import React, { useState } from 'react'
import InputField from '../../formElement/InputField';
import { Button } from 'primereact/button';
import MultiSelectFilter from '../../formElement/MultiSelect';
import UserManagement from '../../DataTable/PermissionsDataTable/UserManagement';
import InputDropdown from '../../formElement/InputDropdown';
import { Dialog } from 'primereact/dialog';
import AssignPrivileges from '../../../assets/images/AssignPrivileges.svg';
import { PickList } from 'primereact/picklist';
import Input from '../../formElement/Input';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { GetUserList } from '../../../redux/store/PermissionsReducer';
import PermissionUser from './PermissionUser';

type data = {
    role_id: string;
    role_name: string;
    page_name: string;
    page_id: string;
    section_name: string;
    user_id: string;
    user_name: string

};
const UserManagementFilter = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<boolean>(false);
    const { permissionsList, pages, UserList, section, loading, errorMessage } =
        useSelector((state: RootState) => state.permissionsReducer);

    const AllCensus = ["patient", "provider"]
    const handleAssignPl = () => {
        setIsModalOpen(true);
    }
    const handleClose = () => {
        setIsModalOpen(false); // Close the popup
    };
    const [activesubComponent, setActivesubComponent] = useState('AssignData');
    // const handleSubComponentChange = (component: string) => {

    //     setActivesubComponent(component);
    // }
    const [selectedPageId, setSelectedPageId] = useState<string | null>(null); 
    const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null); 
    const [selectedSectionName, setSelectedSectionName] = useState<string>("");
    const [selectedUserName, setselectedUserName] = useState<string>("");

    const filteredSections = section.filter((item: data) => item.page_id === selectedPageId);
    const [activeTabuser, setActiveTabuser] = useState<
        "Register" | "Special" |"Assignrole" |"AssignUser"|"AssignData"
    >("AssignData");
    const handleSubComponentChange = (
        component:  "Register" | "Special" |"Assignrole" |"AssignUser"|"AssignData"
    ) => {

        setActiveTabuser(component);
    };
    return (
        <>
            <div className='roles flex gap-4 pt-2'>
                <Button
                    className={`custom-subrole ${activesubComponent === 'Register' ? 'activesubrole' : ''}`}
                    onClick={() => handleSubComponentChange('Register')}>
                    Register New user</Button>
                <Button
                    className={`custom-subrole ${activesubComponent === 'Special' ? 'activesubrole' : ''}`}
                    onClick={() => handleSubComponentChange('Special')}>
                    Special User Permissions</Button>
                <Button
                    className={`custom-subrole ${activesubComponent === 'Assignrole' ? 'activesubrole' : ''}`}
                    onClick={() => handleSubComponentChange('Assignrole')}>
                    Assign Role to User</Button>
                <Button
                    className={`custom-subrole ${activesubComponent === 'AssignUser' ? 'activesubrole' : ''}`}
                    onClick={() => handleSubComponentChange('AssignUser')}>
                    Assign User to Team</Button>
                <Button
                    className={`custom-subrole ${activesubComponent === 'AssignData' ? 'activesubrole' : ''}`}
                    onClick={() => handleSubComponentChange('AssignData')}>
                    Assign Data level permissions </Button>
            </div>
            <div className="subcomponent-content">
                {activesubComponent === 'Register' && (
                    <div className="user-role-component">

                    </div>
                )}
                {activesubComponent === 'Special' && (
                    <div className="role-privileges-component">
                    </div>
                )}
                {activesubComponent === 'Assignrole' && (
                    <div className="role-privileges-component">
                    </div>
                )}
                {activesubComponent === 'AssignUser' && (
                    <div className="role-privileges-component">
                    </div>
                )}
                {activesubComponent === 'AssignData' && (
                    <div className="role-privileges-component">
                    </div>
                )}
            </div>

            <div className="line"></div>
            <PermissionUser activeTabuser={activeTabuser} />

            {/* <PermissionNew activeTab={activeTab} /> */}

            {/* <div className="grid align-items-center my-1 justify-content-between">
                <div className='flex'>
                    <div className="col-5 pt-3">
                        <InputField
                            placeholder="Search By Role"
                            id="role"
                            className='custom-searchbar'
                        />
                    </div>
                    <div className="col-4">
                        <InputDropdown
                            id="allroles"
                            type="text"
                            placeholder='All Roles'
                            labelclassName="custom-fontwt"
                            value={selectedRoleId || ""}
                            options={permissionsList.map((role: data) => ({
                                label: role.role_name,
                                value: role.role_id,
                            }))}
                            handleChange={(event: any) => setSelectedRoleId(event.target.value)}
                        />
                        {loading && <p>Loading data...</p>}
                        {errorMessage && <p className="error">{errorMessage}</p>}
                     
                    </div>
                    <div className="col-4">
                        <InputDropdown
                            id="AllCensus"
                            type="text"
                            placeholder='AllCensus'
                            labelclassName=""
                            value={selectedPageId || ""}
                            options={pages.map((allpage: data) => ({
                                label: allpage.page_name,
                                value: allpage.page_id,
                            }))}
                            // Update the selectedPageId when a page is selected
                            handleChange={(event: any) => setSelectedPageId(event.target.value)}
                        />
                        {loading && <p>Loading data...</p>}
                        {errorMessage && <p className="error">{errorMessage}</p>}

                      
                    </div>
                    <div className="col-4">
                        <InputDropdown
                            id="AllSection"
                            type="text"
                            placeholder='AllSection'
                            labelclassName=""
                            value={selectedSectionName}
                            options={filteredSections.map((AllSection: data) => ({
                                label: AllSection.section_name || "",
                                value: AllSection.section_name || "",
                            }))}
                        handleChange={(event) => setSelectedSectionName(event.target.value)} 
                          
                        disabled={!selectedPageId}
                        />
                        {loading && <p>Loading data...</p>}
                        {errorMessage && <p className="error">{errorMessage}</p>}
                     
                    </div>
                </div>
                <div className='flex justify-end mr-2'>
                    <Button className='text-sm w-7rem pl-3'
                        onClick={handleAssignPl}>+ ADD NEW</Button>
                </div>

            </div> */}
            {/* <Dialog
                visible={isModalOpen}
                onHide={handleClose}
                style={{ width: '30vw', margin: '10px' }}
                header={<div>
                    <div className="custom-dialog-header">
                        <img src={AssignPrivileges} alt="AssignPrivileges" className="dialog-icon" />
                        <span>Assign Data Level Privileges</span>
                    </div>
                    <p className="dialog-content pl-2">To assign privileges at the data level</p>

                </div>}
            >
                <label className='ml-2'>User Name</label>
                <div className='col-6 pt-0 pl-0'>

                    <InputDropdown
                        id="username"
                        type="text"
                        placeholder='Name'
                        labelclassName="mr-1"
                        value={selectedUserName}
                            options={UserList.map((users: data) => ({
                                label: users.user_name ,
                                value: users.user_id,
                            }))}
                        handleChange={(event) => setselectedUserName(event.target.value)} 
                          
                     
                        />
                        {loading && <p>Loading data...</p>}
                        {errorMessage && <p className="error">{errorMessage}</p>}
                     
                

                </div>
                <label className='ml-2'>Data Level</label>
                <div className='col-6 pt-0 pl-0'>

                    <InputDropdown
                        id="allroles"
                        type="text"
                        placeholder='Fax line'
                        labelclassName=""
                        options={AllCensus}
                    />
                </div>
                <label className='ml-2'>Select Fax Line</label>
                <div className="input">
                    <div className="grid m-0 pl-0">
                        <div className="col-12">
                            <PickList dataKey="id" breakpoint="1280px"
                                sourceHeader={
                                    <InputField
                                        placeholder="Search By Fax Line"
                                        id="role"
                                    />
                                }
                                className='ml-0'
                                targetStyle={{ height: '100px' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="outlined flex justify-content-end gap-2">
                    <Button label="Cancel" outlined className="w-7rem" onClick={handleClose} />
                    <Button label="Save" className="w-7rem" onClick={handleClose} />
                </div>
            </Dialog> */}
            <UserManagement />


        </>
    )
}
export default UserManagementFilter;