const Loader = ({
    label,
    path,
    location,
  }: {
    label: string;
    path: string;
    location: string;
  }) => {
    return (
      <div className="loader-component">
        <span className="loader"></span>
        <div className="loading-message font-semibold text-lg mt-4">
          {location === path && `Loading ${label}....`}
        </div>
      </div>
    );
  };
  
  export default Loader;
  