import { Dialog } from "primereact/dialog";
import InputDropdown from "../../formElement/InputDropdown";
import { cprUser, patientSubject } from "../../../types/IHealthAppTypes";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { SetStateAction, useEffect, useRef, useState } from "react";
import InputTextArea from "../../formElement/InputTextArea";
import InputDate from "../../formElement/InputDate";
import MultiSelectFilter from "../../formElement/MultiSelect";
import { addPatientBillingNote } from "../../../redux/store/ARManagerReducer";
import { FormatDate } from "../../../utils/formatDate";
import { displayToast } from "../../Toast/ToastMessage";
import { OverlayPanel } from "primereact/overlaypanel";
import { Close } from "../../../assets/images/Icon";
import { Chip } from "primereact/chip";

type Invoice = {
  mrn: string;
  inv_no: string;
};

interface TemplatesDataType {
  value_data_id: number;
  description: string;
  note_body: string;
}

const AddNotesTemplateBox = ({
  op,
  setTemplateNotes,
}: {
  op: any;
  setTemplateNotes: React.Dispatch<SetStateAction<string>>;
}) => {
  const { billingNoteTemplate } = useSelector(
    (state: RootState) => state.arManagerReducer
  );

  return (
    <OverlayPanel className="template-popup" ref={op}>
      <div className="w-16rem">
        <div className="p-2 flex align-items-center justify-content-between">
          <p className="text-sm font-bold">Templates</p>
          <img
            src={Close}
            alt="Close"
            className="cursor-pointer"
            onClick={() => op.current?.hide()}
          />
        </div>
        <div className="template-list">
          {billingNoteTemplate.map((item: TemplatesDataType) => (
            <Chip
              key={item.value_data_id}
              label={item.description}
              className="m-1 text-xs cursor-pointer"
              onClick={() => {
                let convertText = item.note_body.replace(/<\/br>/g, "\n");
                setTemplateNotes(convertText);
              }}
            />
          ))}
        </div>
      </div>
    </OverlayPanel>
  );
};

const AddNotesDialogBox = ({
  userEmail,
  selectedCpkInvoices,
  rowCpkInvoice,
  setRowCpkInvoice,
  visible,
  setVisible,
  source,
  // onStatusUpdateLoading,
  // onStatusUpdated,
  filterUpdate,
}: {
  userEmail: string | undefined;
  selectedCpkInvoices: { mrn: string; billno: string }[];
  rowCpkInvoice: { mrn: string; billno: string } | undefined;
  setRowCpkInvoice: React.Dispatch<
    React.SetStateAction<
      | {
          mrn: string;
          billno: string;
        }
      | undefined
    >
  >;
  visible: boolean;
  setVisible: any;
  source: string;
  // onStatusUpdateLoading: (isLoading: boolean) => void;
  // onStatusUpdated: () => void;
  filterUpdate: string;
}) => {
  const { patientInvoiceId, patientBillingSubject, cprUser, arManagerData } = useSelector(
    (state: RootState) => state.arManagerReducer
  );
  const dispatch = useDispatch<AppDispatch>();
  const op = useRef<OverlayPanel>(null);
  // const [billno, setBillNo] = useState<
  //   Array<{
  //     mrn: string;
  //     inv_no: string;
  //   }>
  // >([
  //   {
  //     mrn: "",
  //     inv_no: "",
  //   },
  // ]);
  const [addNotes, setAddNotes] = useState({
    billno: [
      {
        mrn: "",
        inv_no: "",
      },
    ],
    subject_name: "",
    notes_details: "",
    assign_user: "",
    follow_up_date: undefined,
  });
  const [errorMessage, setErrorMessage] = useState<any>({
    billno: "",
    subject_name: "",
    notes_details: "",
  });

  const requiredFields = [
    { field: "billno", message: "Please Select atleast one Invoice!" },
    { field: "subject_name", message: "Subject Name is required!" },
    { field: "notes_details", message: "Note is required!" },
  ];

  const [templateNotes, setTemplateNotes] = useState("");

  const validateFields = (
    data: any,
    requiredFields: { field: string; message: string }[]
  ) => {
    let errors: any = {};
    
    requiredFields.forEach(({ field, message }) => {
      if (!data[field] || data[field] === "" || data[field].length === 0) {
        errors[field] = message;
      } else {
        errors[field] = "";
      }
    });

    return errors;
  };

  useEffect(() => {
    setAddNotes({ ...addNotes, notes_details: templateNotes });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateNotes]);

  useEffect(() => {
    // Preselect invoices based on rowCpkInvoice or selectedCpkInvoices
    if (rowCpkInvoice) {
      // setBillNo([{ mrn: rowCpkInvoice.mrn, inv_no: rowCpkInvoice.billno }]);
      setAddNotes({
        ...addNotes,
        billno: [{ mrn: rowCpkInvoice.mrn, inv_no: rowCpkInvoice.billno }],
      });
    } else {
      const preselectedInvoices = invoiceData.filter((invoice: any) =>
        selectedCpkInvoices.some(
          (selected: any) => selected.billno === invoice.inv_no
        )
      );
      // setBillNo(preselectedInvoices);
      setAddNotes({ ...addNotes, billno: preselectedInvoices });
    }
  }, [rowCpkInvoice, selectedCpkInvoices]);

  // Update the Row Status
  const AddNote = async () => {
    const inv_no = addNotes.billno.map(
      (billno: { mrn: string; inv_no: string }) => ({
        mrn: billno.mrn,
        billno: billno.inv_no,
      })
    );

    const validationErrors = validateFields(addNotes, requiredFields);

    const filteredErrors = Object.fromEntries(
      Object.entries(validationErrors).filter(([key, value]) => value !== "")
    );

    if (Object.keys(filteredErrors).length > 0) {
      setErrorMessage(filteredErrors);
      return;
    }

    // onStatusUpdateLoading(true);
    const updateNote = {
      inputjson: JSON.stringify(inv_no),
      usermail: userEmail,
      subject: addNotes.subject_name.trim(),
      body: addNotes.notes_details,
      followupDate: FormatDate(addNotes.follow_up_date),
      followupUserno: addNotes.assign_user,
    };

    if (
      addNotes.billno.length > 0 &&
      addNotes.subject_name &&
      addNotes.notes_details
    ) {
      await dispatch(
        addPatientBillingNote({
          updateData: updateNote,
          callback: (res: any) => {
            if (res.data.success) {
              displayToast({
                msg: "Notes for the selected claims have been added successfully.",
                type: "success",
              });
              const preselectedInvoices = invoiceData.filter(
                (invoice: any) =>
                  selectedCpkInvoices.some(
                    (selected: any) => selected.billno === invoice.inv_no
                  )
              );
              // setBillNo(preselectedInvoices);
              setAddNotes({
                billno: preselectedInvoices,
                subject_name: "",
                notes_details: "",
                assign_user: "",
                follow_up_date: undefined,
              });
            } else {
              displayToast({
                msg: res?.data?.message || "Something went wrong",
                type: "error",
              });
            }
          },
        })
      );
      setVisible(false);
    }
    // dispatch(getArManagerData({ updateData: filterUpdate }));
    // onStatusUpdated(); // Trigger the callback after successful update
  };

  const invoiceData = arManagerData.map(item => ({
    mrn: item.mrn,
    inv_no: item.cpk_invoices
}));

  // Dialog Footer
  const Footer = (
    <div className="buttons flex gap-2 justify-content-end">
      <Button
        label="Cancel"
        outlined
        onClick={() => {
          const preselectedInvoices = invoiceData.filter((invoice: any) =>
            selectedCpkInvoices.some(
              (selected: any) => selected.billno === invoice.inv_no
            )
          );
          // setBillNo(preselectedInvoices);
          setRowCpkInvoice(undefined);
          setErrorMessage({});
          setVisible(false);
          setAddNotes({
            billno: preselectedInvoices,
            subject_name: "",
            notes_details: "",
            assign_user: "",
            follow_up_date: undefined,
          });
        }}
      />
      <Button label="Save" onClick={AddNote} />
    </div>
  );

  const handleChange = (e: any) => {
    const { id, value } = e.target;

    setAddNotes((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <>
      <Dialog
        style={{ width: "700px", zIndex: 1111 }}
        draggable
        header="Add Notes:"
        footer={Footer}
        resizable
        modal={false}
        visible={visible}
        onHide={() => {
          const preselectedInvoices = invoiceData.filter((invoice: any) =>
            selectedCpkInvoices.some(
              (selected: any) => selected.billno === invoice.inv_no
            )
          );
          // setBillNo(preselectedInvoices);
          setErrorMessage({});
          setRowCpkInvoice(undefined);
          setVisible(false);
          setAddNotes({
            billno: preselectedInvoices,
            subject_name: "",
            notes_details: "",
            assign_user: "",
            follow_up_date: undefined,
          });
        }}
      >
        <div className="grid m-0 align-items-center">
          <div className="col-3">
            <label className="font-medium text-sm" htmlFor="inputjson">
              <span className="text-red-500">*</span> For Invoice
            </label>
          </div>
          <div className="col-9">
            <MultiSelectFilter
              filterValue="inv_no"
              id="inputjson"
              value={addNotes.billno.map((bill) => bill.inv_no)}
              label="inv_no"
              onChange={(e: any) => {
                if (rowCpkInvoice) return;
                const selectedInvNos = e.target.value;
                const updatedBillNo = selectedInvNos.map((inv_no: string) => {
                  // Find the corresponding `mrn` for each selected `inv_no`
                  const originalBill = (invoiceData as Invoice[]).find(
                    (invoice: any) => invoice.inv_no === inv_no
                  );
                  return {
                    mrn: originalBill?.mrn || "", // Use the mrn from the original data
                    inv_no: inv_no,
                  };
                });
                // setBillNo(updatedBillNo);
                setAddNotes({ ...addNotes, billno: updatedBillNo });
              }}
              data={invoiceData}
              virtualScrollerOptions={{ itemSize: 25 }}
              disabled={source === "single"}
              required={true}
              showSelectAll={false}
              invalid={errorMessage.billno}
            />
          </div>
        </div>
        <InputDropdown
          value={addNotes.subject_name || ""}
          handleChange={handleChange}
          label="Subject"
          labelclassName="col-3"
          dropdownclassName="col-9"
          type="text"
          optionLabel="label"
          id="subject_name"
          options={patientBillingSubject.map((subject: patientSubject) => ({
            label: subject.subject_name,
            value: subject.subject_name,
          }))}
          required={true}
          filter
          handleFocus={() => op.current?.hide()}
          invalid={errorMessage.subject_name}
        />
        <InputTextArea
          label="Note Details"
          id="notes_details"
          labelclassName="col-3"
          value={addNotes.notes_details}
          inputclassName="col-9"
          placeholder="Note Details"
          handleChange={handleChange}
          handleClick={(e) => op.current?.toggle(e)}
          rows={5}
          required={true}
          invalid={errorMessage.notes_details}
        />
        <InputDropdown
          value={addNotes.assign_user || ""}
          handleChange={handleChange}
          label="Follow up Assigned To"
          labelclassName="col-3"
          dropdownclassName="col-9"
          type="text"
          optionLabel="label"
          id="assign_user"
          filter
          options={cprUser.map((followUpAssign: cprUser) => ({
            label: `${followUpAssign.first_name} ${followUpAssign.last_name}`,
            value: followUpAssign.user_no,
          }))}
          handleFocus={() => op.current?.hide()}
        />
        <InputDate
          value={addNotes.follow_up_date}
          label="Follow up Date"
          id="follow_up_date"
          labelclassName="col-3"
          inputclassName="col-9"
          handleChange={handleChange}
        />
      </Dialog>
      <AddNotesTemplateBox op={op} setTemplateNotes={setTemplateNotes} />
    </>
  );
};

export default AddNotesDialogBox;
