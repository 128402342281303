import React from "react";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { SortDecre, SortIncre } from "../../assets/images/Icon";
import { FormatDate } from "../../utils/formatDate";
import CustomTooltip from "../Tooltip/CustomTooltip";

const powerappUrl = process.env.REACT_APP_CENSUS_LINK;

const CensusDataTable = ({
  name,
  userEmail,
  data,
  filters,
  filterUpdate,
}: {
  name: string | undefined;
  userEmail: string | undefined;
  data: any;
  filters?: DataTableFilterMeta;
  filterUpdate: string;
}) => {
  // Handle Row Click for Redirection
  const handleRowClick = (event: any, rowData: any) => {
    window.location.href = (
      `${powerappUrl}&uid=${Number(rowData.mrn).toString(16).toUpperCase()}`
    );
  };

  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };

  // Custom column bodies
  const LastNameBody = (rowData: any) => (
    <div className="flex align-items-center">
      <CustomTooltip position="bottom" target=".patient-status"/>
      <span
        className={`mr-2 patient-status ${
          rowData.patient_status === "Active"
            ? "active-status"
            : rowData.patient_status === "Cancelled"
            ? "cancelled-status"
            : rowData.patient_status === "Inactive"
            ? "inactive-status"
            : rowData.patient_status === "On Hold"
            ? "onhold-status"
            : rowData.patient_status === "Pending"
            ? "pending-status"
            : ""
        }`}
        data-pr-tooltip={
          rowData.patient_status === "Active"
            ? "Active"
            : rowData.patient_status === "Cancelled"
            ? "Cancelled"
            : rowData.patient_status === "Inactive"
            ? "Inactive"
            : rowData.patient_status === "On Hold"
            ? "On Hold"
            : rowData.patient_status === "Pending"
            ? "Pending"
            : ""
        }
      ></span>
      <span>{rowData.last_name}</span>
    </div>
  );

  // Columns
  const columns = [
    {
      field: "mrn",
      header: "MRN",
      sortable: true,
    },
    {
      field: "last_name",
      header: "Last Name",
      sortable: true,
      body: LastNameBody,
    },
    {
      field: "first_name",
      header: "First Name",
      sortable: true,
    },
    { field: "date_of_birth", header: "Date of Birth", sortable: true, body: (rowData: any) => FormatDate(rowData.date_of_birth) },
    { field: "referral_date", header: "Ref Date", sortable: true, body: (rowData: any) => FormatDate(rowData.referral_date) },
    { field: "prescriber_name", header: "Prescriber", sortable: true },
    { field: "therapy", header: "Category", sortable: true },
    { field: "insurance_name", header: "Insurance", sortable: true },
    { field: "site", header: "Site", sortable: true },
    { field: "phone_number", header: "Phone", sortable: true },
  ];

  return (
    <>
      <DataTable
        value={data}
        paginator
        rows={15}
        rowsPerPageOptions={[5, 10, 15, 50]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        emptyMessage="No Data Found"
        globalFilterFields={columns.map((column) => column.field)}
        filters={filters}
        scrollable
        sortIcon={sortIcon}
        onRowClick={(e) => handleRowClick(e, e.data)} // Handle row click for redirection
        selectionPageOnly
      >
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            sortable={column.sortable}
            body={column.body}
          />
        ))}
      </DataTable>
    </>
  );
};

export default CensusDataTable;
