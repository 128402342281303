// ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { QuestionList } from "../../Data/QuestionList";
import { InitialStateType, Option } from "../../types/IHealthAppTypes";
import { getBillerList } from "./ARManagerReducer";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASEURL;

// GET Method For Insurance Company List
export const getInsuranceCompanyList = createAsyncThunk(
  "insurance/getInsuranceCompanyList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/getInsuranceCompanyList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getStateListInsuranceListing = createAsyncThunk(
  "insurance/getStateListInsuranceListing",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/getStateListInsuranceListing`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getArManagerPayorTypeList = createAsyncThunk(
  "insurance/getArManagerPayorTypeList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/getArManagerPayorTypeList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getInsuranceCompanyBillingOrganizationList = createAsyncThunk(
  "insurance/getInsuranceCompanyBillingOrganizationList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/getInsuranceCompanyBillingOrganizationList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getBillingQualifier = createAsyncThunk(
  "insurance/getBillingQualifier",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/getBillingQualifier`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getSiteList = createAsyncThunk(
  "insurance/getSiteList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/getSiteList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getBillingInvoiceType = createAsyncThunk(
  "insurance/getBillingInvoiceType",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/getBillingInvoiceType`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getAllQuestionAnswerValueList = createAsyncThunk(
  "insurance/getAllQuestionAnswerValueList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/getAllQuestionAnswerValueList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getOldQuestionAnswer = createAsyncThunk(
  "insurance/getOldQuestionAnswer",
  async ({ company_id }: { company_id: number }) => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/getOldQuestionAnswer/${company_id}`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getContractState = createAsyncThunk(
  "insurance/getContractState",
  async ({ company_id }: { company_id: number }) => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/GetContractState?contractId=${company_id}`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getKeyValueDataForContractStatus = createAsyncThunk(
  "insurance/getKeyValueDataForContractStatus",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/GetKeyValue?decriiption=contract_status`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getKeyValueDataForPayorPricing = createAsyncThunk(
  "insurance/getKeyValueDataForPayorPricing",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/GetKeyValue?decriiption=Payor_Pricing`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const addNewInsuranceCompanyRequest = createAsyncThunk(
  "insurance/addNewInsuranceCompanyRequest",
  async ({ data, callback }: { data: any; callback: any }, apiThunk) => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/addNewInsuranceCompanyRequest`;
      const response = await axios.post(endpoint, data);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data) {
        callback(response);
      }
      await apiThunk.dispatch(getInsuranceCompanyList());
    } catch (error: any) {
      console.error("Error fetching user details", error);
      callback(error.response);
    }
  }
);

export const updateGeneralBillingInsuranceData = createAsyncThunk(
  "insurance/updateGeneralBillingInsuranceData",
  async ({ data, callback }: { data: any; callback: any }, apiThunk) => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/updateGeneralBillingInsuranceData`;
      const response = await axios.post(endpoint, data);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data) {
        callback(response);
      }
      await apiThunk.dispatch(getInsuranceCompanyList());
    } catch (error: any) {
      console.error("Error fetching user details", error);
      callback(error.response);
    }
  }
);

export const updateAnswersInBillingInsurance = createAsyncThunk(
  "insurance/updateAnswersInBillingInsurance",
  async ({ data, callback }: { data: any; callback: any }, apiThunk) => {
    try {
      const endpoint = `${baseUrl}/api/Insurance/updateAnswersInBillingInsurance`;
      const response = await axios.post(endpoint, data);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      if (callback && response.data) {
        callback(response);
      }
      await apiThunk.dispatch(getInsuranceCompanyList());
    } catch (error: any) {
      console.error("Error fetching user details", error);
      callback(error.response);
    }
  }
);

const initialState: InitialStateType = {
  userEmail: "",
  insuranceCompanyActiveList: [],
  insuranceCompanyInactiveList: [],
  allinsuranceCompanyList: [],
  stateListInsuranceListing: [],
  questionAnswerValueList: [],
  getBillerList: [],
  contractState: [],
  payorPricingList: [],
  contractStatusList: [],
  arManagerPayorTypeList: [],
  oldQuestionAnswer: [],
  insuranceCompanyBillingOrganizationList: [],
  billingQualifier: [],
  siteList: [],
  billingInvoiceType: [],
  errorMessage: "",
  loading: false,
};

// Insurance Slice
const insuranceSlice = createSlice({
  name: "insurance",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    // setLoading: (state, action) => {
    //     state.loading = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInsuranceCompanyList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getInsuranceCompanyList.fulfilled, (state, action) => {
        const active = action.payload?.data?.filter(
          (item: any) => item.inactive === "0"
        );
        const inactive = action.payload?.data?.filter(
          (item: any) => item.inactive === "1"
        );
        state.insuranceCompanyActiveList = active || [];
        state.insuranceCompanyInactiveList = inactive || [];
        state.allinsuranceCompanyList = action.payload?.data || [];
        state.loading = false;
      })
      .addCase(getInsuranceCompanyList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getKeyValueDataForContractStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getKeyValueDataForContractStatus.fulfilled, (state, action) => {
        state.contractStatusList = action.payload?.data;
        state.loading = false;
      })
      .addCase(getKeyValueDataForContractStatus.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(addNewInsuranceCompanyRequest.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addNewInsuranceCompanyRequest.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewInsuranceCompanyRequest.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(updateGeneralBillingInsuranceData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateGeneralBillingInsuranceData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateGeneralBillingInsuranceData.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(updateAnswersInBillingInsurance.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateAnswersInBillingInsurance.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateAnswersInBillingInsurance.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getKeyValueDataForPayorPricing.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getKeyValueDataForPayorPricing.fulfilled, (state, action) => {
        state.payorPricingList = action.payload?.data;
        state.loading = false;
      })
      .addCase(getKeyValueDataForPayorPricing.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getStateListInsuranceListing.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getStateListInsuranceListing.fulfilled, (state, action) => {
        state.stateListInsuranceListing = action.payload?.data;
        state.loading = false;
      })
      .addCase(getStateListInsuranceListing.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getArManagerPayorTypeList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getArManagerPayorTypeList.fulfilled, (state, action) => {
        state.arManagerPayorTypeList = action.payload?.data;
        state.loading = false;
      })
      .addCase(getArManagerPayorTypeList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(
        getInsuranceCompanyBillingOrganizationList.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        getInsuranceCompanyBillingOrganizationList.fulfilled,
        (state, action) => {
          state.insuranceCompanyBillingOrganizationList = action.payload?.data;
          state.loading = false;
        }
      )
      .addCase(
        getInsuranceCompanyBillingOrganizationList.rejected,
        (state, action) => {
          state.errorMessage = action.error.message || "";
          state.loading = false;
        }
      )
      .addCase(getBillerList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBillerList.fulfilled, (state, action) => {
        state.getBillerList = action.payload?.data;
        state.loading = false;
      })
      .addCase(getBillerList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getBillingQualifier.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBillingQualifier.fulfilled, (state, action) => {
        state.billingQualifier = action.payload?.data;
        state.loading = false;
      })
      .addCase(getBillingQualifier.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getSiteList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSiteList.fulfilled, (state, action) => {
        const filteredSites = action.payload?.data
          ?.filter((site: { id: number; site: string }) => site.id !== 1)
          .sort((a: { site: string }, b: { site: string }) =>
            a.site.localeCompare(b.site)
          );
        state.siteList = filteredSites || [];
        state.loading = false;
      })
      .addCase(getSiteList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getBillingInvoiceType.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBillingInvoiceType.fulfilled, (state, action) => {
        state.billingInvoiceType = action.payload?.data;
        state.loading = false;
      })
      .addCase(getBillingInvoiceType.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getContractState.pending, (state, action) => {
        // state.loading = true
      })
      .addCase(getContractState.fulfilled, (state, action) => {
        state.contractState = action.payload?.data;
        // state.loading = false
      })
      .addCase(getContractState.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        // state.loading = false
      })
      .addCase(getAllQuestionAnswerValueList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getAllQuestionAnswerValueList.fulfilled,
        (state, action: PayloadAction<{ data: Option[] }>) => {
          const specificQuestions = ["question11", "question12"];
          state.questionAnswerValueList = QuestionList.map(
            (question, index) => {
              const questionLabel = `question${index + 1}`;
              const matchingOptions = action.payload?.data?.filter(
                (option) => option.questionID === question.question_id
              );
              
              return {
                response_id: question.response_id,
                question_id: question.question_id,
                options: specificQuestions.includes(questionLabel)
                  ? state.getBillerList
                  : matchingOptions,
                question: question.question,
                Question_type: question.Question_type,
                answer: question.answer,
                question_label: questionLabel,
              };
            }
          );

          state.loading = false;
        }
      )
      .addCase(getAllQuestionAnswerValueList.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.loading = false;
      })
      .addCase(getOldQuestionAnswer.pending, (state, action) => {
        // state.loading = true
      })
      .addCase(getOldQuestionAnswer.fulfilled, (state, action) => {
        state.oldQuestionAnswer = action.payload?.data;
        // state.loading = false
      })
      .addCase(getOldQuestionAnswer.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        // state.loading = false
      });
  },
});

export const { setEmail } = insuranceSlice.actions;
export default insuranceSlice.reducer;
