import React, { useEffect, useRef, useState } from "react";
import InputField from "../../formElement/InputField";
import {
  AddFilter,
  AddPatient,
  Calender,
  Close,
  Delete,
  Filter,
  HealthInformation,
  HealthInformationDialog,
  MapView,
  SearchInvoice,
  SearchRx,
} from "../../../assets/images/Icon";
import MultiSelectFilter from "../../formElement/MultiSelect";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewCustomFilter,
  GetCustomFilterList,
  getDrugCategoryList,
} from "../../../redux/store/ARManagerReducer";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomFilterList } from "../../../types/IHealthAppTypes";
import { Calendar } from "primereact/calendar";
import {
  getStateListInsuranceListing,
} from "../../../redux/store/InsuranceReducer";
import { Getteams } from "../../../redux/store/TasklistReducer";
import {
  getCaseManager,
  getIntakeReport,
  getPrescriber,
  getSalesPerson,
  censusSearchPatient,
  getStatusOptionList,
  resetFilter,
} from "../../../redux/store/CensusReducer";
import SearchInvoiceDialogBox from "../../Dialog/CensusDialogBox/SearchInvoiceDialogBox";
import HealthInformationDialogBox from "../../Dialog/CensusDialogBox/HealthInformationDialogBox";
import AddNewPatientDialogBox from "../../Dialog/CensusDialogBox/AddNewPatientDialogBox";
import MapViewDialogBox from "../../Dialog/CensusDialogBox/MapViewDialogBox";
import { Ripple } from "primereact/ripple";
import { Sidebar } from "primereact/sidebar";
import { useUserPermission } from "../../../hooks/useUserPermission";
import { getSites } from "../../../redux/store/FaxSliceReducer";
import { useMedia } from "../../../hooks/useMedia";

const CensusFilter = ({
  userEmail,
  setFilterUpdate,
  setNoData,
  onStatusUpdateLoading,
}: {
  userEmail: string | undefined;
  setFilterUpdate: any;
  setNoData: any;
  onStatusUpdateLoading: (isLoading: boolean) => void;
}): React.ReactElement => {
  const { drugCategoryList, customFilterList, filterloading } = useSelector(
    (state: RootState) => state.arManagerReducer
  );

  const isMobileScreen = useMedia("(max-width: 767px)");
  const isTabletScreen = useMedia("(max-width: 991px)");

  const { sites } = useSelector(
    (state: RootState) => state.FaxSliceReducer
  );
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const { teams } = useSelector((state: RootState) => state.tasklistReducer);
  const {
    caseManagerList,
    prescriberList,
    salesPersonList,
    intakeReport,
    statusList,
    censusData,
  } = useSelector((state: RootState) => state.CensusReducer);

  const dispatch = useDispatch<AppDispatch>();
  const [customFilterJson, setCustomFilterJson] = useState("");
  const [showSaveFilter, setShowSaveFilter] = useState<boolean>(false);
  const [searchInvoiceVisible, setSearchInvoiceVisible] = useState(false);
  const [healthInformationVisible, setHealthInformationVisible] =
    useState(false);
  const [addNewFilterVisible, setAddNewFilterVisible] = useState(false);
  const [mapViewVisible, setMapViewVisible] = useState(false);
  const [filterValue, setFilterValue] = useState<string>("");
  const [error, setError] = useState("");
  const [CensusFilters, setCensusFilters] = useState({
    patientSearch: "",
    site_no: [],
    patient_status: [],
    patient_dob: undefined,
    drug_category_id: [],
    prescriber_no: [],
    sales_rep_employee_id: [],
    intake_employee_id: [],
    primary_rn_employee_id: [],
    team_id: [],
  });
  const [advancedFilterVisible, setAdvancedFilterVisible] = useState(false);
  const op = useRef<OverlayPanel>(null);

  const { write } = useUserPermission("Census", "New Patient");
  const { read } = useUserPermission("Census", "Map View");

  // Dispatch method to render the filter data
  useEffect(() => {
    dispatch(getDrugCategoryList());
    dispatch(getStatusOptionList());
    dispatch(getCaseManager());
    dispatch(getIntakeReport());
    dispatch(getSalesPerson());
    dispatch(getPrescriber());
    dispatch(getSites());
    dispatch(getStateListInsuranceListing());
    dispatch(Getteams());
  }, [dispatch]);

  useEffect(() => {
    if (statusList?.length > 0) {
      setCensusFilters((prevFilters: any) => ({
        ...prevFilters,
        patient_status: statusList?.map(
          (status: any) => status.status_description
        ),
      }));
    }
  }, [statusList]);

  // Function to Check Filter Empty
  const isFiltersEmpty = () => {
    return Object.values(CensusFilters)?.every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return value === "" || value === undefined;
    });
  };

  // Change the filter data
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setCensusFilters((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      dispatch(
        censusSearchPatient({
          updateData: {
            json: `{"patientSearch":"${CensusFilters.patientSearch}"}`,
            mail: userEmail,
          },
        })
      );
      setFilterUpdate(
        `"{\\\"patientSearch\\\":\\\"${CensusFilters.patientSearch}\\\"}"`
      );
    }
  };

  const handleCustomFilter = (e: any, op: any) => {
    op.current?.toggle(e);
    dispatch(
      GetCustomFilterList({
        updateData: JSON.stringify({
          email_address: `${userEmail}`,
          preference_name: "Census_page_filter",
          usage: "USER",
        }),
      })
    );
    // if (customFilterList.length > 0) { setShowSaveFilter(false) };
  };

  const handleSaveFilter = () => {
    const filterExists = customFilterList?.some(
      (filter: CustomFilterList) => filter.name === filterValue
    );

    if (filterExists) {
      setError("Filter already exists");
      return;
    }
    dispatch(
      AddNewCustomFilter({
        preference_name: "Census_page_filter",
        filter_name: filterValue,
        preference_json_value: customFilterJson,
        usage: "USER",
        type: 1,
        custom_preference_id: 0,
        email_address: `${userEmail}`,
      })
    ).then(() => {
      setShowSaveFilter(false); // Show table again after saving
      refreshCustomFilterList(); // Refresh the filter table after saving
    });
  };

  useEffect(() => {
    if (isTabletScreen) {
      setAdvancedFilterVisible(true);
    }
  }, [setAdvancedFilterVisible]);

  // Filter Action From Favourite Filter
  const handleFilterAction = (e: any, rowData: any) => {
    const { innerText } = e.target;

    if (innerText === "Apply Filter") {
      const data = JSON.parse(rowData.json_value);
      dispatch(
        censusSearchPatient({
          updateData: { json: rowData.json_value, mail: userEmail },
        })
      ).then(() => {
        setShowSaveFilter(false);
      });

      setCensusFilters({
        patientSearch: data.patientSearch,
        site_no: data.site_no,
        patient_status: data.patient_status,
        patient_dob: data.patient_dob,
        drug_category_id: data.drug_category_id,
        prescriber_no: data.prescriber_no,
        sales_rep_employee_id: data.sales_rep_employee_id,
        intake_employee_id: data.intake_employee_id,
        primary_rn_employee_id: data.primary_rn_employee_id,
        team_id: data.team_id,
      });
    } else {
      dispatch(
        AddNewCustomFilter({
          preference_name: "Census_page_filter",
          filter_name: rowData?.name,
          preference_json_value: JSON.stringify(rowData.json_value),
          usage: "USER",
          type: 3,
          custom_preference_id: rowData?.custom_preference_id,
          email_address: `${userEmail}`,
        })
      ).then(() => {
        refreshCustomFilterList(); // Refresh table after deleting
      });
    }
  };

  const refreshCustomFilterList = () => {
    dispatch(
      GetCustomFilterList({
        updateData: JSON.stringify({
          email_address: `${userEmail}`,
          preference_name: "Census_page_filter",
          usage: "USER",
        }),
      })
    );
  };

  const handleStatusUpdate = () => {
    onStatusUpdateLoading(false);
  };

  // Applying the filters
  const handleApplyFilter = () => {
    const result: Record<string, any> = {};
    setShowSaveFilter(true);

    for (const [field, value] of Object.entries(CensusFilters)) {
      if (Array.isArray(value)) {
        if (value?.length > 0) {
          result[field] = value; // Include non-empty arrays
        }
      } else if (
        field === "patient_dob" &&
        typeof value !== "undefined" &&
        isValidDate(value)
      ) {
        // Convert `patient_dob` from Date to a string in YYYY-MM-DD format
        const date = value as Date;
        result[field] = `${(date.getMonth() + 1)
          ?.toString()
          .padStart(2, "0")}-${date
          .getDate()
          ?.toString()
          .padStart(2, "0")}-${date.getFullYear()}`;
      } else if (value !== "" && value !== null) {
        result[field] = value; // Include non-empty and non-null values
      }
    }

    function isValidDate(value: any): value is Date {
      return value instanceof Date && !isNaN(value.getTime());
    }

    const data = JSON.stringify(result);

    setFilterUpdate(data);

    dispatch(
      censusSearchPatient({ updateData: { json: data, mail: userEmail } })
    ).then(() => {
      if (censusData.length === 0) {
        setNoData(true);
      }
    });

    setCustomFilterJson(data);
  };

  // Clear All Filters
  const handleClearFilter = () => {
    setNoData(false);
    setCensusFilters({
      patientSearch: "",
      site_no: [],
      patient_status: [],
      patient_dob: undefined,
      drug_category_id: [],
      prescriber_no: [],
      sales_rep_employee_id: [],
      intake_employee_id: [],
      primary_rn_employee_id: [],
      team_id: [],
    });
    dispatch(resetFilter());
    setShowSaveFilter(false);
  };

  const FilterActionBody = (rowData: any) => {
    return (
      <>
        <Button
          outlined
          className="p-1 text-sm"
          label="Apply Filter"
          onClick={(e) => handleFilterAction(e, rowData)}
        />
        <Button
          icon={() => <img src={Delete} alt="Filter" />}
          style={{ background: "transparent", border: "none" }}
          onClick={(e) => handleFilterAction(e, rowData)}
        />
      </>
    );
  };

  const StatusTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <span
          className={`mr-2 block patient-status ${
            option.label === "Active"
              ? "active-status"
              : option.label === "Cancelled"
              ? "cancelled-status"
              : option.label === "Inactive"
              ? "inactive-status"
              : option.label === "On Hold"
              ? "onhold-status"
              : option.label === "Pending"
              ? "pending-status"
              : ""
          }`}
        ></span>
        <div>{option.label}</div>
      </div>
    );
  };

  return (
    <>
      <div className="filters">
        <div className="lg:flex align-items-center justify-content-between lg:pr-3">
          <div className="lg:flex grid lg:flex-nowrap mx-0 align-items-center mx-0">
            <div className="md:col-4 sm:col-6 col-12 lg:col-3">
              <InputField
                placeholder="Search with MRN, F&L Name, Phone"
                globalFilterValue={CensusFilters.patientSearch}
                onGlobalFilterChange={handleChange}
                handleKeyDown={handleKeyDown}
                id="patientSearch"
              />
            </div>
            <div className="md:col-4 sm:col-6 col-12 lg:col-2">
              <MultiSelectFilter
                filterValue="site_no"
                id="site_no"
                value={CensusFilters.site_no}
                onChange={handleChange}
                label="site_name"
                data={sites}
                placeholder="All Site"
              />
            </div>
            <div className="md:col-4 sm:col-6 col-12 lg:col-2">
              <MultiSelectFilter
                filterValue="status_description"
                id="patient_status"
                value={CensusFilters.patient_status}
                onChange={handleChange}
                label="status_description"
                itemTemplate={StatusTemplate}
                data={statusList}
                placeholder="All"
              />
            </div>
            <div className="md:col-4 sm:col-6 col-12 lg:col-2">
              <Calendar
                value={CensusFilters.patient_dob}
                id="patient_dob"
                className="w-full"
                showIcon
                icon={() => <img src={Calender} alt="Calender" />}
                onChange={handleChange}
                placeholder="Select DOB"
              />
            </div>
            {!isTabletScreen && (
              <div className="md:col-4 col-12 gap-2 flex lg:col-3">
                <Button
                  label="Reset"
                  outlined
                  onClick={handleClearFilter}
                  disabled={isFiltersEmpty()}
                >
                  <Ripple
                    pt={{
                      root: { style: { background: "rgba(0, 0, 0, 0.3)" } },
                    }}
                  />
                </Button>
                <Button
                  label="Apply Filter"
                  onClick={handleApplyFilter}
                  disabled={isFiltersEmpty()}
                />
              </div>
            )}
          </div>
          {!isTabletScreen && (
            <div className="icons flex align-items-center justify-content-end">
              <div className="favourite-filter">
                {/* <CustomTooltip target=".fav-filter" position="bottom" /> */}
                <Button
                  className="p-0"
                  icon={() => (
                    <img
                      className="fav-filter"
                      src={
                        censusData.length > 0 && showSaveFilter
                          ? AddFilter
                          : Filter
                      }
                      alt="Filter"
                      title={`${
                        censusData.length > 0 && showSaveFilter
                          ? `Save Filter`
                          : `Apply Filter`
                      }`}
                    />
                  )}
                  onClick={(e) => handleCustomFilter(e, op)}
                  style={{ background: "transparent", border: "none" }}
                />
                <OverlayPanel ref={op}>
                  <div className="favourite-filers">
                    <div className="filter-title flex align-items-center justify-content-between mb-3">
                      <div>
                        {showSaveFilter ? (
                          <>
                            <div className="text-sm font-bold">Save Filter</div>
                            <p className="text-xs m-0">
                              Mark filter as favourite
                            </p>
                          </>
                        ) : (
                          <>
                            <div className="text-sm font-bold">
                              Apply filter
                            </div>
                            <p className="text-xs m-0">
                              Apply filter from your favourite
                            </p>
                          </>
                        )}
                      </div>
                      <div>
                        <img
                          src={Close}
                          alt="Close"
                          onClick={() => op.current?.hide()}
                        />
                      </div>
                    </div>

                    {showSaveFilter ? (
                      <div className="filter-name">
                        <label
                          className="text-sm font-semibold"
                          htmlFor="filter-name"
                        >
                          Filter Name
                        </label>
                        <InputText
                          className="p-2 mb-1 mt-1"
                          id="filter-name"
                          aria-describedby="username-help"
                          placeholder="Write filter name"
                          onChange={(e) => setFilterValue(e.target.value)}
                        />
                        <p className="text-red-500 text-xs mb-1">{error}</p>
                        <Button
                          label="Save Filter"
                          outlined
                          onClick={handleSaveFilter}
                        />
                      </div>
                    ) : (
                      <div className="filter-table">
                        <DataTable
                          value={customFilterList}
                          loading={filterloading}
                        >
                          <Column header="Filter Name" field="name" />
                          <Column
                            header="Actions"
                            body={(rowData) => FilterActionBody(rowData)}
                          />
                        </DataTable>
                      </div>
                    )}
                  </div>
                </OverlayPanel>
              </div>
              <div className="search-invoice">
                <Button
                  style={{ background: "transparent", border: "none" }}
                  className="p-0"
                  icon={() => (
                    <img
                      src={SearchRx}
                      alt="Search Rx"
                      title="Search Rx"
                      width={24}
                      height={24}
                    />
                  )}
                  onClick={() => setSearchInvoiceVisible(true)}
                />
              </div>
              <div className="patient-diagnosis">
                <Button
                  style={{ background: "transparent", border: "none" }}
                  className="p-0"
                  icon={() => (
                    <img
                      src={HealthInformation}
                      alt="Health Information"
                      title="Health Information"
                      width={24}
                      height={24}
                    />
                  )}
                  onClick={() => setHealthInformationVisible(true)}
                />
              </div>
              {write && (
                <div className="new-patient mr-2">
                  <Button
                    label="New Patient"
                    icon="pi pi-plus"
                    outlined
                    onClick={() => setAddNewFilterVisible(true)}
                  />
                </div>
              )}
              {read && (
                <div className="map-view">
                  <Button
                    label="Map View"
                    icon="pi pi-map"
                    outlined
                    onClick={() => setMapViewVisible(true)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="grid align-items-center mx-0">
          {advancedFilterVisible || (
            <>
              <div className="md:multiselect-filter sm:col-6 col-12 md:col-4 lg:col-2">
                <MultiSelectFilter
                  filterValue="drug_category_id"
                  id="drug_category_id"
                  value={CensusFilters.drug_category_id}
                  onChange={handleChange}
                  label="description"
                  data={drugCategoryList}
                  placeholder="Search Category"
                />
              </div>
              <div className="md:multiselect-filter sm:col-6 col-12 md:col-4 lg:col-2">
                <MultiSelectFilter
                  filterValue="prescriber_no"
                  id="prescriber_no"
                  value={CensusFilters.prescriber_no}
                  onChange={handleChange}
                  label="full_name"
                  data={prescriberList}
                  placeholder="Search Prescriber"
                  virtualScrollerOptions={{ itemSize: 25 }}
                />
              </div>
              <div className="md:multiselect-filter sm:col-6 col-12 md:col-4 lg:col-2">
                <MultiSelectFilter
                  filterValue="employee_id"
                  id="sales_rep_employee_id"
                  value={CensusFilters.sales_rep_employee_id}
                  onChange={handleChange}
                  label="full_name"
                  data={salesPersonList}
                  placeholder="Search Sales Representative"
                  virtualScrollerOptions={{ itemSize: 25 }}
                />
              </div>
              <div className="md:multiselect-filter sm:col-6 col-12 md:col-4 lg:col-2">
                <MultiSelectFilter
                  filterValue="employee_id"
                  id="intake_employee_id"
                  value={CensusFilters.intake_employee_id}
                  onChange={handleChange}
                  label="full_name"
                  data={intakeReport}
                  placeholder="Search Intake"
                />
              </div>
              <div className="md:multiselect-filter sm:col-6 col-12 md:col-4 lg:col-2">
                <MultiSelectFilter
                  filterValue="employee_id"
                  id="primary_rn_employee_id"
                  value={CensusFilters.primary_rn_employee_id}
                  onChange={handleChange}
                  label="full_name"
                  data={caseManagerList}
                  placeholder="Search Case Manager"
                />
              </div>
              <div className="md:multiselect-filter sm:col-6 col-12 md:col-4 lg:col-2">
                <MultiSelectFilter
                  filterValue="team_id"
                  id="team_id"
                  value={CensusFilters.team_id}
                  onChange={handleChange}
                  label="team_name"
                  data={teams}
                  placeholder="Search Team"
                  virtualScrollerOptions={{ itemSize: 25 }}
                />
              </div>
            </>
          )}
          {isTabletScreen && (
            <div className="lg:col-4 col-12 gap-2 flex justify-content-between align-items-center">
              <div className="flex gap-2 align-items-center">
                <Button
                  label="Reset"
                  outlined
                  onClick={handleClearFilter}
                  disabled={isFiltersEmpty()}
                >
                  <Ripple
                    pt={{
                      root: { style: { background: "rgba(0, 0, 0, 0.3)" } },
                    }}
                  />
                </Button>
                <Button
                  label="Apply Filter"
                  onClick={handleApplyFilter}
                  disabled={isFiltersEmpty()}
                />
                <Button
                  label="Advanced Filter"
                  outlined={advancedFilterVisible && true}
                  onClick={() => setAdvancedFilterVisible((prev) => !prev)}
                />
                <div className="favourite-filter">
                  {/* <CustomTooltip target=".fav-filter" position="bottom" /> */}
                  <Button
                    className="p-0"
                    icon={() => (
                      <img
                        className="fav-filter"
                        src={
                          censusData.length > 0 && showSaveFilter
                            ? AddFilter
                            : Filter
                        }
                        alt="Filter"
                        title={`${
                          censusData.length > 0 && showSaveFilter
                            ? `Save Filter`
                            : `Apply Filter`
                        }`}
                      />
                    )}
                    onClick={(e) => handleCustomFilter(e, op)}
                    style={{ background: "transparent", border: "none" }}
                  />
                  <OverlayPanel ref={op}>
                    <div className="favourite-filers">
                      <div className="filter-title flex align-items-center justify-content-between mb-3">
                        <div>
                          {showSaveFilter ? (
                            <>
                              <div className="text-sm font-bold">
                                Save Filter
                              </div>
                              <p className="text-xs m-0">
                                Mark filter as favourite
                              </p>
                            </>
                          ) : (
                            <>
                              <div className="text-sm font-bold">
                                Apply filter
                              </div>
                              <p className="text-xs m-0">
                                Apply filter from your favourite
                              </p>
                            </>
                          )}
                        </div>
                        <div>
                          <img
                            src={Close}
                            alt="Close"
                            onClick={() => op.current?.hide()}
                          />
                        </div>
                      </div>

                      {showSaveFilter ? (
                        <div className="filter-name">
                          <label
                            className="text-sm font-semibold"
                            htmlFor="filter-name"
                          >
                            Filter Name
                          </label>
                          <InputText
                            className="p-2 mb-1 mt-1"
                            id="filter-name"
                            aria-describedby="username-help"
                            placeholder="Write filter name"
                            onChange={(e) => setFilterValue(e.target.value)}
                          />
                          <p className="text-red-500 text-xs mb-1">{error}</p>
                          <Button
                            label="Save Filter"
                            outlined
                            onClick={handleSaveFilter}
                          />
                        </div>
                      ) : (
                        <div className="filter-table">
                          <DataTable
                            value={customFilterList}
                            loading={filterloading}
                          >
                            <Column header="Filter Name" field="name" />
                            <Column
                              header="Actions"
                              body={(rowData) => FilterActionBody(rowData)}
                            />
                          </DataTable>
                        </div>
                      )}
                    </div>
                  </OverlayPanel>
                </div>
              </div>
              <div>
                <Button
                  style={{ background: "transparent", border: "none" }}
                  outlined
                  className="p-0"
                  icon="pi pi-ellipsis-h"
                  title="Advanced Options"
                  onClick={() => setSidebarVisible(true)}
                />
                <Sidebar
                  position="right"
                  visible={sidebarVisible}
                  onHide={() => setSidebarVisible(false)}
                >
                  <h3>Advanced Options</h3>
                  <div className="overflow-y-auto">
                    <ul className="list-none p-0 m-0 overflow-hidden">
                      <li onClick={() => setSearchInvoiceVisible(true)}>
                        <a className="p-ripple flex align-items-center cursor-pointer py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                          <Button
                            className="p-0 border-none"
                            outlined
                            icon={() => <img src={SearchInvoice} />}
                          />
                          <span className="font-medium">Search Rx/Invoice</span>
                          <Ripple />
                        </a>
                      </li>
                      <li onClick={() => setHealthInformationVisible(true)}>
                        <a className="p-ripple flex align-items-center cursor-pointer py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                          <Button
                            className="p-0 border-none"
                            outlined
                            icon={() => <img src={HealthInformationDialog} />}
                          />
                          <span className="font-medium">
                            Health Information
                          </span>
                          <Ripple />
                        </a>
                      </li>
                      {write && (
                        <li onClick={() => setAddNewFilterVisible(true)}>
                          <a className="p-ripple flex align-items-center cursor-pointer py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                            <Button
                              className="p-0 border-none"
                              outlined
                              icon={() => <img src={AddPatient} />}
                            />
                            <span className="font-medium">Add New Patient</span>
                            <Ripple />
                          </a>
                        </li>
                      )}

                      {read && (
                        <li onClick={() => setMapViewVisible(true)}>
                          <a className="p-ripple flex align-items-center cursor-pointer py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                            <Button
                              className="p-0 border-none"
                              outlined
                              icon={() => <img src={MapView} />}
                            />
                            <span className="font-medium">Map View</span>
                            <Ripple />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </Sidebar>
              </div>
            </div>
          )}
        </div>
      </div>
      <SearchInvoiceDialogBox
        visible={searchInvoiceVisible}
        setVisible={setSearchInvoiceVisible}
      />
      <HealthInformationDialogBox
        visible={healthInformationVisible}
        setVisible={setHealthInformationVisible}
        onStatusUpdateLoading={onStatusUpdateLoading}
        onStatusUpdated={handleStatusUpdate}
      />
      <AddNewPatientDialogBox
        userEmail={userEmail}
        visible={addNewFilterVisible}
        setVisible={setAddNewFilterVisible}
        onStatusUpdateLoading={onStatusUpdateLoading}
        onStatusUpdated={handleStatusUpdate}
      />
      <MapViewDialogBox
        visible={mapViewVisible}
        setVisible={setMapViewVisible}
      />
    </>
  );
};

export default CensusFilter;
