import React from "react";
import PatientProfileInfo from "./PatientProfileCards/PatientProfileInfo";

const PatientProfile = () => {
  return (
    <div className="patient-container">
      <PatientProfileInfo />
    </div>
  );
};

export default PatientProfile;
