import * as React from "react";
import { InputText } from "primereact/inputtext";

const Input = ({
  value,
  label,
  type,
  id,
  disabled,
  labelclassName,
  inputclassName,
  handleChange,
  invalid,
  placeholder,
  required,
}: {
  disabled?: boolean;
  value?: string | number;
  label?: string;
  type: string | React.HTMLInputTypeAttribute;
  id: string;
  labelclassName?: string;
  inputclassName?: string;
  placeholder?: string;
  invalid?: boolean | string;
  required?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}): React.ReactElement => {

  const stringValue = value !== undefined ? String(value) : "";

  const inputClasses = label
    ? inputclassName
      ? inputclassName
      : "col-6"
    : "col-12";

  return (
    <>
      <div className="grid m-0 align-items-center">
        {label && (
          <div className={`${labelclassName ? `${labelclassName}` : "col-6"}`}>
            <label className="font-medium text-sm" htmlFor={id}>
              {label}{" "}
              {required ? <span className="text-red-500">*</span> : null}
            </label>
          </div>
        )}
        <div className={inputClasses}>
          <InputText
            value={stringValue}
            type={type}
            id={id}
            disabled={disabled}
            placeholder={placeholder}
            className={`p-6px ${invalid ? "invalid-field" : ""}`}
            onChange={handleChange}
          />
          <div>
            <span className="text-red-500 text-xs">{invalid}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Input;
