import React, { useEffect, useMemo, useRef, useState } from "react";
import InputField from "../formElement/InputField";
import InputRange from "../formElement/InputRange";
import {
  AddFilter,
  Close,
  Delete,
  Excel,
  Filter,
} from "../../assets/images/Icon";
import MultiSelectFilter from "../formElement/MultiSelect";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewCustomFilter,
  getArManagerData,
  getArManagerPatientCount,
  getArManagerPayorCount,
  getBillerList,
  getBillingNoteTemplate,
  getClaimNoStatusList,
  getCompanyList,
  getCprUser,
  GetCustomFilterList,
  getDrugCategoryList,
  getPatientBillingSubject,
  getPatientInvoicesID,
  getPayorList,
  getStatusList,
  getTherapyTypeList,
  resetFilter,
} from "../../redux/store/ARManagerReducer";
import { getArManagerPayorTypeList } from "../../redux/store/InsuranceReducer";
import InputNum from "../formElement/InputNumber";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomFilterList } from "../../types/IHealthAppTypes";

const ARManagerFilter = ({
  setUnappliedChecked,
  setVisible,
  userEmail,
  setFilterUpdate,
  setNoData,
}: {
  setUnappliedChecked: any;
  setVisible: any;
  userEmail: string | undefined;
  setFilterUpdate: any;
  setNoData: any;
}): React.ReactElement => {
  const {
    arManagerData,
    billerList,
    claimNoStatusList,
    statusList,
    companyList,
    payorList,
    therapyType,
    drugCategoryList,
    patientInvoiceId,
    patientBillingSubject,
    cprUser,
    customFilterList,
    patientDemographicDetails,
    blackAndWhiteFormDetails,
    filterloading,
  } = useSelector((state: RootState) => state.arManagerReducer);
  const { arManagerPayorTypeList } = useSelector(
    (state: RootState) => state.insuranceReducer
  );
  const dispatch = useDispatch<AppDispatch>();
  const [customFilterJson, setCustomFilterJson] = useState("");
  const [showSaveFilter, setShowSaveFilter] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>("");
  const [error, setError] = useState("");
  const [ARManagerFilters, setARManagerFilters] = useState({
    patientSearch: "",
    balanceLt: null,
    balanceGt: null,
    collectorIds: [],
    claimNoIds: [],
    agingLt: null,
    agingGt: null,
    last_fu_day: null,
    next_fu_day: null,
    statusIds: [],
    companyIds: [],
    payorIds: [],
    payorTypes: [],
    therapyTypeIds: [],
    drugcategory: [],
  });
  const op = useRef<OverlayPanel>(null);

  // Dispatch method to render the filter data
  useEffect(() => {
    dispatch(getBillerList());
    dispatch(getClaimNoStatusList());
    dispatch(getStatusList());
    dispatch(getCompanyList());
    dispatch(getPayorList());
    dispatch(getBillingNoteTemplate());
    dispatch(getArManagerPayorTypeList());
    dispatch(getTherapyTypeList());
    dispatch(getDrugCategoryList());
    dispatch(getPatientInvoicesID());
    dispatch(getPatientBillingSubject());
    dispatch(getCprUser());
  }, [dispatch]);

  // Function to Check Filter Empty
  const isFiltersEmpty = () => {
    return Object.values(ARManagerFilters).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return value === "" || value === null;
    });
  };

  // Change the filter data
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setARManagerFilters((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      dispatch(
        getArManagerData({
          updateData: `"{\\\"patientSearch\\\":\\\"${ARManagerFilters.patientSearch}\\\"}"`,
        })
      ).then(() => {
        if (arManagerData.length === 0) {
          setNoData(true);
        }
      });
      dispatch(
        getArManagerPatientCount({
          updateData: `"{\\\"patientSearch\\\":\\\"${ARManagerFilters.patientSearch}\\\"}"`,
        })
      );
      dispatch(
        getArManagerPayorCount({
          updateData: `"{\\\"patientSearch\\\":\\\"${ARManagerFilters.patientSearch}\\\"}"`,
        })
      );
      setFilterUpdate(
        `"{\\\"patientSearch\\\":\\\"${ARManagerFilters.patientSearch}\\\"}"`
      );
    }
  };

  const handleCustomFilter = (e: any, op: any) => {
    op.current?.toggle(e);
    dispatch(
      GetCustomFilterList({
        updateData: JSON.stringify({
          email_address: `${userEmail}`,
          preference_name: "AR_page_filter",
          usage: "USER",
        }),
      })
    );
    // if (customFilterList.length > 0) { setShowSaveFilter(false) };
  };

  const handleSaveFilter = () => {
    const filterExists = customFilterList.some(
      (filter: CustomFilterList) => filter.name === filterValue
    );

    if (filterExists) {
      setError("Filter already exists");
      return;
    }

    if (filterValue) {
      dispatch(
        AddNewCustomFilter({
          preference_name: "AR_page_filter",
          filter_name: filterValue,
          preference_json_value: customFilterJson,
          usage: "USER",
          type: 1,
          custom_preference_id: 0,
          email_address: `${userEmail}`,
        })
      ).then(() => {
        setShowSaveFilter(false); // Show table again after saving
        refreshCustomFilterList(); // Refresh the filter table after saving
      });
    }
  };

  // Filter Action From Favourite Filter
  const handleFilterAction = (e: any, rowData: any) => {
    const { innerText } = e.target;

    if (innerText === "Apply Filter") {
      const data = JSON.parse(rowData.json_value);

      dispatch(
        getArManagerData({ updateData: JSON.stringify(rowData.json_value) })
      ).then(() => {
        setShowSaveFilter(false);
      });

      setARManagerFilters({
        patientSearch: data.patientSearch,
        balanceLt: data.balanceLt,
        balanceGt: data.balanceGt,
        collectorIds: data.collectorIds,
        claimNoIds: data.claimNoIds,
        agingLt: data.agingLt,
        agingGt: data.agingGt,
        last_fu_day: data.last_fu_day,
        next_fu_day: data.next_fu_day,
        statusIds: data.statusIds,
        companyIds: data.companyIds,
        payorIds: data.payorIds,
        payorTypes: data.payorTypes,
        therapyTypeIds: data.therapyTypeIds,
        drugcategory: data.drugcategory,
      });
    } else {
      dispatch(
        AddNewCustomFilter({
          preference_name: "AR_page_filter",
          filter_name: rowData?.name,
          preference_json_value: JSON.stringify(rowData.json_value),
          usage: "USER",
          type: 3,
          custom_preference_id: rowData?.custom_preference_id,
          email_address: `${userEmail}`,
        })
      ).then(() => {
        refreshCustomFilterList(); // Refresh table after deleting
      });
    }
  };

  const refreshCustomFilterList = () => {
    dispatch(
      GetCustomFilterList({
        updateData: JSON.stringify({
          email_address: `${userEmail}`,
          preference_name: "AR_page_filter",
          usage: "USER",
        }),
      })
    );
  };

  // Applying the filters
  const handleApplyFilter = () => {
    const result: Record<string, any> = {};
    setShowSaveFilter(true);

    const fieldMapping: Record<string, (item: any) => any> = {
      collectorIds: (item: { employee_id: number }) => item.employee_id,
      statusIds: (item: { delivery_status_id: number }) =>
        item.delivery_status_id,
      claimNoIds: (item: { id: number }) => item.id,
      companyIds: (item: { company_id: number }) => item.company_id,
      payorIds: (item: { company_id: number }) => item.company_id,
      payorTypes: (item: { demographic_value: string }) =>
        item.demographic_value,
      therapyTypeIds: (item: { therapy_type_id: number }) =>
        item.therapy_type_id,
      drugcategory: (item: { description: string }) => item.description,
    };

    for (const [field, value] of Object.entries(ARManagerFilters)) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          // if (fieldMapping[field]) {
          // result[field] = value.map(fieldMapping[field]);
          // } else {
          result[field] = value;
          // }
        }
      } else if (value !== "" && value !== null) {
        result[field] = value;
      }
    }

    const data = JSON.stringify(result);
    const newData = `\"${data.replace(/"/g, '\\"')}\"`;

    setFilterUpdate(newData);
    dispatch(getArManagerData({ updateData: newData })).then(() => {
      if (arManagerData.length === 0) {
        setNoData(true);
      }
    });
    dispatch(getArManagerPatientCount({ updateData: newData }));
    dispatch(getArManagerPayorCount({ updateData: newData }));
    setCustomFilterJson(data);
  };

  // Clear All Filters
  const handleClearFilter = () => {
    setUnappliedChecked(false);
    setNoData(false);
    setARManagerFilters({
      patientSearch: "",
      balanceLt: null,
      balanceGt: null,
      collectorIds: [],
      claimNoIds: [],
      agingLt: null,
      agingGt: null,
      last_fu_day: null,
      next_fu_day: null,
      statusIds: [],
      companyIds: [],
      payorIds: [],
      payorTypes: [],
      therapyTypeIds: [],
      drugcategory: [],
    });
    dispatch(resetFilter());
    setShowSaveFilter(false);
  };

  //   const newBillerList = useMemo(() => {
  //     return billerList.map((bill: billerList) => {
  //         return {
  //             value: bill.employee_id,
  //             first_name: bill.first_name,
  //         }
  //     })
  //   }, [billerList]);

  const FilterActionBody = (rowData: any) => {
    return (
      <>
        <Button
          outlined
          className="p-1 text-sm"
          label="Apply Filter"
          onClick={(e) => handleFilterAction(e, rowData)}
        />
        <Button
          icon={() => <img src={Delete} alt="Filter" />}
          style={{ background: "transparent", border: "none" }}
          onClick={(e) => handleFilterAction(e, rowData)}
        />
      </>
    );
  };

  return (
    <>
      <div className="filters">
        <div className="flex justify-content-between align-items-center pr-3">
          <div className="grid align-items-center mx-0">
            <div className="col-6">
              <InputField
                placeholder="Search Patient Name, MRN, Invoice Number"
                globalFilterValue={ARManagerFilters.patientSearch}
                onGlobalFilterChange={handleChange}
                handleKeyDown={handleKeyDown}
                id="patientSearch"
              />
            </div>
            <div className="col-6 custom-padding">
              <InputRange
                from={ARManagerFilters.balanceGt}
                to={ARManagerFilters.balanceLt}
                onChange={handleChange}
                label="Balance Range"
                Fromid="balanceGt"
                Toid="balanceLt"
                className="col-3"
              />
            </div>
          </div>
          <div className="icons flex align-items-center">
            <div className="favourite-filter">
              <Button
                className="p-0"
                icon={() => (
                  <img
                    src={
                      arManagerData.length > 0 && showSaveFilter
                        ? AddFilter
                        : Filter
                    }
                    alt="Filter"
                    title={`${
                      arManagerData.length > 0 && showSaveFilter
                        ? `Save Filter`
                        : `Apply Filter from the Preferences`
                    }`}
                  />
                )}
                onClick={(e) => handleCustomFilter(e, op)}
                style={{ background: "transparent", border: "none" }}
              />
              <OverlayPanel ref={op}>
                <div className="favourite-filers">
                  <div className="filter-title flex align-items-center justify-content-between mb-3">
                    <div>
                      {showSaveFilter ? (
                        <>
                          <div className="text-sm font-bold">Save Filter</div>
                          <p className="text-xs m-0">Mark filter as favorite</p>
                        </>
                      ) : (
                        <>
                          <div className="text-sm font-bold">Apply Filter</div>
                          <p className="text-xs m-0">
                            Apply filter from your favorite
                          </p>
                        </>
                      )}
                    </div>
                    <div>
                      <img
                        src={Close}
                        alt="Close"
                        onClick={() => op.current?.hide()}
                      />
                    </div>
                  </div>

                  {showSaveFilter ? (
                    <div className="filter-name">
                      <label
                        className="text-sm font-semibold"
                        htmlFor="filter-name"
                      >
                        Filter Name
                      </label>
                      <InputText
                        className="p-2 mb-1 mt-1"
                        id="filter-name"
                        aria-describedby="username-help"
                        placeholder="Write filter name"
                        onChange={(e) => setFilterValue(e.target.value)}
                      />
                      <p className="text-red-500 text-xs mb-1">{error}</p>
                      <Button
                        label="Save Filter"
                        outlined
                        onClick={handleSaveFilter}
                      />
                    </div>
                  ) : (
                    <div className="filter-table">
                      <DataTable
                        value={customFilterList}
                        loading={filterloading}
                      >
                        <Column header="Filter Name" field="name" />
                        <Column
                          header="Actions"
                          body={(rowData) => FilterActionBody(rowData)}
                        />
                      </DataTable>
                    </div>
                  )}
                </div>
              </OverlayPanel>
            </div>
            <div className="excel" title="Export Excel">
              <Button
                disabled={arManagerData.length === 0}
                style={{ background: "transparent", border: "none" }}
                className="p-0"
                icon={() => <img src={Excel} alt="Excel" />}
                onClick={() => setVisible(true)}
              />
            </div>
          </div>
        </div>
        <div className="grid align-items-center mx-0">
          <div className="col-2">
            <MultiSelectFilter
              filterValue="employee_id"
              id="collectorIds"
              value={ARManagerFilters.collectorIds}
              onChange={handleChange}
              label="full_name"
              data={billerList}
              placeholder="Search Collector"
            />
          </div>
          <div className="col-2">
            <MultiSelectFilter
              filterValue="id"
              id="claimNoIds"
              value={ARManagerFilters.claimNoIds}
              onChange={handleChange}
              label="description"
              data={claimNoStatusList}
              placeholder="Search Insurance Type"
            />
          </div>
          <div className="col-2 custom-padding">
            <InputRange
              from={ARManagerFilters.agingGt}
              to={ARManagerFilters.agingLt}
              onChange={handleChange}
              label="A/R Aging"
              Fromid="agingGt"
              Toid="agingLt"
              className="col-3"
            />
          </div>
          <div className="col-2 custom-padding">
            <InputNum
              value={ARManagerFilters.last_fu_day}
              label="Last Follow-up Days"
              id="last_fu_day"
              labelclassName="col-5"
              inputClassName="col-7"
              onChange={handleChange}
              maxLength={6}
            />
          </div>
          <div className="col-2 custom-padding">
            <InputNum
              value={ARManagerFilters.next_fu_day}
              label="Next Follow-up Days"
              id="next_fu_day"
              labelclassName="col-5"
              inputClassName="col-7"
              onChange={handleChange}
              maxLength={6}
            />
          </div>
        </div>
        <div className="grid align-items-center mx-0">
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="delivery_status_id"
              id="statusIds"
              value={ARManagerFilters.statusIds}
              onChange={handleChange}
              label="status_code_and_description"
              data={statusList}
              placeholder="Search Status Code, Name"
              virtualScrollerOptions={{ itemSize: 25}}
            />
          </div>
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="company_id"
              id="companyIds"
              value={ARManagerFilters.companyIds}
              onChange={handleChange}
              label="company_name"
              data={companyList}
              placeholder="Search Company Name"
            />
          </div>
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="company_id"
              id="payorIds"
              value={ARManagerFilters.payorIds}
              onChange={handleChange}
              label="company_name"
              data={payorList}
              placeholder="Search Payor"
              virtualScrollerOptions={{ itemSize: 25}}
            />
          </div>
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="demographic_value"
              id="payorTypes"
              value={ARManagerFilters.payorTypes}
              onChange={handleChange}
              label="demographic_value"
              data={arManagerPayorTypeList}
              placeholder="Search Payor Type"
            />
          </div>
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="therapy_type_id"
              id="therapyTypeIds"
              value={ARManagerFilters.therapyTypeIds}
              onChange={handleChange}
              label="description"
              data={therapyType}
              placeholder="Search Therapy Type"
            />
          </div>
          <div className="multiselect-filter col-2">
            <MultiSelectFilter
              filterValue="description"
              id="drugcategory"
              value={ARManagerFilters.drugcategory}
              onChange={handleChange}
              label="description"
              data={drugCategoryList}
              placeholder="Search Category"
            />
          </div>
          <div className="col-2 gap-2 flex">
            <Button label="Reset" outlined onClick={handleClearFilter} />
            <Button
              label="Apply Filter"
              onClick={handleApplyFilter}
              disabled={isFiltersEmpty()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ARManagerFilter;
