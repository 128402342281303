import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { stringify } from "querystring";
import { json } from "stream/consumers";

const baseUrl = process.env.REACT_APP_BASEURL;



// GET Method For Category list
export const getCategoryList = createAsyncThunk(
    "prescriberworkflow/getCategoryList",
    async () => {
      try {
        const endpoint = `${baseUrl}/api/ToDo/GetCategory`;
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data;
        return data;
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    }
  );


  // GET Method For Team List
export const getTeamList = createAsyncThunk(
    "prescriberworkflow/getTeamList",
    async () => {
      try {
        const endpoint = `${baseUrl}/api/ToDo/GetTeams`;
        const response = await axios.get(endpoint);
        if (!response.statusText) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.data;
        return data;
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    }
  );

 // GET Method For Sent Via List
 export const getSentViaList = createAsyncThunk(
  "prescriberworkflow/getSentViaList",
  async () => {
    try {
      const endpoint = `${baseUrl}/api/PrescriberOrder/GetDwoSentViaList`;
      const response = await axios.get(endpoint);
      if (!response.statusText) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);



// POST Method For get Prescribersworkflow data
export const getPrescriberWorkflowdata = createAsyncThunk(
    "prescriberworkflow/getPrescriberWorkflowdata",
    async ({ ApplyData }: { ApplyData: any }, apiThunk) => {
        try {
    
            const endpoint = `${baseUrl}/api/PrescriberOrder/getFreeFormDWOStatus`;
            const response = await axios.post(endpoint, ApplyData, {
                headers: {
                  Accept: "application/json;odata=verbose",
                  "Content-Type": "application/json;odata=verbose",
                },
              });

            
         
            if (!response.statusText) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response?.data;
            return data;
        } catch (error) {
            console.error("Error fetching user details", error);
        }
    }
);




export const PrescriberWorkflowSlice = createSlice({
    name: 'PrescriberWorkflow',
    initialState: {
        PrescriberWorkflowData: [],
        CategoryList:[],
        TeamList:[],
        SentViaList:[],
        errorMessage: "",
        loading: false,
        filterloading: false
    },
    reducers: {
        resetFilter: (state) => {
            state.PrescriberWorkflowData = []
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getCategoryList.pending, (state, action) => {
            state.filterloading = true;
          })
          .addCase(getCategoryList.fulfilled, (state, action) => {
            state.CategoryList = action.payload?.data;
            state.filterloading = false;
          })
          .addCase(getCategoryList.rejected, (state, action) => {
            state.errorMessage = action.error.message || "";
            state.filterloading = false;
          })
          .addCase(getTeamList.pending, (state, action) => {
            state.filterloading = true;
          })
          .addCase(getTeamList.fulfilled, (state, action) => {
            state.TeamList = action.payload?.data;
            state.filterloading = false;
          })
          .addCase(getTeamList.rejected, (state, action) => {
            state.errorMessage = action.error.message || "";
            state.filterloading = false;
          })
          .addCase(getSentViaList.pending, (state, action) => {
            state.filterloading = true;
          })
          .addCase(getSentViaList.fulfilled, (state, action) => {
            state.SentViaList = action.payload?.data;
            state.filterloading = false;
          })
          .addCase(getSentViaList.rejected, (state, action) => {
            state.errorMessage = action.error.message || "";
            state.filterloading = false;
          })
            
    }
})

;
export const { resetFilter } = PrescriberWorkflowSlice.actions
export default PrescriberWorkflowSlice.reducer
