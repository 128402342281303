import React from 'react'
import { SortDecre, SortIncre } from '../../../assets/images/Icon';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import Loader from '../../loader/Loader';

const HistoryPopupforPrescribersWorkflow = ({
    visible,
    setVisible,
    Treatmentid
  }: {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    Treatmentid:any
  }) => {

//for get the data
    // const { HistoryDataFromAPIPASS, historyLoading } = useSelector(
    //     (state: RootState) => state.shipmentReducer
    //   );

    const sortIcon = (order: any) => {
        if (order.sortOrder === 1) {
          // Ascending
          return <img className="ml-1" src={SortIncre} alt="Increase" />;
        } else if (order.sortOrder === -1) {
          // Descending
          return <img className="ml-1" src={SortDecre} alt="Decrease" />;
        }
        return null;
      };
    

 // Columns
 const columns = [
    { field: "date", header: "Created by", sortable: true },
    { field: "derivedStatus", header: "Created date", sortable: true },
    { field: "eventDescription", header: "Log Data", sortable: true },
    
  ];






  return (
    <div>
    <Dialog
      style={{ width: "50%", height: "40%" }}
      draggable
      header="Prescribers Order History"
      //   footer={Footer}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      {false ? (
        <div style={{height: "60vh"}}>
          <Loader label="History Data" path="" location="" />
        </div>
      ) : (
        <DataTable
          // filteredStatuslist={filteredStatuslist}
          value={[]}
          scrollable
          removableSort
          scrollHeight="645px"
          // paginator
          // style={{ fontSize: "0.1rem !important" }}
          sortIcon={sortIcon}
          // onRowClick={}
          // selectionMode="single"
          // rowsPerPageOptions={pageOptions}
          // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rows={25}
          // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          // filters={filters}
          // loading={loading}
          globalFilterFields={columns.map((column) => column.field)}
          emptyMessage="No Data Found"
        >
          <Column headerStyle={{ paddingLeft: "3px" }}></Column>

          {columns.map((column, index) => (
            <Column
              key={index}
              field={column.field}
              header={column.header}
              // body={column.body}
              sortable={column.sortable}
              // bodyStyle={{ fontSize: "0.1rem !important" }} // Inline style for specific column
            />
          ))}
        </DataTable>
      )}
    </Dialog>
  </div>
  )
}

export default HistoryPopupforPrescribersWorkflow
