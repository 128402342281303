import React, { useEffect, useState } from "react";
import { DataTable, DataTableSelectEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { SortDecre, SortIncre } from "../../assets/images/Icon";
import UpdateOwnerNameDialogBox from "../Dialog/UpdateOwnerNameDialogBox";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const FaxDirectoryDataTable = ({
  data,
  filters,
  onStatusUpdateLoading,
  name,
  userEmail,
  filterUpdate,
  search_string,
}: {
  data: any;
  filters: any;
  name: string | undefined;
  userEmail?: string;
  search_string: string;
  onStatusUpdateLoading: (isLoading: boolean) => void;
  filterUpdate: string;
}) => {
    const pageOptions = [5, 10, 25, 50, 75, 100, 125];

  const [visible, setVisible] = useState(false);
  const [faxNumber, setFaxNumber] = useState("");
  const [faxRecordid, setRecordid] = useState(0);


  const sortIcon = (order: any) => {
    if (order.sortOrder === 1) {
      // Ascending
      return <img className="ml-1" src={SortIncre} alt="Increase" />;
    } else if (order.sortOrder === -1) {
      // Descending
      return <img className="ml-1" src={SortDecre} alt="Decrease" />;
    }
    return null;
  };
  
  


  // Columns
  const columns = [
    { field: "id", header: "Fax Directory Owner ID", sortable: true },
    { field: "fax_number", header: "Fax Number", sortable: true },
    { field: "fax_owner_name", header: "Fax Owner Name", sortable: true },
    { field: "fax_dir_owner_id", header: "ID", sortable: true },
    { field: "owner_type", header: "Owner Type", sortable: true },
    { field: "owner_unique_id", header: "Owner Unique Id", sortable: true },
  ];

  const onRowSelect = (data: DataTableSelectEvent) => {
    setFaxNumber(data.data.fax_number);
    setRecordid(data.data.id);
    setVisible(true);
  };

  return (
    <>
      <DataTable
        value={data}
        scrollable
        removableSort
        scrollHeight="645px"
        paginator
        sortIcon={sortIcon}
        onRowSelect={onRowSelect}
        selectionMode="single"
        rowsPerPageOptions={pageOptions}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rows={25}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        filters={filters}
        // loading={loading}
        globalFilterFields={columns.map((column) => column.field)}
        emptyMessage="No Data Found"
      >
        <Column headerStyle={{ width: "3rem" }}></Column>
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            sortable={column.sortable}
          />
        ))}
      </DataTable>
      <UpdateOwnerNameDialogBox search_string={search_string} userEmail={userEmail} faxRecordid={faxRecordid} faxNumber={faxNumber} visible={visible} setVisible={setVisible} />
    </>
  );
};

export default FaxDirectoryDataTable;
